import { AmountDataHolder } from './amountDataHolder';
import {DocumentStatus, ExtendedDocument} from './DocumentsTypes/Document';
import {dateObjectToString, roundDecimals} from '../fromKotlin/nkutils';


export interface CartesetDataHolder {
    id: number;
    doc: ExtendedDocument;
    status: DocumentStatus;
    name: string;
    agent: string;
    date: Date;
    dateIssue: Date;
    amount_tn: number;
    value_tn: number;
    open_tn: number;
    amount_pay: number;
    value_pay: number;
    debt: number;
    type: string;
    yearly?: number;
    comment?:string;
    notes5?:string;
    cname:string
}


export const buildDebts = (carteset: CartesetDataHolder[],startDate:Date): CartesetDataHolder[] => {
    let s = 0

    const carteset_midterm = carteset.filter((s)=>s.yearly==1).sort((a, b) => a.date==b.date? (a.doc.type ==b.doc.type ? (a.id-b.id):(a.doc.nkObject.type.getConnectedType()-b.doc.nkObject.getConnectedType()))  :a.date.getTime()-b.date.getTime())

    const carteset_midterm_real = carteset.filter((s)=>!s.yearly)
    carteset_midterm.forEach((c)=>{
      if(dateObjectToString(c.date)==dateObjectToString(startDate)){
          carteset_midterm_real.push(c)
      }
    })

    carteset_midterm_real.sort((a, b) => a.date==b.date? (a.doc.type ==b.doc.type ? (a.id-b.id):(a.doc.nkObject.type.getConnectedType()-b.doc.nkObject.getConnectedType()))  :a.date.getTime()-b.date.getTime())

    carteset_midterm_real.forEach((c)=>{
        c.debt = roundDecimals(s + c.value_tn - c.value_pay,2)
        s = c.debt
    })

    carteset_midterm_real.reverse()

    return carteset_midterm_real;
};
