import React, { useEffect } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    Tab,
    Tabs,
} from "@mui/material";
import { getFile } from "api/customer/files.api";
import { buildQuerySum, dailyBuildQuerySum } from "api/customer/documents.api";
import Product from "types/product";
import { Search } from "@mui/icons-material";
import { pdf } from "assets/images";
import JSDB from "../../fromKotlin/nk";

const ProductsToExportDialog = ({ open, setOpen, selectedCustomers, date, detailed }) => {
    const [selectedProductes, setSelectedProductes] = React.useState<any[]>([]);
    const [checkAll, setCheckAll] = React.useState(false);
    const [seperateProducts, setSeperateProducts] = React.useState(false);
    const [diff, setDiff] = React.useState(false);
    const [checkedCategories, setCheckedCategories] = React.useState<any[]>([]);
    const [checkedCategories2, setCheckedCategories2] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);
    const [categories2, setCategories2] = React.useState<any[]>([]);
    const [fullProductsArray, setFullProductArray] = React.useState<any[]>();
    const [productsArray, setProductsArray] = React.useState<any[]>();
    const [externalIdSearch, setExternalIdSearch] = React.useState(
        parseInt(localStorage.getItem("externalIdOrders") ?? "0") === 1
    );
    const [activeTab, setActiveTab] = React.useState<"products" | "categories" | "categories2">("products");

    const [activeGroupTab, setActiveGroupTab] = React.useState<"category" | "category2" | "notes2" | "notes3" | "notes4" | "notes5">("category");
    const [groupValues, setGroupValues] = React.useState<any[]>([]);
    const [checkedGroups, setCheckedGroups] = React.useState<any[]>([]);

    useEffect(() => {
        const productsMap = {};
        JSDB().getAllClientProduct(true)
            .first.toArray()
            .forEach((product) => {
                productsMap[`p_${product.id}`] = product;
            });

        const productsList = new Set(
            selectedCustomers?.map((customer) => {
                const customerProducts = Object.keys(customer).filter((key) => productsMap[key]);
                return customerProducts.map((key) => productsMap[key]);
            })?.flat()
        );

        const productsToArr = Array.from(productsList);
        setProductsArray(productsToArr);
        setFullProductArray(JSDB().getAllClientProduct(true).first.toArray());
        const categories = new Set(productsToArr?.map((product: any) => product?.category));
        const categories2 = new Set(productsToArr?.map((product: any) => product?.category2));
        setCategories(Array.from(categories).filter((category) => category !== ""));
        setCategories2(Array.from(categories2).filter((category) => category !== ""));
    }, [open]);

    useEffect(() => {
        const groupSet = new Set(selectedCustomers.map((customer) => customer[activeGroupTab]??''));
        setGroupValues(Array.from(groupSet));
    }, [activeGroupTab, selectedCustomers]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="select-products-to-export-dialog-title"
            aria-describedby="select-products-to-export-dialog-description"
            fullWidth
            maxWidth="md"
            PaperProps={{
                style: {
                    height: "70vh",
                },
            }}
        >
            <DialogTitle
                id="select-products-to-export-dialog-title"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                בחר מוצרים וקבוצות לקוח לייצוא
            </DialogTitle>
            <DialogContent style={{ display: "flex", height: "100%" }}>
                {/* Left side: Customer group tabs */}
                <div style={{ minWidth: "200px", borderRight: "1px solid #ddd", paddingRight: "10px" }}>
                    <Tabs
                        orientation="vertical"
                        value={activeGroupTab}
                        onChange={(event, newValue) => setActiveGroupTab(newValue)}
                        style={{ marginBottom: "10px" }}
                    >
                        <Tab label="קטגוריה" value="category" />
                        <Tab label="קבוצה" value="category2" />
                        <Tab label={JSDB().getUser().first?.getJsonDesc("notes2")?.name??'הערות 2'} value="notes2" />
                        <Tab label={JSDB().getUser().first?.getJsonDesc("notes3")?.name ?? 'הערות 3'} value="notes3" />
                        <Tab label={JSDB().getUser().first?.getJsonDesc("notes4")?.name??'הערות 4'} value="notes4" />
                        <Tab label={JSDB().getUser().first?.getJsonDesc("notes5")?.name??'הערות 5'} value="notes5" />
                    </Tabs>
                    <ul style={{
                        listStyle: "none",
                        padding: 0,
                        marginTop: "10px",
                        overflowY: "auto",
                        maxHeight: "calc(70vh - 120px)",
                    }}>
                        {groupValues.map((group) => (
                            <li key={group}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedGroups.includes(group)}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setCheckedGroups([...checkedGroups, group]);
                                                } else {
                                                    setCheckedGroups(checkedGroups.filter((g) => g !== group));
                                                }
                                            }}
                                        />
                                    }
                                    label={group}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Right side: Products */}
                <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                        <Tab label="מוצרים" value="products" />
                        <Tab label="קטגוריות" value="categories" />
                        <Tab label="קבוצות" value="categories2" />
                    </Tabs>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <FormControl variant="standard" sx={{
                            marginBottom: "10px",
                            width: "120px",
                        }}>
                            <InputLabel htmlFor="select-products-to-export-dialog-input">
                                חיפוש
                            </InputLabel>
                            <Input
                                id="select-products-to-export-dialog-input"
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (value) {
                                        if(activeTab=== "categories") {
                                            const categories = new Set(fullProductsArray?.map((product: any) => product?.category));
                                            setCategories(Array.from(categories).filter((category) => category !== "" && category.includes(value)));

                                        } else if(activeTab=== "categories2") {
                                            const categories2 = new Set(fullProductsArray?.map((product: any) => product?.category2));
                                            setCategories2(Array.from(categories2).filter((category) => category !== "" && category.includes(value)));
                                        } else {
                                            const filteredProducts = fullProductsArray?.filter((product) => product?.name.includes(value) || externalIdSearch && product?.external_id?.includes(value));
                                            setProductsArray(filteredProducts);
                                        }

                                    }
                                    if (!value || value === "") setProductsArray(fullProductsArray);
                                }}
                                startAdornment={
                                    <>
                                        <InputAdornment position="start">
                                            <Search/>
                                        </InputAdornment>
                                    </>
                                }
                            />
                        </FormControl>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={checkAll} onChange={(event) => {
                                if (event.target.checked) {
                                    setCheckAll(true);
                                            const allProducts = JSDB().getAllClientProduct(true).first.toArray();
                                            setSelectedProductes(allProducts);
                                    setCheckedCategories(categories);
                                    setCheckedCategories2(categories2);
                                } else {
                                    setCheckAll(false);
                                    setSelectedProductes([]);
                                    setCheckedCategories([]);
                                    setCheckedCategories2([]);
                                }
                            }}/>}
                                              label={"בחר הכל"}
                            />

                        </FormGroup>
                    </div>
                    <Grid container spacing={2} sx={{ marginTop: "10px", flexDirection: "column", padding: "0 20px" }}>
                        {/* Existing product logic goes here */}

                        <ul style={{
                            listStyle: "none",
                            padding: "0",
                        }}>
                            {activeTab === "products"
                                ? productsArray?.map((product: Product) => (
                                    <li key={product?.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedProductes?.find((p) => p.id === product.id)}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setSelectedProductes([...selectedProductes, product]);
                                                        } else {
                                                            setSelectedProductes(selectedProductes?.filter((p) => p.id !== product?.id));
                                                        }
                                                    }}
                                                />
                                            }
                                            label={`${product?.name} ${(externalIdSearch) ? product?.external_id : ''}`}
                                        />
                                    </li>
                                ))
                                : activeTab === "categories"
                                    ? categories?.map((category) => (
                                        <li key={category}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedCategories?.indexOf(category) !== -1}
                                                        onChange={(event) => {
                                                            const allProducts = JSDB().getAllClientProduct(true).first.toArray();
                                                            if (event.target.checked) {
                                                                setSelectedProductes([...selectedProductes, ...allProducts?.filter((product) => product?.category === category)]);
                                                                setCheckedCategories([...checkedCategories, category]);
                                                            } else {
                                                                setSelectedProductes(selectedProductes?.filter((p) => !allProducts?.filter((product) => product?.category === category)?.map((product) => product?.id)?.includes(p.id)));
                                                                setCheckedCategories(checkedCategories?.filter((cat) => cat !== category));
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={category}
                                            />
                                        </li>
                                    ))
                                    : activeTab === "categories2"
                                        ? categories2?.map((category) => (
                                            <li key={category}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkedCategories2?.indexOf(category) !== -1}
                                                            onChange={(event) => {
                                                                const allProducts = JSDB().getAllClientProduct(true).first.toArray();
                                                                if (event.target.checked) {
                                                                    setSelectedProductes([...selectedProductes, ...allProducts?.filter((product) => product?.category2 === category)]);
                                                                    setCheckedCategories2([...checkedCategories2, category]);
                                                                } else {
                                                                    setSelectedProductes(selectedProductes?.filter((p) => !allProducts?.filter((product) => product?.category2 === category)?.map((product) => product?.id)?.includes(p.id)));
                                                                    setCheckedCategories2(checkedCategories2?.filter((cat) => cat !== category));
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={category}
                                                />
                                            </li>
                                        ))
                                        : null}
                        </ul>
                    </Grid>

                </div>
            </DialogContent>
            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                    color="error"
                    variant="contained"
                >
                    סגור
                </Button>
                <div style={{
                    display: "flex",
                    gap: "20px",
                }}>
                    <Button
                        onClick={() => {
                            const ids: any = [];
                            const title_holder = new Set();
                            const cGroupsValues = new Set(checkedGroups)
                            selectedCustomers.forEach((data) => {
                                // if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                                const cFilter = cGroupsValues.size==0 || cGroupsValues.has(data[activeGroupTab])
                                if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != '')) && cFilter ) ids.push(data?.orderId);
                                if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))&& cFilter) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                            })

                            const curPdf = ids.map((u) => Number(u));
                            let title = Array.from(title_holder).join(',');
                            let groupType = 1
                            let seperate=undefined
                            if (curPdf.length > 0) {
                                let name = ''
                                if (checkedCategories2.length > 0) {
                                    groupType=4
                                    name = checkedCategories2.join(',')
                                    if(seperateProducts)
                                        seperate=2
                                } else if (checkedCategories.length > 0) {
                                    groupType=3
                                    name = checkedCategories.join(',')
                                    if(seperateProducts)
                                        seperate=1
                                }
                                if (detailed)
                                    getFile(false, () => dailyBuildQuerySum(curPdf, date, groupType, undefined, 2, selectedProductes.map((p)=>p.id),name,seperate,(diff)? 2 : 1), `סיכום מפורט ${name}`, "application/pdf");
                                else
                                    getFile(false, () => buildQuerySum(curPdf, date, date, name, false, true,  selectedProductes.map((p)=>p.id),seperate,(diff)? 2 : 1), `סיכום הזמנות ${name}`, "application/pdf");

                            }

                            close();
                            setOpen(false);
                        }}
                        variant="outlined"
                        style={{
                            color: "#3f51b5",
                            border: "1px solid #3f51b5",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <img src={pdf} style={{width: '25px', height: '25px', marginRight: "5px"}}/>
                        ייצא ל pdf
                    </Button>
                    <FormControlLabel control={<Checkbox checked={seperateProducts} onChange={(event) => {
                        if (event.target.checked) {
                            setSeperateProducts(true);

                        } else {
                            setSeperateProducts(false);
                        }
                    }}/>}
                                      label={"הפרד מוצרים בדוח"}
                    />
                    <FormControlLabel control={<Checkbox checked={diff} onChange={(event) => {
                        if (event.target.checked) {
                            setDiff(true);

                        } else {
                            setDiff(false);
                        }
                    }}/>}
                                      label={"פערים"}
                    />
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ProductsToExportDialog;
