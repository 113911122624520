import * as React from 'react';
import {Avatar, Box, Dialog, IconButton, TableCell, TextField, Tooltip, Typography} from '@mui/material';
import {format} from 'date-fns';
import {enUS, he} from 'date-fns/locale';
import {FormattedMessage} from 'react-intl';
import config from 'config';
import MyChip from 'ui-component/extended/Chip';
import MasterTable from 'ui-component/master-table/MasterTable';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import DocumentPreview from './DocumentPreview';
import PrintIcon from '@mui/icons-material/PrintTwoTone';
import FunctionsIcon from '@mui/icons-material/Functions';
import {HeadCell} from 'types';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {documentStatuses, ExtendedDocument} from 'types/DocumentsTypes/Document';
import {IconArchive, IconCurrencyShekel, IconInfoCircle} from '@tabler/icons';
import {pdf} from 'assets/images';
import {getFile} from '../../../../api/customer/files.api';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import JSDB from '../../../../fromKotlin/nk';
import {number_style} from '../../../../styles/tablecell';
import {selectiveDistinct} from '../../../../utils/sort';
import {
    buildNotesSum,
    buildSupNotesSum,
    getDocumentPhoto,
    getDocumentSign,
    getMassDocumentPdf,
    getNameOfDoc
} from '../../../../api/customer/documents.api';
import {ClosedCaption, Person} from '@mui/icons-material';
import Swal from 'sweetalert2';
import {createDVFromOrder, refreshDocs, useOpenOrders, usePaidDvs} from '../../../../hooks/customer/documents.hooks';
import ShowImageDialog from '../../../../ui-component/dialogs/showImageDialog';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import GenerativeLoader, {
    GenerativeLoaderChip,
    GenerativeLoaderChipForPrint,
    GenerativeLoaderChipSum
} from '../../../../ui-component/GenerativeLoader';
import {dateObjectToString, dateObjectToStringIsrael, roundDecimals} from "../../../../fromKotlin/nkutils";
import {cloneDeep} from "lodash";
import AgTable, {ITableControls, selectionMode} from '../../../../ui-component/ag-grid/Ag-Table';
import {
    customGroup,
    customGroupActiveForChip,
    customGroupLabelForChip, numberCellRenderer,
    numberPinnedRowRenderer,
    rowGroupRenderer
} from 'ui-component/ag-grid/ag-table-utils';
import CalendarContainer from 'ui-component/calendar-container/calendar-container';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AgTableContainer from 'ui-component/ag-grid/table-container';
import {DocumentsFilterBar} from '.';
import DocumentRowMemo from './DocumentsRowMemo';
import {MapPin} from "tabler-icons-react";
import {deserializeDocument, isNoteCompare} from "../../../../types/DocumentsTypes/utils";
import basicPinnedControl from "../../../../ui-component/ag-grid/utils/basicPinnedTopRow";
import contextMenu from './ContextMenu';
import DocumentNew from './DocumentNew';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import {DocumentType} from 'types/document';
import AgTableMobile from 'ui-component/ag-grid/Ag-Table-Mobile';
import SendMessageDialog from "../../../../ui-component/dialogs/sendMessage";
import ChooseDateMultiAction from "../../../../ui-component/dialogs/chooseDateMultiAction";
import {debtDataHolder} from "../../../../types/debtDataHolder";
import CartesetList from "../debts";

export const MultiDocumentsActions = React.memo(({

                                                     closeDVS,
                                                     selected,
                                                     data,
                                                     cols,
                                                     extraActions
                                                 }: { closeDVS: any, selected: ExtendedDocument[], data?: any[], cols?: any[], extraActions?: any }) => {
        return <div className='document-actions-div' style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

        }}>
            {extraActions ? extraActions(selected) : undefined}

            <Tooltip title='סגירת מסמכים' onClick={(a) => {
                Swal.fire({
                    title: 'האם תרצה לסגור תעודות משלוח?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'סגור תעודות',
                    cancelButtonText: 'בטל'
                }).then((result) => {
                    if (result.isConfirmed) {
                        /* Perform insert, update, and delete to the database here */
                        closeDVS.mutate(selected);
                        // callbackNetworkResult(()=>close_all_dv(selected),(result)=>{})
                    }
                });

            }}>
                <MyChip icon={<DoNotDisturbOnOutlinedIcon/>} sx={{
                    ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                        color: '#000080',
                    },
                }} label="סגירת תעודת משלוח" variant="outlined"/>

            </Tooltip>

            <Tooltip title={'סיכום תעודות'}>

                <GenerativeLoaderChipSum func={(setLoader) => {

                    if (selected.some((d) => d.type == 'supplier_note')) {
                        getFile(false, () => buildSupNotesSum(selected?.filter((d) => (d.type == 'supplier_note') && d.status != 'canceled'), undefined), 'מסמכים', 'application/pdf', undefined, setLoader);
                    } else if (selected.every((d) => d.type == 'order')) {
                        getFile(false, () => buildNotesSum(selected?.filter((d) => (d.type == 'order') && d.status != 'canceled'), undefined, undefined, true), 'מסמכים', 'application/pdf', undefined, setLoader);
                    } else {
                        getFile(false, () => buildNotesSum(selected?.filter((d) => (d.type == 'delivery_certificate' || d.type == 'supplier_note') && d.status != 'canceled'), undefined), 'מסמכים', 'application/pdf', undefined, setLoader);
                    }

                }} icon={<FunctionsOutlinedIcon sx={{width: '24px', height: '24px', mr: 0, ml: 0, p: 0}}/>}/>

            </Tooltip>

            <Tooltip title={'יצא מסמכים pdf'}>

                <GenerativeLoaderChip func={(setLoader) => {
                    Swal.fire({
                        title: 'מקור/העתק',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'מקור',
                        cancelButtonText: 'ביטול',
                        denyButtonText: 'העתק',
                        showDenyButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (selected.some((d) => d.type == 'supplier_note')) {
                                getFile(false, () => buildSupNotesSum(selected?.filter((d) => d.type == 'supplier_note'), undefined), getNameOfDoc(selected), 'application/pdf', undefined, setLoader);
                            } else {
                                getFile(false, () => getMassDocumentPdf(selected, undefined, undefined, true), getNameOfDoc(selected), 'application/pdf', undefined, setLoader);
                            }
                        } else if (result.isDenied) {
                            if (selected.some((d) => d.type == 'supplier_note')) {
                                getFile(false, () => buildSupNotesSum(selected?.filter((d) => d.type == 'supplier_note'), undefined), getNameOfDoc(selected), 'application/pdf', undefined, setLoader);
                            } else {
                                getFile(false, () => getMassDocumentPdf(selected, undefined, undefined, false), getNameOfDoc(selected), 'application/pdf', undefined, setLoader);
                            }
                        }
                    });


                }} icon={<Avatar variant='square' sx={{cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px'}}
                                 src={pdf}/>}/>

            </Tooltip>

            <Tooltip title={'הכן להדפסה'}>
                <GenerativeLoaderChipForPrint func={(setLoader) => {
                    if (selected.some((d) => d.type == 'supplier_note')) {
                        getFile(true, () => buildNotesSum(selected?.filter((d) => d.type == 'supplier_note'), undefined), 'מסמכים', 'application/pdf', undefined, setLoader);
                    } else {
                        getFile(true, () => getMassDocumentPdf(selected, undefined, undefined), 'מסמכים', 'application/octet-stream', undefined, setLoader);
                    }

                }} icon={<PrintIcon sx={{width: '24px', height: '24px'}}/>}/>

            </Tooltip>
            <MyChip icon={<ShowImageDialog docs={selected?.sort((a, b) => a?.id - b?.id)} calcFunction={(d) => {
                return getDocumentPhoto(d);
            }} icon={<CameraAltIcon/>} title={'צילום בשטח'}
                                           tooltip={'צפייה גורפת בצילומי שטח'} subtitle={''}></ShowImageDialog>} sx={{
                ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                    color: '#000080',
                },
            }} label="צילום בשטח" variant="outlined"/>
            {/* <ShowImageDialog docs={selected.sort((a, b) => a.id - b.id)} calcFunction={(d) => {
        return getDocumentPhoto(d);
    }} icon={<CameraAltIcon />} title={'צילום בשטח'}
                tooltip={'צפייה גורפת בצילומי שטח'} subtitle={''}        ></ShowImageDialog> */}

        </div>
    }
);
export const docColumnsShrink: HeadCell[] = [
    {
        id: 'id',
        numeric: false,
        label: 'מזהה',
        align: 'center',
        searchQueryPlaceholder: 'הזן מזהה',
        type: 'string',
        cellRenderer({params, setPreviewOpen, setPreviewData}) {

            return (
                <div
                    style={{cursor: "pointer", color: "#3f51b5"}}
                    onClick={() => {
                        setPreviewOpen(true);
                        setPreviewData(deserializeDocument(params?.node?.data?.nkObject));
                    }}
                >
                    {params?.value}

                </div>


            );
        },
    },
    {
        id: 'name', numeric: false, label: 'סוג', align: 'center', type: 'string', cellRenderer({params}) {
            if (params?.node?.group) {
                return params?.value || '';
            }
            return (
                <MyChip label={params?.data?.name} chipcolor={params?.data?.baseColor} size='small'/>
            );
        },
        enableRowGroup: true,
    },
    {
        id: 'entName',
        numeric: false,
        label: 'שם לקוח',
        align: 'center',
        type: 'string',
        extendedSearchProperties: ['entBName', 'entName'],
        searchQueryPlaceholder: 'שם לקוח/שם חברה',
        enableRowGroup: true,

    },
    {
        id: 'date',
        numeric: false,
        label: 'תאריך מסמך',
        align: 'center',
        type: 'dateStr',
        filterable: true,
        enableRowGroup: true,
        dataFormat: "date",
        searchFunc: (row) => {
            const v = row.date.getFullYear() == 2011 ? 'ללא תאריך' : format(row.date, 'PPP', {locale: config.i18n === 'he' ? he : enUS})
            const z = row.date.getFullYear() == 2011 ? 'ללא תאריך' : JSDB().getDatesManipulator().dateIsrael((dateObjectToStringIsrael(row.date)));
            return [v, z];
        },
        extractFunc: (row) => {
            if (!row?.date) return '';
            return row?.date?.getFullYear() == 2011 ? 'ללא תאריך' : ((dateObjectToStringIsrael(row?.date)));
        }
    },

]
export const docColumns: HeadCell[] = [
    {
        id: 'checkbox',
        numeric: false,
        label: '',
        align: 'center',
        type: 'string',
    },
    {
        id: 'id',
        numeric: false,
        label: 'מזהה',
        align: 'center',
        searchQueryPlaceholder: 'הזן מזהה',
        type: 'string',
        cellRenderer({params, setPreviewOpen, setPreviewData}) {

            return (
                <div
                    style={{cursor: "pointer", color: "#3f51b5"}}
                    onClick={() => {
                        setPreviewOpen(true);
                        setPreviewData(deserializeDocument(params?.node?.data?.nkObject));
                    }}
                >
                    {params?.value}

                </div>


            );
        },
    },

    {
        id: 'bul',
        numeric: false,
        label: 'בול',
        align: 'center',
        searchQueryPlaceholder: 'בול',
        type: 'string',
        priority: -1,
        hide: true,
        dataFormat: "boolean",
    },

    {
        id: 'name',
        numeric: false,
        label: 'סוג',
        align: 'center',
        type: 'string',
        cellRenderer({params, setPreviewData, setPreviewOpen}) {
            if (params?.node?.group) {
                return params?.value || '';
            }
            return (
                <MyChip label={params?.data?.name} chipcolor={params?.data?.baseColor} size='small' onClick={(e) => {
                    setPreviewOpen(true);
                    setPreviewData(deserializeDocument(params?.node?.data?.nkObject));
                }}/>
            );
        },
        enableRowGroup: true,
    },
    {
        id: 'date',
        numeric: false,
        label: 'תאריך מסמך',
        align: 'center',
        type: 'dateStr',
        filterable: true,
        enableRowGroup: true,
        dataFormat: "date",
        searchFunc: (row) => {
            const v = row.date.getFullYear() == 2011 ? 'ללא תאריך' : format(row.date, 'PPP', {locale: config.i18n === 'he' ? he : enUS})
            const z = row.date.getFullYear() == 2011 ? 'ללא תאריך' : JSDB().getDatesManipulator().dateIsrael((dateObjectToStringIsrael(row.date)));
            return [v, z];
        },
        extractFunc: (row) => {
            if (!row?.date) return '';
            return row?.date?.getFullYear() == 2011 ? 'ללא תאריך' : ((dateObjectToStringIsrael(row?.date)));
        }
    },
    {id: 'entId', numeric: true, label: 'מזהה לקוח', align: 'center', type: 'number', hide: true},
    {
        id: 'entName',
        numeric: false,
        label: 'שם לקוח',
        align: 'center',
        type: 'string',
        extendedSearchProperties: ['entBName', 'entName'],
        searchQueryPlaceholder: 'שם לקוח/שם חברה',
        enableRowGroup: true,
        // cellRenderer({params, setSelectedCustomerId}) {
        //     if(setSelectedCustomerId === undefined) return params?.value;
        //     if (params?.node?.group) return '';
        //     if (params?.node?.rowPinned) return params?.value;
        //     return (
        //         <div style={{
        //             display: 'flex',
        //             justifyContent: 'flex-start',
        //             alignItems: 'center',
        //             // flexWrap: 'wrap',
        //             width: '100%',
        //             overflow: 'hidden',
        //         }}>
        //             <Tooltip title='פרטי לקוח' style={{color: 'blue', marginRight: 0, marginLeft: 0, paddingInline: 0}}>
        //                 <>
        //                     <IconButton
        //                         style={{
        //                             color: 'blue',
        //                             marginRight: 0,
        //                             marginLeft: 0,
        //                             paddingInline: 0,
        //                             display: "flex",
        //                             flexDirection: "column"
        //                         }} size='medium'
        //                         onClick={() => {
        //                             setSelectedCustomerId(params?.data?.entId);
        //                         }}>
        //                         <Person/>
        //                     </IconButton>
        //                     <span style={{
        //                         fontSize: "0.8rem"
        //                     }}>
        //                         {params?.data?.entName ?? ''}
        //                     </span>
        //                 </>
        //             </Tooltip>
        //         </div>
        //     )
        // },
    },
    {
        id: 'month',
        numeric: false,
        label: 'חודש',
        align: 'center',
        hide: true,
        type: 'string',
        sortable: true,
        filterable: true,
        extractFunc: (row) => {

            return row?.date ? row?.date?.getFullYear() == 2011 ? 'ללא תאריך' : (((row?.date?.getMonth() + 1))) : '';
        },
        enableRowGroup: true
    },
    {
        id: 'year',
        numeric: false,
        label: 'שנה',
        align: 'center',
        hide: true,
        type: 'string',
        sortable: true,
        filterable: true,
        extractFunc: (row) => {

            return row?.date ? row?.date?.getFullYear() == 2011 ? 'ללא תאריך' : (((row?.date?.getFullYear().toString()))) : '';
        },
        enableRowGroup: true
    },

    {
        id: 'entBName',
        numeric: false,
        label: 'שם חברה',
        align: 'center',
        hide: true,
        type: 'string',
        extendedSearchProperties: ['entBName', 'entName'],
        searchQueryPlaceholder: 'שם לקוח/שם חברה'
    },
    {
        id: 'agent', numeric: false, label: 'מנפיק', align: 'center', type: 'string', enableRowGroup: true,
        extractFunc: (row) => {
            return row?.agent;
        },
    },

    {
        id: 'sign',
        numeric: false,
        label: 'חתימה',
        align: 'center',
        searchQueryPlaceholder: 'הזן מזהה',
        type: 'string',
        cellRenderer({params, setPreviewOpen, setPreviewData}) {
            return (
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                    {params?.data?.sign ? <ShowImageDialog calcFunction={() => {
                        return getDocumentSign(params?.data);
                    }} icon={<BorderColorIcon/>} title={'חתימה בשטח'}
                                                           tooltip={'חתימה דיגיטלית'}
                                                           subtitle={`נחתם בתאריך ${params?.data?.nkObject.signData()?.date} ע''י ${params?.data?.nkObject.signData()?.signer} `}></ShowImageDialog> : undefined}
                    {params?.data?.photo ? <ShowImageDialog docs={[params?.data]} calcFunction={(d) => {
                        return getDocumentPhoto(d ?? params?.data!);
                    }} icon={<CameraAltIcon/>} title={'צילום בשטח'}
                                                            tooltip={'צילום בשטח'}
                                                            subtitle={`צולם בתאריך ${params?.data?.nkObject.signData()?.date} `}></ShowImageDialog> : undefined}
                </Box>


            );
        },
        pinnedRowProps: {
            action: "booleanCount"
        },
    },
    {
        id: 'kav', numeric: false, label: 'שם קו', align: 'center', type: 'string', hide: true, extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.getAgent();
        },

        searchFunc: (row) => {

            const client = JSDB().getClient(Number(row.entId)).first;
            return [client?.getAgent()];
        }

    },
    {
        id: "paymentComments",
        label: "תנאי תשלום",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.payment_notes;
        },
    },
    {
        id: "bussinessId",
        label: "מס עוסק",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {
            if (row?.details?.companyId && row?.details?.companyId != '')
                return row?.details?.companyId
            return JSDB().getClient(Number(row?.entId))?.first?.getBusinessId();
        },
    },
    {id: 'driver', numeric: false, label: 'נהג', align: 'center', type: 'string', hide: true, enableRowGroup: true},
    {
        id: 'mainValue',
        numeric: false,
        label: 'ערך כספי(כולל)',
        align: 'center',
        type: 'number',
        sortable: true,
        pinnedRowProps: {
            action: "sum",
            format: "currency",
        },
        dataFormat: "currency",
        sumFunc: (d: any[]) => d.reduce((sum, x) => sum + x.mainValue * ((x.type == 'refund') ? -1 : 1), 0),
        extractFunc: (row) => {
            return row.mainValue * ((row.type == 'refund') ? -1 : 1)
        },
        cellRenderer({params}) {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value ? "₪ " + params?.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '');
            }
            return params?.value ? "₪ " + params?.value : '';
        }

    },
    {
        id: 'mainValueBefore',
        numeric: false,
        label: 'ערך כספי(לפני מעמ)',
        align: 'center',
        type: 'number',
        hide: true,
        sortable: true,
        pinnedRowProps: {
            action: "sum",
            format: "currency",
        },
        dataFormat: "currency",
        sumFunc: (d: any[]) => d.reduce((sum, x) => sum + x.mainValue * ((x.type == 'refund') ? -1 : 1), 0),
        cellRenderer({params}) {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value ? "₪ " + params?.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '');
            }
            return params?.value ? "₪ " + params?.value : '';
        },
        extractFunc: (row) => {

            return roundDecimals((row?.mainValue ?? 0) - (row?.taxValue ?? 0)) * ((row.type == 'refund') ? -1 : 1)
        }

    },
    {
        id: 'maam',
        numeric: false,
        label: 'מע"מ',
        align: 'center',
        type: 'number',
        sortable: true,
        pinnedRowProps: {
            action: "sum",
            format: "currency",
        },
        dataFormat: "currency",
        sumFunc: (d: any[]) => d.reduce((sum, x) => sum + x.mainValue * ((x.type == 'refund') ? -1 : 1), 0),
        extractFunc: (row) => {
            return (row?.taxValue ?? 0) * ((row.type == 'refund') ? -1 : 1)
        },
        cellRenderer({params}) {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value ? "₪ " + params?.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '');
            }
            return params?.value ? "₪ " + params?.value : '';
        }

    },

    {
        id: 'mainValueValid',
        numeric: true,
        label: 'חיוב',
        align: 'center',
        type: 'number',
        hide: true,
        sortable: true,
        sumFunc: (d: any[]) => d.reduce((sum, x) => sum + x.mainValue * ((x.type == 'refund') ? -1 : 1), 0),
        cellRenderer({params}) {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value ? "₪ " + params?.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '');
            }
            return params?.value ? "₪ " + params?.value : '';
        },
        extractFunc: (row) => {

            return row.type == 'tax_invoice' || row.type == 'refund' || row.type == 'invoice' || row.type == 'delivery_certificate' || row.type == 'visit_note' ? roundDecimals(row?.mainValue ?? 0) : 0
        },
        pinnedRowProps: {
            action: "sum",
            format: "currency",
        },
    },
    {
        id: 'paymentValue',
        numeric: false,
        label: 'סהכ תשלום',
        align: 'center',
        type: 'number',
        hide: true,
        sortable: true,
        cellRenderer({params}) {
            if (params?.node?.group) {
                return rowGroupRenderer(params?.value ? "₪ " + params?.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '');
            }
            return params?.value ? "₪ " + params?.value : '';
        },
        extractFunc: (row) => {
            return roundDecimals((row?.nkObject?.getConnectedPayment()))
        },
        pinnedRowProps: {
            action: "sum",
            format: "currency",
        },
    },
    {
        id: 'totalAmount',
        numeric: false,
        label: 'כמות',
        align: 'center',
        type: 'number',
        hide: true,
        sortable: true,
        pinnedRowProps: {
            action: "sum",
        },

    },
    {
        id: 'totalRetrunAmount',
        numeric: false,
        label: 'החזרות',
        align: 'center',
        type: 'number',
        hide: true,
        sortable: true,
        pinnedRowProps: {
            action: "sum",
        },
        extractFunc: (row) => {

            return       row?.nkObject?.getReturnAmounts()
        }
    },
    {
        id: 'avgPrice',
        numeric: false,
        label: 'מחיר ממוצע למוצר',
        align: 'center',
        type: 'number',
        hide: true,
        sortable: true,

        extractFunc: (row) => {
            return roundDecimals(row?.totalAmount ? (row?.mainValue ?? 0) / (row?.totalAmount ?? 0) : 0)
        }

    },

    {
        id: 'city',
        numeric: false,
        label: 'עיר', hide: true,
        align: 'center',
        type: 'string',
        filterable: true,

        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.getCity();
        },

        searchFunc: (row) => {

            const client = JSDB().getClient(Number(row.entId)).first;
            return [client?.getCity(), client?.getStreet()];
        }

    },
    {
        id: 'value_carteset',
        numeric: true,
        label: 'כרטסת',
        align: 'center',
        type: 'number',
        sortable: true,
        hide: true,
        filterable: false,
        pinnedRowProps: {
            action: "sum",
            format: "currency",
        },
        moneyFunc: (d: any[]) => d.reduce((sum, x) => sum + x.value_carteset, 0),
        cellRenderer({params, setSelectedDoc}) {
            if (params?.node?.rowPinned) return numberCellRenderer(params, "currency");
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'right',
                    alignItems: 'end',
                }}>

                    {params?.node?.group ? numberCellRenderer(params, "currency") :
                        <Typography variant='h5' style={number_style}>
                            כרטסת
                        </Typography>}
                    <Tooltip title={<FormattedMessage id='Customer Archive'/>}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}>
                            <IconButton size='medium' onClick={() => {

                                setSelectedDoc(params?.node?.group ? params?.node?.allLeafChildren?.[0]?.data : params?.node?.data)
                            }}>
                                <IconArchive size={20}/>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            )
        },
    },
    {
        id: 'gps',
        numeric: false,
        label: 'גי פי אס',
        hide: true,
        type: 'string',
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row.entId)).first;
            return client?.location ?? '';
        },
        cellRenderer: ({params}) => {
            const openGmaps = (result) => {
                window.open(`https://www.google.com/maps/search/?api=1&query=${result}`, '_blank')?.focus();
            };
            return (
                <div style={{
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: "center",
                }}
                     onClick={() => openGmaps(params?.value)}
                >
                    {params?.value != "" && <><MapPin size={14} strokeWidth={1} color="#000000"/>
                        <div><Typography variant="caption">{params?.value}</Typography></div>
                    </>}
                </div>
            )
        }

    },
    {
        id: 'status',
        numeric: false,
        label: 'סטטוס',
        align: 'center',
        type: 'string',
        filterable: false,
        enableRowGroup: true,
        extractFunc: (row) => {
            return documentStatuses[row?.status];
        },
        cellRenderer({params}) {
            if (params?.node?.group) return params.value || '';
            return (params?.data?.status ?
                    <MyChip
                        label={<FormattedMessage id={params?.data?.status}/>}
                        variant='outlined'
                        size='small'
                        chipcolor={
                            // eslint-disable-next-line no-nested-ternary
                            params?.data?.status === 'pending_approval'
                                ? 'warning'
                                // eslint-disable-next-line no-nested-ternary
                                : params?.data?.status === 'approved'
                                    ? 'success'
                                    : params?.data?.status === 'open'
                                        ? 'error'
                                        : ''
                        }
                    /> : <></>
            );
        },
    },
    {
        id: "docUpdated",
        numeric: false,
        label: "עודכן",
        hide: true,
        extractFunc(row) {
            // CALLBACK FUNCTION TO CHECK IF OK OR NOT

            return (row?.type === 'delivery_certificate' || row?.type === "order") && row?.history && row?.history?.length > 0
        },
        cellRenderer({params, setPreviewOpen, setPreviewData}) {
            if (params?.node?.group) return params.value || '';
            if (params?.value == undefined || params?.value == false) return ''

            return (
                <MyChip
                    label={'עודכן'}
                    onClick={() => {
                        setPreviewOpen(true);
                        setPreviewData(deserializeDocument(params?.node?.data?.nkObject));
                    }}
                    variant='outlined'
                    size='small'
                    chipcolor={'orange'}
                />
            );
        }
    },
    {
        id: "orderId",
        numeric: false,
        label: "לפי הזמנה",
        hide: true,
        extractFunc(row) {
            // CALLBACK FUNCTION TO CHECK IF OK OR NOT
            return row?.orderId;
        },
        cellRenderer({params, setPreviewOpen, setPreviewData}) {

            if (params?.node?.group) return params.value || '';
            if (params?.value == undefined || params?.value == -1 || params?.node?.data?.type != 'delivery_certificate' && params?.node?.data?.type != 'invoice' && params?.node?.data?.type != 'debt') return ''

            return (
                <MyChip
                    label={params?.value}
                    onClick={() => {
                        setPreviewOpen(true);
                        setPreviewData(deserializeDocument(params?.node?.data?.nkObject));
                    }}
                    variant='outlined'
                    size='small'
                    chipcolor={params?.node?.data?.nkObject?.getConnectedOrder() && isNoteCompare(params?.node?.data?.products ?? [], deserializeDocument(params?.node?.data?.nkObject?.getConnectedOrder())?.products ?? []) ? 'success' : 'orange'}
                />
            );
        }
    },
    {
        id: 'orderStatus',
        numeric: false,
        label: 'סטטוס הזמנה', hide: true,
        align: 'center',
        type: 'string',
        filterable: true,

        extractFunc: (row) => {
            if (row.type == 'order')
                return row?.orderStatus;
            const orderId = row?.orderId;

            if (orderId == undefined || orderId == -1 || row?.type != 'delivery_certificate' && row?.type != 'invoice') return ''
            return row?.nkObject?.getConnectedOrder()?.orderStatus ? row?.nkObject?.getConnectedOrder()?.orderStatus.orderName() : undefined;
        },


    },
    {
        id: 'externalId',
        numeric: false,
        label: 'מזהה לקוח חיצוני', hide: true,
        align: 'center',
        type: 'string',
        filterable: true,

        extractFunc: (row) => {

            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.external_id;
        },
    },
    {
        id: 'days_to_pay',
        numeric: false,
        label: 'תאריך לתשלום', hide: true,
        align: 'center',
        type: 'dateStr', filterable: true,

        extractFunc: (row) => {

            if (row.type != 'invoice' && row.type!='refund')
                return ''
            const client = JSDB().getClient(Number(row?.entId))?.first;
            const dayToPay = client?.days_to_pay ?? 0;
            const curD = dateObjectToString(row?.date)

            if(dayToPay == 0)
                return JSDB().getDatesManipulator().dateIsrael(curD);
            const nextMonth = JSDB().getDatesManipulator().getNextMonth(curD).split("-");
            const firstDateNextMonth = `${nextMonth[0]}-${nextMonth[1]}-01`
            const dateToPay = JSDB().getDatesManipulator().getNextDay(firstDateNextMonth, dayToPay-1);
            return JSDB().getDatesManipulator().dateIsrael(dateToPay);
        },
    },
    {
        id: 'agent_id',
        numeric: false,
        label: 'נהג זמני', hide: true,
        align: 'center',
        type: 'string',
        filterable: true,

        extractFunc: (row) => {

            if (row?.agent_id && row?.agent_id != -1) {
                const agent = JSDB().getAgentBy(row.agent_id, null, false, false, false, true)?.first?.user_name
                return agent
            }
            return ''

        },
    },
    {
        id: 'issueDate', numeric: false, type: "date", label: 'תאריך הנפקה', align: 'center', filterable: false,
        dataFormat: "dateWithTime",
        cellRenderer: ({params}) => {
            if (params?.node?.group) return params.value || '';
            return (params?.data?.issueDate ?
                    <span>{format(params?.data?.issueDate, 'PPP HH:mm', {locale: config.i18n === 'he' ? he : enUS})}</span> : <></>
            );
        },
    },
    {
        id: 'comment',
        numeric: false,
        label: 'הערות',
        type: 'string',
        hide: true

    },
    {
        id: 'notes2',
        numeric: false,
        label: 'הערות 2',
        type: 'string',
        hide: true

    },
    {
        id: 'notes3',
        numeric: false,
        label: 'הערות 3',
        type: 'string',
        hide: true

    },
    {
        id: 'comment_client',
        numeric: false,
        label: 'הערות לקוח',
        hide: true,
        align: 'center',
        type: 'string',
        filterable: true,

        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.comments;
        },

        searchFunc: (row) => {

            const client = JSDB().getClient(Number(row.entId)).first;
            return [client?.getCity(), client?.getStreet()];
        }

    },
    {
        id: 'net', numeric: false, label: 'רשת', align: 'center', type: 'string', hide: true, extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            const branch = client?.branch
            if (branch && branch != -1) {
                return client?.getBusinessName()
            }
            return ''
        },
    },
    {
        id: "obligo",
        numeric: false,
        label: "אובליגו",
        align: "center",
        hide: true,
        aggFunc: 'max',
        enableValue: true,
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.getObligo();
        }
    },
    {
        id: "category",
        label: "קטגורית לקוחות",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.category;
        },
    },
    {
        id: "group",
        label: "קבוצת לקוחות",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.category2;
        },
    },
    {
        id: "taxConfirmation",
        label: "מספר הקצאה",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {

            return row?.nkObject?.taxConfirmation ?? ''
        },
    },
    {
        id: "paymentComments",
        label: "תנאי תשלום",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.payment_notes;
        },
    },
    {
        id: "comments",
        label: "הערות קבועות",
        numeric: false,
        priority: -1,
        hide: true,
        extractFunc: (row) => {
            const client = JSDB().getClient(Number(row?.entId))?.first;
            return client?.comments;
        },
    },


];


function DocumentsTable({
                            data,
                            onCloseDialog,
                            extraActions,
                            noScrollOpt,
                            setRange,
                            load,
                            setStatus,
                            range,
                            tableName,
                            status,
                            singleDate,
                            setSingleDate,
                            pool,
                            setSelectedCustomerId, setSelectedCustomersSendF
                        }: { data: ExtendedDocument[], onCloseDialog?: any, extraActions?: any, noScrollOpt?: boolean, setRange?: any, load?: any, setStatus?: any, range?: any, tableName?: string, status?: any, singleDate?, setSingleDate?, pool?: string, setSelectedCustomerId?: any, setSelectedCustomersSendF?: any }) {
    const [cols, setCols] = React.useState<HeadCell[]>(docColumns);

    React.useEffect(() => {
        const apiConfig = JSDB().apiConfig;
        const newCols = cloneDeep((cols))
        if (apiConfig) {

            if (apiConfig.hasStoreNext()) {

                newCols.push(
                    {
                        id: 'storeNext', numeric: false, label: 'סטורנקסט', align: 'center', filterable: true,
                        extendedSearchProperties: ['כן נשלח', 'לא נשלח', 'מחיר'],
                        searchFunc: (row) => {
                            const x = (row.storeNext != null && row.storeNext.send) ? ['כן נשלח'] : ['לא נשלח'];
                            const reason = (row.storeNext != null && row.storeNext.send) ? '' : `${row.storeNext?.reason ?? ''}`;
                            if (reason && reason != '')
                                x.push(reason);
                            return x;
                        },
                        extractFunc: (row) => {
                            return (row.storeNext != null && row.storeNext.send) ? 'כן נשלח' : 'לא נשלח';
                        }
                    }
                );
            }
            if (apiConfig.hasManager()) {
                newCols.push(
                    {
                        id: 'manager',
                        numeric: false,
                        label: 'מנגר',
                        align: 'center',
                        filterable: true,
                        searchFunc: (row) => {
                            const x = (row.manager != null && row.manager.send) ? ['כן נשלח'] : ['לא נשלח'];
                            const reason = (row.manager != null && row.manager.send) ? '' : `${row.manager?.reason ?? ''}`;
                            if (reason && reason != '')
                                x.push(reason);
                            return x;
                        },
                        extractFunc: (row) => {
                            return (row.manager != null && row.manager.send) ? 'כן נשלח' : 'לא נשלח';
                        }
                    }
                );
            }
            if (apiConfig.hasOne()) {
                newCols.push(
                    {
                        id: 'one', numeric: false, label: 'One', align: 'center', filterable: true,
                        extendedSearchProperties: ['כן נשלח', 'לא נשלח', 'מחיר'],
                        searchFunc: (row) => {
                            const x = (row.one != null && row.one.send) ? ['כן נשלח'] : ['לא נשלח'];
                            const reason = (row.one != null && row.one.send) ? '' : `${row.one?.reason ?? ''}`;
                            if (reason && reason != '')
                                x.push(reason);
                            return x;
                        }, extractFunc: (row) => {
                            return (row.one != null && row.one.send) ? 'כן נשלח' : 'לא נשלח';
                        }
                    }
                );
            }
            if (apiConfig.hasHashavshevet()) {
                newCols.push(
                    {
                        id: 'hash', numeric: false, label: 'חשבשבת', align: 'center', filterable: true,
                        extendedSearchProperties: ['כן נשלח', 'לא נשלח', 'מחיר'],
                        searchFunc: (row) => {
                            const x = (row?.hashavshevet != null && row?.hashavshevet?.send) ? ['כן נשלח'] : ['לא נשלח'];
                            const reason = (row?.hashavshevet != null && row?.hashavshevet?.send) ? '' : `${row?.hashavshevet?.reason ?? ''}`;
                            if (reason && reason != '')
                                x.push(reason);
                            return x;
                        }, extractFunc: (row) => {
                            return (row?.hashavshevet != null && row?.hashavshevet?.send) ? 'כן נשלח' : 'לא נשלח';
                        }
                    }
                );
            }

        }
        const notes2 = JSDB().getUser().first?.getJsonDesc("cnotes2")
        let labelNotes2 = "הערות לקוח 2";
        if (notes2) {
            labelNotes2 = notes2.name;


        }
        newCols.push({
            id: "cnotes2",
            label: labelNotes2,
            numeric: false,
            hide: true,
            priority: -1,
            extractFunc: (row) => {
                const client = JSDB().getClient(Number(row?.entId))?.first;
                return client?.notes2;
            }
        })


        const notes3 = JSDB().getUser().first?.getJsonDesc("cnotes3")
        let labelNotes3 = "הערות לקוח 3";
        if (notes3) {
            labelNotes3 = notes3.name;


        }
        newCols.push({
            id: "cnotes3",
            label: labelNotes3,
            numeric: false,
            hide: true,
            priority: -1,
            extractFunc: (row) => {
                const client = JSDB().getClient(Number(row?.entId))?.first;
                return client?.notes3;
            }
        })
        const notes4 = JSDB().getUser().first?.getJsonDesc("cnotes4")
        let labelNotes4 = "הערות לקוח 4";
        if (notes4) {
            labelNotes4 = notes4.name;
        }
        newCols.push({
            id: "cnotes4",
            label: labelNotes4,
            numeric: false,
            hide: true,
            priority: -1,
            extractFunc: (row) => {
                const client = JSDB().getClient(Number(row?.entId))?.first;
                return client?.notes4;
            }
        })
        setCols(newCols)
    }, []);

    const closeDVS = usePaidDvs(pool);
    React.useEffect(() => {
        cols[1].priority = data.some((d) => d.type == 'supplier_note') ? undefined : -1
        cols[7].priority = data.some((d) => d.type == 'supplier_note') ? -1 : undefined
    }, [data]);

    const refresh = refreshDocs(pool)
    //---------------------------------------------------------------------------------
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewData, setPreviewData] = React.useState<any>(null);
    const [openCalendar, setOpenCalendar] = React.useState(false);
    const [selectedDoc, setSelectedDoc] = React.useState<any | null>(null);
    const chipDetails = React.useMemo(() => {
        return [
            {
                action: (params) => {
                    setOpenCalendar(true);
                },
                label: (params) => openCalendar ? "סגור לוח שנה" : "פתח לוח שנה",
                icon: <CalendarMonthIcon/>,
                tooltip: "פתח תצוגת לוח שנה",
                active: () => openCalendar,
            },
            {
                action: (params) => customGroup(params, ["driver"]),
                label: (params) => customGroupLabelForChip(params, ["driver"], "קבץ לפי נהגים"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי נהגים ",
                active: (params) => customGroupActiveForChip(params, ["driver"]),

            },
            {
                action: (params) => customGroup(params, ["kav"]),
                label: (params) => customGroupLabelForChip(params, ["kav"], "קבץ לפי קווים"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי קווים ",
                active: (params) => customGroupActiveForChip(params, ["kav"]),
            },
            {
                action: (params) => customGroup(params, ["agent"]),
                label: (params) => customGroupLabelForChip(params, ["agent"], "קבץ לפי מנפיק"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי מנפיק ",
                active: (params) => customGroupActiveForChip(params, ["agent"]),
            },
            {
                action: (params) => customGroup(params, ["name"]),
                label: (params) => customGroupLabelForChip(params, ["name"], "קבץ לפי סוג מסמך"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי סוג מסמך ",
                active: (params) => customGroupActiveForChip(params, ["name"]),
            },
            {
                action: (params) => customGroup(params, ["entName"]),
                label: (params) => customGroupLabelForChip(params, ["entName"], "קבץ לפי שם לקוח"),
                icon: <IconInfoCircle/>,
                tooltip: "קבץ את העמודות לפי שם לקוח ",
                active: (params) => customGroupActiveForChip(params, ["entName"]),
            },
        ]
    }, [openCalendar]);

    const selectionControls = React.useCallback((selectedItems) => {
        return <MultiDocumentsActions
            closeDVS={closeDVS}
            selected={selectedItems}
            data={data}
            extraActions={extraActions}
        />
    }, [data, extraActions]);

    const costumeFunctions = React.useMemo(() => {
        return {
            onCloseDialog: onCloseDialog,
            closeDVS: closeDVS
        }
    }, [onCloseDialog, closeDVS]);

    const tableControls: ITableControls = {
        tableName: "documents/allDocuments" + tableName ? tableName : "",
        groupBy: {
            enable: true,
            rowGroup: true,
            pivot: true,
            value: true,
        },
        selection: {
            enable: true,
            mode: "multiple" as selectionMode,
        },
        drag: {
            enable: false,
            multiRow: true,
        },
        extractId: (row) => {
            return `${row.data.id}${row.data.type}`
        },

        cellRenderer: (params: any, column: any) => {
            if (params.node?.rowPinned) {
                return params.data[params?.column?.colDef?.field] || ''


            }
            if (column.cellRenderer) {
                return column.cellRenderer({
                    params,
                    setPreviewData,
                    setPreviewOpen,
                    setSelectedCustomerId,
                    setSelectedDoc,
                });
            }
            if (column?.type === "number") {
                numberPinnedRowRenderer(params);
            }
            if (typeof params?.value === "object") {
                const value = params?.value?.value || "";
                return value;
            }
            return params?.value || "";
        },
        sidebar: true,
        localisation: true,
    }
    //---------------------------------------------------------------------------------

    const distinctValues = React.useMemo(() => selectiveDistinct(data, cols), [data]);

    const extras: HeadCell[] = [
        {
            id: 'comment',
            numeric: false,
            label: 'היערות',
            type: 'string',
        },
        {
            id: 'gps',
            numeric: false,
            label: 'גי פי אס',
            type: 'string',
            extractFunc: (row) => {
                const client = JSDB().getClient(Number(row.entId)).first;
                return client?.location ?? '';
            },
        },
        {
            id: 'lon',
            numeric: false,
            label: 'lon',
            type: 'string',
            extractFunc: (row) => {
                const client = JSDB().getClient(Number(row.entId)).first;
                return client?.getLon() ?? '';
            },
        },
        {
            id: 'lat',
            numeric: false,
            label: 'lat',
            type: 'string',
            extractFunc: (row) => {
                const client = JSDB().getClient(Number(row.entId)).first;
                return client?.getLat() ?? '';
            },
        }
    ];
    const openOrders = useOpenOrders(pool);
    const createDV = createDVFromOrder(pool);
    const [createDocOpen, setCreateDocOpen] = React.useState(undefined);
    const [moveOrderOpen, setMoveOrderOpen] = React.useState(false);
    const [documentAction, setDocumentAction] = React.useState<{
        title: string,
        action: string,
        docs: ExtendedDocument[]
    } | null>(null);
    const [duplicateOrderOpen, setDuplicateOrderOpen] = React.useState(false);
    const [selectedCustomersSend, setSelectedCustomersSend] = React.useState<any>(undefined)
    const extentedContextMenu = React.useCallback((params) => {
        return contextMenu(params, setCreateDocOpen, setDocumentAction, setSelectedCustomersSend) as any;
    }, [createDocOpen,]);

    const isPhone = window.innerWidth < 768;

    return (
        <>
            <AgTableContainer controls={() => {
                return (
                    <DocumentsFilterBar singleDate={singleDate ? singleDate : undefined}
                                        setSingleDate={setSingleDate ? setSingleDate : undefined}
                                        tableName={(tableName ?? '') + "/customer/documents/all-documents"}
                                        status={status}
                                        setStatus={setStatus} range={range} setRange={setRange}
                                        isLoading={load}/>
                )
            }} tableName={tableName || ""}>
                {isPhone ?
                    <AgTableMobile data={data} columns={docColumnsShrink} tableControls={tableControls}
                                   costumeFunctions={costumeFunctions}/>
                    // <MasterTable
                    //     headCells={cols}
                    //     data={data}
                    //     title={<Box />}
                    //     headerIcons={<Box />}
                    //     keyExtractor={(d) => `${d.id}_${d.type}`}
                    //     withPagination
                    //     paginationOptions={{
                    //         rowsPerPage: 50
                    //     }}
                    //     noScrollOpt={noScrollOpt}
                    //     distinctDataValues={distinctValues}
                    //     withSelection
                    //     selectionOptions={{
                    //         selectedActions: (selected) => <MultiDocumentsActions closeDVS={closeDVS}
                    //             selected={selected}
                    //             data={data} cols={cols}
                    //             extraActions={extraActions} />
                    //     }}
                    //     withSort
                    //     sortOptions={{
                    //         sortBy: {
                    //             order: 'desc',
                    //             orderBy: 'start'
                    //         }
                    //     }}
                    //     extras={extras}
                    //     withFilter
                    //     withXslx
                    //     TableItem={(row) => <DocumentRowMemo row={row} onCloseDialog={onCloseDialog} />}
                    //     alignText={true}
                    // />
                    :
                    <AgTable
                        tableControls={tableControls}
                        columns={cols}
                        data={data}
                        chipDetails={chipDetails}
                        selectionControls={selectionControls}
                        costumeFunctions={costumeFunctions}
                        pinnedControl={basicPinnedControl()}
                        extentedContextMenu={extentedContextMenu}
                    />
                }
            </AgTableContainer>
            {openCalendar ? <Dialog
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                fullWidth
                maxWidth="xl"
                sx={{'& .MuiDialog-paper': {p: 0}}}
            >
                <CalendarContainer
                    setOpenCalendar={openCalendar}
                    events={data ?? []}
                    setRange={setRange}
                    setPreviewOpen={setPreviewOpen}
                    setPreviewData={setPreviewData}
                    useTooltip={false}
                    useControlls={false}
                />
            </Dialog> : undefined}
            {selectedDoc ? <Dialog
                fullWidth
                maxWidth='xl'
                scroll='body'
                open={selectedDoc}
                onBackdropClick={() => setSelectedDoc(null)}>
                {selectedDoc && <CartesetList cid={selectedDoc.entId.toString()} rowsPerPage={30}
                />}
            </Dialog> : undefined}
            {previewOpen ? <Dialog fullWidth open={previewOpen} maxWidth="lg" onBackdropClick={() => {
                setPreviewOpen(false);
                if (costumeFunctions?.onCloseDialog)
                    costumeFunctions?.onCloseDialog();
            }}
                                   sx={{'& .MuiDialog-paper': {p: 0}}}>
                <DocumentPreview
                    setSelectedCustomersSend={setSelectedCustomersSendF ? setSelectedCustomersSendF : setSelectedCustomersSend}
                    document={previewData} pool={pool}/>
            </Dialog> : undefined}
            {createDocOpen ? <Dialog maxWidth={'md'} fullWidth open={createDocOpen} disableEscapeKeyDown={true}
                                     onBackdropClick={() => {
                                         const docs = createDocOpen?.documents?.length > 0 ? createDocOpen?.documents?.map((d) => d.id) : createDocOpen?.documents?.id ? [createDocOpen?.documents?.id] : undefined
                                         if (docs) {
                                             const types = createDocOpen?.documents?.length > 0 ? createDocOpen?.documents?.map((d) => d.nkObject.getConnectedDocType()) : [createDocOpen?.documents?.nkObject.getConnectedDocType()]
                                             refresh.mutate({ids: docs, types: types, offline: false})
                                         }

                                         setCreateDocOpen(undefined)

                                     }}
            >
                <DocumentNew
                    customerId={createDocOpen?.documents?.length > 0 ? createDocOpen?.documents?.[0]?.entId : createDocOpen?.documents?.entId}
                    type={createDocOpen?.type ? createDocOpen.type : createDocOpen?.documents?.length > 0 ? createDocOpen?.documents?.[0]?.type : createDocOpen?.documents?.type}
                    refId={createDocOpen?.documents?.length > 0 ? createDocOpen?.documents?.map((d) => d.id) : createDocOpen?.documents?.id ? [createDocOpen?.documents?.id] : undefined}
                    productsFromAbove={createDocOpen?.manyProducts}
                />
            </Dialog> : undefined}
            {selectedCustomersSend != undefined ? <SendMessageDialog
                open={selectedCustomersSend != undefined}
                setOpen={(open) => setSelectedCustomersSend(undefined)}

                toSelected={selectedCustomersSend?.selected ?? []}
                files={selectedCustomersSend?.files ?? []}
                msg={selectedCustomersSend?.msg ?? ""}
                subject={selectedCustomersSend?.subject ?? ""}
                defaultPlatform={selectedCustomersSend?.defaultPlatform ?? "email"}
                withFiles={false}

            /> : undefined}
            <ChooseDateMultiAction title={documentAction?.title || ""}
                                   func={documentAction?.action == 'move' ? (date) => {
                                       openOrders.mutate({date: date, docs: documentAction?.docs});
                                       setDocumentAction(null)
                                   } : (date) => {
                                       createDV.mutate({date: date, docs: documentAction?.docs || [], refresh: true});
                                       setDocumentAction(null)
                                   }}
                                   setDocumentAction={setDocumentAction}
            />

            {moveOrderOpen || duplicateOrderOpen ?
                <Dialog
                    open={moveOrderOpen || duplicateOrderOpen}
                    onClose={() => {
                        setMoveOrderOpen(false);
                        setDuplicateOrderOpen(false);
                    }}
                    fullWidth
                    maxWidth="md"
                    sx={{'& .MuiDialog-paper': {p: 0}}}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                        <DatePicker
                            open={true}
                            renderInput={(props) => <></>}
                            label={"בחר תאריך"}
                            value={new Date()}
                            onChange={(newValue) => {
                                if (newValue) {
                                    documents?.forEach((doc) => {
                                        const docId = doc?.id;
                                        if (moveOrderOpen) {
                                            // MOVE CALLBACK
                                        }
                                        if (duplicateOrderOpen) {
                                            // DUPLICATE CALLBACK
                                        }
                                    });
                                }
                            }}
                            PopperProps={{
                                anchorEl: {
                                    getBoundingClientRect: () => ({
                                        top: (window.innerHeight / 2) - 500,
                                        left: window.innerWidth / 2,
                                        right: window.innerWidth / 2,
                                        bottom: window.innerHeight / 2,
                                        width: 0,
                                        height: 0,
                                        x: window.innerWidth / 2,
                                        y: window.innerHeight / 2,
                                        toJSON: () => null,
                                    }),
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Dialog>
                : undefined
            }
        </>
    );
}

export default React.memo(DocumentsTable)
