import {MoreVert} from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import type {ColDef} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import 'dayjs/locale/he';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {DefaultRootStateProps, HeadCell} from 'types';
import CreateStateDialog from './CreateStateDialog';
import {
    createPinnedRowDataNew,
    onCellKeyDown,
    onColumnGroupOpened,
    onColumnMoved,
    onColumnPinned,
    onColumnResized,
    onColumnVisible,
    onFilterChanged,
    onManyCellEditingStopped,
    openColumnMenuOnRightClick,
    resetTable,
    saveColumnState
} from './ag-grid-functions';
import AgTableActionsBar from './ag-table-actions-bar';
import {defaultColDef, getDetailCellRenderer, loadRefsForAgTable} from './ag-table-init';
import {AG_GRID_LOCALE_HE} from './ag-table-localization';
import {
    costumFunction,
    getSavedColumnState,
    mapFilterState,
    removeDuplicatesById,
    rightClickItems,
    setColumns,
    sideBarDef
} from './ag-table-utils';
import './ag-table.scss';
import AgChips from './components/Ag-Chip';
import GrossValueChangeDialog from './gross-value-change';
import {IAgTable} from './interfaces';
import {AgTableActionsBarStyle, AgTableMainInnerStyle} from './utils/AgTableStyles';
import {calculateTableHeight, costumAggFunctions, processCellValueChange} from './utils/utilities';
import {ResetTableDialog} from './components/ResetTableDialog';
import {SnackBarAlert} from './components/SnackBarAlert';

const AgTable: React.FC<IAgTable> = ({
                                         columns,
                                         data,
                                         tableControls,
                                         costumeControls,
                                         cellRenderer,
                                         refreshCells,
                                         costumFunc,
                                         extentedContextMenu,
                                         clearEditFromAbove,
                                         outSideTableRef,
                                         chipDetails,
                                         saveDataCallback,
                                         menuiItems,
                                         addDataAction,
                                         rightClickActions,
                                         customeCancelEdit,
                                         editCallback,
                                         editCallbackMany,
                                         selectionControls,
                                         specialDialogs,
                                         customHeaderComponents,
                                         onLoad,
                                         pinnedControl,
                                         onCellClick,
                                         onCellFocused,
                                         onColumnMoveCallback,
                                         filterQ,
                                         getMainMenuItems
                                     }) => {
    const [columnDefs, setColumnDefs] = useState<[]>([]);
    const [nestedColumnsDefs, setNestedColumnsDefs] = useState<HeadCell[]>([]);

    const [rowData, setRowData] = useState<any[]>([]);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [reOrderMode, setReOrderMode] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [gridReady, setGridReady] = useState<boolean>(false);
    let externalVar = undefined;
    const setIsExternalFilterPresent = useCallback((newValue) => {
        externalVar = newValue;
        setTimeout(() => {
            tableRef?.current?.api?.onFilterChanged();
        }, 0);
        setFilterChange(!filterChange);
    }, []);

    const isExternalFilterPresent = useCallback(() => {
        // if ageType is not everyone, then we are filtering
        return externalVar != undefined;
    }, [externalVar]);

    const doesExternalFilterPass = useCallback(
        (node) => {
            if (externalVar) return externalVar.has(node.id);
            return true;
        },
        [externalVar]
    );

    const [statusBarData, setStatusBarData] = useState<{
        numberOfItems: number;
        selectedRows: number;
        filteredRows: number;
        editedRows: number;
    }>({
        numberOfItems: 0,
        selectedRows: 0,
        filteredRows: 0,
        editedRows: 0
    });
    const [editList, setEditList] = useState<any[]>([]);
    const [isPhone, setIsPhone] = useState<boolean>(window.innerWidth < 768);

    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const [pinnedTopRowDataConfiguration, setPinnedTopRowDataConfiguration] = useState<number[]>(
        (localStorage.getItem(`${tableControls?.tableName}_pinnedRowTopDefault`)
            ? JSON.parse(localStorage.getItem(`${tableControls?.tableName}_pinnedRowTopDefault`)!)
            : pinnedControl?.pinnedRowTopDefault) || []
    );
    const [pinnedBottomRowDataConfiguration, setPinnedBottomRowDataConfiguration] = useState<number[]>(
        (localStorage.getItem(`${tableControls?.tableName}_pinnedRowBotDefault`)
            ? JSON.parse(localStorage.getItem(`${tableControls?.tableName}_pinnedRowBotDefault`)!)
            : pinnedControl?.pinnedRowBotDefault) || []
    );
    const [filters, setFilters] = useState(undefined);
    const [filterChange, setFilterChange] = useState<boolean>(false);
    const [sortChange, setSortChange] = useState<boolean>(false);
    const [tableActionsOpen, setTableActionsOpen] = useState<boolean>(false);
    const [resetTableDialog, setResetTableDialog] = useState<boolean>(false);
    const [grossValueChangeModal, setGrossValueChangeModal] = useState<boolean>(false);
    const [grossValue, setGrossValue] = useState<any>("");
    const [valueAlert, setValueAlert] = useState<boolean>(false);
    const [defaultGroupAlert, setDefaultGroupAlert] = useState<boolean>(false);
    const [newRowDataDD, setNewRowDataDD] = useState<any>(null);
    const [newRowDataDD2, setNewRowDataDD2] = useState<any>(null);
    const [customeUserState, setCustomeUserState] = useState<any>(0);
    const [customeUserButtons, setCustomeUserButtons] = useState<any[]>([]);
    const [openCreateStateDialog, setOpenCreateStateDialog] = useState<boolean>(false);
    const {
        editQueueRef,
        debounceTimeoutRef,
        setColumnsCallbackTimeoutRef,
        colStateTimeoutRef,
        timeoutRef,
        lockRefresh
    } = loadRefsForAgTable();
    // const editQueueRef: MutableRefObject<any[]> = useRef([]);
    // const debounceTimeoutRef: MutableRefObject<NodeJS.Timeout | null | undefined> = useRef();

    useEffect(() => {
        if (!gridReady) return;
        const newDefaultTop = pinnedTopRowDataConfiguration;
        const newDefaultBot = pinnedBottomRowDataConfiguration;
        if (newDefaultTop?.length) {
            localStorage.setItem(`${tableControls?.tableName}_pinnedRowTopDefault`, JSON.stringify(newDefaultTop));
        } else {
            localStorage.removeItem(`${tableControls?.tableName}_pinnedRowTopDefault`);
        }
        if (newDefaultBot?.length) {
            localStorage.setItem(`${tableControls?.tableName}_pinnedRowBotDefault`, JSON.stringify(newDefaultBot));
        } else {
            localStorage.removeItem(`${tableControls?.tableName}_pinnedRowBotDefault`);
        }
    }, [pinnedTopRowDataConfiguration, pinnedBottomRowDataConfiguration]);

    const onCellEditingStoppedQ = useCallback(
        (params) => {
            editQueueRef.current = [...editQueueRef.current, params];
            // If a timeout is already pending, clear it
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }

            // Set a new timeout
            debounceTimeoutRef.current = setTimeout(() => {
                // Process the queue here
                const many = editQueueRef.current.map((edit) => edit)
                editQueueRef.current = [];
                debounceTimeoutRef.current = null;

                onManyCellEditingStopped(many, setEditMode, setStatusBarData, setValueAlert, setEditList, editList, editCallback, editCallbackMany, tableControls?.allowEditSameValue)


                // Clear the queue and the timeout reference
            }, tableControls?.manyEditTime ?? 500);
        }, [editList, editCallback, editCallbackMany, tableControls?.manyEditTime, tableControls?.allowEditSameValue])
    useEffect(() => {
        if (!resetTableDialog) return;
        setPinnedTopRowDataConfiguration(
            pinnedControl?.pinnedRowTopDefault?.map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c))?.map((c) => c.id) ||
            []
        );
        setPinnedBottomRowDataConfiguration(
            pinnedControl?.pinnedRowBotDefault?.map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c))?.map((c) => c.id) ||
            []
        );
    }, [resetTableDialog]);

    const handleRowDragLeave = useCallback(
        (event) => {
            const draggedRowNodes = event.nodes;
            if (!event.node) {
                return;
            }
            if (event.node.group) {
                const newRowData = newRowDataDD2;
                const fromGroupKey = draggedRowNodes[0].key;
                const groupingField = draggedRowNodes[0]?.field;
                const draggedGroupRows = newRowData.filter((row) => row && row[groupingField] === fromGroupKey);
                const newRowDataWithoutDraggedGroup = newRowData.filter((row) => row && row[groupingField] !== fromGroupKey);
                newRowDataWithoutDraggedGroup.splice(0, 0, ...draggedGroupRows);
                tableRef.current.api.applyTransaction({remove: newRowDataWithoutDraggedGroup});
                tableRef.current.api.applyTransaction({add: newRowDataWithoutDraggedGroup});
            }
        },
        [newRowDataDD2]
    );
    const handleRowDragEnd = useCallback(
        (event) => {
            const newRowData = newRowDataDD2;
            const draggedRowNodes = event.nodes;
            if (!event.node) {
                return;
            }
            const fromIndex = draggedRowNodes[0].rowIndex;

            const isDragGroup = draggedRowNodes[0].group;
            if (isDragGroup) {
                const fromGroupKey = draggedRowNodes[0].key;
                const toGroupKey = event?.overNode?.key;
                const groupingField = draggedRowNodes[0]?.field;
                if (fromGroupKey !== toGroupKey) {
                    const draggedGroupRows = newRowData.filter((row) => row && row[groupingField] === fromGroupKey);
                    const newRowDataWithoutDraggedGroup = newRowData.filter((row) => row && row[groupingField] !== fromGroupKey);

                    const targetGroupLastRowIndex = newRowDataWithoutDraggedGroup.findIndex((row, index, arr) => {
                        if (row[groupingField] === toGroupKey) {
                            if (index === arr.length - 1 || arr[index + 1][groupingField] !== toGroupKey) {
                                return true;
                            }
                        }

                        return false;
                    });
                    newRowDataWithoutDraggedGroup.splice(targetGroupLastRowIndex + 1, 0, ...draggedGroupRows);

                    tableRef.current.api.applyTransaction({remove: newRowDataWithoutDraggedGroup});
                    tableRef.current.api.applyTransaction({add: newRowDataWithoutDraggedGroup});
                }
                return;
            }

            handleRowDragMove(event, true);
            tableControls?.drag?.customeDragEndAction?.(event);
        },
        [newRowDataDD2]
    );
    const onStartDrag = useCallback((event) => {
        const allRowObjects: any = [];
        tableRef.current.api.forEachNode(function (rowNode) {
            allRowObjects.push(rowNode.data);
        });
        const allRowObjects2: any = [];
        tableRef.current.api.forEachNodeAfterFilterAndSort(function (rowNode) {
            allRowObjects2.push(rowNode.data);
        });
        // M - potentially redundant code, no use of NewRowDataDD
        setNewRowDataDD(allRowObjects);
        setNewRowDataDD2(allRowObjects2);
    }, []);
    const handleRowDragMove = useCallback(
        (event) => {
            const newRowData = newRowDataDD2;
            const draggedRowNodes = event.nodes;

            if (!event.node || !event.overNode) {
                return;
            }

            const fromIndex = draggedRowNodes[0].rowIndex;
            const lastIndex = draggedRowNodes[draggedRowNodes.length - 1].rowIndex;
            const toIndex = event.overNode.rowIndex;

            const isDragGroup = draggedRowNodes[0].group;
            const isToDragGroup = event.overNode.group;

            if (isDragGroup && isToDragGroup) {
                const fromGroupKey = draggedRowNodes[0].key;
                const toGroupKey = event.overNode.key;
                const groupingField = event.overNode.field;
                if (fromGroupKey !== toGroupKey) {
                    const draggedGroupRows = newRowData.filter((row) => row && row[groupingField] === fromGroupKey);
                    const newRowDataWithoutDraggedGroup = newRowData.filter((row) => row && row[groupingField] !== fromGroupKey);

                    const targetGroupLastRowIndex = newRowDataWithoutDraggedGroup.findIndex((row, index, arr) => {
                        if (row[groupingField] === toGroupKey) {
                            return true;
                        }
                        if (index === arr.length - 1 || arr[index + 1][groupingField] !== toGroupKey) {
                            return true;
                        }
                        return false;
                    });

                    newRowDataWithoutDraggedGroup.splice(targetGroupLastRowIndex + 1, 0, ...draggedGroupRows);

                    tableRef.current.api.applyTransaction({remove: draggedGroupRows});
                }
                return;
            } else if (isDragGroup) {
                return;
            } else if (isToDragGroup) {
                // Expand the group you are hovering above
                tableRef.current.api.setRowNodeExpanded(event.overNode, true);

                return;
            }

            if (event.overNode.parent) {
                const groupingField = event.overNode.parent.field;
                const fromGroupKey = draggedRowNodes[0].data[groupingField];
                const toGroupKey = event.overNode.data[groupingField];

                if (fromGroupKey !== toGroupKey) {
                    // The groups are different
                    // Remove dragged rows from source group
                    draggedRowNodes.forEach((m, index) => {
                        newRowData.splice(m.rowIndex - index, 1);
                    });

                    // Update the group key for the dragged rows
                    const updatedDraggedRows = draggedRowNodes.map((m) => {
                        return {
                            ...m.data,
                            category: toGroupKey
                        };
                    });

                    // Add dragged rows to the target group
                    newRowData.splice(toIndex, 0, ...updatedDraggedRows);
                    tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
                    return;
                }
            }

            if (lastIndex - fromIndex + 1 !== draggedRowNodes.length) {
                draggedRowNodes.forEach((m, index) => {
                    newRowData.splice(m.rowIndex - index, 1);
                });
                newRowData.splice(fromIndex, 0, ...draggedRowNodes.map((m) => m.data));
                tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
            } else if (fromIndex - toIndex >= 1) {
                newRowData.splice(fromIndex, draggedRowNodes.length);
                newRowData.splice(toIndex, 0, ...draggedRowNodes.map((m) => m.data));
                tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
            } else if (toIndex - fromIndex >= draggedRowNodes.length) {
                newRowData.splice(fromIndex, draggedRowNodes.length);
                newRowData.splice(toIndex - draggedRowNodes.length, 0, ...draggedRowNodes.map((m) => m.data));
                tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
            }
        },
        [newRowDataDD2]
    );

    const tableRef = outSideTableRef ? outSideTableRef : useRef<any>(null);

    const pushNewRow = useCallback((c, top) => {
        if (top) setPinnedTopRowDataConfiguration((prev) => [...prev, c.id]);
        else setPinnedBottomRowDataConfiguration((prev) => [...prev, c.id]);
    }, []);
    const removeRow = useCallback((index, top) => {
        if (top) {
            setPinnedTopRowDataConfiguration((prev) => {
                const newFormat = [...prev];
                newFormat.splice(index, 1);
                return newFormat;
            });
        } else {
            setPinnedBottomRowDataConfiguration((prev) => {
                const newFormat = [...prev];
                newFormat.splice(index, 1);
                return newFormat;
            });
        }
    }, []);

    const [pinnedRowsSideBarControl, setPinnedRowsSideBarControl] = useState({
        topCollapse: false,
        bottomCollapse: false
    });

    const pinnedRowPropsForSideBar = useMemo(() => {
        return {
            pushNewRow: pushNewRow,
            removeRow: removeRow,
            pinnedRowControls: pinnedControl?.pinnedRowControlsNew,
            pinnedTopRowDataConfiguration: pinnedTopRowDataConfiguration,
            pinnedBottomRowDataConfiguration: pinnedBottomRowDataConfiguration,
            pinnedRowsSideBarControl: pinnedRowsSideBarControl.topCollapse,
            setPinnedRowsSideBarControl: (value: boolean) =>
                setPinnedRowsSideBarControl({
                    ...pinnedRowsSideBarControl,
                    topCollapse: value
                })
        };
    }, [
        pushNewRow,
        removeRow,
        pinnedControl?.pinnedRowControlsNew,
        pinnedTopRowDataConfiguration,
        pinnedBottomRowDataConfiguration,
        pinnedRowsSideBarControl.topCollapse
    ]);

    const setColumnsCallback = useCallback(() => {
        const columnsForDef = setColumns(columns, tableControls, menuiItems, undefined, cellRenderer);
        setColumnDefs(columnsForDef);
        setTimeout(() => {
            tableRef?.current?.columnApi?.addValueColumns(columnsForDef.filter((c) => c.aggFunc).map((c) => c.id));
            setIsLoaded(true);
            setColumnState();
        }, 50);
    }, [columns, tableControls, tableRef, tableControls, cellRenderer, menuiItems]);
    const setCellRenderrerColumnsCallback = useCallback(() => {
        if (isLoaded) {
            const defs = tableRef?.current?.api?.getColumnDefs()?.slice();

            const newDefs = defs?.map((d: any) => {
                const column = columns.find((c) => c.id === d.id);
                const cd = {
                    ...d,
                    cellRenderer:
                        column && (tableControls?.cellRenderer ?? cellRenderer)
                            ? (params) => (tableControls?.cellRenderer ?? cellRenderer)(params, column)
                            : null
                };
                delete cd.colId;
                return cd;
            });

            setTimeout(() => {

                tableRef?.current?.api?.setColumnDefs(newDefs);
            }, 0);
            setTimeout(() => {
                setColumnState();
            }, 100);
        }
    }, [columns, tableControls, tableRef, cellRenderer, isLoaded]);

    //--- set columns
    useEffect(() => {
        if (cellRenderer) setCellRenderrerColumnsCallback();
    }, [cellRenderer]);

    useEffect(() => {
        if (clearEditFromAbove && clearEditFromAbove.size > 0) {
            const keep: any = [];
            editList.forEach((e) => {
                if (!clearEditFromAbove.has(e.id)) {
                    keep.push(e);
                }
            });
            setEditList(keep);
            clearEditFromAbove.clear();
        }
    }, [clearEditFromAbove]);


    useEffect(() => {
        setGridReady(false);
        if (setColumnsCallbackTimeoutRef.current) {
            clearTimeout(setColumnsCallbackTimeoutRef.current);
        }
        setColumnsCallbackTimeoutRef.current = setTimeout(() => {
            setColumnsCallback();
        }, 400); // 1 second delay
    }, [columns, menuiItems]);

    //--- set data
    useEffect(() => {
        if (!rowData.length || rowData !== data) {
            setRowData(data?.map((item: any) => ({...item})));
            tableRef?.current?.api?.refreshHeader();
            setStatusBarData({
                numberOfItems: data?.length,
                selectedRows: 0,
                filteredRows: 0,
                editedRows: 0
            });
        }

        if (tableControls.additionalData) {
            setRowData(data?.map((item: any) => ({...item})));
            tableRef?.current?.api?.refreshHeader();
            setStatusBarData({
                numberOfItems: data?.length,
                selectedRows: 0,
                filteredRows: 0,
                editedRows: 0
            });
        }

        // if(tableRef && false){
        //     if(filters && Object.keys(filters).length > 0){
        //         const state = tableRef?.current.columnApi?.getColumnState();
        //         const filteredState = mapFilterState(filters,state);
        //         setTimeout(() => {
        //             loadSavedStates(state, null, tableRef,filteredState);
        //
        //         },800)
        //     }
        // }


    }, [data, columns]);

    //--- set columns state
    const setColumnState = useCallback(() => {
        if (colStateTimeoutRef.current) {
            clearTimeout(colStateTimeoutRef.current);
        }
        colStateTimeoutRef.current = setTimeout(() => {
            getSavedColumnState(tableRef, tableControls, gridReady);
            setGridReady(true);

        }, 500); // 1 second delay
    }, [tableRef, tableControls]);


    const setFilterMod = useCallback((fm) => {
        const cs2 = tableRef?.current?.columnApi?.getColumnState()
        const fs = mapFilterState(fm, cs2)
        tableRef?.current?.api?.setFilterModel(fs)


    }, [tableRef, tableControls]);

    // - check for custome user buttons
    // M - transfer to function loadLocalStorage(name), transfer tableControls.tableName+ -customeButtons to constants.
    useEffect(() => {
        const customeButtonsStorage = localStorage.getItem(tableControls?.tableName + '-customeButtons');
        const customeButtons = customeButtonsStorage ? JSON.parse(customeButtonsStorage) : [];
        setCustomeUserButtons(customeButtons);
    }, []);

    useEffect(() => {
        if (refreshCells && refreshCells.size > 0) {
            setTimeout(() => {
                const nodes: any = [];
                refreshCells.forEach((cell) => {
                    const node = tableRef?.current?.api?.getRowNode(cell);
                    if (node) {
                        nodes.push(node);
                    }
                });

                refreshCells.clear();
                setTimeout(() => {
                    tableRef?.current?.api?.refreshCells({rowNodes: nodes, force: true});

                }, 0);

                setPinnedRowDataCallback();
            }, 650);
        }
    }, [refreshCells]);


    // costume functions
    const costumAggFunctions = useMemo(() => {
        const x = costumFunction();
        if (costumFunc) {
            Object.keys(costumFunc).forEach((key) => {
                x[key] = costumFunc[key]
            })
        }
        return x
    }, []);


    const setPinnedRowDataCallback = useCallback(() => {
        if (tableControls.noPinned) return;
        // Clear the previous timeout if there is one
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timeout
        timeoutRef.current = setTimeout(() => {
            const filterdNodeData: any = [];
            tableRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => {
                node.data && filterdNodeData.push(node.data);
            });
            // build top
            let top = [];
            let bot = [];

            pinnedTopRowDataConfiguration
                .map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c))
                .forEach((c) => {
                    let r = {};
                    console.log(c);
                    if (c?.editable) {
                        r = c.editableData;
                        r.editable = c.editable;
                    } else {
                        if (c?.sumByConfigurations && pinnedControl?.pinnedRowControlsNew) {
                            const conf1 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[0]);
                            const conf2 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[1]);
                            let r1;
                            let r2;
                            if (conf1!.editableData) {
                                r1 = conf1!.editableData;
                            } else {
                                r1 = createPinnedRowDataNew(columns, filterdNodeData, conf1, true);
                            }
                            if (conf2!.editableData) {
                                r2 = conf2!.editableData;
                            } else {
                                r2 = createPinnedRowDataNew(columns, filterdNodeData, conf2, true);
                            }
                            Object.keys(r1).forEach((k) => {
                                switch (c.sumByConfigurations.func) {
                                    case 'sum':
                                        if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k])))
                                            r[k] = (Number(r1[k]) ?? 0) + (Number(r2[k]) ?? 0);
                                        else r[k] = '';
                                        break;
                                    case 'minus':
                                        if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k])))
                                            r[k] = (Number(r1[k]) ?? 0) - (Number(r2[k]) ?? 0);
                                        else r[k] = '';
                                        break;
                                }
                            });
                            r[c.sumByConfigurations.name] = c.label;
                        } else {
                            if (c?.editableData) {
                                r = c.editableData;
                            } else r = createPinnedRowDataNew(columns, filterdNodeData, c);
                        }
                    }

                    top.push(r);
                });

            pinnedBottomRowDataConfiguration
                .map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c))
                .forEach((c) => {
                    let r = {};
                    if (c?.editable) {
                        r = c.editableData;
                        r.editable = c.editable;
                    } else {
                        if (c?.sumByConfigurations && pinnedControl?.pinnedRowControlsNew) {
                            const conf1 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[0]);
                            const conf2 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[1]);
                            let r1;
                            let r2;
                            if (conf1!.editableData) {
                                r1 = conf1!.editableData;
                            } else {
                                r1 = createPinnedRowDataNew(columns, filterdNodeData, conf1);
                            }
                            if (conf2!.editableData) {
                                r2 = conf2!.editableData;
                            } else {
                                r2 = createPinnedRowDataNew(columns, filterdNodeData, conf2);
                            }
                            Object.keys(r1).forEach((k) => {
                                switch (c.sumByConfigurations.func) {
                                    case 'sum':
                                        if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k])))
                                            r[k] = (Number(r1[k]) ?? 0) + (Number(r2[k]) ?? 0);
                                        else r[k] = '';
                                        break;
                                    case 'minus':
                                        if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k])))
                                            r[k] = (Number(r1[k]) ?? 0) - (Number(r2[k]) ?? 0);
                                        else r[k] = '';
                                        break;
                                }
                            });
                            r[c.sumByConfigurations.name] = c.label;
                        } else {
                            if (c?.editableData) {
                                r = c.editableData;
                            } else r = createPinnedRowDataNew(columns, filterdNodeData, c);
                        }
                    }

                    bot.push(r);
                });

            // Set the pinned top and bottom row data after a delay
            setTimeout(() => {
                if (tableRef?.current?.api) {
                    tableRef?.current?.api.setPinnedTopRowData(top.length > 0 ? top : null);
                    tableRef?.current?.api.setPinnedBottomRowData(bot.length > 0 ? bot : null);
                }
                lockRefresh.current = false;
            }, 0); // Execute after the current call stack has cleared
        }, 1000); // 1 second delay
    }, [columns, pinnedBottomRowDataConfiguration, pinnedTopRowDataConfiguration, pinnedControl, tableControls]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (colStateTimeoutRef.current) {
                clearTimeout(colStateTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (gridReady) {
            const pinnedTop = tableRef?.current?.api.getPinnedTopRowCount();
            const pinnedBot = tableRef?.current?.api.getPinnedBottomRowCount();
            if (pinnedTop == 0 && pinnedBot == 0) {
                if (!lockRefresh.current) {
                    lockRefresh.current = true;
                    setPinnedRowDataCallback();
                }
            }
            setTimeout(() => {
                tableRef?.current?.api.onFilterChanged();
            }, 0);

        }
    }, [gridReady]);

    useEffect(() => {
        if (gridReady) {
            statusBarChange(tableRef.current);
            if (!lockRefresh.current) {
                lockRefresh.current = true;
                setPinnedRowDataCallback();
            }
        }
    }, [filterChange, rowData, pinnedBottomRowDataConfiguration, pinnedTopRowDataConfiguration, pinnedControl]);

    // listen to screen size change and set isPhone
    useEffect(() => {
        const handleResize = () => {
            setIsPhone(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // change status bar data
    const statusBarChange = useCallback((params: any) => {
        setStatusBarData((prev) => {
            return {
                numberOfItems: prev?.numberOfItems,
                selectedRows: params?.api?.getSelectedRows()?.length,
                filteredRows: params?.api?.isAnyFilterPresent() ? params?.api?.getDisplayedRowCount() : 0,
                editedRows: prev?.editedRows
            };
        });
    }, []);

    //  default column settings
    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 200,
            filter: true, //"agMultiColumnFilter",
            floatingFilter: tableControls.floatingFilters ?? true,
            flex: 1,
            enableRowGroup: tableControls?.groupBy?.rowGroup,
            enablePivot: tableControls?.groupBy?.pivot,
            enableValue: tableControls?.groupBy?.value,
            //floatingFilterComponent: (params) => <AgTableHeaderSearch params={params} tableRef={tableRef} />,
        };
    }, []);

    const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);

    const [tableHeightNum, setTableHeight] = useState(0);

    useEffect(() => {
        const table = document.getElementById(tableControls?.customeId ? tableControls?.customeId : 'actual-ag-table');
        if (table) {
            setTimeout(() => {
                if (isPhone) {
                    const rect = table.getBoundingClientRect();
                    setTableHeight(window.outerHeight - rect.top - 20);
                }
                const rect = table.getBoundingClientRect();
                setTableHeight(window.innerHeight - rect.top - 20);
            }, 500)
        }
    }, [data, rowData, leftDrawerOpened]);

    const tableHeight = useMemo(() => {
        const table = document.getElementById(tableControls?.customeId ? tableControls?.customeId : 'actual-ag-table');
        if (table) {
            if (isPhone) {
                const rect = table.getBoundingClientRect();
                return window.outerHeight - rect.top - 20;
            }
            const rect = table.getBoundingClientRect();
            return window.innerHeight - rect.top - 20;
        }
    }, [data, rowData, leftDrawerOpened, tableHeightNum]);

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        if (tableControls?.groupBy?.headerName)
            return {
                headerName: tableControls?.groupBy?.headerName,
                width: 200,
                lockPosition: true,
                pinned: 'right',
                filter: 'agGroupColumnFilter',
                lockPinned: true,
                aggFunc: 'count'
            };
        else
            return {
                width: 200,
                lockPosition: true,
                pinned: 'right',
                filter: 'agGroupColumnFilter',
                lockPinned: true
            };
    }, [tableControls?.groupBy?.headerName]);
    return (
        <div id="ag-table-main-div">
            {
                <div
                    style={{
                        marginBottom: 10,
                        position: 'relative',
                        display: costumeControls || selectionControls || chipDetails || tableControls?.allowReorder ? 'flex' : 'none',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <div style={AgTableMainInnerStyle}>
                        {chipDetails && (selectedItems.length == 0 || tableControls?.allowReorder) && (
                            <AgChips
                                gridReady={gridReady}
                                chipList={chipDetails}
                                chipDisplayAmount={tableControls?.chipDisplayAmount}
                                reOrderMode={reOrderMode}
                                setReOrderMode={setReOrderMode}
                                params={tableRef?.current}
                                tableControls={tableControls}
                                rowData={rowData}
                                setRowData={setRowData}
                            />
                        )}
                    </div>
                    <div style={AgTableMainInnerStyle}>
                        {selectionControls && selectedItems.length ? selectionControls(tableRef.current.api.getSelectedRows()) : null}
                        {costumeControls ? costumeControls() : null}
                    </div>
                </div>
            }
            <div style={tableControls?.groupBy?.enable ? {} : AgTableActionsBarStyle}>
                {
                    // if isPhone create mui icon to open table actions bar
                    isPhone && (
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 10000,
                                marginTop: tableControls?.groupBy?.enable ? 50 : 20,
                                padding: 0
                            }}
                            onClick={() => setTableActionsOpen(true)}
                        >
                            <MoreVert fontSize="large"/>
                        </IconButton>
                    )
                }
                {isPhone ? (
                    <Dialog
                        open={isPhone && tableActionsOpen}
                        onClose={() => setTableActionsOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        id="ag-table-actions-dialog"
                    >
                        <AgTableActionsBar
                            rowData={rowData}
                            setRowData={setRowData}
                            statusBarData={statusBarData}
                            setStatusBarData={setStatusBarData}
                            tableRef={tableRef}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            tableControls={tableControls}
                            setResetTableDialog={setResetTableDialog}
                            setEditList={setEditList}
                            editList={editList}
                            allowGraph={tableControls?.allowGraph}
                            columns={columns}
                            setPinnedRowDataCallback={setPinnedRowDataCallback}
                            filterChange={filterChange}
                            sortChange={sortChange}
                            customHeaderComponents={customHeaderComponents}
                            saveDataCallback={saveDataCallback}
                            addDataAction={addDataAction}
                            customeCancelEdit={customeCancelEdit}
                            gridReady={gridReady}
                            setFilters={setFilters}
                            setIsExternalFilterPresent={setIsExternalFilterPresent}
                        />
                    </Dialog>
                ) : (
                    <AgTableActionsBar
                        rowData={rowData}
                        setRowData={setRowData}
                        statusBarData={statusBarData}
                        setStatusBarData={setStatusBarData}
                        tableRef={tableRef}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        tableControls={tableControls}
                        setResetTableDialog={setResetTableDialog}
                        setEditList={setEditList}
                        editList={editList}
                        allowGraph={tableControls?.allowGraph}
                        columns={columns}
                        setPinnedRowDataCallback={setPinnedRowDataCallback}
                        filterChange={filterChange}
                        sortChange={sortChange}
                        customHeaderComponents={customHeaderComponents}
                        saveDataCallback={saveDataCallback}
                        addDataAction={addDataAction}
                        customeCancelEdit={customeCancelEdit}
                        gridReady={gridReady}
                        setFilters={setFilters}
                        setIsExternalFilterPresent={setIsExternalFilterPresent}
                    />
                )}
            </div>
            <div
                id={tableControls?.customeId ? tableControls?.customeId : 'actual-ag-table'}
                className="ag-theme-alpine"
                style={{height: tableHeight, minWidth: '100%'}}
                onContextMenu={(e) => {
                    openColumnMenuOnRightClick(e, tableRef);
                }}
            >
                {isLoaded ? (
                    <AgGridReact
                        //---- basic settings ----//
                        ref={tableRef}
                        // column def (column settings)

                        columnDefs={columnDefs}
                        // default column settings
                        defaultColDef={defaultColDef}
                        // row data
                        rowData={rowData}
                        // enable sidebar
                        sideBar={
                            tableControls?.sidebar &&
                            sideBarDef(
                                tableRef,
                                tableControls,
                                customeUserState,
                                setCustomeUserState,
                                customeUserButtons,
                                setCustomeUserButtons,
                                setOpenCreateStateDialog,
                                pinnedRowPropsForSideBar,
                                pinnedControl
                            )
                        }
                        // enable nested table
                        masterDetail={tableControls?.nestedColumns?.enable}
                        //---- general settings ----//
                        autoGroupColumnDef={autoGroupColumnDef}
                        // pinned top row data
                        // pinnedTopRowData={pinnedTopRowData?.length ? pinnedTopRowData : undefined}
                        // pinnedBottomRowData={pinnedBottomRowData?.length ? pinnedBottomRowData : undefined}
                        // debug={true}
                        // costume agg functions
                        aggFuncs={costumAggFunctions}


                        chartToolPanelsDef={{
                            defaultToolPanel: 'data',
                            dataPanel: {
                                groups: [
                                    {type: 'seriesChartType', isOpen: true},
                                    {type: 'categories', isOpen: true},
                                    {type: 'series', isOpen: true}
                                ]
                            }
                        }}
                        maintainColumnOrder={true}
                        // costume right click menu
                        getContextMenuItems={() =>
                            rightClickItems(
                                tableRef,
                                statusBarData,
                                tableControls,
                                setGrossValueChangeModal,
                                setGrossValue,
                                extentedContextMenu,
                                rightClickActions,
                                menuiItems
                            )
                        }
                        getMainMenuItems={getMainMenuItems}
                        // enable charts
                        enableCharts={true}
                        // enable to change data on drag
                        enableFillHandle={true}

                        // status bar
                        //statusBar={statusBar}

                        // enable right to left
                        enableRtl={true}
                        getRowId={(row) => {
                            return tableControls.extractId ? tableControls.extractId(row) : Number(row?.data?.id);
                        }}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        // enable group by panel
                        rowGroupPanelShow={tableControls?.groupBy?.enable ? 'always' : 'never'}
                        showOpenedGroup={true}
                        // locale text
                        localeText={AG_GRID_LOCALE_HE}
                        detailCellRenderer={
                            tableControls?.nestedColumns?.isDataDisplay
                                ? (params) => {
                                    getDetailCellRenderer(params, tableControls);
                                }
                                : undefined
                        }
                        detailRowHeight={70}
                        //---- group settings ----//

                        // group checkbox select all children
                        groupSelectsChildren={true}
                        enableBrowserTooltips={false}
                        // sticky group rows

                        groupDisplayType={'multipleColumns'}
                        multiSortKey={'ctrl'}
                        suppressScrollOnNewData={true}
                        groupRemoveSingleChildren={tableControls?.groupBy?.removeSingleChildren}
                        suppressAggFuncInHeader={true}
                        //---- drag and drop settings ----//

                        // enable row drag
                        rowDragManaged={!tableControls?.drag?.complex}
                        onDragStarted={tableControls?.drag?.complex ? onStartDrag : undefined}
                        onRowDragEnd={tableControls?.drag?.complex ? handleRowDragEnd : undefined}
                        onRowDragMove={tableControls?.drag?.complex ? handleRowDragMove : undefined}
                        onRowDragLeave={tableControls?.drag?.complex ? handleRowDragLeave : undefined}
                        // enable row animation
                        animateRows={true}
                        // enable multi row drag
                        rowDragMultiRow={tableControls?.drag?.multiRow}
                        //---- selection settings ----//

                        // allow multiple / single selection
                        rowSelection={tableControls?.selection?.mode}
                        // enable range selection
                        enableRangeSelection={tableControls?.selection?.enable}
                        // disable row click selection (only checkbox selection)
                        suppressRowClickSelection={tableControls?.selection?.enable}
                        //---- events ----//

                        // listen for grid ready
                        onGridReady={(params) => {
                            console.log("onGridReady")
                            setTimeout(() => {
                                params.api.setHeaderHeight(60);
                            }, 0)

                            setTimeout(() => {
                                onLoad ? onLoad(params) : undefined;
                            }, 1000);
                        }}
                        groupSelectsFiltered={true}
                        undoRedoCellEditing={true}
                        undoRedoCellEditingLimit={6}
                        // listen for column group changes
                        onColumnRowGroupChanged={(params: any) => {
                            const groupCol = params?.columnApi?.getRowGroupColumns();

                            if (tableControls?.defaultGroupBy) {
                                if (!groupCol.length || !groupCol.some((col: any) => col.colId === tableControls?.defaultGroupBy)) {
                                    params.columnApi.addRowGroupColumn(tableControls?.defaultGroupBy);
                                    setDefaultGroupAlert(true);
                                }
                            }
                            saveColumnState(params, tableControls, gridReady, params?.columns);
                        }}
                        onCellClicked={(params) => {
                            if (params?.rowPinned === 'top') {
                                const numOfRows = params?.api?.getDisplayedRowCount();
                                params?.api?.addCellRange({
                                    rowStartIndex: 0,
                                    rowEndIndex: numOfRows,
                                    columns: [params?.column]
                                });
                            }
                            if (onCellClick) {
                                onCellClick(params);
                            }
                        }}
                        onCellFocused={(params) => {
                            if (onCellFocused) {
                                onCellFocused(params);
                            }
                        }}
                        // listen for selection changes
                        onSelectionChanged={(params) => {
                        const selectedRows = getSelectedRows(params.api)
                            setSelectedItems(selectedRows);
                            statusBarChange(params);
                        }}
                        // listen for filter changes

                        onFilterModified={(params) => {
                            setFilterChange(!filterChange);
                        }}
                        // listen for sort changes
                        onSortChanged={(params) => {
                            setSortChange(!sortChange);
                            setTimeout(() => {
                                params.api.refreshCells({force: true});
                            }, 0);
                            saveColumnState(params, tableControls, gridReady);
                        }}
                        // listen for row group open
                        onRowGroupOpened={(params) => statusBarChange(params)}
                        // listen for column pinning
                        onColumnPinned={(params) => {
                            onColumnPinned(params, tableControls, gridReady);
                        }}
                        // listen if column becomes visible
                        onColumnVisible={(params) => onColumnVisible(params, tableControls, gridReady)}
                        // listen for filter changes
                        onFilterChanged={(params: any) => {
                            if (filterQ) {
                                setFilterChange(!filterChange);
                            } else {
                                statusBarChange(params);
                            }
                            setTimeout(() => {
                                onFilterChanged(params);
                            }, 0);
                        }}
                        // for editing and navigation
                        onCellKeyDown={(params: any) => !tableControls.strictEdit && onCellKeyDown(params, tableRef, menuiItems)}
                        // list to mouse down
                        onCellMouseDown={(params: any) => {
                            if (params.event.detail === 2 && params?.column?.colDef?.editable) {
                                params.api.startEditingCell({
                                    rowIndex: params.rowIndex,
                                    colKey: params.column.colId
                                });
                            }
                        }}
                        onCellValueChanged={(params) => {
                            processCellValueChange(
                                params,
                                editList,
                                statusBarData,
                                setStatusBarData,
                                setEditMode,
                                setEditList,
                                menuiItems,
                                setValueAlert,
                                onCellEditingStoppedQ
                            );
                        }}
                        onCellEditingStarted={(params) => {
                            if (params?.rowPinned && !params?.data?.editable) {
                                params.api.stopEditing();
                                return;
                            }
                        }}
                        onCellEditingStopped={(params) => {
                            if (params?.rowPinned) {
                                if (params?.data?.editable) {
                                    params?.data?.editable(params);
                                } else {
                                    params.api.stopEditing();
                                }

                                return;
                            }
                            if (!tableControls.allowEditSameValue && params?.newValue == params?.oldValue && editMode) {
                                statusBarData.editedRows == 0 && setEditMode(false);
                                const isInEditList = editList?.find((node) => node?.id === params?.node?.id);
                                !isInEditList && params.data.hasBeenEditedFromStart ? delete params.data.hasBeenEditedFromStart : null;
                                !isInEditList && params.data.hasBeenEdited ? delete params.data.hasBeenEdited : null;
                                params.api.refreshCells({force: true, rowNodes: [params.node]});
                            }
                            if (tableControls.strictEdit) {
                                onCellEditingStoppedQ(params);
                                params.colDef.editable = false;
                            } else {
                                onCellEditingStoppedQ(params);
                            }
                        }}
                        // column moved

                        onColumnMoved={(params: any) => {
                            onColumnMoved(params, tableControls, gridReady);
                            onColumnMoveCallback && onColumnMoveCallback(params);
                        }}
                        onColumnGroupOpened={(params: any) => onColumnGroupOpened(params, tableControls, gridReady)}
                        // column resized
                        onColumnResized={(params: any) => onColumnResized(params, tableControls, gridReady)}
                        stopEditingWhenCellsLoseFocus={true}
                        //---- nested table ----//
                        detailCellRendererParams={{
                            detailGridOptions: {
                                columnDefs: nestedColumnsDefs,
                                defaultColDef: defaultColDef,
                                enableRtl: true,
                                enableRangeSelection: true,
                                enableCharts: true,
                                cellStyle: {direction: 'rtl'}
                            },
                            getDetailRowData: (params) => {
                                const nestedData = params.data?.[tableControls?.nestedColumns?.subData as keyof typeof params.data] || [];
                                params.successCallback(nestedData);
                            }
                        }}
                    />
                ) : (
                    <CircularProgress/>
                )}
            </div>
            {resetTableDialog ? (
                <ResetTableDialog
                    open={resetTableDialog}
                    openDialog={setResetTableDialog}
                    resetTable={() => resetTable(tableRef, tableControls, columnDefs, setCustomeUserState, gridReady)}
                />
            ) : undefined}
            {grossValueChangeModal ? (
                <GrossValueChangeDialog
                    grossValueChangeModal={grossValueChangeModal}
                    setGrossValueChangeModal={setGrossValueChangeModal}
                    grossValue={grossValue}
                    setGrossValue={setGrossValue}
                    tableRef={tableRef}
                    setEditMode={setEditMode}
                    statusBarData={statusBarData}
                    setStatusBarData={setStatusBarData}
                    tableControls={tableControls}
                    setValueAlert={setValueAlert}
                    setEditList={setEditList}
                    editList={editList}
                    menuiItems={menuiItems}
                />
            ) : undefined}

            {valueAlert ? (
                <SnackBarAlert
                    open={valueAlert}
                    onClose={() => setValueAlert(false)}
                    autoHideDuration={6000}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    value={AG_GRID_LOCALE_HE.valueAlertText}
                />
            ) : undefined}
            <CreateStateDialog
                openCreateStateDialog={openCreateStateDialog}
                setOpenCreateStateDialog={setOpenCreateStateDialog}
                tableRef={tableRef}
                customeUserButtons={customeUserButtons}
                setCustomeUserButtons={setCustomeUserButtons}
                tableControls={tableControls}
                setCustomeUserState={setCustomeUserState}
            />
            {specialDialogs && specialDialogs(tableRef?.current)}
            {defaultGroupAlert ? (
                <SnackBarAlert
                    open={defaultGroupAlert}
                    onClose={() => setDefaultGroupAlert(false)}
                    autoHideDuration={3000}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    value={AG_GRID_LOCALE_HE.defaultGroupAlertText}
                />
            ) : undefined}
        </div>
    );
};
export function getSelectedRows(api) {
    const sortedSelectedRows = [];
    api?.forEachNodeAfterFilterAndSort((node) => {
        if (node.isSelected() && node.data) {

            sortedSelectedRows.push(node.data);
        }
    });
    return sortedSelectedRows;
}
export default React.memo(AgTable);
