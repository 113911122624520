import {Info} from "@mui/icons-material";
import {List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {Box} from "@mui/system";
import dayjs, {Dayjs} from "dayjs";

interface IUpdate {
    title: string;
    description?: string;
    date: Date | Dayjs;
}

const updates: IUpdate[] = [{
    title: "מעמ עולה ל18% החל מ2025",
    description: "כל חשבונית שתופק עם תאריך מסמך מעל 1.1.25 יתווסף מעמ 18% גם אם יצאו תעודות על שנת 24",
    date: dayjs().year(2024).month(11).date(22), // example of a date
},{
    title: "תשלום חלקי על חשבוניות",
    description: "ברגע שמסמנים חשבניות לסגירה התוכנה אוטומתית מחשבת מה פתוח וסגור",
    date: dayjs().year(2024).month(11).date(22), // example of a date
},{
    title: "הוספת תכנון מסלולים עם מפה בהזמנות",
    description: "ניתן לבחור תאריכים מהר",
    date: dayjs().year(2024).month(4).date(25), // example of a date
},
    {
        title: "תמיכה בחשבונית ישראל",
        description: "ניתן לבחור תאריכים מהר",
        date: dayjs().year(2024).month(3).date(24), // example of a date
    }
].sort((a, b) => b.date.valueOf() - a.date.valueOf());

const MainPage = () => {

    return (
        <div className="main-page">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <Typography variant="h2" component="h1" gutterBottom>
                    העדכונים האחרונים
                </Typography>
                <List
                    sx={{
                        width: "100%",
                        maxWidth: "80%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",

                        borderRadius: "10px",
                        "& ul": {padding: 0},
                    }}
                >
                    {updates.map((update, index) => (
                        <ListItem key={index} alignItems="flex-start">
                            <ListItemAvatar style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "15px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <Info style={{
                                        color: "#3f51b5",
                                        marginLeft: "5px"
                                    }}/>
                                    <span>{dayjs(update.date).format("DD/MM/YYYY")}</span>
                                </div>
                            </ListItemAvatar>
                            <ListItemText
                                primary={update.title}
                                secondary={
                                    <div>
                                        <span>{update.description}</span>
                                        <br/>

                                    </div>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </div>
    )
}

export default MainPage
