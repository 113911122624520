import Swal from "sweetalert2";
import {DocumentType} from "types/document";
import IItem from "types/product_delivery";
import {email, whatsapp2} from "../../../../assets/images";
import {distinctByField} from "../../../../utils/sort";
import {getSelectedRows} from "../../../../ui-component/ag-grid/Ag-Table";

const contextMenu = (params, setCreateDocOpen, setDocumentAction, setSelectedCustomersSend) => {

    const ranges = params?.api?.getCellRanges();
    const range = ranges?.length > 0 ? ranges[0] : null;
    const startRowIndex = range?.startRow.rowIndex < range?.endRow.rowIndex ? range?.startRow.rowIndex : range?.endRow.rowIndex;
    const endRowIndex = range?.endRow.rowIndex > range?.startRow.rowIndex ? range?.endRow.rowIndex : range?.startRow.rowIndex;
    let nodesInRange: any = [];
    const nodesInRangeSet: any = new Set();
    const selected = getSelectedRows(params?.api)

    selected?.forEach((row) => {

        if (row && !nodesInRangeSet.has(row)) {
            nodesInRangeSet.add(row);
            nodesInRange.push(row);
        }
    })
    if(!selected.length) {
        ranges?.forEach((range) => {

            const startRowIndex = range?.startRow.rowIndex < range?.endRow.rowIndex ? range?.startRow.rowIndex : range?.endRow.rowIndex;
            const endRowIndex = range?.endRow.rowIndex > range?.startRow.rowIndex ? range?.endRow.rowIndex : range?.startRow.rowIndex;
            for (let i = startRowIndex; i <= endRowIndex; i++) {
                const row = params.api?.getDisplayedRowAtIndex(i);

                if (row?.allLeafChildren) {
                    row?.allLeafChildren?.forEach((child) => {
                        if (!nodesInRangeSet.has(child?.data)) {
                            nodesInRangeSet.add(child?.data);
                            nodesInRange.push(child?.data);
                        }
                    })
                } else {
                    if (!nodesInRangeSet.has(row?.data)) {
                        nodesInRangeSet.add(row?.data);
                        nodesInRange.push(row?.data);
                    }
                }


            }
        });
    }
    const rowData = params.api?.getDisplayedRowAtIndex(startRowIndex)?.data;

    let allOrders = true;
    let allOfTheSameCustomer = true;
    let allInvoice = true;
    let allDeliveryCertificates = true;
    let customerId = rowData?.entId;


    if (nodesInRange.length > 0) {
        for (let node of nodesInRange) {
            if (allOrders && node?.type !== "order") {
                allOrders = false;
            }
            if (allInvoice && node?.type !== "invoice") {
                allInvoice = false;
            }
            // if (allOfTheSameCustomer && node?.entId !== customerId) {
            //     allOfTheSameCustomer = false;
            // }
            if (allDeliveryCertificates && node?.type !== "delivery_certificate" ) {
                allDeliveryCertificates = false;
            }

            if (!allOrders && !allInvoice && !allOfTheSameCustomer && !allDeliveryCertificates) {
                break;
            }
        }
    } else {
        allOrders = false;
        allOfTheSameCustomer = false;
        allInvoice = false;
        allDeliveryCertificates = false;
    }
    const ordersActions = [
        {
            name: (startRowIndex !== endRowIndex && allOrders) ? "הנפק תעודות משלוח מהזמנות" : "הנפק תעודת משלוח מהזמנה",
            action() {
                const goodNodes = nodesInRange.filter((n) => n.status == 'open' && n.type == 'order')
                if (allOrders && goodNodes.length > 1) {
                    setDocumentAction({
                        docs: goodNodes,
                        action: "create",
                        doc_type: "order",
                        title: "צור תעודות עבור ההזמנות",
                    })
                } else if (allOrders && goodNodes.length == 1) {

                    setCreateDocOpen({
                        documents: goodNodes[0],
                        type: "delivery_certificate",
                        manyProducts: goodNodes[0]?.products
                    });
                }

            },
            disabled: (startRowIndex !== endRowIndex && !allOrders)
        },
        {
            name: "העבר הזמנות לתאריך אחר",
            action() {
                const goodNodes = nodesInRange.filter((n) => n.status == 'open' && n.type == 'order')
                if (goodNodes.length > 0)
                    setDocumentAction({
                        docs: goodNodes,
                        action: "move",
                        doc_type: "order",
                        title: "העבר הזמנות לתאריך אחר",
                    })
            },
            disabled: !allOrders
        },

    ];

    const invoiceActions = [
        {
            name: "צור קבלה חדשה",
            action() {
                setCreateDocOpen({
                    documents: nodesInRange,
                    type: "receipt",
                });
            },
            disabled: !allOfTheSameCustomer || !allInvoice
        }
    ];

    const deliveryCertificateActions = [
        {
            name: "הנפק חשבונית מס",
            action() {
                const goodNodes = nodesInRange.filter((n) => n.status == 'open' && n.type == 'delivery_certificate')
                if (goodNodes.length > 0)
                    setCreateDocOpen({
                        documents: goodNodes,
                        type: "invoice"
                    });
            },
            disabled: !allDeliveryCertificates
        }
    ];

    const contextMenuItems: ({
        name: string;
        subMenu?: {
            name: string;
            action: () => void;
            disabled?: boolean;
        }[];
        action?: () => void;
        disabled?: boolean;
    } | string)[] = [];

    if (allOrders) {
        contextMenuItems.push(...ordersActions, "separator");
    }

    if (false && allOfTheSameCustomer && allInvoice) {
        contextMenuItems.push(...invoiceActions, "separator");
    }

    if (allOfTheSameCustomer && allDeliveryCertificates) {
        contextMenuItems.push(...deliveryCertificateActions, "separator");
    }
    const massSend = [
        {
            name: "שלח מסמכים למייל",
            icon: `<img src="${email}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            action() {
                const lst = [...nodesInRange]
                setSelectedCustomersSend({
                    selected: lst.map((u) => Number(u.entId)),
                    defaultPlatform: 'email',
                    files: {
                        ids: lst.map((u) => Number(u.id)),
                        types: lst.map((u) => u.nkObject.getConnectedDocType()),
                    },
                    msg: 'מסמכים',
                    subject: 'שליחת מסמכים',


                })

                close();
            },
        },
        {
            name: "שלח תעודות לווטסאפ",
            icon: `<img src="${whatsapp2}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            action() {
                const lst = [...nodesInRange]
                setSelectedCustomersSend({
                    selected: lst.map((u) => Number(u.entId)),
                    defaultPlatform: 'whatsapp',
                    files: {
                        ids: lst.map((u) => Number(u.id)),
                        types: lst.map((u) => u.nkObject.getConnectedDocType()),
                    },
                    msg: 'מסמכים',
                    subject: 'שליחת מסמכים',
                })

                close();
            },
        },
    ];
    contextMenuItems.push(...massSend, "separator");


    return contextMenuItems;
}


export const contextMenuProducts = (params, setCreateDocOpen, setDocumentAction, setSelectedCustomersSend) => {

    const ranges = params?.api?.getCellRanges();
    const range = ranges?.length > 0 ? ranges[0] : null;
    const startRowIndex = range?.startRow.rowIndex < range?.endRow.rowIndex ? range?.startRow.rowIndex : range?.endRow.rowIndex;
    const endRowIndex = range?.endRow.rowIndex > range?.startRow.rowIndex ? range?.endRow.rowIndex : range?.startRow.rowIndex;
    let nodesInRange: any = [];
    const nodesInRangeSet: any = new Set();

    ranges?.forEach((range) => {
        const startRowIndex = range?.startRow.rowIndex < range?.endRow.rowIndex ? range?.startRow.rowIndex : range?.endRow.rowIndex;
        const endRowIndex = range?.endRow.rowIndex > range?.startRow.rowIndex ? range?.endRow.rowIndex : range?.startRow.rowIndex;
        for (let i = startRowIndex; i <= endRowIndex; i++) {
            const row = params.api?.getDisplayedRowAtIndex(i);
            if (!nodesInRangeSet.has(row?.data)) {
                nodesInRangeSet.add(row?.data);
                nodesInRange.push(row?.data);
            }

        }
    });
    const rowData = params.api?.getDisplayedRowAtIndex(startRowIndex)?.data;

    let allOrders = true;
    let allOfTheSameCustomer = true;
    let allInvoice = true;
    let allDeliveryCertificates = true;
    let customerId = rowData?.c_id;


    if (nodesInRange.length > 0) {
        for (let node of nodesInRange) {
            if (allOrders && node?.doc_type !== "order") {
                allOrders = false;
            }
            if (allInvoice && node?.doc_type !== "invoice") {
                allInvoice = false;
            }
            // if (allOfTheSameCustomer && node?.c_id !== customerId) {
            //     allOfTheSameCustomer = false;
            // }
            if (allDeliveryCertificates && node?.note.type !== "delivery_certificate" || node?.status == "closed") {
                allDeliveryCertificates = false;
            }

            if (!allOrders && !allInvoice && !allOfTheSameCustomer && !allDeliveryCertificates) {
                break;
            }
        }
    } else {
        allOrders = false;
        allOfTheSameCustomer = false;
        allInvoice = false;
        allDeliveryCertificates = false;
    }
    const ordersActions = [
        {
            name: (startRowIndex !== endRowIndex && allOrders) ? "הנפק תעודות משלוח מהזמנות" : "הנפק תעודת משלוח מהזמנה",
            action() {
                const goodNodes = nodesInRange.filter((n) => n.status == 'open' && n.type == 'order')
                if (allOrders && goodNodes.length > 1) {
                    setDocumentAction({
                        docs: goodNodes,
                        action: "create",
                        doc_type: "order",
                        title: "צור תעודות עבור ההזמנות",
                    })
                } else if (allOrders && goodNodes.length == 1) {

                    setCreateDocOpen({
                        documents: goodNodes[0],
                        type: "delivery_certificate",
                        manyProducts: goodNodes[0]?.products
                    });
                }

            },
            disabled: (startRowIndex !== endRowIndex && !allOrders)
        },
        {
            name: "העבר הזמנות לתאריך אחר",
            action() {
                const goodNodes = nodesInRange.filter((n) => n.status == 'open' && n.type == 'order')
                if (goodNodes.length > 0)
                    setDocumentAction({
                        docs: goodNodes,
                        action: "move",
                        doc_type: "order",
                        title: "העבר הזמנות לתאריך אחר",
                    })
            },
            disabled: !allOrders
        },

    ];

    const invoiceActions = [
        {
            name: "צור קבלה חדשה",
            action() {
                setCreateDocOpen({
                    documents: nodesInRange,
                    type: "receipt",
                });
            },
            disabled: !allOfTheSameCustomer || !allInvoice
        }
    ];

    const deliveryCertificateActions = [
        {
            name: "הנפק חשבונית מס",
            action() {
                let goodNodes = nodesInRange.filter((n) => n.status == 'open' && n.note.type == 'delivery_certificate')
                goodNodes = distinctByField(goodNodes, 'doc_id');

                goodNodes = goodNodes.map((d) => d.note)
                if (goodNodes.length > 0)
                    setCreateDocOpen({
                        documents: goodNodes,
                        type: "invoice"
                    });
            },
            disabled: !allDeliveryCertificates
        }
    ];

    const contextMenuItems: ({
        name: string;
        subMenu?: {
            name: string;
            action: () => void;
            disabled?: boolean;
        }[];
        action?: () => void;
        disabled?: boolean;
    } | string)[] = [];

    if (false && allOrders) {
        contextMenuItems.push(...ordersActions, "separator");
    }

    if (false && allOfTheSameCustomer && allInvoice) {
        contextMenuItems.push(...invoiceActions, "separator");
    }

    if (allOfTheSameCustomer && allDeliveryCertificates) {
        contextMenuItems.push(...deliveryCertificateActions, "separator");
    }
    const massSend = [
        {
            name: "שלח מסמכים למייל",
            icon: `<img src="${email}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            action() {

                let goodNodes = distinctByField(nodesInRange, 'c_id').map((d) => d.note);
                const lst = [...goodNodes]
                setSelectedCustomersSend({
                    selected: lst.map((u) => Number(u.entId)),
                    defaultPlatform: 'email',
                    files: {
                        ids: lst.map((u) => Number(u.id)),
                        types: lst.map((u) => u.nkObject.getConnectedDocType()),
                    },
                    msg: 'מסמכים',
                    subject: 'שליחת מסמכים',


                })

                close();
            },
        },
        {
            name: "שלח תעודות לווטסאפ",
            icon: `<img src="${whatsapp2}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            action() {
                let goodNodes = distinctByField(nodesInRange, 'c_id').map((d) => d.note);
                const lst = [...goodNodes]
                setSelectedCustomersSend({
                    selected: lst.map((u) => Number(u.entId)),
                    defaultPlatform: 'whatsapp',
                    files: {
                        ids: lst.map((u) => Number(u.id)),
                        types: lst.map((u) => u.nkObject.getConnectedDocType()),
                    },
                    msg: 'מסמכים',
                    subject: 'שליחת מסמכים',
                })

                close();
            },
        },
    ];
    contextMenuItems.push(...massSend, "separator");


    return contextMenuItems;
}


export default contextMenu;
