package DataBase

import DataBase.Interface.AdapterInterface
import DataBase.Interface.DBInterface
import Network.NetworkOutput
import Network.Tasks
import Network.httpWorker
import Structs.*
import Structs.Api.ApiConfig
import Structs.Api.ApiNote
import Utils.*
import kotlin.Triple
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlinx.serialization.json.*
import myName
import printPlat
import kotlin.collections.HashMap
import kotlin.js.JsName
import kotlin.math.*

open class NKDBInterface : DBInterface {
    protected lateinit var adapter: AdapterInterface

    constructor() {

    }

    private var company: String = ""
    protected val idToClientProduct: HashMap<Int, ProductSequence>
    private val idToSupplierProduct: HashMap<Int, ProductSequence>
    protected val idToClient: HashMap<Int, EntitySequence>
    protected val dateToDailyFinder: HashMap<String, HashMap<Int, ClientDailyData>>
    protected val idToClientDaily: HashMap<String, HashMap<Int, ClientDailyData>>
    protected val idToApiNote: HashMap<Pair<Int, Int>, ApiNote>
    protected val idToClientDailyUpdated: HashMap<Int, ClientDailyData>
    val tax: DatedTaxFactor = DatedTaxFactor()
    var TAX_ISRAEL_LIMIT = 25000f
    private val idToSupplier: HashMap<Int, EntitySequence>
    private val clientIdToVisitNote: HashMap<Int, MutableList<VisitNote>>
    private val idToVisitNote: HashMap<Int, VisitNote>
    private val clientIdToDeliveryNote: HashMap<Int, MutableList<DeliveryNote>>
    private val clientIdToOrderNote: HashMap<Int, MutableList<OrderNote>>
    private val clientDeliveryIdToDeliveryNote: HashMap<Int, DeliveryNote>
    private val clientOrderIdToDeliveryNote: HashMap<Int, OrderNote>
    private val clientIdToProductUse: HashMap<Int, HashMap<Int, Float>>
    private val docIdToSign: HashMap<Int, HashMap<Int, NoteSignHolder>>
    private val clientEdiNoteIdToNote: HashMap<Int, EdiNote>
    private val supplierDeliveryIdToDeliveryNote: HashMap<Int, DeliveryNote>
    private val supplierIdToDeliveryNote: HashMap<Int, MutableList<DeliveryNote>>
    private val supplierIdToOrderNote: HashMap<Int, MutableList<OrderNote>>
    private var clientProductsData: MutableMap<Pair<Int, Int>, ClientProductData>? = null

    @myName("user")
    protected var user: User? = null

    @myName("userCofcConf")
    protected var userCofcConf: CofcConf? = null

    @myName("cofcMendatoryDate")
    private var cofcMendatoryDate: String? = null

    @myName("logo")
    var logo: ByteArray? = null
    private val clientIdToMonthlyCycle: HashMap<Int, MutableList<MonthlyCycle>>
    private val clientIdToTaxNote: HashMap<Int, MutableList<ClientLightTaxNote>>
    private val idToTaxNote: HashMap<Int, ClientLightTaxNote>
    private val clientIdToTaxPayNote: HashMap<Int, MutableList<ClientLightTaxNote>>
    private val idToTaxPayNote: HashMap<Int, ClientLightTaxNote>
    private val clientIdToTaxCancel: HashMap<Int, MutableList<ClientLightTaxNote>>
    private val idToTaxCancel: HashMap<Int, ClientLightTaxNote>
    private val clientIdToClientPay: HashMap<Int, MutableList<ClientLightPay>>
    private val clientIdToCreationTime: HashMap<Int, String>
    private val supplierIdToMonthlyCycle: HashMap<Int, MutableList<MonthlyCycle>>
    protected val productIdToByteArray: HashMap<Int, ByteArray>
    private val productIdToCostPrice: HashMap<Int, Price>
    private val productIdToDefaultPrice: HashMap<Int, Price>
    private val clientIdToPrice: HashMap<Int, HashMap<Int, Price>>
    private val clientIdToAvailable: HashMap<Int, HashMap<Int, Available>>
    private val supplierIdToPrice: HashMap<Int, HashMap<Int, Price>>
    private val cityMapping: MutableMap<String, MutableSet<String>>
    private val clientDebtWall: MutableMap<Int, DebtWall>
    private val ediMap: MutableMap<Int, EdiMember>
    private val cartesetMap: MutableMap<Int, MutableList<ClientCarteset>>
    private val taklitProducts: MutableMap<Int, TaklitProduct>
    private val taklitProductsDated: MutableMap<String, MutableMap<Int, TaklitProduct>>
    private val orderProducts: MutableMap<Int, MutableList<OrderProduct>>
    private val supIdToMonthlyCycle: HashMap<Int, MutableList<MonthlyCycle>>
    private val supIdToTaxNote: HashMap<Int, MutableList<ClientLightTaxNote>>
    private val idToSupTaxNote: HashMap<Int, ClientLightTaxNote>
    private val supIdToTaxPayNote: HashMap<Int, MutableList<ClientLightTaxNote>>
    private val idToSupTaxPayNote: HashMap<Int, ClientLightTaxNote>
    private val supIdToTaxCancel: HashMap<Int, MutableList<ClientLightTaxNote>>
    private val idToTSupaxCancel: HashMap<Int, ClientLightTaxNote>
    private val supIdToClientPay: HashMap<Int, MutableList<ClientLightPay>>
    private val supCartesetMap: MutableMap<Int, MutableList<ClientCarteset>>


    val todayClientDelivery: MutableSet<Int> = mutableSetOf()
    val clientIdToLastVisit: HashMap<Int, String>
    val todaySupplierDelivery: MutableSet<Int> = mutableSetOf()
    val todayClientOrder: MutableSet<Int> = mutableSetOf()
    val todaySupplierOrder: MutableSet<Int> = mutableSetOf()
    protected var agents: List<Agent> = listOf()
    public var fetched: Boolean = false
    var imageFetched: Boolean = false
    val imagesFolder = "images"
    var timer: Long = 0
    var fetchProductTimer: Long = 0
    protected var estimatesClientPrices: Int = 0
    var isOffline: Boolean = false
    val optimizeDaysDocuments = 3

    @myName("apiConfig")
    var apiConfig: ApiConfig? = null
    public val isClientsFetched: BoolValueStore = BoolValueStore(-1, false)
    public val isPricesFetched: BoolValueStore = BoolValueStore(-1, false)
    val productsFetched: BoolValueStore = BoolValueStore(-1, false)

    constructor(company: String, company_id: String? = null, local: Boolean = false) {
        this.company = company
        adapter = NKAdapter(company, local)
    }

    @myName("setCompany")
    open fun setCompany(company: String, company_id: String? = null, local: Boolean = false, web: Boolean = false) {
        this.company = company
        adapter = NKAdapter(company, local, web)
        company_id?.let {
            adapter.setConcreteCompanyId(company_id)
        }
    }

    fun getAdapterInterface(): AdapterInterface {
        return adapter
    }

    @myName("getCompanyId")
    suspend fun getCompanyId(): String {
        return adapter.getCompanyId()
    }

    suspend fun setCompanyId(id: String? = null) {
        adapter.setCompanyId(id)
    }

    override fun setConcreteCompanyId(company_id: String) {
        adapter.setConcreteCompanyId(company_id)
    }

    suspend fun fetchTaxData() {
        if (isOffline) {
            getTaxInfoOffline()
            return;
        }

        val taxData = adapter.getTaxData()
        val dbQuery = analyzeResponse(taxData.second)
        if (taxData.first.isEmpty()) {
            getTaxInfoOffline()
            return
        } else {
            TAX_ISRAEL_LIMIT = taxData.first.first().tax_israel_limit
            taxData.first.forEach {

                tax.add(it)
            }
        }

    }

    @myName("login")
    suspend fun login(
        name: String, password: String, company: String, local: Boolean = false, web: Boolean = false
    ): Respone<Agent?, DBQuery> {

        val log = NKAdapter(local, web).login(name, password, company)
        if (log.second != NetworkOutput.SUCCESS) {
            if (log.second == NetworkOutput.CONNECTION_ERROR) {
                return Respone(null, DBQuery.INTERNET_CONNECTION)
            } else {
                return Respone(null, DBQuery.LOGIN_FAILED)
            }

        }
        setCompany(company = company, company_id = log.first!!.company_id, local = local, web = web)
        isOffline = false
        fetchTaxData()

        return Respone(log.first, DBQuery.SUCCESS)
    }

    init {
        idToClientProduct = HashMap()
        idToSupplierProduct = HashMap()
        idToClient = HashMap()
        idToClientDaily = HashMap()
        idToApiNote = HashMap()
        dateToDailyFinder = HashMap()
        idToClientDailyUpdated = HashMap()
        idToSupplier = HashMap()
        clientIdToCreationTime = HashMap()
        clientIdToVisitNote = HashMap()
        idToVisitNote = HashMap()
        clientIdToLastVisit = HashMap()
        clientIdToDeliveryNote = HashMap()
        clientIdToOrderNote = HashMap()
        clientDeliveryIdToDeliveryNote = HashMap()
        clientOrderIdToDeliveryNote = HashMap()
        clientIdToProductUse = HashMap()
        supplierDeliveryIdToDeliveryNote = HashMap()
        supplierIdToDeliveryNote = HashMap()
        clientIdToTaxPayNote = HashMap()
        clientIdToTaxCancel = HashMap()
        idToTaxCancel = HashMap()
        idToTaxPayNote = HashMap()
        idToTaxNote = HashMap()
        clientIdToPrice = HashMap()
        clientIdToAvailable = HashMap()
        productIdToCostPrice = HashMap()
        productIdToDefaultPrice = HashMap()
        supplierIdToPrice = HashMap()
        docIdToSign = HashMap()
        supplierIdToOrderNote = HashMap()
        clientIdToMonthlyCycle = HashMap()
        supplierIdToMonthlyCycle = HashMap()
        clientEdiNoteIdToNote = HashMap()
        clientIdToTaxNote = HashMap()

        supIdToMonthlyCycle = HashMap()
        supIdToTaxNote = HashMap()
        idToSupTaxNote = HashMap()
        supIdToTaxPayNote = HashMap()
        idToSupTaxPayNote = HashMap()
        supIdToTaxCancel = HashMap()
        idToTSupaxCancel = HashMap()
        supIdToClientPay = HashMap()
        supCartesetMap = HashMap()
        productIdToByteArray = HashMap()
        clientIdToClientPay = HashMap()
        taklitProducts = HashMap()
        taklitProductsDated = HashMap()
        cityMapping = HashMap()
        clientDebtWall = HashMap()
        ediMap = HashMap()
        cartesetMap = HashMap()
        orderProducts = HashMap()
        agents = listOf()
    }

    private fun analyzeResponse(response: NetworkOutput, new: Boolean = false): DBQuery {
        when (response) {
            NetworkOutput.ERROR_ID_NOT_EXIST -> return DBQuery.ID_ISSUE
            NetworkOutput.ERROR_GENERIC, NetworkOutput.ERROR_ARG_MISSING -> return DBQuery.INTERNET_GENERIC_ERROR
            NetworkOutput.ERROR_DUPLICATE -> return DBQuery.ERROR_DUPLICATE
            NetworkOutput.ERROR_EMPTY_QUERY -> return if (new) DBQuery.ERROR_EMPTY_NEW else DBQuery.SUCCESS
            NetworkOutput.CONNECTION_ERROR -> return DBQuery.INTERNET_CONNECTION
            NetworkOutput.TOKEN_ERROR -> return DBQuery.LOGIN_FAILED
            NetworkOutput.TOKEN_FAIL -> return DBQuery.LOGIN_FAILED
            NetworkOutput.FAILED -> return DBQuery.FAILED
        }
        return DBQuery.SUCCESS
    }

    @myName("hasBeenToday")
    fun hasBeenToday(ent: Entity): Boolean {
        return if (ent is Client) {
            ent.id in todayClientDelivery
        } else {
            ent.id in todaySupplierDelivery
        }
    }

    @myName("hasOrderToday")
    fun hasOrderToday(ent: Entity): Boolean {
        return if (ent is Client) {
            ent.id in todayClientOrder
        } else {
            ent.id in todaySupplierOrder
        }
    }


    suspend fun fetchClientsTodayOrders(ids: List<Int>? = null, date: String? = null): DBQuery {
        val today = date ?: DatesManipulator.dateNowNoHour()
        var q: Respone<List<OrderNote>, DBQuery> =
            buildClientOrderNotes(client_ids = ids, fromDate = today, toDate = today)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }

        if (date == null || date != today) q.first.forEach {
            val client = getClient(it.ent_id)
            if (client.second == DBQuery.SUCCESS && it.isActive()) {
                _updateClientOrderNoteForToday(client.first!!.id, it)
            }
        }
        return DBQuery.SUCCESS
    }

    suspend fun fetchClientsReqOrders(
        id: Int? = null, date: String? = null, withDaily: Boolean? = null, full: Boolean? = null
    ): Respone<List<OrderNote>, DBQuery> {
        val today = date ?: DatesManipulator.dateNowNoHour()
        var q: Respone<List<OrderNote>, DBQuery> =
            buildClientOrderNotes(client_id = id, fromDate = today, toDate = today, full = full ?: false)
        if (q.second != DBQuery.SUCCESS) {
            return Respone(listOf(), q.second)
        }

        val p = getSign(q.first.map { it.id }, doc_type = 10)

        var notDone: MutableList<OrderNote> = mutableListOf()
        notDone.addAll(q.first)
        if (((user?.order_cofc ?: 0) / 2 % 2) == 1) {
            val notDoneOrders = buildClientOrderNotes(
                client_id = id, inverse_status = listOf(2, 0), fromDate = unclosed_date, toDate = "2030-01-01"
            )
            if (notDoneOrders.second != DBQuery.SUCCESS) {
                return Respone(listOf(), notDoneOrders.second)
            }
            notDone.addAll(notDoneOrders.first)
        }

        if (withDaily == true) {
            val daily =
                buildClientsDailyData(date = today, ids = getAllClient(true).first.map { it.id }, dailyFinder = true)
            if (daily.second != DBQuery.SUCCESS) {
                Respone(notDone.distinctBy { it.order_id }, DBQuery.SUCCESS)
            }
        }
        if (id == null)
            getProductAvailable(notDone.map { it.ent_id }.distinct())

        return Respone(notDone.distinctBy { it.order_id }, DBQuery.SUCCESS)
    }

    suspend fun fetchClientsTodayDeliveryNotes(id: Int? = null, date: String? = null): DBQuery {
        val today = date ?: DatesManipulator.dateNowNoHour()
        var q: Respone<List<DeliveryNote>, DBQuery> = buildClientNotes(client_id = id, fromDate = today, toDate = today)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }

        if (id == null) q.first.forEach {

            val client = getClient(it.ent_id)
            if (client.second == DBQuery.SUCCESS && it.isActive()) {
                todayClientDelivery.add(client.first!!.id)
            }
        }
        return DBQuery.SUCCESS
    }

    suspend fun fetchClientsTodayTaxNotes(id: Int? = null, date: String? = null): DBQuery {
        val today = date ?: DatesManipulator.dateNowNoHour()
        var q: Respone<List<ClientTaxNote>, DBQuery> =
            buildClientTaxNote(client_id = id, fromDate = today, toDate = today, withProduct = 1)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }

        if (id == null) q.first.forEach {
            val client = getClient(it.client_id)
            if (client.second == DBQuery.SUCCESS && it.isActive()) {
                todayClientDelivery.add(client.first!!.id)
            }
        }
        return DBQuery.SUCCESS
    }

    suspend fun fetchSupplierTodayDeliveryNotes(id: Int? = null, date: String? = null): DBQuery {
        val today = date ?: DatesManipulator.dateNowNoHour()

        var q = buildSupplierNotes(supplier_id = id, fromDate = today, toDate = today)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        if (id == null) q.first.forEach {
            todaySupplierDelivery.add(it.ent_id)
        }
        return DBQuery.SUCCESS
    }

    override suspend fun fetch(agent: Agent?, loadPrices: Boolean, forceAttach: Boolean): DBQuery {
        //fetching latest data from DB
        if (buildUserInfo(company = getCompany()) != DBQuery.SUCCESS) return DBQuery.FAILED
        saveUserOffline()
        var q: Respone<*, DBQuery> = buildAgents()
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        saveAgentsOffline(q.first as List<Agent>)

        val ids = agent?.let {
            if (it.isLineManager() || it.isLineDriver()) {
                null
            } else if (it.isDriver()) {
                idToClientDailyUpdated.clear()
                q = buildClientsDailyData(driver_id = listOf(it.id))
                if (q.second != DBQuery.SUCCESS) {
                    return q.second
                }
                idToClientDailyUpdated.values.groupBy { it.driver_id }[it.id]?.map { it.id }?.distinct()
            } else if (it.isCollector()) {
                idToClientDailyUpdated.clear()
                q = buildClientsDailyData(collector_id = listOf(it.id))
                if (q.second != DBQuery.SUCCESS) {
                    return q.second
                }
                idToClientDailyUpdated.values.groupBy { it.collector_id }[it.id]?.map { it.id }?.distinct()
            } else {
                null
            }
        }
        if (ids == null || !ids.isEmpty())
            q = buildClients(ids = ids, withDaily = ids == null)
        else
            q = Respone(listOf<Client>(), DBQuery.SUCCESS)

        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        isClientsFetched.value = true
        saveDaily()
        saveClientOffline(q.first as List<Client>)
        q = buildClientProducts()
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        productsFetched.value = true
        saveProductOffline(q.first as List<Product>)

        if (loadPrices && (estimatesClientPrices < 45000) && (ids == null || !ids.isEmpty())) {

            q = buildLatestClientPrices(ids = ids, clear = true, fetchControlAndMasters = true)
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
            savePriceOffline(q.first as List<RawPrice>)
        }

        var qu: DBQuery
        if (forceAttach || getAllClient(false).first.size * getAllClientProduct(false).first.size < 500000) {
            qu = attachClientProductsToClients()
            if (qu != DBQuery.SUCCESS) {
                return qu
            }
        }


        todayClientDelivery.clear()
        todaySupplierDelivery.clear()
        todayClientOrder.clear()
        todaySupplierOrder.clear()
        fetchTodayNotes(withSuppliers = agent?.suppliers_auth == 1, visit = agent?.visit)
        if ((user?.order_cofc ?: 0) > 0 && userCofcConf == null) {
            buildCofcUserConf()
        }
        if ((user!!.edi_state) > 0) {
            buildClientEdiMap(DatesManipulator.dateNowNoHour())
        }

        if (agent?.suppliers_auth == 1) {
            q = buildSupplierProducts()
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
            q = buildSuppliers()
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }

            q = buildLatestSupplierPrices()
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
            var qi = attachSupplierProductsToSuppliers()
            if (qi != DBQuery.SUCCESS) {
                return qi
            }

        }

        if (user!!.taklit >= 1) {
            val date = DatesManipulator.dateNowNoHour()
            val day = DatesManipulator.getDayOfWeek(date)
            val prevDay =
                if (day >= 4) DatesManipulator.getNextDay(date, 3 - day) else DatesManipulator.getNextDay(date, -1)
            buildTaklitProducts(date = prevDay)
            buildTaklitProducts()
        }
        if (getApiConfig(whatsapp = true).second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }
        if (getClientCPData().second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }

        fetched = true
        freeMemoryApp()
        return DBQuery.SUCCESS
    }

    override suspend fun fetchClients(from_date: String, to_date: String, withDaily: Boolean): DBQuery {
        var q: Respone<*, DBQuery> = buildClientProducts(fromDate = from_date, toDate = to_date)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildAgents()
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildClients(fromDate = from_date, toDate = to_date, withDaily = withDaily)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildClientPrices(fromDate = from_date, toDate = to_date)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        var qu = attachClientProductsToClients()
        if (qu != DBQuery.SUCCESS) {
            return qu
        }
        return DBQuery.SUCCESS
    }

    override suspend fun fetchSuppliers(from_date: String, to_date: String): DBQuery {
        var q: Respone<*, DBQuery> = buildSupplierProducts(fromDate = from_date, toDate = to_date)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildSuppliers(fromDate = from_date, toDate = to_date)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }

        q = buildSupplierPrices(id = null, fromDate = from_date, toDate = to_date)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        val qu = attachSupplierProductsToSuppliers()
        if (qu != DBQuery.SUCCESS) {
            return qu
        }
        return DBQuery.SUCCESS
    }

    override suspend fun fetchAll(fromDate: String, toDate: String): DBQuery {
        var q: Respone<*, DBQuery> = buildClientProducts(fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildClients(fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }

        q = buildClientPrices(fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        var qu = attachClientProductsToClients()
        if (qu != DBQuery.SUCCESS) {
            return qu
        }
        q = buildClientTaxNote(fromDate = fromDate, toDate = toDate, full = true)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildClientNotes(fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildClientPay(fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildAgents()
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        return DBQuery.SUCCESS
    }

    override fun clearDB() {
        fetched = false
        isOffline = false
        idToClientProduct.clear()
        idToSupplierProduct.clear()
        idToClient.clear()
        idToClientDaily.clear()
        idToApiNote.clear()
        dateToDailyFinder.clear()
        idToClientDailyUpdated.clear()
        idToSupplier.clear()
        clientIdToCreationTime.clear()
        clientIdToVisitNote.clear()
        idToVisitNote.clear()
        clientIdToDeliveryNote.clear()
        clientIdToOrderNote.clear()
        clientIdToLastVisit.clear()
        clientDeliveryIdToDeliveryNote.clear()
        clientOrderIdToDeliveryNote.clear()
        clientIdToProductUse.clear()
        supplierDeliveryIdToDeliveryNote.clear()
        supplierIdToDeliveryNote.clear()
        clientIdToTaxPayNote.clear()
        clientIdToTaxCancel.clear()
        idToTaxCancel.clear()
        idToTaxPayNote.clear()
        idToTaxNote.clear()
        clientIdToPrice.clear()
        clientIdToAvailable.clear()
        productIdToCostPrice.clear()
        docIdToSign.clear()
        supplierIdToPrice.clear()
        supplierIdToOrderNote.clear()
        clientIdToMonthlyCycle.clear()
        supplierIdToMonthlyCycle.clear()
        clientEdiNoteIdToNote.clear()
        clientIdToTaxNote.clear()
        supIdToTaxNote.clear()
        idToSupTaxNote.clear()
        supIdToTaxPayNote.clear()
        idToSupTaxPayNote.clear()
        supIdToTaxCancel.clear()
        idToTSupaxCancel.clear()
        supIdToClientPay.clear()
        supCartesetMap.clear()
        supIdToMonthlyCycle.clear()

        productIdToByteArray.clear()
        clientIdToClientPay.clear()
        taklitProducts.clear()
        taklitProductsDated.clear()
        cityMapping.clear()
        clientDebtWall.clear()
        ediMap.clear()
        orderProducts.clear()
        cartesetMap.clear()
        todayClientDelivery.clear()
        clientIdToLastVisit.clear()
        todayClientOrder.clear()
        todayClientDelivery.clear()
        todaySupplierOrder.clear()
        clientProductsData?.clear()
        userCofcConf = null
        user = null
        imageFetched = false
        isClientsFetched.value = false
        isPricesFetched.value = false
        isPricesFetched.setListener(null)
        isClientsFetched.setListener(null)
        productsFetched.setListener(null)
        productsFetched.value = false
        logo = null

        agents = listOf()
    }

    override suspend fun fetchTodayNotes(
        date: String?, withSuppliers: Boolean, visit: Int?
    ): DBQuery {


        val today = date ?: DatesManipulator.dateNowNoHour()
        val clients = getAllClient(true).first.map { it.id }
        val response =
            adapter.getClientVisit(if (clients.isNotEmpty()) clients.joinToString(",") else null, today, today)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS) {
            return dbQuery
        }
        data.forEach {
            when (it.doc_type) {
                DocType.ORDERS.state -> {
                    todayClientOrder.add(it.ent_id)
                }

                DocType.TAX_NOTE.state, DocType.TAX_PAY_NOTE.state, DocType.TAX_NOTE_CANCEL.state -> {
                    todayClientDelivery.add(it.ent_id)
                }

                DocType.DELIVERY_NOTES.state -> {
                    todayClientDelivery.add(it.ent_id)
                }
            }

        }
        if ((visit ?: 0) > 0) {
            val v = visit!!
            val dv_visitation: Boolean = v % 2 == 1
            val tax_visitation: Boolean = (v / 2) % 2 == 1
            val note_visitation: Boolean = (v / 4) % 2 == 1
            getClientsLastVisitationOptimized(
                ids = clients,
                dv_visitation = dv_visitation,
                tax_visitation = tax_visitation,
                note_visitation = note_visitation
            )
        }
        if (withSuppliers) fetchSupplierTodayDeliveryNotes(date = date)

        return DBQuery.SUCCESS
    }

    override suspend fun fetchSpecificClientData(
        ids: List<Int>,
        fromDate: String,
        toDate: String,
        optimized: Boolean,
        fromApp: Boolean,
        product_ids: List<Int>?,
        withLatestProducts: Boolean
    ): DBQuery {
        isPricesFetched.value = false
        if (!fetched || estimatesClientPrices > 45000 || getAllClient(false).first.size * getAllClientProduct(false).first.size > 500000) {
            buildPricesOffline(ids)
            attachClientProductsToClients(ids)
        }
        var q: Respone<*, DBQuery>
        if (!optimized || idToClientProduct.isEmpty()) {
            q = buildClientProducts(fromDate = fromDate, toDate = toDate)
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
        }

        q = buildClients(ids, fromDate = fromDate, toDate = toDate)

        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        if (fromApp) saveClientOffline(q.first, delete = false)

        if (agents.isEmpty()) {
            q = buildAgents()
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
            if (fromApp) saveAgentsOffline(q.first)
        }
        if (product_ids == null || product_ids.size > 0) {
            q = buildClientPrices(ids, product_id = product_ids, fromDate = fromDate, toDate = toDate)
//            q = if(product_ids==null && fromDate==toDate) buildClientPriorityPrices(ids,fromDate)
//            else buildClientPrices(ids, product_id = product_ids, fromDate = fromDate, toDate = toDate)
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
            if (fromApp) savePriceOffline(q.first, delete = false)

        }

        isPricesFetched.value = true


        var qu: DBQuery
        if (fromApp) {
            qu = fetchClientsTodayOrders(ids)
            if (qu != DBQuery.SUCCESS) {
                return qu
            }
        }
        if (!optimized) {
            var qu = attachClientProductsToClients(ids)
            if (qu != DBQuery.SUCCESS) {
                return qu
            }
        }
        if (withLatestProducts)
            lastProducts(ids.first(), true)

        return DBQuery.SUCCESS
    }

    override suspend fun fetchSpecificSupplierData(ids: List<Int>, fromDate: String, toDate: String): DBQuery {
        var q: Respone<*, DBQuery> = buildSupplierProducts(fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        q = buildSuppliers(ids, fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }

        q = buildSupplierPrices(ids, fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        var qu = attachSupplierProductsToSuppliers(ids)
        if (qu != DBQuery.SUCCESS) {
            return qu
        }
        return DBQuery.SUCCESS
    }

    override suspend fun fetchSpecificClientData(params: HashMap<String, Any>): DBQuery {
        val id = params["id"]!! as Int
        val fromApp = (params["fromApp"] as Boolean?) ?: false
        val optimized = (params["optimized"] as Boolean?) ?: true
        val fromDate = (params["fromDate"] as String?) ?: DatesManipulator.dateNowNoHour()
        val toDate = (params["fromDate"] as String?) ?: DatesManipulator.dateNowNoHour()
        val withLatestProducts = (params["withLatestProducts"] as Boolean?) ?: false
        return fetchSpecificClientData(
            listOf(id),
            fromDate,
            toDate,
            optimized,
            fromApp,
            withLatestProducts = withLatestProducts
        )
    }

    override fun getCompany(): String {
        return company.toLowerCase()
    }

    fun _updateClientOrderNoteForToday(id: Int, orderNote: OrderNote) {
        val client = getClient(id)
        val today = DatesManipulator.dateNowNoHour()
        if (client.second == DBQuery.SUCCESS && today.compareTo(orderNote.date) == 0) {
            todayClientOrder.add(client.first!!.id)
        }
    }

    fun _updateClientNoteForToday(id: Int, note: InformationBase) {
        val client = getClient(id)
        val today = DatesManipulator.dateNow().split(" ")[0]
        if (client.second == DBQuery.SUCCESS && today.compareTo(note.getConnectedDate()) == 0) {
            todayClientDelivery.add(client.first!!.id)
        }
    }

    private fun _updateClientsMap(value: Client) {
        var existing = idToClient[value.id]
        if (existing == null) {
            existing = EntitySequence()
            idToClient[value.id] = existing
        }
        existing.add(value)
        if (!clientIdToPrice.containsKey(value.id)) {
            clientIdToPrice[value.id] = HashMap()
        }
    }

    private fun _updateClientsProductMap(value: Product) {
        var existing = idToClientProduct[value.id]
        if (existing == null) {
            existing = ProductSequence()
            idToClientProduct[value.id] = existing
        }
        existing.add(value)
    }

    private fun _updateClientsPrice(value: RawPrice, runOver: Boolean = false) {
        val clientDBQuery = getClient(value.id)
        val productDBQuery = getClientProduct(value.product_id)
        if (clientDBQuery.second == DBQuery.SUCCESS && productDBQuery.second == DBQuery.SUCCESS) {
            val client = clientDBQuery.first!!
            val p = productDBQuery.first!!
            client.addRawPrice(value, p, runOver)
        }
    }

    private fun _updateSuppliersPrice(value: RawPrice) {
        val supplierDBQuery = getSupplier(value.id)
        val productDBQuery = getSupplierProduct(value.product_id)
        if (supplierDBQuery.second == DBQuery.SUCCESS && productDBQuery.second == DBQuery.SUCCESS) {
            val supplier = supplierDBQuery.first!!
            val p = productDBQuery.first!!
            supplier.addRawPrice(value, p)
        }
    }

    private fun _updateClientsMonthlyCycleMap(value: MonthlyCycle) {

        if (!clientIdToMonthlyCycle.containsKey(value.id)) {
            clientIdToMonthlyCycle[value.id] = mutableListOf()
        }
        val cur = clientIdToMonthlyCycle[value.id]!!.indexOfFirst { it.id == value.id && it.date == value.date }
        if (cur == -1) clientIdToMonthlyCycle[value.id]!!.add(value)
        else clientIdToMonthlyCycle[value.id]!![cur] = value

    }

    private fun _updateClientsVistNotesMap(value: VisitNote) {
        if (!clientIdToVisitNote.containsKey(value.client_id)) {
            clientIdToVisitNote[value.client_id] = mutableListOf()
        }
        val exist = clientIdToVisitNote[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            clientIdToVisitNote[value.client_id]!![exist] = value
        } else {
            clientIdToVisitNote[value.client_id]!!.add(value)
        }
        idToVisitNote[value.id] = value
    }

    fun _updateSignIdData(values: List<NoteSignHolder>) {
        values.forEach {
            if (!docIdToSign.containsKey(it.note_type)) docIdToSign[it.note_type] = HashMap()
            docIdToSign[it.note_type]!![it.note_id] = it
            when (DocType.ORDERS.convert(it.note_type)) {
                DocType.ORDERS -> {
                    clientOrderIdToDeliveryNote[it.note_id]?.setSign(it)
                }

                DocType.DELIVERY_NOTES -> {
                    clientDeliveryIdToDeliveryNote[it.note_id]?.setSign(it)
                }

                DocType.TAX_NOTE -> {
                    idToTaxNote[it.note_id]?.setSign(it)
                }

                DocType.TAX_PAY_NOTE -> {
                    idToTaxPayNote[it.note_id]?.setSign(it)
                }

                DocType.TAX_NOTE_CANCEL -> {
                    idToTaxCancel[it.note_id]?.setSign(it)
                }
//                DocType.PAY_NOTE->{
//                    clientIdToClientPay[it.note_id]?.setSign(it)
//                }
                DocType.VISIT -> {
                    idToVisitNote[it.note_id]?.setSign(it)
                }

            }

        }

    }

    private fun _updateClientsNotesMap(value: DeliveryNote) {
        if (!clientIdToDeliveryNote.containsKey(value.ent_id)) {
            clientIdToDeliveryNote[value.ent_id] = mutableListOf()
        }
        val exist = clientIdToDeliveryNote[value.ent_id]!!.indexOfFirst { it.delivery_id == value.delivery_id }
        if (exist != -1) {
            clientIdToDeliveryNote[value.ent_id]!![exist] = value
        } else {
            clientIdToDeliveryNote[value.ent_id]!!.add(value)
        }

        _updateClientsDeliveryIdtoNotesMap(value)
    }

    private fun _updateClientsEdiNotesMap(value: EdiNote) {
        clientEdiNoteIdToNote[value.note_id] = value
    }

    private fun _updateClientsOrderNotesMap(value: OrderNote) {
        if (!clientIdToOrderNote.containsKey(value.ent_id)) {
            clientIdToOrderNote[value.ent_id] = mutableListOf()
        }
        val exist = clientIdToOrderNote[value.ent_id]!!.indexOfFirst { it.order_id == value.order_id }
        if (exist != -1) {
            clientIdToOrderNote[value.ent_id]!![exist] = value
        } else {
            clientIdToOrderNote[value.ent_id]!!.add(value)
        }
        _updateClientsOrderIdtoNotesMap(value)
    }

    private fun _updateClientsTaxNotePayMap(value: ClientLightTaxNote) {
        if (!clientIdToTaxPayNote.containsKey(value.client_id)) {
            clientIdToTaxPayNote[value.client_id] = mutableListOf()
        }
        val exist = clientIdToTaxPayNote[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            clientIdToTaxPayNote[value.client_id]!![exist] = value
        } else {
            clientIdToTaxPayNote[value.client_id]!!.add(value)
        }
    }

    private fun _updateClientsCancelNoteMap(value: ClientLightTaxNote) {
        if (!clientIdToTaxCancel.containsKey(value.client_id)) {
            clientIdToTaxCancel[value.client_id] = mutableListOf()
        }
        val exist = clientIdToTaxCancel[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            clientIdToTaxCancel[value.client_id]!![exist] = value
        } else {
            clientIdToTaxCancel[value.client_id]!!.add(value)
        }
    }

    private fun _updateClientsTaxNoteMap(value: ClientLightTaxNote) {
        if (!clientIdToTaxNote.containsKey(value.client_id)) {
            clientIdToTaxNote[value.client_id] = mutableListOf()
        }
        val exist = clientIdToTaxNote[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            clientIdToTaxNote[value.client_id]!![exist] = value
        } else {
            clientIdToTaxNote[value.client_id]!!.add(value)
        }
    }

    private fun _updateClientsPayMap(value: ClientLightPay) {
        if (!clientIdToClientPay.containsKey(value.client_id)) {
            clientIdToClientPay[value.client_id] = mutableListOf()
        }
        val exist = clientIdToClientPay[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            clientIdToClientPay[value.client_id]!![exist] = value
        } else {
            clientIdToClientPay[value.client_id]!!.add(value)
        }
    }

    private fun _updateSupsPayMap(value: ClientLightPay) {
        if (!supIdToClientPay.containsKey(value.client_id)) {
            supIdToClientPay[value.client_id] = mutableListOf()
        }
        val exist = supIdToClientPay[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            supIdToClientPay[value.client_id]!![exist] = value
        } else {
            supIdToClientPay[value.client_id]!!.add(value)
        }
    }

    private fun _updateSupsTaxNoteMap(values: List<ClientLightTaxNote>) {
        for (value: ClientLightTaxNote in values) {
            _updateSupTaxNoteMap(value)
        }

    }

    private fun _updateSupTaxNoteMap(value: ClientLightTaxNote) {
        var mapSupId = supIdToTaxNote
        var mapId = idToSupTaxNote
        if (value.type == 1) {
            mapSupId = supIdToTaxPayNote
            mapId = idToSupTaxPayNote

        } else if (value.type == 2) {
            mapSupId = supIdToTaxCancel
            mapId = idToTSupaxCancel
        }
        mapId[value.id] = value
        if (!mapSupId.containsKey(value.client_id)) {
            mapSupId[value.client_id] = mutableListOf()
        }
        val exist = mapSupId[value.client_id]!!.indexOfFirst { it.id == value.id }
        if (exist != -1) {
            mapSupId[value.client_id]!![exist] = value
        } else {
            mapSupId[value.client_id]!!.add(value)
        }
    }

    private fun _updateClientsDeliveryIdtoNotesMap(value: DeliveryNote) {
        clientDeliveryIdToDeliveryNote[value.delivery_id] = (value)
    }

    private fun _updateClientsOrderIdtoNotesMap(value: OrderNote) {
        clientOrderIdToDeliveryNote[value.order_id] = (value)
    }

    private fun _updateClientTaxNoteIdtoTaxMap(value: ClientLightTaxNote) {
        when (value.taxNoteType) {
            TaxNoteType.REGULAR -> {
                idToTaxNote[value.id] = (value)
            }

            TaxNoteType.CANCEL -> {
                idToTaxCancel[value.id] = (value)
            }

            TaxNoteType.WITH_PAYMENT -> {
                idToTaxPayNote[value.id] = (value)
            }

        }

    }

    protected fun _updateClientsMap(values: List<Client>) {
        for (value: Client in values) {
            _updateClientsMap(value)
        }
    }

    protected fun _updateClientsProductMap(values: List<Product>) {
        for (value: Product in values) {
            _updateClientsProductMap(value)
        }
    }

    protected fun _updateClientsVisitNotesMap(values: List<VisitNote>) {
        for (value: VisitNote in values) {
            _updateClientsVistNotesMap(value)
        }

    }

    private fun _updateClientsNotesMap(values: List<DeliveryNote>) {
        for (value: DeliveryNote in values) {
            _updateClientsNotesMap(value)
        }

    }

    private fun _updateClientsOrderNotesMap(values: List<OrderNote>) {
        for (value: OrderNote in values) {
            _updateClientsOrderNotesMap(value)
        }

    }

    protected fun _updateClientsPrice(values: List<RawPrice>, runOver: Boolean = false) {
        for (value: RawPrice in values) {
            if (value.id >= 0 || value.id < -9)
                _updateClientsPrice(value, runOver)
        }
    }

    private fun _updateSuppliersPrice(values: List<RawPrice>) {
        for (value: RawPrice in values) {
            _updateSuppliersPrice(value)
        }
    }

    protected fun _updateClientsMonthlyCycleMap(values: List<MonthlyCycle>) {
        for (value: MonthlyCycle in values) {
            _updateClientsMonthlyCycleMap(value)
        }

    }

    private fun _updateClientsTaxNoteMap(values: List<ClientLightTaxNote>, sup: Boolean = false) {
        if (sup) {
            _updateSupsTaxNoteMap(values)
        } else
            for (value: ClientLightTaxNote in values) {
                when (value.taxNoteType) {
                    TaxNoteType.REGULAR -> _updateClientsTaxNoteMap(value)
                    TaxNoteType.WITH_PAYMENT -> _updateClientsTaxNotePayMap(value)
                    TaxNoteType.CANCEL -> _updateClientsCancelNoteMap(value)
                }
                _updateClientTaxNoteIdtoTaxMap(value)
            }

    }

    private fun _updateClientsPayMap(values: List<ClientLightPay>, sup: Boolean = false) {
        for (value: ClientLightPay in values) {
            if (sup) {
                _updateSupsPayMap(value)
            } else
                _updateClientsPayMap(value)
        }

    }

    private suspend fun _buildBranchInfo(
        client: Client, fromDate: String, toDate: String
    ): DBQuery {
        if (client.master == -1) {
            return DBQuery.SUCCESS
        }

        var masterRaw = getClient(client.master)
        var masterClient: Client = if (masterRaw.second != DBQuery.SUCCESS) {
            val master = buildClients(ids = listOf(client.master), fromDate = fromDate, toDate = toDate)
            if (master.second != DBQuery.SUCCESS || master.first.isEmpty()) {
                return DBQuery.FAILED
            }
            getClient(client.master).first!! // since we have DBquery success
        } else {
            masterRaw.first!!
        }
        return DBQuery.SUCCESS
    }

    private suspend fun _buildBranchInfo(
        clients: List<Client>, fromDate: String, toDate: String
    ): List<Client> {
        val masters: MutableList<Client> = mutableListOf()
        clients.filter { it.branch != -1 }.forEach {
            _buildBranchInfo(it, fromDate, toDate)
            val m = getClient(it.master).first
            m?.let { masters.add(m) }
        }
        return masters.distinctBy { it.id }
    }


    override suspend fun buildClients(
        ids: List<Int>?,
        active: Boolean?,
        fromDate: String,
        toDate: String,
        branch: Int?,
        branches: List<Int>?,
        withDaily: Boolean
    ): Respone<List<Client>, DBQuery> {
        val response = adapter.getClients(
            ids = ids?.joinToString(","),
            active = active?.let { if (it) 1 else 0 }?.toString(),
            fromDate = fromDate,
            toDate = toDate,
            branch = branch?.toString(),
            branches = branches?.joinToString(",")
        )
        val data = response.first.toMutableList()
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsMap(data)
            data.addAll(_buildBranchInfo(data, fromDate, toDate))
            if (withDaily) {
                val q = buildClientsDailyData(ids = ids, date = toDate)
                if (q.second != DBQuery.SUCCESS) {
                    dbQuery = q.second
                }
            }

        }
        return Respone(data.distinctBy { it.id }, dbQuery)
    }

    override fun getClient(ids: List<Int>): Respone<List<Client>, DBQuery> {
        var retClients: MutableList<Client> = mutableListOf()
        ids.forEach {
            if (idToClient.containsKey(it.toLong().toInt())) {
                retClients.add(idToClient[it]!!.get() as Client)
            } else {
                Respone(listOf<Client>(), DBQuery.ID_ISSUE)
            }
        }
        return Respone(retClients, DBQuery.SUCCESS)
    }

    override fun getClient(id: Int): Respone<Client?, DBQuery> {
        return if (id in idToClient) {
            Respone(idToClient[id]!!.get() as Client, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.ID_ISSUE)
        }
    }

    override fun getClient(name: String): Respone<Client?, DBQuery> {
        val client = idToClient.values.filter { it.get().name.compareTo(name) == 0 }
        return if (client.isNotEmpty()) {
            Respone(client.first().get() as Client, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.NAME_ISSUE)
        }
    }

    override fun getAllClient(active: Boolean): Respone<List<Client>, DBQuery> {
        return if (idToClient.isEmpty()) {
            Respone(listOf(), DBQuery.SUCCESS)
        } else {
            Respone(idToClient.values.toList().map { it.get() as Client }.filter {
                if (active) {
                    it.getActive()
                } else {
                    true
                }
            }, DBQuery.SUCCESS)
        }

    }

    fun _addDailyData(c: List<ClientDailyData>) {
        c.forEach { cdd ->
            idToClientDailyUpdated[cdd.id]?.let { if (cdd.date >= it.date) idToClientDailyUpdated[cdd.id] = cdd }
                ?: let { idToClientDailyUpdated[cdd.id] = cdd }
            val map = idToClientDaily[cdd.date] ?: let {
                idToClientDaily[cdd.date] = HashMap()
                idToClientDaily[cdd.date]!!
            }
            map[cdd.id] = cdd

        }
    }

    fun getDateDailyData(date: String? = null): HashMap<Int, ClientDailyData>? {
        return date?.let {
            idToClientDaily[date]
        } ?: idToClientDailyUpdated
    }

    fun getDateDailyDataFinder(date: String): HashMap<Int, ClientDailyData>? {
        return dateToDailyFinder[date]
    }

    @myName("getBaseDay")
    fun getBaseDay(day: Int): String {
        return baseOrderDaysToData[day]!!
    }

    fun getClientCreation(id: Int): String? {
        return clientIdToCreationTime[id]
    }

    fun getClientEdi(id: Int): EdiMember? {
        return ediMap[id]
    }

    fun getLastVisit(id: Int): String? {
        return clientIdToLastVisit[id]
    }

    suspend fun buildClientCreation(
    ): Respone<List<ClientCreation>, DBQuery> {

        val response = adapter.getClientsCreationData(

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = false)
        if (dbQuery == DBQuery.SUCCESS) {
            data.forEach {
                clientIdToCreationTime[it.id] = it.creation_time
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientsDailyData(
        id: Int?,
        date: String,
        driver_id: List<Int>?,
        collector_id: List<Int>?,
        day: Int?,
        ids: List<Int>?,
        dailyFinder: Boolean
    ): Respone<List<ClientDailyData>, DBQuery> {
        val cdate = day?.let { baseOrderDaysToData[day] } ?: date
        val response = adapter.getClientsDailyData(
            id = id?.toString(),
            date = cdate.toString(),
            driver_id = driver_id?.joinToString(","),
            collector_id = collector_id?.joinToString(","),
            ids = ids?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = false)
        if (dbQuery == DBQuery.SUCCESS) {
            _addDailyData(data)
            if (dailyFinder) {
                val dd = dateToDailyFinder.getOrPut(date, { HashMap() })
                data.forEach {
                    dd[it.id] = it
                }
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newClientDailyData(
        id: Int, date: String, driver_id: Int, collector_id: Int, position: Int, car: String,
    ): Respone<List<ClientDailyData>, DBQuery> {
        val response = adapter.newClientDailyData(
            id = id.toString(),
            date = date.toString(),
            driver_id = driver_id.toString(),
            collector_id = collector_id.toString(),
            position = position.toString(),
            car = car
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _addDailyData(data)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newClientAllDailyData(
        date: String, daily: List<ClientDailyData>, dailyFinder: Boolean, onlyDriver: Boolean
    ): Respone<List<ClientDailyData>, DBQuery> {
        val dailyData = ClientDailyData.toJsonArrayString(daily, onlyDriver)

        val response = adapter.newClientAllDailyData(
            date = date.toString(), data = dailyData
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _addDailyData(data)
            if (dailyFinder) {
                val dd = dateToDailyFinder.getOrPut(date, { HashMap() })
                data.forEach {
                    dd[it.id] = it
                }
            }
        }
        return Respone(data, dbQuery)
    }

    suspend fun updateDriverAll(client: Int, driver: Int, all_base: Boolean, fromDate: String? = null): DBQuery {
        val daily = mutableListOf(
            ClientDailyData(
                fromDate ?: DatesManipulator.dateNowNoHour(), client, driver, -1, -1, 0, 0, "", 1
            )
        )
        if (all_base) daily.addAll(baseOrderDaysToData.map {
            ClientDailyData(
                it.value,
                client,
                driver,
                -1,
                -1,
                0,
                0,
                "",
                1
            )
        })
        val d = newClientAllDailyData(daily = daily)
        return d.second
    }

    override suspend fun newClient(
        agent: String,
        name: String,
        date: String,
        include_tax: Int?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: Int?,
        phone: String?,
        branch: Int?,
        master: Int?,
        phone_contact: String?,
        discount: Float?,
        email: String?,
        external_id: String?,
        day: Int?,
        notes: Int?,
        no_tax_client: Int?,
        position: Int?,
        comments: String?,
        min_order: Int?,
        id: Int?,
        location: String?,
        category: String?,
        obligo: Float?,
        price_control: Int?,
        payment_notes: String?,
        category2: String?,
        driver: String?,
        min_order_sum: Float?,
        tax_note_type: Int?,
        net_split: Int?,
        days_to_pay: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        date_contact: String?,
    ): Respone<Client?, DBQuery> {
        val response = adapter.newClient(
            id = id?.toString(),
            agent = agent,
            name = name.replace("\"", "''").replace("\n", "").trim(),
            date = date,
            include_tax = include_tax?.toString(),
            business_name = (if (business_name.isNullOrEmpty()) name else business_name).replace("\"", "''")
                .replace("\n", "").trim(),
            business_id = business_id?.replace("\"", "''")?.replace("\n", "")?.trim(),
            address = prepareStrToJson(address ?: ""),
            print_state = print_state?.toString(),
            phone = phone?.replace("\n", "")?.trim(),
            branch = branch?.toString(),
            master = master?.toString(),
            phone_contact = phone_contact?.let { prepareStrToJson(it) },
            discount = discount?.toString(),
            email = email?.replace("\"", "''")?.replace("\n", ""),
            external_id = external_id?.toString(),
            day = day?.toString(),
            notes = notes?.toString(),
            no_tax_client = no_tax_client?.toString(),
            position = position?.toString(),
            comments = comments?.take(255)?.let { prepareStrToJson(it) },
            min_order = min_order?.toString(),
            location = location,
            category = category,
            obligo = obligo?.toString(),
            price_control = price_control?.toString(),
            payment_notes = payment_notes?.take(40)?.let { prepareStrToJson(it) },
            category2 = category2,
            min_order_sum = min_order_sum?.toString(),
            tax_note_type = tax_note_type?.toString(),
            net_split = net_split?.toString(),
            days_to_pay = days_to_pay?.toString(),
            notes2 = notes2?.take(255)?.let { prepareStrToJson(it) },
            notes3 = notes3?.take(255)?.let { prepareStrToJson(it) },
            notes4 = notes4?.take(255)?.let { prepareStrToJson(it) },
            date_contact = date_contact
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsMap(data!!)
            attachClientProductsToClients(data.map { it.id })
            if (driver != null) {
                val ag = getAgents().first.firstOrNull { it.isDriver() && it.user_name == driver }
                if (ag != null) {
                    val curDriver = data.first().getDriver()
                    if (curDriver == null || ag.id != curDriver.id) {
                        val dt = getDateDailyData(date)?.get(id)
                        newClientDailyData(
                            data.first().id,
                            DatesManipulator.dateNowNoHour(),
                            ag.id,
                            dt?.collector_id ?: -1,
                            dt?.position ?: 0,
                            dt?.car ?: "",
                        )
                    }

                }

            }
        } else {
            return Respone(null, dbQuery)
        }
        return Respone(getClient(data.first().id).first, dbQuery)
    }


    override suspend fun updateClient(
        id: Int,
        date: String,
        agent: String?,
        name: String?,
        include_tax: Int?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: Int?,
        position: Int?,
        active: Int?,
        phone: String?,
        branch: Int?,
        master: Int?,
        phone_contact: String?,
        discount: Float?,
        email: String?,
        external_id: String?,
        day: Int?,
        notes: Int?,
        no_tax_client: Int?,
        comments: String?,
        min_order: Int?,
        location: String?,
        category: String?,
        obligo: Float?,
        price_control: Int?,
        payment_notes: String?,
        category2: String?,
        driver: String?,
        min_order_sum: Float?,
        tax_note_type: Int?,
        net_split: Int?,
        days_to_pay: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        date_contact: String?,

        ): Respone<Client?, DBQuery> {
        val client = getClient(id).first!!
        val existingMaster = client.master

        val response = if (existingMaster != -1) {
            var q = updateClient(
                id = existingMaster,
                date = date,
                agent = client.masterBranch!!.getAgent(),
                name = client.masterBranch!!.getName().trim(),
                position = client.masterBranch!!.position,
                print_state = client.masterBranch!!.print_state,
                include_tax = include_tax ?: client.masterBranch!!.getIncludeTax(),
                business_name = business_name?.replace("\"", "''")?.replace("\n", "")?.trim()
                    ?: client.masterBranch!!.getBusinessName(),
                business_id = business_id?.replace("\"", "''")?.replace("\n", "")?.trim()
                    ?: client.masterBranch!!.getBusinessId(),
                address = client.masterBranch!!.address,
                phone = client.masterBranch!!.getPhone()?.trim(),
                branch = client.masterBranch!!.branch,
                master = (-1),
                phone_contact = client.masterBranch!!.phone_contact,
                discount = discount ?: client.masterBranch!!.discount,
                email = email?.replace("\"", "''")?.replace("\n", "") ?: client.masterBranch!!.getEmail(),
                external_id = client.masterBranch!!.getExternalId(),
                day = client.masterBranch!!.days,
                notes = client.masterBranch!!.notes,
                no_tax_client = client.masterBranch!!.getNoTaxClient(),
                comments = client.masterBranch!!.comments,
                min_order = client.masterBranch!!.min_order,
                location = client.masterBranch!!.location,
                category = client.masterBranch!!.category,
                obligo = client.masterBranch!!.obligo,
                price_control = client.masterBranch!!.price_control,
                payment_notes = client.masterBranch!!.payment_notes,
                category2 = client.masterBranch!!.category2,
                min_order_sum = client.masterBranch!!.min_order_sum,
                tax_note_type = client.masterBranch!!.tax_note_type,
                net_split = client.masterBranch!!.net_split,
                days_to_pay = client.masterBranch!!.days_to_pay,
                notes2 = client.masterBranch!!.notes2,
                notes3 = client.masterBranch!!.notes3,
                notes4 = client.masterBranch!!.notes4,
                date_contact = client.masterBranch!!.date_contact
            )
            if (q.second != DBQuery.SUCCESS) {
                return q
            }
            adapter.updateClient(
                id = id.toString(),
                date = date,
                agent = agent ?: client.getAgent(),
                name = name?.replace("\"", "''")?.replace("\n", "") ?: client.getName(),
                include_tax = include_tax?.toString() ?: client.getIncludeTax().toString(),
                business_name = business_name?.replace("\"", "''")?.replace("\n", "") ?: client.getBusinessName(),
                business_id = business_id?.replace("\"", "''")?.replace("\n", "") ?: client.getBusinessId(),
                address = address?.let { prepareStrToJson(address) } ?: client.getAddress(),
                phone = phone?.replace("\n", "") ?: client.phone.toString(),
                position = position?.toString() ?: client.position?.toString(),
                active = active?.toString() ?: client.active.toString(),
                print_state = print_state?.toString() ?: client.print_state.toString(),
                branch = branch?.toString() ?: client.branch.toString(),
                master = master?.toString() ?: client.master.toString(),
                phone_contact = phone_contact?.let { prepareStrToJson(it) } ?: client.phone_contact,
                discount = discount?.toString() ?: client.discount.toString(),
                email = email?.replace("\"", "''")?.replace("\n", "") ?: client.getEmail(),
                external_id = external_id?.toString() ?: client.getExternalId().toString(),
                day = day?.toString() ?: client.getDays().toString(),
                notes = notes?.toString() ?: client.getNotes().toString(),
                no_tax_client = no_tax_client?.toString() ?: client.getNoTaxClient().toString(),
                comments = comments?.take(255)?.let { prepareStrToJson(it) } ?: client.comments,
                min_order = (min_order ?: client.getMinOrder()).toString(),
                location = location ?: client.location,
                obligo = (obligo ?: client.obligo).toString(),
                price_control = (price_control ?: client.obligo).toString(),
                payment_notes = payment_notes?.take(40)?.let { prepareStrToJson(it) } ?: client.payment_notes,
                category2 = category2 ?: client.category2,
                category = category ?: client.category,
                min_order_sum = min_order_sum?.toString(),
                tax_note_type = tax_note_type?.toString() ?: client.tax_note_type.toString(),
                net_split = net_split?.toString() ?: client.net_split.toString(),
                days_to_pay = days_to_pay?.toString() ?: client.days_to_pay.toString(),
                notes2 = notes2?.take(255)?.let { prepareStrToJson(it) } ?: client.notes2,
                notes3 = notes3?.take(255)?.let { prepareStrToJson(it) } ?: client.notes3,
                notes4 = notes4?.take(255)?.let { prepareStrToJson(it) } ?: client.notes4,
                date_contact = date_contact?.toString() ?: client.date_contact.toString()
            )
        } else {
            adapter.updateClient(
                id = id.toString(),
                date = date,
                agent = agent ?: client.getAgent(),
                name = name?.replace("\"", "''")?.replace("\n", "") ?: client.getName(),
                include_tax = include_tax?.toString() ?: client.getIncludeTax().toString(),
                business_name = business_name?.replace("\"", "''")?.replace("\n", "") ?: client.getBusinessName(),
                business_id = business_id?.replace("\"", "''")?.replace("\n", "") ?: client.getBusinessId(),
                address = address?.let { prepareStrToJson(address) } ?: client.getAddress(),
                print_state = print_state?.toString() ?: client.print_state.toString(),
                position = position?.toString() ?: client.position.toString(),
                active = active?.toString() ?: client.active.toString(),
                phone = phone?.replace("\n", "") ?: client.phone.toString(),
                branch = branch?.toString() ?: client.branch.toString(),
                master = master?.toString() ?: client.master.toString(),
                phone_contact = phone_contact?.let { prepareStrToJson(it) } ?: client.phone_contact,
                discount = discount?.toString() ?: client.discount.toString(),
                email = email?.let { prepareStrToJson(it) } ?: client.getEmail(),
                external_id = external_id?.toString() ?: client.getExternalId().toString(),
                day = day?.toString() ?: client.getDays().toString(),
                notes = notes?.toString() ?: client.getNotes().toString(),
                no_tax_client = no_tax_client?.toString() ?: client.getNoTaxClient().toString(),
                comments = comments?.take(255)?.let { prepareStrToJson(it) } ?: client.comments,
                min_order = (min_order ?: client.getMinOrder()).toString(),
                location = location ?: client.location,
                obligo = (obligo ?: client.obligo).toString(),
                price_control = (price_control ?: client.price_control).toString(),
                payment_notes = payment_notes?.take(40)?.let { prepareStrToJson(it) } ?: client.payment_notes,
                category2 = category2 ?: client.category2,
                category = category ?: client.category,
                min_order_sum = min_order_sum?.toString(),
                tax_note_type = tax_note_type?.toString() ?: client.tax_note_type.toString(),
                net_split = net_split?.toString() ?: client.net_split.toString(),
                days_to_pay = days_to_pay?.toString() ?: client.days_to_pay.toString(),
                notes2 = notes2?.take(255)?.let { prepareStrToJson(it) } ?: client.notes2,
                notes3 = notes3?.take(255)?.let { prepareStrToJson(it) } ?: client.notes3,
                notes4 = notes4?.take(255)?.let { prepareStrToJson(it) } ?: client.notes4,
                date_contact = date_contact?.toString() ?: client.date_contact.toString()
            )
        }
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsMap(data)
            _buildBranchInfo(data, date, date)
            if (driver != null) {
                val ag = getAgents().first.firstOrNull { it.isDriver() && it.user_name == driver }
                if (ag != null) {
                    val curDriver = data.first().getDriver()
                    if (curDriver == null || ag.id != curDriver.id) {
                        val dt = getDateDailyData(date)?.get(id)
                        newClientDailyData(
                            data.first().id,
                            DatesManipulator.dateNowNoHour(),
                            ag.id,
                            dt?.collector_id ?: -1,
                            dt?.position ?: 0,
                            dt?.car ?: "",
                        )
                    }

                }

            }
        }
        return Respone(getClient(id).first, dbQuery)
    }

    override suspend fun updateClientAllData(
        mapper: List<Map<String, Any>>,
        ids: List<Int>?,
        mimshak: Boolean
    ): Respone<List<Client>, DBQuery> {
        val mapIn = if (mimshak) mapOf(
            '\n' to "",
            '\"' to "",
            '\r' to "",
            '\\' to "",
            '?' to "",
            '%' to ""
        ) else null
        val driversUpdate: MutableList<ClientDailyData> = mutableListOf()
        val m = mapper.map { n ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            val driver = n["driver"]?.toString()
            val id = n["id"]?.toString()?.toInt()
            if (!driver.isNullOrEmpty() && id != null) {
                val ag = getAgents().first.firstOrNull { it.isDriver() && it.user_name == driver }
                val cur = getDateDailyData(DatesManipulator.dateNowNoHour())?.get(id)
                if (ag != null && (cur == null || ag.id != cur.driver_id)) {

                    val dt = getDateDailyData(DatesManipulator.dateNowNoHour())?.get(id)
                        ?.copy(date = DatesManipulator.dateNowNoHour(), driver_id = ag.id) ?: ClientDailyData(
                        date = DatesManipulator.dateNowNoHour(),
                        id = id,
                        driver_id = ag.id,
                        collector_id = -1,
                        position = 0,
                        car = "",
                        position_col = 0,
                        group_id = 0,
                        active = 0
                    )

                    driversUpdate.add(dt)

                }

            }
            n.forEach {
                map[it.key] = JsonPrimitive(prepareStrToJson(it.value.toString(), mapIn))
            }
            JsonObject(map)
        }.joinToString(",")

        val date = DatesManipulator.minDay(DatesManipulator.dateNowNoHour())
        val response = adapter.updateClientsAll(
            date = date, data = "[$m]"
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsMap(data)
            if (ids != null)
                buildClientEdiMap(date = date, ids = ids)

            if (driversUpdate.isNotEmpty()) {
                newClientAllDailyData(date = DatesManipulator.dateNowNoHour(), driversUpdate)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientProducts(
        id: Int?, active: Int?, fromDate: String, toDate: String, ids: List<Int>?
    ): Respone<List<Product>, DBQuery> {
        val response = adapter.getClientProducts(id = id?.toString(),
            active = active?.toString(),
            fromDate = fromDate,
            toDate = toDate,
            ids = ids?.let { it.joinToString(",") })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsProductMap(data)
            val q = buildOrderProducts(product_id = id)
            if (q.second != DBQuery.SUCCESS) {
                return Respone(listOf(), q.second)
            }
        }
        return Respone(data, dbQuery)
    }

    fun getClientProduct(ids: List<Int>): Respone<List<Product>, DBQuery> {
        val lst: MutableList<Product> = mutableListOf()
        ids.forEach { id ->
            if (id in idToClientProduct) {
                lst.add((idToClientProduct[id]!!.get()))
            } else {
                return Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        return Respone(lst, DBQuery.SUCCESS)
    }

    override fun getClientProduct(id: Int): Respone<Product?, DBQuery> {
        return if (id in idToClientProduct) {
            Respone(idToClientProduct[id]!!.get(), DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.ID_ISSUE)
        }
    }

    override fun getClientProduct(name: String): Respone<Product?, DBQuery> {
        val product = idToClientProduct.values.filter { it.get().name.compareTo(name) == 0 }
        return if (product.isNotEmpty()) {
            Respone(product.first().get(), DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.NAME_ISSUE)
        }
    }

    override fun getClientPrices(id: Int): HashMap<Int, Price> {
        return clientIdToPrice[id]!!
    }

    fun getAllCategories(): Respone<List<String>, DBQuery> {
        return if (idToClientProduct.isEmpty()) {
            Respone(listOf(), DBQuery.SUCCESS)
        } else {
            Respone(
                idToClientProduct.values.map { it.get().getCategory() }.toSet().toList(), DBQuery.SUCCESS
            )
        }
    }

    override fun getAllClientProduct(active: Boolean): Respone<List<Product>, DBQuery> {
        return if (idToClientProduct.isEmpty()) {
            Respone(listOf(), DBQuery.SUCCESS)
        } else {
            Respone(idToClientProduct.values.map { it.get() }.toList().filter {
                if (active) {
                    it.active == 1
                } else {
                    true
                }
            }, DBQuery.SUCCESS
            )
        }
    }

    override suspend fun newClientProduct(
        name: String,
        date: String,
        barcode: String?,
        default_price: Float?,
        no_tax_product: Int?,
        external_id: String?,
        unit: Int?,
        unit_amount: Float?,
        category: String?,
        cost_price: Float?,
        split_category: Int?,
        position: Int?,
        base_price: Float?,
        cost_include_returns: Int?,
        category2: String?,
        id: Int?,
        taklit_id: Int?,
        taklit_type: Int?,

        ): Respone<Product?, DBQuery> {
        val response = adapter.newClientProduct(
            name = prepareStrToJson(name),
            date = date,
            barcode = barcode?.trim(),
            default_price = default_price?.toString(
            ),
            no_tax_product = no_tax_product?.toString(),
            external_id = external_id?.trim(),
            unit = unit?.toString(),
            unit_amount = unit_amount?.toString(),
            category = category?.let { prepareStrToJson(it) },
            cost_price = cost_price?.toString(),
            split_category = split_category?.toString(),
            position = position?.toString(),
            base_price = base_price?.toString(),
            cost_include_returns = cost_include_returns?.toString(),
            category2 = category2,
            id = id?.toString(),
            taklit_id = taklit_id?.toString(),
            taklit_type = taklit_type?.toString(),

            )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsProductMap(data!!)
            data.let {
                getAllClient(true).first.forEach { c ->
                    c.fillProduct(it)
                }
            }
        }
        return Respone(data, dbQuery)
    }


    override suspend fun updateClientProduct(
        id: Int,
        date: String,
        name: String?,
        barcode: String?,
        default_price: Float?,
        position: Int?,
        active: Int?,
        no_tax_product: Int?,
        taklit_id: Int?,
        taklit_type: Int?,
        external_id: String?,
        unit: Int?,
        unit_amount: Float?,
        category: String?,
        cost_price: Float?,
        split_category: Int?,
        base_price: Float?,
        cost_include_returns: Int?,
        category2: String?
    ): Respone<Product?, DBQuery> {
        val product = getClientProduct(id).first!!
        val response = adapter.updateClientProduct(id = id.toString(),
            date = date,
            name = name?.replace("\"", "''")?.replace("\n", "")?.trim() ?: product.getName(),
            barcode = barcode?.trim() ?: product.getBarcode(),
            default_price = default_price?.toString() ?: product.default_price.toString(),
            position = position?.toString() ?: product.getPosition().toString(),
            active = active?.toString() ?: product.active.toString(),
            no_tax_product = no_tax_product?.toString() ?: product.no_tax_product.toString(),
            taklit_id = taklit_id?.toString() ?: product.taklit_id.toString(),
            taklit_type = taklit_type?.toString() ?: product.taklit_type.toString(),
            external_id = external_id?.toString() ?: product.getExternalId().toString(),
            unit = unit?.toString() ?: product.getUnit().toString(),
            unit_amount = unit_amount?.toString() ?: product.getUnitAmmount().toString(),
            category = category?.let { prepareStrToJson(it) } ?: product.getCategory(),
            cost_price = (cost_price ?: product.getCostPrice()).toString(),
            split_category = (split_category?.toString() ?: product.getSplitCategory().toString()),
            base_price = base_price?.toString() ?: product.getBasePrice().toString(),
            cost_include_returns = cost_include_returns?.toString() ?: product.getCostIncludeReturns().toString(),
            category2 = category2 ?: product.getCategory2()

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsProductMap(data)
        }
        return Respone(getClientProduct(id).first, dbQuery)
    }

    suspend fun updateSupplierAllProduct(
        mapper: List<Map<String, Any>>,
    ): Respone<List<Product>, DBQuery> {


        val m = mapper.map { n ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            n.forEach {
                map[it.key] = JsonPrimitive(it.value.toString())
            }
            JsonObject(map)
        }.joinToString(",")


        val response = adapter.updateSupplierAllProduct(
            date = DatesManipulator.dateNowNoHour(), data = "[$m]"
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersProductMap(data)
        }

        return Respone(data, dbQuery)
    }


    override suspend fun updateClientAllProduct(
        mapper: List<Map<String, Any>>, full: Boolean, mimshak: Boolean
    ): Respone<List<Product>, DBQuery> {
        val ids = mapper.map {
            val p_id = it["product_id"]
            val id = if (p_id is String) p_id.toInt() else p_id as Int
            id
        }
        val mapIn = if (mimshak) mapOf(
            '\n' to "",
            '\"' to "",
            '\r' to "",
            '\\' to "",
            '?' to "",
            '%' to ""
        ) else null
        val m = mapper.map { n ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            n.forEach {
                map[it.key] = JsonPrimitive(prepareStrToJson(it.value.toString(), mapIn))
            }
            JsonObject(map)
        }.joinToString(",")


        val response = if (full) adapter.updateClientAllProductFull(
            date = DatesManipulator.minDay(DatesManipulator.dateNowNoHour()),
            data = "[$m]"
        ) else adapter.updateClientAllProduct(
            date = DatesManipulator.dateNowNoHour(), data = "[$m]"
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsProductMap(data)
            buildOrderProducts(product_ids = ids)
        }
        return Respone(data, dbQuery)
    }

    suspend fun updateAllClientProductPrice(
        date: String,
        prices: List<RawPrice>,
        toDate: String? = null,
        ids: List<Int>? = null,
        product_ids: List<Int>? = null,
        availables: List<Int>? = null,
        unique: Boolean = false
    ): DBQuery {
        val pricesWithMasters = prices.toMutableList()
        val allids = prices.map { it.id }.distinct().toSet()
        val applyToDate = if (unique && toDate != null) DatesManipulator.getNextDay(toDate) else null
        if (applyToDate != null) {
            val curPrices = buildClientPrices(
                id = ids, product_id = product_ids, fromDate = applyToDate, toDate = applyToDate
            )
            if (curPrices.second != DBQuery.SUCCESS) {
                return DBQuery.FAILED
            }
        }



        prices.forEach {
            if (applyToDate != null) {
                val client = getClient(it.id).first!!
                val price = client.getPrice(it.product_id)?.get(applyToDate)
                pricesWithMasters.add(
                    it.copy(
                        date = applyToDate,
                        price = price?.first ?: it.price,
                        discount = price?.second ?: it.discount
                    )
                )
            }
            if (it.id < 0)
                return@forEach
            val c = getClient(it.id).first!!
            val master = c.master

            if (master != -1 && !allids.contains(master)) {
                pricesWithMasters.add(it.copy(id = master))
                if (applyToDate != null) {
                    pricesWithMasters.add(it.copy(id = master, date = applyToDate))
                }
            }
        }

        val response = adapter.updateClientAllPrices(
            date = date,
            data = "[${pricesWithMasters.map { it.toJson().toString() }.joinToString(",")}]",
            toDate = toDate
        )
        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            toDate?.let {
                ids?.forEach {
                    if (it == -1) {
                        product_ids?.forEach { p_id -> productIdToCostPrice.remove(p_id) }

                    } else
                        product_ids?.forEach { p_id -> clientIdToPrice[it]?.remove(p_id) }
                }
            }
            _updateClientsPrice(dataR)
//            dataR.forEach { clientMonthlyCycleReCalculate(date = it.date, id = it.id) }
            toDate?.let {
                val curDate = DatesManipulator.dateNowNoHour()
                if (curDate > toDate || curDate < date) {
                    val curPrices =
                        buildClientPrices(id = ids, product_id = product_ids, fromDate = curDate, toDate = curDate)
                }
            }
            if (availables != null) {
                return newAllAvailable(prices.mapIndexed { index: Int, rawPrice: RawPrice ->
                    Available(
                        rawPrice.id,
                        rawPrice.product_id,
                        availables[index]
                    )
                }).second
            }
        }
        return dbQuery
    }

    override suspend fun updateAllSpecialProductPrice(
        ids: List<Int>,
        product_id: List<Int>,
        date: String,
        price: Float?,
        default_price: Float?,
        percent: Float?,
        tax_mutate: Boolean,
        percent_price: Float?,
        toDate: String?,
        byPrice: Float?,
        unique: Boolean?
    ): DBQuery {
        //price is with no maam
        val curIds = ids
        val applyToDate =
            if (unique != null && unique && toDate != null) DatesManipulator.getNextDay(toDate) else null
        val curPrices = buildClientCosts(
            ids = ids, fromDate = date, toDate = applyToDate ?: toDate ?: date
        )

        if (curPrices.second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }


        val data: MutableList<RawPrice> = mutableListOf()
        product_id.forEach { p_id ->
            val p = getClientProduct(p_id).first!!
            val x = curIds.map {

                var updatedPrice = ((price?.let {
                    if (tax_mutate && p.getNoTaxProduct(date) != 1) {
                        tax.get(toDate ?: date) * price
                    } else {
                        price
                    }
                }) ?: getProductCost(p_id)?.get(date)?.first?.times((percent_price?.let { 1 + it / 100 } ?: 1f)))
                updatedPrice = if (updatedPrice != null && updatedPrice > 0) {
                    max(
                        0f, ((byPrice?.times(
                            if (tax_mutate && p.getNoTaxProduct(date) != 1) {
                                tax.get(toDate ?: date)
                            } else {
                                1f
                            }
                        ) ?: 0f) + updatedPrice)
                    )
                } else {
                    0f
                }

                val finalPrice = roundToDecimals(updatedPrice!!, 2)
                val discount = percent ?: 0f
                val changer = mutableListOf(RawPrice(it, p_id, finalPrice, date, discount))
                if (applyToDate != null) {
                    val priceX = getProductCost(p_id)?.get(applyToDate)
                    priceX?.let { priceX ->
                        changer.add(RawPrice(it, p_id, priceX.first, applyToDate, priceX.second))
                    }

                }
                changer
            }.flatten()
            data.addAll(x)
        }
        val response = adapter.updateClientAllPrices(
            date = date, data = "[${data.map { it.toJson().toString() }.joinToString(",")}]", toDate = toDate
        )
        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            return buildClientCosts(date, toDate ?: date, ids).second
        }
        return DBQuery.FAILED
    }

    override suspend fun updateAllClientProductPrice(
        ids: List<Int>?,
        product_id: List<Int>,
        date: String,
        price: Float?,
        default_price: Float?,
        percent: Float?,
        tax_mutate: Boolean,
        percent_price: Float?,
        toDate: String?,
        byPrice: Float?,
        unique: Boolean?,
        baseChange: Boolean,
        alreadyPrice: Boolean
    ): DBQuery {
        //price is with no maam
        val curIds = ids?.let {
            val curId = it.toMutableSet()
            curId.addAll(getAllMasters(ids).first)
            curId.toList()

        } ?: getAllClient(true).first.map { it.id }
        val applyToDate =
            if (unique != null && unique && toDate != null) DatesManipulator.getNextDay(toDate) else null
        val curPrices = buildClientPrices(
            id = ids, product_id = product_id, fromDate = date, toDate = applyToDate ?: toDate ?: date
        )

        if (curPrices.second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }


        val data: MutableList<RawPrice> = mutableListOf()
        product_id.forEach { p_id ->
            val p = getClientProduct(p_id).first!!
            val x = curIds.map {
                val c = getClient(it).first!!
                val dontHavePrice = c.getPrice(p_id)!!.get(date).first == 0f
                if (alreadyPrice && dontHavePrice) {
                    //if there is no price to this client and we dont want to change it
                    return@map listOf()
                }
                var updatedPrice = ((price?.let {
                    if (tax_mutate && c.getIncludeTax(date) == 1 && p.getNoTaxProduct(date) != 1) {
                        tax.get(toDate ?: date) * price
                    } else {
                        price
                    }
                }) ?: c.getPrice(p_id)!!.get(date).first * (percent_price?.let { 1 + it / 100 } ?: 1f))
                if (updatedPrice > 0) {
                    updatedPrice = max(
                        0f, ((byPrice?.times(
                            if (tax_mutate && c.getIncludeTax(date) == 1 && p.getNoTaxProduct(date) != 1) {
                                tax.get(toDate ?: date)
                            } else {
                                1f
                            }
                        ) ?: 0f) + updatedPrice)
                    )
                }

                val finalPrice = roundToDecimals(updatedPrice, 2)
                val discount = percent ?: c.getPrice(p_id)!!.get(date).second
                val changer = mutableListOf(RawPrice(it, p_id, finalPrice, date, discount))
                if (applyToDate != null) {
                    val priceX = c.getPrice(p_id)!!.get(applyToDate)
                    changer.add(RawPrice(it, p_id, priceX.first, applyToDate, priceX.second))
                }
                changer
            }.flatten()
            data.addAll(x)
        }
        if (baseChange && price != null) {
            val includeTax = getAllClient(true).first.map { it.include_tax }.average() >= 0.5
            val pp = if (includeTax) tax.get(date) * price else price
            product_id.forEach {

                updateClientProduct(it, DatesManipulator.minDay(date), default_price = pp)
            }
        }
        return updateAllClientProductPrice(date, data, toDate, curIds, product_id)
    }

    override fun attachClientProductsToClients(ids: List<Int>?): DBQuery {
        //assuming already filled.
        val productsQuery = getAllClientProduct(false)
        val clientsQuery = ids?.let { getClient(it) } ?: getAllClient(false)
        if (!(productsQuery.second == DBQuery.SUCCESS && clientsQuery.second == DBQuery.SUCCESS)) {
            return DBQuery.FAILED
        }
        val products = productsQuery.first
        val clients = clientsQuery.first
        for (c: Client in clients) {
            for (p: Product in products) {
                c.fillProduct(p)
            }

        }
        return DBQuery.SUCCESS
    }

    @myName("getProductCost")
    fun getProductCost(product_id: Int): Price? {
        return productIdToCostPrice[product_id]
    }

    @myName("getAllProductCost")
    fun getAllProductCost(): List<Price> {
        return productIdToCostPrice.values.toList()
    }

    suspend fun buildClientCosts(
        fromDate: String, toDate: String, ids: List<Int>, clear: Boolean = false
    ): Respone<List<RawPrice>, DBQuery> {
        val response = adapter.getSpecialPrices(
            fromDate = fromDate, toDate = toDate, ids = ids.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            if (clear) {
                productIdToCostPrice.clear()
                productIdToDefaultPrice.clear()
            }
            val cost = ids.contains(-1)
            val default = ids.contains(-2)
            val productGrp = data.groupBy { it.product_id }
            getAllClientProduct(true).first.forEach {
                val items = productGrp[it.id]
                if (cost) {
                    if (!productIdToCostPrice.containsKey(it.id)) {
                        val p = Price(it.id, "client", true)
                        productIdToCostPrice[it.id] = p
                    }
                }
                if (default) {
                    if (!productIdToDefaultPrice.containsKey(it.id)) {
                        val p = Price(it.id, "client")
                        productIdToDefaultPrice[it.id] = p
                    }
                }

                items?.forEach { price ->
                    if (price.id == -1) {
                        productIdToCostPrice[it.id]?.add(price)
                    } else if (price.id == -2) {
                        productIdToDefaultPrice[it.id]?.add(price)
                    }
                }
            }
        }
        return Respone(data, dbQuery)
    }

    suspend fun bringMasterPrices(ids: List<Int>, fromDate: String, toDate: String): DBQuery {
        val slaves = ids.let { getClient(ids) }
        if (slaves.second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }
        val s = slaves.first.map { it.id }.toSet()
        val masters: MutableList<Int> = mutableListOf()
        slaves.first.forEach {
            it.masterBranch?.let { master ->
                if (master.id !in s) {
                    masters.add(master.id)
                }

            }
            if (it.price_control <= -10 && it.price_control !in s) {
                masters.add(it.price_control)
            }
        }
        if (masters.isNotEmpty()) {
            val q = fetchSpecificClientData(masters.distinct(), fromDate, toDate)
            if (q != DBQuery.SUCCESS) {
                return DBQuery.FAILED
            }
        }
        return DBQuery.SUCCESS
    }

    override suspend fun buildClientPrices(
        id: List<Int>?,
        product_id: List<Int>?,
        fromDate: String,
        toDate: String,
        available: Boolean?,
        master: Boolean?
    ): Respone<List<RawPrice>, DBQuery> {
        val response = adapter.getClientPrices(
            ids = id?.joinToString(","),
            product_ids = product_id?.joinToString(","),
            fromDate = fromDate,
            toDate = toDate,
            master = master?.let { if (it) "1" else null },
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsPrice(data)
            if (id != null) {
                val q = bringMasterPrices(id!!, fromDate, toDate)
                if (q != DBQuery.SUCCESS) {
                    return Respone(listOf(), DBQuery.FAILED)
                }
            }

            if (available == true) {
                getProductAvailable(id, product_id)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientPriorityPrices(id: List<Int>, date: String): Respone<List<RawPrice>, DBQuery> {
        val ids = id.first()
        val client = getClient(ids).first ?: return Respone(listOf(), DBQuery.FAILED)

        val specificId =
            if (client.masterBranch != null) (if (client.masterBranch!!.price_control != 0) client.masterBranch!!.price_control else client.masterBranch!!.id) else if (client.price_control != 0) client.price_control else client.id
        val response = adapter.getClientPriorityPrices(
            id = ids.toString(),
            date = date,
            master = specificId.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            val pricesRaw = data.mapNotNull { it.toRawPrice(specificId) }
            pricesRaw.forEach { rp ->
                val prices = clientIdToPrice[rp.id] ?: let {
                    clientIdToPrice[rp.id] = HashMap<Int, Price>()
                    clientIdToPrice[rp.id]!!
                }

                if (!prices.containsKey(rp.product_id)) {
                    prices[specificId] = Price(specificId, "client")
                }
                prices[rp.product_id]!!.add(rp, false)
            }

            data.forEach {
                clientIdToAvailable.getOrPut(it.id, { HashMap() }).put(it.product_id, it.toAvailable())
            }
        }
        return Respone(data.mapNotNull { it.toRawPrice(specificId) }, dbQuery)
    }


    override suspend fun buildLatestClientPrices(
        ids: List<Int>?, product_id: Int?, clear: Boolean, fetchControlAndMasters: Boolean
    ): Respone<List<RawPrice>, DBQuery> {
        val response =
            adapter.getLastClientPrices(ids = ids?.joinToString(","), product_id = product_id?.toString())
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsPrice(data, clear)
            if (ids != null && fetchControlAndMasters) {
                val d = DatesManipulator.dateNowNoHour()
                val q = bringMasterPrices(ids!!, d, d)
                if (q != DBQuery.SUCCESS) {
                    return Respone(listOf(), DBQuery.FAILED)
                }
            }


        }

        return Respone(data, dbQuery)
    }

    override suspend fun newClientPrice(
        id: Int, product_id: Int, price: Float, date: String?, discount: Float, toDate: String?
    ): Respone<RawPrice?, DBQuery> {

        val response = updateAllClientProductPrice(
            ids = listOf(id),
            product_id = listOf(product_id),
            price = price,
            date = date!!,
            toDate = toDate,
            percent = discount,
            tax_mutate = false
        )

        if (response == DBQuery.SUCCESS) {
            id.let { clientMonthlyCycleReCalculate(date = date, id = id) }
        }
        return Respone(null, response)
    }

    suspend fun buildFullClientOrderNotes(notes: List<OrderNote>, withDaily: Boolean = true): DBQuery {
        if (notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        val dates = notes.map { it.date }.distinct()

        val d = DatesHolder(dates)
        var first_date = d.getFirst()
        var last_date = d.getLast()
        if (first_date == unclosed_date && last_date == unclosed_date) {
            first_date = DatesManipulator.dateNowNoHour()
            last_date = DatesManipulator.dateNowNoHour()
        }
        val ids = notes.map { it.ent_id }.distinct()
        val product_ids = Note.getAllProducts(notes).toList()
        var q = fetchSpecificClientData(
            ids,
            fromDate = first_date,
            toDate = last_date,
            product_ids = if (product_ids.size > 0) product_ids.toList() else null
        )
        if (q != DBQuery.SUCCESS) {
            return q
        }
        if (withDaily)
            q = buildClientsDailyData(ids = ids, date = last_date).second // need to switch this?

//        val p = getSign(notes.map { it.id }, doc_type = 10)
//        if (p.second != DBQuery.SUCCESS && p.second != DBQuery.ERROR_EMPTY_NEW) {
//            return p.second
//        }
        q = calcDeliveryNoteSumMap(notes.filter { it.ent_id > -1 || it.ent_id <= -10 }).second
        return q

    }

    override suspend fun buildClientOrderNotes(
        client_id: Int?,
        order_id: Int?,
        fromDate: String?,
        toDate: String?,
        full: Boolean,
        withoutInventory: Boolean,
        order_ids: List<Int>?,
        status: List<Int>?,
        client_ids: List<Int>?,
        inverse_status: List<Int>?,
        ref_id: List<Int>?,
        agent_id: List<Int>?
    ): Respone<List<OrderNote>, DBQuery> {
        val needHistoryQ =
            if (getUser().first?.company_type == 4 && (fromDate != null && toDate != null) && DatesManipulator.daysDiffBetweenDates(
                    toDate,
                    fromDate
                ) > optimizeDaysDocuments
            ) {

                getClientOrderNotesOpt(
                    client_id = client_id,
                    order_id = order_id,
                    fromDate = fromDate,
                    toDate = toDate,
                    withoutInventory = withoutInventory,
                    client_ids = client_ids,
                    order_ids = order_ids,
                    states = status,

                    inverse_status = inverse_status,
                    ref_ids = ref_id,
                    agent_ids = agent_id
                ).first
            } else null

        val response = adapter.getClientOrderNotes(
            client_ids?.joinToString(",") ?: client_id?.toString(),
            order_id?.toString(),
            fromDate,
            toDate,
            order_ids = order_ids?.joinToString(","),
            status = status?.joinToString(","),
            inverse_status = inverse_status?.joinToString(","),
            ref_id = ref_id?.joinToString(","),
            agent_id = agent_id?.joinToString(","),
            historyQ = needHistoryQ?.let { lst ->
                val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                lst.forEach {
                    map[it.id.toString()] = JsonPrimitive(it.action_time)
                }
                JsonObject(map).toString()
            }
        )
        var data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            if (withoutInventory) data = data.filter { it.ent_id != -1 || it.ent_id != -2 || it.ent_id != -3 }
            _updateClientsOrderNotesMap(data)
            if (needHistoryQ != null)
                data = getClientOrderNotesOpt(
                    client_id = client_id,
                    order_id = order_id,
                    fromDate = fromDate,
                    toDate = toDate,
                    withoutInventory = withoutInventory,
                    order_ids = order_ids,
                    states = status,
                    client_ids = client_ids,
                    inverse_status = inverse_status,
                    ref_ids = ref_id,
                    agent_ids = agent_id
                ).first
            if (full && buildFullClientOrderNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override suspend fun buildClientLastOrderNotes(
        client_id: Int,
        full: Boolean
    ): Respone<List<OrderNote>, DBQuery> {
        val response = adapter.getClientLastOrderNotes(
            client_id.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsOrderNotesMap(data)
            if (full && buildFullClientOrderNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override fun getAllClientOrderNotes(): Respone<Map<Int, List<OrderNote>>, DBQuery> {
        return Respone(clientIdToOrderNote.toMap(), DBQuery.SUCCESS)
    }

    override suspend fun getLastUpdateOrders(
        date: String,
        action_time: String,
        full: Boolean
    ): Respone<List<OrderNote>, DBQuery> {
        val response = adapter.getLastUpdateOrders(
            date,
            action_time
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsOrderNotesMap(data)
            if (full && buildFullClientOrderNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun getLastUpdateEnt(
        action_time: String,
    ): Respone<List<Int>, DBQuery> {
        val response = adapter.getLastUpdateEnt(
            action_time
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override fun getClientOrderNotes(
        order_id: Int?, client_id: Int?, date: String?, order_ids: List<Int>?
    ): Respone<List<OrderNote>, DBQuery> {
        (order_id?.let { listOf(it) } ?: order_ids)?.let {
            val retMap = it.mapNotNull { od -> clientOrderIdToDeliveryNote[od] }
            return if (retMap.size == it.size) {
                Respone(retMap, DBQuery.SUCCESS)
            } else {
                Respone(listOf(), DBQuery.ID_ISSUE)
            }

        }
        var ret = client_id?.let {
            if (it in clientIdToOrderNote) {
                clientIdToOrderNote[it]!!
            } else {
                listOf()
            }
        } ?: clientIdToOrderNote.values.flatten()
        ret = date?.let { d -> ret.filter { it.date == d } } ?: ret
        return Respone(ret, DBQuery.SUCCESS)
    }

    override fun getClientOrderNotesOpt(
        order_id: Int?,
        client_id: Int?,
        fromDate: String?,
        toDate: String?,
        order_ids: List<Int>?,
        withoutInventory: Boolean,
        states: List<Int>?,
        inverse_status: List<Int>?,
        ref_ids: List<Int>?,
        agent_ids: List<Int>?,
        client_ids: List<Int>?,
    ): Respone<List<OrderNote>, DBQuery> {

        (order_id?.let { listOf(it) } ?: order_ids)?.let {
            val retMap = it.mapNotNull { od -> clientOrderIdToDeliveryNote[od] }
            return if (retMap.size == it.size) {
                Respone(retMap, DBQuery.SUCCESS)
            } else {
                Respone(listOf(), DBQuery.ID_ISSUE)
            }

        }

        var ret = client_id?.let {
            if (it in clientIdToOrderNote) {
                clientIdToOrderNote[it]!!
            } else {
                listOf()
            }
        } ?: clientIdToOrderNote.values.flatten()
        val statusSet = states?.toSet()
        val inverse_statusSet = inverse_status?.toSet()
        val ref_idsSet = ref_ids?.toSet()
        val agent_idsSet = agent_ids?.toSet()
        val client_idsSet = client_ids?.toSet()
        ret = ret.filter {
            (fromDate == null || it.date >= fromDate) &&
                    (toDate == null || it.date <= toDate) &&
                    (client_idsSet == null || it.ent_id in client_idsSet) &&
                    (statusSet == null || it.active in statusSet) &&
                    (inverse_statusSet == null || it.active !in inverse_statusSet) &&
                    (ref_idsSet == null || it.ref_id in ref_idsSet) &&
                    (agent_idsSet == null || it.agent_id in agent_idsSet) &&
                    (!withoutInventory || (withoutInventory && (it.ent_id != -1 || it.ent_id != -2 || it.ent_id != -3)))
        }
        return Respone(ret, DBQuery.SUCCESS)
    }

    fun getfirstMatchlientOrderNotes(
        order_id: String, date: String? = null
    ): OrderNote? {
        if (order_id.isEmpty())
            return null
        clientIdToOrderNote.values.flatten().forEach {
            if (date != null && it.date != date) {
                return@forEach
            }
            val oSize = order_id
            val curOrderSize = it.order_id.toString()
            if (curOrderSize.length >= oSize.length && curOrderSize.substring(
                    curOrderSize.length - oSize.length,
                    curOrderSize.length
                ) == order_id
            ) {
                return it
            }

        }
        clientIdToOrderNote.values.flatten().forEach {
            if (it.order_id.toString() == (order_id)) {
                return it
            }

        }

        return null
    }

    override suspend fun getClientBaseOrderNotes(
        client_ids: List<Int>?,
        day: Int,
        full: Boolean
    ): Respone<List<OrderNote>, DBQuery> {
        val date = baseOrderDaysToData[day]
        val o = buildClientOrderNotes(client_ids = client_ids, fromDate = date, toDate = date)
        if (full)
            calcDeliveryNoteSumMap(o.first)
        return o
    }


    override suspend fun newClientOrderNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: String,
        sup_delivery_info: String,
        active: Int,
        notes: String?,
        category: String?,
        order_id: Int?,
        notify: Boolean,
        ref_id: String?,
        agent_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        history: String?,
        collection: String?,
        increase_id: Int?,
        noApi: Boolean?,
        static_data: ClientStaticData?,
    ): Respone<OrderNote?, DBQuery> {
        val response = adapter.newClientOrderNote(
            agent = agent,
            ent_id = ent_id.toString(),
            date = date,
            date_issued = date_issued,
            delivery_value = delivery_value,
            sup_delivery_info = sup_delivery_info,
            active = active.toString(),
            notes = notes?.let { prepareStrToJson(it) },
            category = category,
            order_id = order_id?.toString(),
            notify = if (notify) "true" else null,
            ref_id = ref_id,
            agent_id = agent_id?.toString(),
            notes2 = notes2?.let { prepareStrToJson(it) },
            notes3 = notes3?.let { prepareStrToJson(it) },
            notes4 = notes4?.let { prepareStrToJson(it) },
            notes5 = notes5?.let { prepareStrToJson(it) },
            history = history,
            collection = collection,
            increase_id = increase_id?.toString(),
            noApi = if (noApi == true) "true" else null,
            static_data = static_data?.let {
                ClientStaticData.toJsonArrayString(static_data)
            }
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientOrderNoteForToday(data!!.ent_id, data)
            _updateClientsOrderNotesMap(listOf(data))
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newClientOrderNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        sup_delivery_value: List<ProductDelivery>,
        active: Int,
        notes: String?,
        category: String?,
        order_id: Int?,
        notify: Boolean,
        ref_id: String?,
        agent_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        history: List<ProductDelivery>?,
        with_price: Boolean,
        collection: Map<Int, CollectionObject>?,
        checkUpdate: Boolean?,
        increase_id: Int?,
        noApi: Boolean?,
        static_data: ClientStaticData?,
    ): Respone<OrderNote?, DBQuery> {

        if (with_price) {
            var ent_to_change_id = ent_id
            if (getClient(ent_id).first?.master != -1) {
                ent_to_change_id = getClient(ent_id).first!!.master
            }

            val q = changePriceFromProductDelivery(delivery_value, ent_to_change_id, date, "client")
            if (q != DBQuery.SUCCESS) {
                return Respone(null, DBQuery.FAILED)
            }
        }
        var collect = collection
        val filled = agents.filter {
            (it.is_collector == 5) && (delivery_value.any { pd ->
                pd.getProduct().getOrderProduct()?.collector == it.id
            })
        }
        if (filled.size > 0) {
            collect = (collect ?: mutableMapOf()).toMutableMap()
            filled.forEach { ag ->
                val curProductsPds = delivery_value.filter { it.getProduct().getOrderProduct()?.collector == ag.id }
                val boxes = ProductDelivery.kartonEstimate(curProductsPds)
                collect[ag.id] = CollectionObject(ag.id, boxes)
            }

        }
        var oId = order_id
        if (order_id == null && checkUpdate == true) {
            buildClientOrderNotes(client_ids = listOf(ent_id), fromDate = date, toDate = date).first.let {
                oId = it.firstOrNull()?.order_id
            }
        }

        return newClientOrderNote(
            agent = agent,
            ent_id = ent_id,
            date = date,
            date_issued = date_issued,
            delivery_value = ProductDelivery.toJsonArrayString(delivery_value),
            sup_delivery_info = ProductDelivery.toJsonArrayString(sup_delivery_value),
            active = active,
            notes = notes?.let { prepareStrToJson(it) },
            category = category,
            order_id = oId,
            notify = notify,
            ref_id = ref_id,
            agent_id = agent_id,
            notes2 = notes2?.let { prepareStrToJson(it) },
            notes3 = notes3?.let { prepareStrToJson(it) },
            notes4 = notes4?.let { prepareStrToJson(it) },
            notes5 = notes5?.let { prepareStrToJson(it) },
            history = history?.let { ProductDelivery.toJsonArrayString(history) }
                ?: collect?.let { ProductDelivery.toJsonArrayString(delivery_value) },
            collection = collect?.let { CollectionObject.toJson(it) },
            increase_id = increase_id,
            noApi = noApi,
            static_data = static_data
        )

    }

    override suspend fun updateClientOrderNote(
        order_id: Int,
        agent: String?,
        active: Int?,
        date: String?,
        delivery_value: List<ProductDelivery>?,
        sup_delivery_value: List<ProductDelivery>?,
        notes: String?,
        category: String?,
        updatePrice: Boolean,
        notify: Boolean,
        ref_id: String?,
        inventory: String?,
        agent_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        history: List<ProductDelivery>?,
        collection: Map<Int, CollectionObject>?,
        collector_id: Int?,
        karton: Float?,
        increase_id: Int?,
        update_agent: String?,
        static_data: ClientStaticData?,
    ): Respone<OrderNote?, DBQuery> {
        val orderNote = if (collector_id != null) buildClientOrderNotes(order_id = order_id).first.firstOrNull()
        else if ((delivery_value != null && updatePrice)) getClientOrderNotes(order_ids = listOf(order_id)).first.first()
        else null

        if (delivery_value != null && updatePrice) {
            val currentNote = orderNote!!
            if (currentNote.ent_id != -1) {
                val priceUpdateList: MutableList<ProductDelivery> = mutableListOf()
                val client = currentNote.getEnt(true) as Client
                delivery_value.forEach {
                    if (client.getPrice(it.productId)?.get(currentNote.date)?.first != it.price) {
                        priceUpdateList.add(it)
                    }
                }
                var ent_to_change_id = client.id
                if (client.master != -1) {
                    ent_to_change_id = client.master
                }

                if (priceUpdateList.isNotEmpty()) {
                    val q =
                        changePriceFromProductDelivery(
                            priceUpdateList,
                            ent_to_change_id,
                            currentNote.date,
                            "client"
                        )
                    if (q != DBQuery.SUCCESS) {
                        return Respone(null, DBQuery.FAILED)
                    }
                }
            }
        }
        var h = history

        var collect = collection?.toMutableMap()
        var status = active
        var pds = delivery_value?.toMutableList()
        var filledCollect = false
        if (collector_id != null && pds != null && orderNote != null) {
            filledCollect = true
            collect = (collect ?: orderNote.collectionObject.toMutableMap() ?: mutableMapOf()).toMutableMap()
            val currentNote = orderNote

            orderNote.delivery_info.forEach {
                val isHere = it.getProduct().getOrderProduct()?.collector == collector_id
                if (!isHere) {
                    pds.add(it)
                }
            }
            val collectors = agents.filter {
                (it.is_collector == 4 || it.is_collector == 5) && (it.id == collector_id || pds.any { pd ->
                    pd.getProduct().getOrderProduct()?.collector == it.id
                })
            }
            val filled = agents.filter { it.is_collector == 5 }
            val collectorsIds = agents.filter { it.is_collector == 5 }.map { it.id }.toSet()

            var needHistoryUpdate = true
            collect.keys.forEach {
                if (!collectorsIds.contains(it)) {
                    needHistoryUpdate = false
                }
            }

            h = history
                ?: if (currentNote.histroy_info.isEmpty() || needHistoryUpdate) currentNote.delivery_info else currentNote.histroy_info



            collect[collector_id] = CollectionObject(collector_id, karton ?: 0f)

            filled.forEach { ag ->
                val curProductsPds = pds.filter { it.getProduct().getOrderProduct()?.collector == ag.id }
                if (curProductsPds.isEmpty()) {
                    collect!!.remove(ag.id)
                } else {
                    val boxes = ProductDelivery.kartonEstimate(curProductsPds)
                    collect!![ag.id] = CollectionObject(ag.id, boxes)
                }

            }
            status = if (collectors.size == collect.size) {
                OrderStatus.COLLECTED.state
            } else {

                OrderStatus.COLLECT_START.state
            }

        }
        if (!filledCollect) {
            val needAutoCollection = agents.filter { (it.is_collector == 5) }
            if (needAutoCollection.isNotEmpty()) {
                val orderNote = orderNote ?: getClientOrderNotes(order_ids = listOf(order_id)).first.firstOrNull()
                if (orderNote != null) {
                    val filled = needAutoCollection

                    collect = (collect ?: mutableMapOf()).toMutableMap()
                    filled.forEach { ag ->
                        val curProductsPds =
                            (pds ?: orderNote.delivery_info).filter {
                                it.getProduct().getOrderProduct()?.collector == ag.id
                            }
                        if (curProductsPds.isEmpty()) {
                            if (collect.containsKey(ag.id)) {
                                collect.remove(ag.id)
                            }
                        } else {
                            val boxes = ProductDelivery.kartonEstimate(curProductsPds)
                            collect[ag.id] = CollectionObject(ag.id, boxes)
                        }
                    }

                    orderNote.collectionObject.forEach { col ->
                        val ag = agents.firstOrNull { it.is_collector == 4 && it.id == col.value.id }
                        if (ag != null) {
                            collect[ag.id] = col.value
                        }
                    }
                }


            }

        }

        val response = adapter.updateClientOrderNote(
            order_id = order_id.toString(),
            agent = agent,
            date = date,
            delivery_value = pds?.let { ProductDelivery.toJsonArrayString(pds) },
            sup_delivery_info = sup_delivery_value?.let { ProductDelivery.toJsonArrayString(sup_delivery_value) },
            active = status?.toString(),
            notes = notes?.let { prepareStrToJson(it) },
            category = category,
            notify = if (notify) "true" else null,
            ref_id = ref_id,
            inventory = inventory,
            agent_id = agent_id?.toString(),
            notes2 = notes2?.let { prepareStrToJson(it) },
            notes3 = notes3?.let { prepareStrToJson(it) },
            notes4 = notes4?.let { prepareStrToJson(it) },
            notes5 = notes5?.let { prepareStrToJson(it) },
            history = h?.let { ProductDelivery.toJsonArrayString(h) },
            collection = collect?.let { CollectionObject.toJson(it) },
            increase_id = increase_id?.toString(),
            update_agent = update_agent,
            static_data = static_data?.let {
                ClientStaticData.toJsonArrayString(static_data)
            }
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsOrderNotesMap(data!!)
            if (buildFullClientOrderNotes(listOf(data)) != DBQuery.SUCCESS) {
                return Respone(data, DBQuery.SUCCESS)
            }
        }
        return Respone(data, dbQuery)
    }


    override suspend fun buildClientDeliveryNotePdf(
        id: Int, origin: Int, force_price: Boolean?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.getClientDeliveryNotePdf(id.toString(),
            origin.toString(),
            force_price?.let { if (it) "1" else "0" })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientCartesetPdf(
        id: Int, dates: String, onlyTaxSide: Boolean?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcClientCartesetPdf(id.toString(),
            dates,
            onlyTaxSide = onlyTaxSide?.let { if (it) "1" else null })

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientPricesPdf(
        id: Int,
        date: String,
        p_ids: List<Int>?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcClientPricesPdf(
            id.toString(), date, p_ids = p_ids?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientVisitNote(
        fromDate: String?,
        toDate: String?,
        ids: List<Int>?,
        ent_ids: List<Int>?,
        states: List<Int>?,
        full: Boolean,
        has_order: Boolean
    ): Respone<List<VisitNote>, DBQuery> {
        val response = adapter.getClientVisitNote(
            fromDate = fromDate,
            toDate = toDate,
            ids = ids?.joinToString(","),
            ent_ids = ent_ids?.joinToString(","),
            states = states?.joinToString(","),
            has_order = has_order.let { if (it) "1" else null }
        )
        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput, false)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsVisitNotesMap(data)
            if (fromDate == null && full && data.size > 0) {
                val p = getSign(data.map { it.id }, 12)
                if (p.second != DBQuery.SUCCESS && p.second != DBQuery.ERROR_EMPTY_NEW) {
                    return Respone(data, p.second)
                }

            }
            if (full) {
                val order_ids = data.mapNotNull { it.order_id }.distinct()
                if (order_ids.isNotEmpty()) {
                    val q = buildClientOrderNotes(order_ids = order_ids, full = true)
                }
            }

        }

        return Respone(data, dbQuery)
    }

    suspend fun buildClientVisitDistinct(
        fromDate: String, toDate: String, ids: List<Int>?,
    ): Respone<List<ClientVisitHolder>, DBQuery> {
        val response =
            adapter.getClientVisit(ids?.joinToString(","), fromDate, toDate)

        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput, false)
        return Respone(data, dbQuery)
    }

    override suspend fun newClientVisitNote(
        client_id: Int,
        agent: String,
        document_date: String,
        date_issued: String,
        lon_lat: String,
        states: List<Int>,
        comment: String,
        f: ByteArray?,
        order_id: Int?,
        payment: Float?,
        pd: ProductDelivery?
    ): Respone<VisitNote?, DBQuery> {
        var valueForDebt: Float? = null
        var o = order_id
        if (pd != null && order_id != null) {
            val q = updateClientOrderNote(order_id, delivery_value = listOf(pd), updatePrice = false)
            if (q.second != DBQuery.SUCCESS)
                return Respone(null, DBQuery.FAILED)
            valueForDebt = q.first?.value

        } else if (order_id != null) {
            val q = getClientOrderNotes(order_id = order_id).first.firstOrNull()
            valueForDebt = q?.value

        } else if (pd != null) {

            val q = newClientOrderNote(
                agent = agent,
                ent_id = client_id,
                date = document_date,
                date_issued = date_issued,
                delivery_value = listOf(pd),
                sup_delivery_value = listOf()
            )
            if (q.second != DBQuery.SUCCESS)
                return Respone(null, DBQuery.FAILED)
            val cd = calcDeliveryNoteSumMap(listOf(q.first!!)).second
            o = q.first.id
            valueForDebt = q.first.value
        }
        val response = adapter.newClientVisitNote(
            client_id = client_id.toString(),
            agent = agent.toString(),
            document_date = document_date,
            date_issued = date_issued,
            lon_lat = lon_lat,
            states = "[${states.joinToString(",")}]",
            comment = comment.replace("\"", "").replace("'", "").replace("0.0", ""),
            order_id = o?.toString(),
            payment = payment?.toString(),
            valueForDebt = valueForDebt?.toString(),
            f = f

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsVisitNotesMap(listOf(data!!))
        }
        return Respone(data, dbQuery)
    }

    override suspend fun updateClientVisitNote(
        id: Int,
        states: List<Int>?,
        payment: Float?,
        pd: ProductDelivery?,
        comment: String?,
        active: Boolean?,
        order_id: Int?,
        date: String?
    ): Respone<VisitNote?, DBQuery> {
        var valueForDebt: Float? = null
        var o = order_id
        if (pd != null && order_id != null) {
            val q = updateClientOrderNote(
                order_id,
                delivery_value = listOf(pd),
                date = date,
                updatePrice = false,
                active = 2
            )

            if (q.second != DBQuery.SUCCESS)
                return Respone(null, DBQuery.FAILED)
            valueForDebt = q.first?.value

        } else if (pd != null && idToVisitNote[id] != null) {
            val v = idToVisitNote[id]
            val q = newClientOrderNote(
                agent = v!!.agent,
                ent_id = v.client_id,
                date = date ?: v.document_date,
                date_issued = DatesManipulator.dateNow(),
                delivery_value = listOf(pd),
                sup_delivery_value = listOf()
            )
            if (q.second != DBQuery.SUCCESS)
                return Respone(null, DBQuery.FAILED)
            o = q.first?.order_id
            valueForDebt = q.first?.value
        }
        val response = adapter.updateClientVisitNote(
            id = id.toString(),
            states = states?.let { "[${states.joinToString(",")}]" },
            comment = comment?.replace("\"", "")?.replace("\'", ""),
            active = active?.let { if (it) "1" else "0" },
            payment = payment?.toString(),
            valueForDebt = valueForDebt?.toString(),
            order_id = o?.toString(),
            date = date
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsVisitNotesMap(listOf(data!!))
        }
        return Respone(data, dbQuery)
    }

    suspend fun buildFullOnDocs(
        notes: List<InformationBase>, withDaily: Boolean = false, api: Boolean = false, sup: Boolean = false
    ): DBQuery {
        if (notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        val dates = notes.map { it.getConnectedDate() }.distinct()

        val d = DatesHolder(dates)
        val first_date = d.getFirst()
        val last_date = d.getLast()
        val ids = notes.map { it.getConnectedEntId() }.distinct()
        val product_ids = mutableSetOf<Int>()
        notes.forEach {
            product_ids.addAll(it.getConnectedProductsIds())
        }
        if (sup) {

            var q = fetchSpecificSupplierData(
                ids,
                fromDate = first_date,
                toDate = last_date
            )
            if (q != DBQuery.SUCCESS) {
                return q
            }
            q = calcDeliveryNoteSumMap(
                notes.filter { it is Note } as List<Note>,
                "supplier"
            ).second
            if (q != DBQuery.SUCCESS)
                return q
            return DBQuery.SUCCESS
        }
        var q = fetchSpecificClientData(
            ids,
            fromDate = first_date,
            toDate = last_date,
            product_ids = if (product_ids.size > 0) product_ids.toList() else null
        )

        if (q != DBQuery.SUCCESS) {
            return q
        }
        notes.groupBy { it.getConnectedDocType() }.forEach { note_ids ->
            val p = getManyOfflineSignHolder(note_ids.value.map { it.getConnectedId() }, note_ids.key, send = false)
            _updateSignIdData(p)
            if (note_ids.key == 4 || note_ids.key == 10 || note_ids.key == 6)
                q = calcDeliveryNoteSumMap(
                    note_ids.value as List<Note>,
                    if (note_ids.key != 6) "client" else "supplier"
                ).second
        }

//        val p = getSign(note_ids)
//        if (p.second != DBQuery.SUCCESS && p.second != DBQuery.ERROR_EMPTY_NEW) {
//            return p.second
//        }
        if (withDaily) {
            val x = DB.buildClientsDailyData(ids = ids, date = last_date).second // need to switch this?
        }


        if (api) {
            q = matchApiNotesToInformationBase(notes)
        }
        return q
    }

    suspend fun buildFullClientNotes(
        notes: List<DeliveryNote>, withDaily: Boolean = false, api: Boolean = false
    ): DBQuery {
        if (notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        val dates = notes.map { it.date }.distinct()
        val note_ids = notes.map { it.delivery_id }.distinct()
        val d = DatesHolder(dates)
        val first_date = d.getFirst()
        val last_date = d.getLast()
        val ids = notes.map { it.ent_id }.distinct()
        val product_ids = Note.getAllProducts(notes).toList()
        var q = fetchSpecificClientData(
            ids,
            fromDate = first_date,
            toDate = last_date,
            product_ids = if (product_ids.size > 0) product_ids else null
        )

        if (q != DBQuery.SUCCESS) {
            return q
        }
        val p = getManyOfflineSignHolder(note_ids, 4, send = false)
        _updateSignIdData(p)
//        val p = getSign(note_ids)
//        if (p.second != DBQuery.SUCCESS && p.second != DBQuery.ERROR_EMPTY_NEW) {
//            return p.second
//        }
        if (withDaily) {
            val x = DB.buildClientsDailyData(ids = ids, date = last_date).second // need to switch this?
        }

        q = calcDeliveryNoteSumMap(notes).second
        if (api) {
            q = matchApiNotesToInformationBase(notes)
        }
        return q
    }

    fun getClientNotes(
        client_id: Int?,
        delivery_id: Int?,
        fromDate: String?,
        toDate: String?,
        open: Boolean?,
        client_ids: List<Int>?,
        orders_ids: List<Int>?,
        delivery_ids: List<Int>?
    ): List<DeliveryNote>? {
        val notes = if (delivery_id != null || delivery_ids != null)
            getClientDeliveryNotes(delivery_id, client_id, delivery_ids).first
        else if (fromDate != null && toDate != null)
            getClientDeliveryNotes(fromDate, toDate, null).first
        else null
        val clientSet = client_ids?.toSet()
        val ordersIsSet = orders_ids?.toSet()
        return notes?.mapNotNull {
            if ((open == null || (it.isActive() && it.isUsed() == !open)) && (clientSet == null || it.ent_id in clientSet) && (ordersIsSet == null || it.order_id in ordersIsSet) && (client_id == null || it.ent_id == client_id)
            )
                it
            else
                null
        }
    }

    override suspend fun buildClientNotes(
        client_id: Int?,
        delivery_id: Int?,
        fromDate: String?,
        toDate: String?,
        full: Boolean,
        open: Boolean?,
        client_ids: List<Int>?,
        withDaily: Boolean,
        api: Boolean,
        orders_ids: List<Int>?,
        delivery_ids: List<Int>?
    ): Respone<List<DeliveryNote>, DBQuery> {
        val needHistoryQ =
            if (getUser().first?.company_type == 4 && (fromDate != null && toDate != null) && DatesManipulator.daysDiffBetweenDates(
                    toDate,
                    fromDate
                ) > optimizeDaysDocuments
            ) {
                getClientNotes(
                    client_id,
                    delivery_id,
                    fromDate,
                    toDate,
                    open,
                    client_ids,
                    orders_ids,
                    delivery_ids
                )
            } else null


        val response = adapter.getClientNotes(
            client_ids?.joinToString(",") ?: client_id?.toString(),
            delivery_id?.toString(),
            fromDate,
            toDate,
            open = open?.let { if (it) "1" else "0" },
            order_ids = orders_ids?.joinToString(","),
            delivery_ids = delivery_ids?.joinToString(","),
            historyQ = needHistoryQ?.let { lst ->
                val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                lst.forEach {
                    map[it.id.toString()] = JsonPrimitive(it.action_time)
                }
                JsonObject(map).toString()
            }
        )
        var data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsNotesMap(data)
            if (needHistoryQ != null)
                data = getClientNotes(
                    client_id,
                    delivery_id,
                    fromDate,
                    toDate,
                    open,
                    client_ids,
                    orders_ids,
                    delivery_ids
                )!!
            if (full && buildFullClientNotes(data, withDaily, api = api) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }

        }

        return Respone(data, dbQuery)
    }


    override suspend fun buildClientNotes(
        client_id: Int?,
        datesHolder: DatesHolder,
        full: Boolean,
        api: Boolean,
        client_ids: List<Int>?,
        open: Boolean?
    ): Respone<List<DeliveryNote>, DBQuery> {
        val notes: MutableList<DeliveryNote> = mutableListOf()
        for (d in datesHolder.ranges) {
            val response = buildClientNotes(
                client_id, null, d.start_date, (d.end_date), client_ids = client_ids, open = open
            )
            val data = response.first
            val dbQuery = response.second
            if (dbQuery == DBQuery.SUCCESS) {
                notes.addAll(data)
            } else {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        val retNotes = notes.distinctBy { it.delivery_id }
        _updateClientsNotesMap(retNotes)
        if (full && buildFullClientNotes(retNotes, api = api) != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }

        return Respone(retNotes, DBQuery.SUCCESS)
    }


    override fun getBranchMembers(branch: Int, active: Boolean): Respone<List<Client>, DBQuery> {
        return Respone(getAllClient(active).first.filter { it.branch == branch }, DBQuery.SUCCESS)
    }

    fun getAllClientsWithBranches(ids: List<Int>): Respone<List<Int>, DBQuery> {
        val ids_set = ids.toSet()
        val x = ids.mapIndexedNotNull { index: Int, i: Int ->
            getClient(i).first?.branch
        }.filter { it >= 0 }.toSet().let { branches ->
            getAllClient(false).first.filter { it.id in ids_set || it.branch in branches }.map { it.id }
        }

        return Respone(x, DBQuery.SUCCESS)
    }

    fun getAllMasters(ids: List<Int>): Respone<List<Int>, DBQuery> {
        val x = ids.mapIndexedNotNull { index: Int, i: Int ->
            getClient(i).first?.master
        }.filter { it >= 0 }

        return Respone(x, DBQuery.SUCCESS)
    }


    override suspend fun buildClientLastNotes(client_id: Int, full: Boolean): Respone<List<DeliveryNote>, DBQuery> {
        val response = adapter.getClientLastNotes(
            client_id.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsNotesMap(data)
            if (full && buildFullClientNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override fun getAllClientDeliveryNotes(): Respone<Map<Int, List<DeliveryNote>>, DBQuery> {
        return Respone(clientIdToDeliveryNote.toMap(), DBQuery.SUCCESS)
    }

    @myName("getClientDeliveryNotes")
    fun getClientDeliveryNotes(ids: List<Int>): Respone<List<DeliveryNote>, DBQuery> {
        return Respone(ids.map {
            clientDeliveryIdToDeliveryNote[it] ?: return Respone(listOf(), DBQuery.FAILED)
        }, DBQuery.SUCCESS)
    }

    fun getClientDeliveryNotesBranch(ids: List<Int>): Respone<List<DeliveryNote>, DBQuery> {
        //only use when all branch is loaded.
        return Respone(
            ids.map {
                clientIdToDeliveryNote[it] ?: listOf()
            }.flatten(), DBQuery.SUCCESS
        )
    }

    fun getClientDeliveryNotes(
        fromDate: String, toDate: String, active: Boolean? = null
    ): Respone<List<DeliveryNote>, DBQuery> {
        return Respone(clientDeliveryIdToDeliveryNote.entries.mapNotNull {
            if ((active == null || (!active || it.value.isActive())) && (it.value.date in fromDate..toDate)) {
                it.value
            } else null
        }, DBQuery.SUCCESS)
    }

    override fun getClientDeliveryNotes(
        delivery_id: Int?, client_id: Int?, delivery_ids: List<Int>?
    ): Respone<List<DeliveryNote>, DBQuery> {
        delivery_id?.let {
            return if (it in clientDeliveryIdToDeliveryNote) {
                return Respone(listOf(clientDeliveryIdToDeliveryNote[it]!!), DBQuery.SUCCESS)
            } else {
                Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        delivery_ids?.let {
            val m = it.map {
                if (it !in clientDeliveryIdToDeliveryNote) {
                    return Respone(listOf(), DBQuery.ID_ISSUE)
                }
                clientDeliveryIdToDeliveryNote[it]!!
            }
            return Respone(m, DBQuery.SUCCESS)

        }
        client_id?.let {
            return if (it in clientIdToDeliveryNote) {
                return Respone(clientIdToDeliveryNote[it]!!, DBQuery.SUCCESS)
            } else {
                Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        return Respone(clientIdToDeliveryNote.values.flatten(), DBQuery.SUCCESS)
    }

    override suspend fun newClientDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: String,
        delivery_id: Int?,
        connected_id: Long?,
        notes: String?,
        order_id: Int?,
        increase_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        agent_id: Int?,
        active: Int?
    ): Respone<DeliveryNote?, DBQuery> {
        val response = adapter.newClientNote(
            agent = agent,
            ent_id = ent_id.toString(),
            date = date,
            date_issued = date_issued,
            delivery_value = delivery_value,
            delivery_id = delivery_id?.toString(),
            connected_id = connected_id?.toString(),
            notes = notes?.let { prepareStrToJson(it) },
            order_id = order_id?.toString(),
            increase_id = increase_id?.toString(),
            notes2 = notes2?.let { prepareStrToJson(it) },
            notes3 = notes3?.let { prepareStrToJson(it) },
            notes4 = notes4?.let { prepareStrToJson(it) },
            notes5 = notes5?.let { prepareStrToJson(it) },
            agent_id = agent_id?.toString(),
            active = active?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsNotesMap(listOf(data!!))
            _updateClientNoteForToday(data.ent_id, data)
            clientMonthlyCycleReCalculate(date = data.date, id = data.ent_id)
            calcDeliveryNoteSumMap(listOf(data))
        }
        return Respone(data, dbQuery)
    }


    override suspend fun newClientDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        with_price: Boolean,
        delivery_id: Int?,
        connected_id: Long?,
        notes: String?,
        order_id: Int?,
        increase_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        agent_id: Int?,
    ): Respone<List<DeliveryNote>, DBQuery> {
        if (with_price) {
            var ent_to_change_id = ent_id
            if (getClient(ent_id).first?.master != -1) {
                ent_to_change_id = getClient(ent_id).first!!.master
            }

            val q = changePriceFromProductDelivery(delivery_value, ent_to_change_id, date, "client")
            if (q != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        if ((user?.tax_note_split_dv ?: 0) == 2 && (user?.tax_note_split ?: 0) > 0) {
            val client = getClient(ent_id).first!!
            var dvs: MutableList<List<ProductDelivery>> = mutableListOf()
            var distribute = 0f
            val values: MutableList<Pair<Float, Float>> = mutableListOf()
            val pair = getSplitFromProducts(client, delivery_value, date, 0, true, true)
            dvs.add(pair.third)
            distribute += pair.first
            val valu = pair.second
            val canSplit = valu.first


            values.add(Pair(valu.second, valu.third))
            val comments = mutableListOf(prepareStrToJson(notes ?: ""))
            if (distribute > 0) {
                dvs.add(
                    listOf(
                        ProductDelivery(
                            productId = user?.tax_note_split!!,
                            value = 1f,
                            price = distribute,
                            use_price = if (canSplit) 1 else 2
                        )
                    )
                )
                val distributeAndVat = distribute * (if (canSplit) 1f else tax.get(date)).toFloat()
                comments.clear()
                comments.add(
                    " ${notes ?: ""}סכום כולל משולב ${
                        roundToDecimals(
                            valu.second + valu.third + distributeAndVat,
                            2
                        )
                    }"
                )
                comments.add("סכום כולל משולב ${roundToDecimals(valu.second + valu.third + distributeAndVat, 2)}")

            } else {
                comments.add(notes ?: "")
            }

            val r = newAllClientNotes(
                agent = agent,
                ids = ent_id.let { od ->
                    List(dvs.size) { od }
                },
                notes = comments,
                notes2 = notes2?.let {
                    List<String>(dvs.size) { notes2!! }

                },
                notes3 = notes3?.let {
                    List<String>(dvs.size) { notes3!! }

                },
                notes4 = notes4?.let {
                    List<String>(dvs.size) { notes4!! }

                },
                notes5 = notes5?.let {
                    List<String>(dvs.size) { notes5!! }

                },
                pds = dvs,
                date = date,
                increase_id = increase_id,
                order_id = order_id?.let { od ->
                    List(dvs.size) { od }
                },
                agent_id = agent_id?.let {
                    List<Int>(dvs.size) { agent_id }

                },
                withSave = false
            )
            if (r.second != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            return Respone((r.first as List<DeliveryNote>).sortedBy { it.delivery_id }, DBQuery.SUCCESS)

        }

        val groups = delivery_value.map { it.getProduct().getSplitCategory() }.distinct()
        val ret = mutableListOf<DeliveryNote>()
        if (groups.size == 1) {
            val r = newClientDeliveryNote(
                agent = agent,
                ent_id = ent_id,
                date = date,
                date_issued = date_issued,
                delivery_value = ProductDelivery.toJsonArrayString(delivery_value),
                delivery_id = delivery_id,
                connected_id = connected_id,
                notes = notes,
                order_id = order_id,
                increase_id = increase_id,
                notes2 = notes2,
                notes3 = notes3,
                notes4 = notes4,
                notes5 = notes5,
                agent_id = agent_id,
            )
            if (r.second != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            ret.add(
                r.first!!
            )
        } else if (groups.size > 1) {
            val delivery_values = delivery_value.groupBy { it.getProduct().getSplitCategory() }.values.toList()
            val r = newAllClientNotes(
                agent = agent,
                ids = ent_id.let { od ->
                    List(groups.size) { od }
                },
                pds = delivery_values,
                date = date,
                increase_id = increase_id,
                order_id = order_id?.let { od ->
                    List(groups.size) { od }
                },
                notes = notes?.let {
                    List<String>(groups.size) { notes!! }

                },
                notes2 = notes2?.let {
                    List<String>(groups.size) { notes2!! }

                },
                notes3 = notes3?.let {
                    List<String>(groups.size) { notes3!! }

                },
                notes4 = notes4?.let {
                    List<String>(groups.size) { notes4!! }

                },
                notes5 = notes5?.let {
                    List<String>(groups.size) { notes5!! }

                },
                agent_id = agent_id?.let {
                    List<Int>(groups.size) { agent_id!! }

                },
                withSave = false
            )
            if (r.second != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            ret.addAll(
                r.first as List<DeliveryNote>
            )
        } else {
            return Respone(listOf(), DBQuery.FAILED)
        }
        return Respone(ret.sortedByDescending { it.delivery_id }, DBQuery.SUCCESS)
    }

    suspend fun editNote(
        delivery_id: Int,
        paid: Int? = null,
        active: Int? = null,
        connected_id: Long? = null,
        delivery_value: List<ProductDelivery>?,
        order_id: Int?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        agent_id: Int?,
        agent: String
    ): Respone<DeliveryNote?, DBQuery> {
        val currentNote = getClientDeliveryNotes(listOf(delivery_id)).first.firstOrNull()
        val canceledNote = adapter.updateClientNote(
            delivery_id = delivery_id.toString(), active = "0"
        )
        var dbQuery = analyzeResponse(canceledNote.second)
        if (dbQuery != DBQuery.SUCCESS) {
            return Respone(null, DBQuery.FAILED)
        }
        _updateClientsNotesMap(canceledNote.first!!)
        if (currentNote != null) {
            val eddited = (notes5 ?: currentNote.notes5).isNotEmpty(
                true,
                "תיקון לתעודה ${canceledNote.first!!.delivery_id.toString()}"
            )
            val r = adapter.newClientNote(
                ent_id = currentNote.ent_id.toString(),
                date = currentNote.date,
                date_issued = DatesManipulator.dateNow(),
                delivery_value = delivery_value?.let { ProductDelivery.toJsonArrayString(delivery_value) }
                    ?: currentNote.delivery_value,
                connected_id = connected_id?.toString() ?: currentNote.connected_id.toString(),
                notes = notes,
                agent = agent,
                order_id = order_id?.toString() ?: currentNote.order_id.toString(),
                notes2 = notes2 ?: currentNote.notes2,
                notes3 = notes3 ?: currentNote.notes3,
                notes4 = notes4 ?: currentNote.notes4,
                notes5 = eddited,
                agent_id = agent_id?.toString() ?: currentNote.agent_id.toString(),

                )
            dbQuery = analyzeResponse(r.second)
            if (dbQuery != DBQuery.SUCCESS) {
                return Respone(null, DBQuery.FAILED)
            }
            _updateClientsNotesMap(r.first!!)
            return Respone(r.first!!, DBQuery.SUCCESS)
        }
        return Respone(null, DBQuery.FAILED)

    }

    override suspend fun updateClientNote(
        delivery_id: Int,
        paid: Int?,
        active: Int?,
        connected_id: Long?,
        delivery_value: List<ProductDelivery>?,
        order_id: Int?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        agent_id: Int?,
        updatePrice: Boolean?,
        agentEdit: String?
    ): Respone<DeliveryNote?, DBQuery> {
        if (delivery_value != null && (updatePrice == null || updatePrice)) {
            val currentNote = getClientDeliveryNotes(listOf(delivery_id)).first.first()
            val priceUpdateList: MutableList<ProductDelivery> = mutableListOf()
            val costPrice: MutableList<ProductDelivery> = mutableListOf()
            val client = currentNote.getEnt(true) as Client
            delivery_value.forEach {
                if (client.getPrice(it.productId)?.get(currentNote.date)?.first != it.price) {
                    priceUpdateList.add(it)
                }
                if (!it.cost.isNullOrZero()) {
                    costPrice.add(it)

                }

            }
            var ent_to_change_id = client.id
            if (client.master != -1) {
                ent_to_change_id = client.master
            }
            if (priceUpdateList.isNotEmpty()) {
                val q =
                    changePriceFromProductDelivery(priceUpdateList, ent_to_change_id, currentNote.date, "client")
                if (q != DBQuery.SUCCESS) {
                    return Respone(null, DBQuery.FAILED)
                }
            }
            if (costPrice.isNotEmpty()) {

                val q = changeCostPriceFromProductDelivery(costPrice, currentNote.date)
                if (q != DBQuery.SUCCESS) {
                    return Respone(null, DBQuery.FAILED)
                }
            }
        }
        val currentNote = getClientDeliveryNotes(listOf(delivery_id)).first.firstOrNull()
        if (agentEdit != null && delivery_value != null && currentNote?.delivery_info != null && !ProductDelivery.compareProductDvList(
                delivery_value,
                currentNote?.delivery_info
            )
        ) {

            val r = editNote(
                delivery_id = delivery_id,
                paid = paid,
                active = active,
                connected_id = connected_id,
                delivery_value = delivery_value,
                order_id = order_id,
                notes = notes,
                notes2 = notes2,
                notes3 = notes3,
                notes4 = notes4,
                notes5 = notes5,
                agent_id = agent_id,
                agent = agentEdit
            )
            if (r.second != DBQuery.SUCCESS) {
                return Respone(null, DBQuery.FAILED)
            }
            return Respone(r.first, DBQuery.SUCCESS)
        }
        val response = adapter.updateClientNote(
            delivery_id.toString(),
            paid?.toString(),
            active?.toString(),
            connected_id?.toString(),
            delivery_value?.let { ProductDelivery.toJsonArrayString(it) },
            order_id = order_id?.toString(),
            notes = notes,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5,
            agent_id = agent_id?.toString(),
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsNotesMap(listOf(data!!))
            calcDeliveryNoteSumMap(listOf(data))
            if (delivery_value != null)
                clientMonthlyCycleReCalculate(date = data.date, id = data.ent_id)
        }
        return Respone(data, dbQuery)
    }

    suspend fun changeCostPriceFromProductDelivery(
        dv: List<ProductDelivery>, date: String,
    ): DBQuery {
        val curDate = DatesManipulator.dateNowNoHour()
        val nextDate = when {
            date >= curDate -> null
            else -> {
                date
            }
        }
        val changes: MutableList<RawPrice> = mutableListOf()
        val p_ids: MutableList<Int> = mutableListOf()
        dv.forEach {
            val costPrice = it.cost
            val pc = getProductCost(it.productId)
            val price = pc?.get(date)?.first
            val discount = pc?.get(date)?.second


            if ((costPrice != null && price != it.cost)) {
                changes.add(RawPrice(-1, it.productId, costPrice, date, discount ?: 0f))
                p_ids.add(it.productId)
            }

        }
        if (changes.isNotEmpty()) {
            updateAllClientProductPrice(date, changes, nextDate)
            buildClientCosts(date, nextDate ?: date, listOf(-1))
        }
        return DBQuery.SUCCESS
    }

    override suspend fun changePriceFromProductDelivery(
        dv: List<ProductDelivery>, ent_id: Int, date: String, ent_type: String
    ): DBQuery {
        val curDate = DatesManipulator.dateNowNoHour()
        var raw_c = if (ent_type.compareTo("client") == 0) getClient(ent_id)
        else getSupplier(ent_id)

        if (raw_c.second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }
        val c = raw_c.first!!
        val nextDate = when {
            date >= curDate -> null
            else -> {
                date
            }
        }
        val changes: MutableList<RawPrice> = mutableListOf()
        dv.forEach {
            val priceMenu = c.getPrice(it.productId) ?: return DBQuery.FAILED
            val price = priceMenu.get(date).first
            val discount = priceMenu.get(date).second
            if (it.changedPrice == (1).toByte() && it.price != 0f && (price != it.price || discount != it.discount)) {
                if (ent_type.compareTo("client") == 0) {
                    changes.add(RawPrice(c.id, it.productId, it.price, date, it.discount))
                } else {
                    val q = newSupplierPrice(c.id, it.productId, it.price, date, it.discount, toDate = nextDate)
                    if (q.second != DBQuery.SUCCESS) {
                        return DBQuery.FAILED
                    }
                }
            }

            if (changes.isNotEmpty() && ent_type.compareTo("client") == 0) {
                updateAllClientProductPrice(date, changes, nextDate)
            }

        }
        return DBQuery.SUCCESS
    }

    @myName("calcClientProductDeliverySumMap")
    fun calcClientProductDeliverySumMap(
        dv: List<ProductDelivery>,
        client_id: Int,
        date: String,
        externalPrices: Boolean = false,
        isClient: Boolean = true,
        force_price_from_tn: Boolean = false
    ): Respone<Map<Int, CalculationHolder>, DBQuery> {
        //����� ������ ���� ��''�
        val pMap = HashMap<Int, CalculationHolder>()
        var finalValue = 0f
        pMap[-1] = CalculationHolder()
        val master = pMap[-1]!!
        var raw_c = if (isClient) getClient(client_id)
        else getSupplier(client_id)

        if (raw_c.second != DBQuery.SUCCESS) {
            return Respone(mapOf(), DBQuery.FAILED)
        }
        val c = raw_c.first!!
        var noteValue = 0f
        var noteAmountPos = 0f
        var noteAmountNeg = 0f
        dv.forEach {
            val prePrice = if (externalPrices) {
                Pair(it.price, it.discount)
            } else {
                val priceMenu = c.getPrice(it.productId) ?: return Respone(mapOf(), DBQuery.FAILED)
                priceMenu.get(date)
            }

            var price: Float
            var priceBefore: Float
            val detailedPrice = this.getPriceDetailed(prePrice.first, c, it.getProduct(), date)
            priceBefore = detailedPrice.first * (1 - prePrice.second / 100)
            price = detailedPrice.second * (1 - prePrice.second / 100)
            if (force_price_from_tn) {
                val pd_pn_price = pdPriceFromTaxNote(it, c, date)
                priceBefore = pd_pn_price.first * (1 - pd_pn_price.third / 100)
                price = pd_pn_price.second * (1 - pd_pn_price.third / 100)
            }
            if (!pMap.containsKey(it.productId)) {
                pMap[it.productId] = CalculationHolder(it.getProduct())
            }
            val holder = pMap[it.productId]!!

            var currPositiveValue = price * it.value
            var currNegativeValue = price * it.returns

            holder.negativeInstances += it.returns
            master.negativeInstances += it.returns
            holder.positiveInstances += it.value
            master.positiveInstances += it.value
            var taxPaid = (price - priceBefore) * (it.value - it.returns)
            val wrapped_amount_value = it.conversion_ratio * it.wrapped_amount
            if (wrapped_amount_value > 0) {
                holder.positiveInstances += wrapped_amount_value
                master.positiveInstances += wrapped_amount_value
                currPositiveValue += wrapped_amount_value * price
                taxPaid += (price - priceBefore) * (wrapped_amount_value)
            }
            val wrapped_amount_return = it.conversion_ratio * it.wrapped_amount_return
            if (wrapped_amount_return > 0) {
                holder.negativeInstances += wrapped_amount_return
                master.negativeInstances += wrapped_amount_return
                currNegativeValue += wrapped_amount_return * price
                taxPaid -= (price - priceBefore) * (wrapped_amount_return)
            }
            val totalValue = currPositiveValue - currNegativeValue
            holder.taxPaid += taxPaid
            master.taxPaid += taxPaid
            holder.price = detailedPrice.second
            holder.discount = prePrice.second
            holder.positiveValue += currPositiveValue
            master.positiveValue += currPositiveValue
            master.negativeValue += currNegativeValue
            holder.negativeValue += currNegativeValue
            holder.totalValue += totalValue
            noteValue += totalValue
            noteAmountPos += it.value
            noteAmountNeg += it.returns
        }
        finalValue += noteValue

        master.totalValue = finalValue
        return Respone(pMap.toMap(), DBQuery.SUCCESS)
    }

    @myName("getPriceDetailed")
    fun getPriceDetailed(
        prePrice: Float,
        c: Entity,
        p: Product,
        date: String,
        forceTaxDate: String? = null
    ): Pair<Float, Float> {
        var afterPrice: Float
        var priceBefore: Float
        when {
            p.getNoTaxProduct(date) == 1 || c.getNoTaxClient(date) == 1 -> {
                afterPrice = prePrice
                priceBefore = prePrice
            }

            c.getIncludeTax(date) == 1 -> {
                afterPrice = prePrice
                priceBefore = afterPrice / tax.get(forceTaxDate ?: date)
            }

            else -> {
                afterPrice = prePrice * tax.get(forceTaxDate ?: date)
                priceBefore = prePrice
            }
        }
        return Pair(priceBefore, afterPrice)

    }

    @myName("pdUsePrice")
    fun pdUsePrice(
        pd: ProductDelivery,
        c: Entity,
        d: String,
        note_used: Byte,
        discounted: Boolean = true,
        forceTaxDate: String? = null
    ): Triple<Float, Float, Float>? {
        if (c.id == -1)
            return Triple(0f, 0f, 0f)
        var price: Float
        var priceBefore: Float
        var discount: Float = pd.discount
        if (discounted) {
            when {
                pd.isNoTax(note_used) -> {
                    priceBefore = pd.price * pd.discountCalculated()
                    price = pd.price * pd.discountCalculated()
                }

                pd.taxNotIncluded(note_used) -> {
                    priceBefore = pd.price * pd.discountCalculated()
                    price = pd.price * tax.get(forceTaxDate ?: d) * pd.discountCalculated()
                }

                pd.taxIncluded(note_used) -> {
                    priceBefore = (pd.price / tax.get(forceTaxDate ?: d)) * pd.discountCalculated()
                    price = pd.price * pd.discountCalculated()
                }

                else -> {
                    val priceMenu = c.getPrice(pd.productId) ?: return null
                    val prePrice = priceMenu.get(d)

                    val detailedPrice = this.getPriceDetailed(prePrice.first, c, priceMenu.product, d, forceTaxDate)
                    discount = prePrice.second
                    priceBefore = detailedPrice.first * (1 - discount / 100)
                    price = detailedPrice.second * (1 - discount / 100)
                }
            }
        } else {
            when {
                pd.isNoTax(note_used) -> {
                    priceBefore = pd.price
                    price = pd.price
                }

                pd.taxNotIncluded(note_used) -> {
                    priceBefore = pd.price
                    price = pd.price * tax.get(forceTaxDate ?: d)
                }

                pd.taxIncluded(note_used) -> {
                    priceBefore = (pd.price / tax.get(forceTaxDate ?: d))
                    price = pd.price
                }

                else -> {
                    val priceMenu = c.getPrice(pd.productId) ?: return null
                    val prePrice = priceMenu.get(d)

                    val detailedPrice = this.getPriceDetailed(prePrice.first, c, priceMenu.product, d, forceTaxDate)
                    discount = prePrice.second
                    priceBefore = detailedPrice.first
                    price = detailedPrice.second
                }
            }
        }

        return Triple(priceBefore, price, discount)
    }

    fun pdPriceFromTaxNote(pd: ProductDelivery, c: Entity, d: String): Triple<Float, Float, Float> {
        // price before,price after,discount
        var price: Float
        var priceBefore: Float
        val discount: Float = pd.discount
        when {
            pd.isNoTax(0) -> {
                priceBefore = pd.price
                price = pd.price
            }

            pd.taxNotIncluded(0) -> {
                priceBefore = pd.price
                price = pd.price * tax.get(d)
            }

            pd.taxIncluded(0) -> {
                priceBefore = (pd.price / tax.get(d))
                price = pd.price
            }

            else -> {
                //the price is include tax.
                price = pd.price
                priceBefore = if (pd.getProduct().getNoTaxProduct(d) == 1 || c.getNoTaxClient(d) == 1) {
                    //there is risk here on tax note the people changed product no tax value. from sep 4 it will be solved.
                    pd.price
                } else {
                    (pd.price / tax.get(d))
                }
            }
        }
        return Triple(priceBefore, price, discount)
    }

    fun calcDeliveryInfoSum(
        dvs: List<ProductDelivery>, c: List<Entity>, date: List<String>
    ): Respone<Map<Int, CalculationHolder>, DBQuery> {

        val pMap = HashMap<Int, CalculationHolder>()
        var finalValue = 0f
        pMap[-1] = CalculationHolder()
        val master = pMap[-1]!!
        dvs.forEachIndexed { index, it ->
            var price: Float
            var priceBefore: Float
            var discount: Float

            val rawPrice = pdUsePrice(it, c[index], date[index], 1) ?: return Respone(mapOf(), DBQuery.FAILED)
            priceBefore = rawPrice.first
            price = rawPrice.second
            discount = rawPrice.third
            if (!pMap.containsKey(it.productId)) {
                pMap[it.productId] = CalculationHolder(it.getProduct())
            }
            val holder = pMap[it.productId]!!

            var currPositiveValue = price * it.value
            var currNegativeValue = price * it.returns

            var taxPaid = (price - priceBefore) * (it.value - it.returns)

            val wrapped_amount_value = it.conversion_ratio * it.wrapped_amount
            if (wrapped_amount_value > 0) {
                holder.positiveInstances += wrapped_amount_value
                master.positiveInstances += wrapped_amount_value
                currPositiveValue += wrapped_amount_value * price
                taxPaid += (price - priceBefore) * (wrapped_amount_value)
            }
            val wrapped_amount_return = it.conversion_ratio * it.wrapped_amount_return
            if (wrapped_amount_return > 0) {
                holder.negativeInstances += wrapped_amount_return
                master.negativeInstances += wrapped_amount_return
                currNegativeValue += wrapped_amount_return * price
                taxPaid -= (price - priceBefore) * (wrapped_amount_return)
            }
            val totalValue = currPositiveValue - currNegativeValue
            holder.price = price
            holder.discount = discount
            holder.price_min = min(price, holder.price_min)
            holder.price_max = max(price, holder.price_max)
            holder.positiveValue += currPositiveValue
            master.positiveValue += currPositiveValue
            master.negativeValue += currNegativeValue
            holder.negativeValue += currNegativeValue
            holder.taxPaid += taxPaid
            master.taxPaid += taxPaid
            holder.negativeInstances += it.returns
            master.negativeInstances += it.returns
            holder.positiveInstances += it.value
            master.positiveInstances += it.value
            holder.totalValue += totalValue
            finalValue += totalValue
        }

        master.totalValue = finalValue
        return Respone(pMap.toMap(), DBQuery.SUCCESS)
    }

    override fun calcDeliveryNoteSumMap(
        notes: List<Note>, ent_type: String, priceDistinct: Boolean, forceTaxDate: String?
    ): Respone<Map<Int, CalculationHolder>, DBQuery> {
        //����� ������ ���� ��''�
        val notes = notes.sortedBy { it.id }
        val pMap = HashMap<Int, CalculationHolder>()
        var finalValue = 0f
        pMap[-1] = CalculationHolder()
        if (forceTaxDate != null)
            pMap[-2] = CalculationHolder()
        val master = pMap[-1]!!
        var totalSubValue = 0f
        var totalSubTaxPaid = 0f
        notes.forEach { dv ->
            var raw_c = if (dv.ent_id == -1) Respone(
                EmptyEnt(-1),
                DBQuery.SUCCESS
            ) else if (ent_type.compareTo("client") == 0) getClient(dv.ent_id)
            else getSupplier(dv.ent_id)
            if (raw_c.second != DBQuery.SUCCESS) {
                return Respone(mapOf(), DBQuery.FAILED)
            }
            val c = raw_c.first!!
            var noteValue = 0f
            var noteAmountPos = 0f
            var noteAmountNeg = 0f
            var noteAmountPosSec = 0f
            var noteAmountNegSec = 0f
            var noteValuePos = 0f
            var noteValueNeg = 0f
            var noteTaxPaid = 0f
            var noteTaxPos = 0f
            var noteTaxNeg = 0f
            var noteDiscount = 0f
            var noteDiscountTax = 0f


            val note_used: Byte = if (dv.isUsed()) 1 else 0
            val date =
                if (dv.date <= unclosed_date && dv.getConnectedDocType() == 10) DatesManipulator.dateNowNoHour() else dv.date
            dv.delivery_info.forEach {
                var price: Float
                var priceBefore: Float
                var discount: Float

                val rawPrice = pdUsePrice(it, c, date, note_used) ?: return Respone(
                    mapOf(),
                    DBQuery.FAILED
                )
                priceBefore = rawPrice.first
                price = rawPrice.second
                discount = rawPrice.third

                if (!pMap.containsKey(it.productId)) {
                    pMap[it.productId] = CalculationHolder(it.getProduct(), priceDistinct = priceDistinct)
                }
                val holder = pMap[it.productId]!!
                if (forceTaxDate != null && tax.get(forceTaxDate) != tax.get(date)) {
                    val rawPrice2 = pdUsePrice(it, c, date, note_used, forceTaxDate = forceTaxDate) ?: return Respone(
                        mapOf(),
                        DBQuery.FAILED
                    )
                    val priceBefore2 = rawPrice2.first
                    val price2 = rawPrice2.second
                    val discount2 = rawPrice2.third

                    val subBefore = priceBefore2 - priceBefore
                    val subPrice = price2 - price
                    val amount =
                        (it.value + it.conversion_ratio * it.wrapped_amount - it.returns - it.wrapped_amount_return * it.conversion_ratio)
                    totalSubValue += amount * subPrice
                    totalSubTaxPaid += (subPrice - subBefore) * amount


                }
                var currPositiveValue = price * it.value
                var currNegativeValue = price * it.returns

                var taxPaid = (price - priceBefore) * (it.value - it.returns)

                noteTaxPos += (price - priceBefore) * (it.value)
                noteTaxNeg += (price - priceBefore) * (it.returns)
                noteAmountPos += it.value
                noteAmountNeg += it.returns
                noteAmountPosSec += it.wrapped_amount
                noteAmountNegSec += it.wrapped_amount_return
                val wrapped_amount_value = it.conversion_ratio * it.wrapped_amount
                if (wrapped_amount_value > 0) {
                    holder.positiveInstances += wrapped_amount_value
                    master.positiveInstances += wrapped_amount_value
                    noteAmountPos += wrapped_amount_value
                    currPositiveValue += wrapped_amount_value * price
                    taxPaid += (price - priceBefore) * (wrapped_amount_value)
                    noteTaxPos += (price - priceBefore) * (wrapped_amount_value)
                }
                val wrapped_amount_return = it.conversion_ratio * it.wrapped_amount_return
                if (wrapped_amount_return > 0) {
                    holder.negativeInstances += wrapped_amount_return
                    master.negativeInstances += wrapped_amount_return
                    noteAmountNeg += wrapped_amount_return
                    currNegativeValue += wrapped_amount_return * price
                    taxPaid -= (price - priceBefore) * (wrapped_amount_return)
                    noteTaxNeg += (price - priceBefore) * (wrapped_amount_return)
                }
                val totalValue = currPositiveValue - currNegativeValue
                holder.price = price
                holder.discount = discount
                holder.price_min = min(price, holder.price_min)
                holder.price_max = max(price, holder.price_max)
                holder.priceBefore = priceBefore
                holder.price_min_before = min(priceBefore, holder.price_min_before)
                holder.price_max_before = max(priceBefore, holder.price_max_before)
                holder.positiveValue += currPositiveValue
                master.positiveValue += currPositiveValue
                master.negativeValue += currNegativeValue
                holder.negativeValue += currNegativeValue
                holder.taxPaid += taxPaid
                master.taxPaid += taxPaid
                holder.negativeInstances += it.returns
                master.negativeInstances += it.returns
                holder.positiveInstances += it.value
                master.positiveInstances += it.value
                holder.totalValue += totalValue
                if (priceDistinct) {
                    val curHolder = holder.calcHolders!!.getOrPut(price) { CalculationHolder(it.getProduct()) }
                    curHolder.price = price
                    curHolder.discount = discount
                    curHolder.price_min = price
                    curHolder.price_max = price

                    curHolder.priceBefore = priceBefore
                    curHolder.price_min_before = min(priceBefore, holder.price_min_before)
                    curHolder.price_max_before = max(priceBefore, holder.price_max_before)


                    curHolder.positiveValue += currPositiveValue
                    curHolder.negativeValue += currNegativeValue
                    curHolder.totalValue += totalValue
                    curHolder.taxPaid += taxPaid
                    curHolder.negativeInstances += it.returns
                    curHolder.positiveInstances += it.value
                    val wrapped_amount_value = it.conversion_ratio * it.wrapped_amount
                    if (wrapped_amount_value > 0) {
                        curHolder.positiveInstances += wrapped_amount_value

                    }
                    val wrapped_amount_return = it.conversion_ratio * it.wrapped_amount_return
                    if (wrapped_amount_return > 0) {
                        curHolder.negativeInstances += wrapped_amount_return

                    }
                }
                noteDiscount += totalValue * (discount / 100) / (if (discount >= 100) 1f else (1 - discount / 100))
                noteDiscountTax += taxPaid * (discount / 100) / (if (discount >= 100) 1f else (1 - discount / 100))
                noteValue += totalValue
                noteTaxPaid += taxPaid
                noteValuePos += currPositiveValue
                noteValueNeg += currNegativeValue
            }
            dv.value = noteValue
            dv.amount_pos = noteAmountPos
            dv.amount_neg = noteAmountNeg
            dv.amount_pos_sec = noteAmountPosSec
            dv.amount_neg_sec = noteAmountNegSec
            dv.value_pos = noteValuePos
            dv.value_neg = noteValueNeg
            dv.taxPaid = noteTaxPaid
            dv.taxPaidPos = noteTaxPos
            dv.taxPaidNeg = noteTaxNeg
            dv.discount = noteDiscount
            dv.discountTax = noteDiscountTax
            finalValue += noteValue
        }
        master.totalValue = finalValue
        if (forceTaxDate != null) {
            pMap[-2]!!.taxPaid = totalSubTaxPaid
            pMap[-2]!!.totalValue = totalSubValue
        }

        return Respone(pMap.toMap(), DBQuery.SUCCESS)
    }

    override suspend fun calcClientDeliveryNotePdf(
        client_id: Int,
        date_range: String?,
        specific_notes: String?,
        tax_id_merge: List<Int>?,
        order: Boolean?,
        client_ids: List<Int>?
    ): Respone<ByteArray?, DBQuery> {
        if (specific_notes == null && date_range == null) {
            return Respone(null, DBQuery.FAILED)
        }
        val response =
            adapter.calcClientDeliveryNotePdf(client_ids?.let { it.joinToString(",") } ?: client_id.toString(),
                date_range,
                specific_notes,
                tax_id_merge?.joinToString(","),
                order?.let { if (it) it.toString() else null })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    suspend fun buildFullClientMonthlyCycle(monthlyCycles: List<MonthlyCycle>): DBQuery {
        if (monthlyCycles.isEmpty()) return DBQuery.SUCCESS
        val dates = monthlyCycles.map { it.date }.distinct()

        val d = DatesHolder(dates)
        val first_date = d.getFirst()
        val last_date = DatesManipulator.maxDay(d.getLast())
        val tn = buildClientLightTaxNote(fromDate = first_date, toDate = last_date).second
        val pn = buildClientLightPay(fromDate = first_date, toDate = last_date).second

        if (tn != DBQuery.SUCCESS || pn != DBQuery.SUCCESS) return DBQuery.FAILED
        val ids = monthlyCycles.map { it.id }.distinct()

        GlobalScope.async {
            try {
                fetchSpecificClientData(ids, fromDate = first_date, toDate = last_date)
            } catch (e: Exception) {

            }

        }

        return DBQuery.SUCCESS
    }

    override suspend fun buildClientMonthlyCycle(
        date: String?, id: Int?, full: Boolean
    ): Respone<List<MonthlyCycle>, DBQuery> {
        val response = adapter.getClientsMonthlyCycle(
            date, id?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsMonthlyCycleMap(data)
            if (full && buildFullClientMonthlyCycle(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override fun getClientMonthlyCycle(): Respone<Map<Int, List<MonthlyCycle>>, DBQuery> {
        return if (clientIdToMonthlyCycle.isEmpty()) Respone(
            clientIdToMonthlyCycle.toMap(), DBQuery.SUCCESS
        ) else Respone(
            clientIdToMonthlyCycle.toMap(), DBQuery.SUCCESS
        )
    }

    override fun getClientMonthlyCycle(date: String?, id: Int?): Respone<List<MonthlyCycle>, DBQuery> {
        var ret: Respone<List<MonthlyCycle>, DBQuery> =
            Respone(clientIdToMonthlyCycle.values.flatten(), DBQuery.SUCCESS)
        id?.let {
            if (it in clientIdToMonthlyCycle) {
                ret = Respone(clientIdToMonthlyCycle[it]!!, DBQuery.SUCCESS)
            } else {
                return Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        date?.let {
            var lst = ret.first
            lst = lst.filter { it.date.compareTo(date) == 0 }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        return ret
    }

    fun updateEdiNote(note_id: Int, date: String, client_id: Int) {
        if (getUser().first?.edi_state != 2 || getClientEdiMap(client_id).second != DBQuery.SUCCESS) {
            return
        }
        val n = getClientDeliveryNotes(delivery_id = note_id).first.first()
        if (n.delivery_info.any { it.returns > 0 }) {
            return
        }
        GlobalScope.async {
            try {
                newClientEdiNote(note_id, date, "[]", -1)
                adapter.calcClientEdiNote(note_id.toString())

            } catch (e: Exception) {

            }
        }
    }

    override suspend fun clientMonthlyCycleReCalculate(id: Int, date: String): DBQuery {
        GlobalScope.async {
            try {
                val min_date = DatesManipulator.minDay(date)
                val max_date = DatesManipulator.maxDay(date)
                val q = buildClientNotes(client_id = id, fromDate = min_date, toDate = max_date, full = true)
                val m = buildClientMonthlyCycle(id = id, date = min_date)
                if (q.second == DBQuery.SUCCESS && m.second == DBQuery.SUCCESS) {
                    val notes = q.first.filter { it.isActive() }
                    val c = calcDeliveryNoteSumMap(notes)
                    if (c.second == DBQuery.SUCCESS) {
                        val date_paid = m.first.firstOrNull()?.date_paid ?: "$min_date 00:00:00"
                        setClientMonthlyCycle(
                            min_date,
                            id,
                            c.first[-1]!!.totalValue,
                            date_updated = DatesManipulator.dateNow(),
                            date_paid = date_paid
                        )
                    }

                }
            } catch (e: Exception) {

            }
        }
        return DBQuery.SUCCESS
    }


    override suspend fun setClientMonthlyCycle(
        date: String, id: Int, value: Float, date_updated: String?, paid: Float?, date_paid: String?
    ): Respone<MonthlyCycle?, DBQuery> {
        val response = adapter.newClientsMonthlyCycle(
            date, id.toString(), value.toString(), date_updated, paid?.toString(), date_paid
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsMonthlyCycleMap(data!!)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientMonthlyCyclePdf(
        client_name: String, month: String, year: String
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.getClientMonthlyCyclePdf(
            client_name, month, year
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientTaxNoteCyclePdf(
        client_name: String, month: String, year: String, id: Int, type: Int, origin: Int
    ): Respone<ByteArray?, DBQuery> {
        val c = getClientTaxNoteAll(id = id, type = type).first.first()
        val client = c.getClient()
        val client_master = if (client.hasBranch()) client.masterBranch?.getName(c.document_date) else null
        val client_name = client.getName(c.document_date)
        val response = adapter.getClientTaxPdf(
            client_name,
            month,
            year,
            id.toString(),
            type.toString(),
            origin.toString(),
            client_master = client_master
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientPayCyclePdf(
        client_name: String, month: String, year: String, id: Int, origin: Int
    ): Respone<ByteArray?, DBQuery> {
        val c = getClientPayAll(id = id).first.first()
        val client = c.getClient()
        val client_master = if (client.hasBranch()) client.masterBranch?.getName(c.document_date) else null
        val client_name = client.getName(c.document_date)
        val response = adapter.getClientPayPdf(
            client_name, month, year, id.toString(), origin.toString(), client_master = client_master
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override fun calcClientTaxNote(id: Int, type: Int): DBQuery {

        GlobalScope.async {
            try {
//                adapter.calcClientTaxNotePdf(id.toString(), type.toString())
            } catch (e: Exception) {

            }
        }

        return DBQuery.SUCCESS
    }

    override suspend fun calcClientPayNote(id: Int): DBQuery {

        GlobalScope.async {
            try {
//                adapter.calcClientPayNotePdf(id.toString())
            } catch (e: Exception) {

            }

        }

        return DBQuery.SUCCESS
    }

    override suspend fun buildClientLightTaxNote(
        client_id: Int?,
        id: Int?,
        fromDate: String?,
        toDate: String?,
        full: Boolean,
        type: Int?,
        withProduct: Int?,
        client_ids: List<Int>?
    ): Respone<List<ClientLightTaxNote>, DBQuery> {
        val response = adapter.getClientsLightTaxNote(
            client_id?.toString(),
            id?.toString(),
            fromDate,
            toDate,
            type?.toString(),
            withProduct?.toString(),
            client_ids = client_ids?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsTaxNoteMap(data)
        }
        return Respone(data, dbQuery)
    }

    suspend fun buildFullClientTaxNote(
        clientTaxNotes: List<ClientTaxNote>, withDaily: Boolean = false, sup: Boolean = false
    ): DBQuery {
        if (clientTaxNotes.isEmpty()) {
            return DBQuery.SUCCESS
        }
        val dates: MutableSet<String> = mutableSetOf()
        val delivery_notes = clientTaxNotes.flatMap {
            it.getNotes()
        }.distinct()
        val tempIds = mutableSetOf<Int>()
        var notes: List<Note> = listOf()
        if (delivery_notes.isNotEmpty()) {
            var q = if (sup) this.buildSupplierNotes(delivery_ids = delivery_notes) else this.buildClientNotes(
                delivery_ids = delivery_notes
            )
            if (q.second != DBQuery.SUCCESS) {
                return q.second
            }
            notes = q.first
            tempIds.addAll(q.first.map { it.ent_id })
            dates.addAll(q.first.map { it.date })
        }

        clientTaxNotes.forEach {
            dates.add(it.document_date)
        }

        val d = DatesHolder(dates.toList())
        val first_date = d.getFirst()
        val last_date = d.getLast()
        tempIds.addAll(clientTaxNotes.map { it.client_id })
        val ids = tempIds.toList()
        val product_ids = ClientTaxNote.getAllProducts(clientTaxNotes).toList()

        val b = if (sup) fetchSpecificSupplierData(
            ids,
            fromDate = first_date,
            toDate = last_date
        ) else fetchSpecificClientData(
            ids,
            fromDate = first_date,
            toDate = last_date,
            product_ids = if (product_ids.size > 0) product_ids else null
        )
        if (b != DBQuery.SUCCESS) {
            return b
        }
        val tn = clientTaxNotes.filter { it.type == 0 }.map { it.id }

//        if (tn.isNotEmpty()) {
//            val p = getSign(tn, doc_type = 0)
//            if (p.second != DBQuery.SUCCESS && p.second != DBQuery.ERROR_EMPTY_NEW) {
//                return p.second
//            }
//        }
//        val tnp = clientTaxNotes.filter { it.type == 1 }.map { it.id }
//
//        if (tnp.isNotEmpty()) {
//            val pp = getSign(tnp, doc_type = 1)
//            if (pp.second != DBQuery.SUCCESS && pp.second != DBQuery.ERROR_EMPTY_NEW) {
//                return pp.second
//            }
//        }
//        val tnz = clientTaxNotes.filter { it.type == 2 }.map { it.id }
//        if (tnz.isNotEmpty()) {
//            val ppp = getSign(tnz, doc_type = 2)
//            if (ppp.second != DBQuery.SUCCESS && ppp.second != DBQuery.ERROR_EMPTY_NEW) {
//                return ppp.second
//            }
//        }

        if (delivery_notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        if (withDaily && !sup) {
            val x = DB.buildClientsDailyData(ids = ids, date = last_date).second // need to switch this?
        }


        return calcDeliveryNoteSumMap(notes, ent_type = if (sup) "supplier" else "client").second
    }

    suspend fun matchApiNotesToInformationBase(info: List<InformationBase>): DBQuery {
        if (info.isEmpty()) {
            return DBQuery.SUCCESS
        }
        val dates: MutableSet<String> = mutableSetOf()
        info.forEach {
            it.apis = mutableListOf()
            dates.add(it.getConnectedDate())
        }
        val d = DatesHolder(dates.toList())
        val first_date = d.getFirst()
        val last_date = d.getLast()

        val apiNotes =
            getApiNote(fromDate = first_date, toDate = last_date, doc_ids = info.map { it.getConnectedId() })
        if (apiNotes.second != DBQuery.SUCCESS) {
            return apiNotes.second
        }
        val infoMap = info.groupBy { it.getConnectedId() }

        apiNotes.first.forEach { api ->
            val notes = infoMap[api.doc_id]
            notes?.let {
                it.firstOrNull { it.getConnectedDocType() == api.doc_type }?.apis?.add(api)
            }
        }
        return DBQuery.SUCCESS
    }


    override suspend fun buildClientTaxNote(
        client_id: Int?,
        id: Int?,
        fromDate: String?,
        toDate: String?,
        full: Boolean,
        type: Int?,
        withProduct: Int?,
        client_ids: List<Int>?,
        ids: List<Int>?,
        open: Boolean?,
        agents: List<String>?,
        check_date: String?,
        order_ids: List<Int>?,
        withDaily: Boolean,
        sup: Boolean
    ): Respone<List<ClientTaxNote>, DBQuery> {
        val needHistoryQ =
            if (getUser().first?.company_type == 4 && (fromDate != null && toDate != null) && DatesManipulator.daysDiffBetweenDates(
                    toDate,
                    fromDate
                ) > optimizeDaysDocuments
            ) {
                getClientTaxNotes(
                    client_id = client_id,
                    ids = id?.let { listOf(it) } ?: ids,
                    fromDate = fromDate,
                    toDate = toDate,
                    type = type,
                    withProduct = withProduct,
                    client_ids = client_ids,

                    open = open,
                    agents = agents,
                    order_ids = order_ids,
                    sup = sup
                ).first

            } else null
        val response = adapter.getClientsTaxNote(
            client_id?.toString(),
            id?.toString(),
            fromDate,
            toDate,
            type?.toString(),
            withProduct?.toString(),
            client_ids = client_ids?.joinToString(","),
            tax_ids = ids?.joinToString(","),
            open = open?.let { if (it) "1" else "0" },
            agent = agents?.joinToString(","),
            check_date = check_date,
            order_ids = order_ids?.joinToString(","),
            historyQ = needHistoryQ?.let { lst ->
                val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                lst.forEach {
                    map["${it.id.toString()}_${it.type.toString()}"] = JsonPrimitive(it.action_time)
                }
                JsonObject(map).toString()
            },
            sup = sup
        )
        var data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsTaxNoteMap(data, sup = sup)
            if (needHistoryQ != null)
                data = getClientTaxNotes(
                    client_id = client_id,
                    ids = id?.let { listOf(it) } ?: ids,
                    fromDate = fromDate!!,
                    toDate = toDate!!,
                    type = type,
                    withProduct = withProduct,
                    client_ids = client_ids,

                    open = open,
                    agents = agents,
                    order_ids = order_ids,
                    sup = sup
                ).first
            if (full && buildFullClientTaxNote(data, withDaily, sup = sup) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override suspend fun buildClientLastTaxNote(
        client_id: Int?, full: Boolean, type: Int?, client_ids: List<Int>?, sup: Boolean
    ): Respone<List<ClientTaxNote>, DBQuery> {
        val response = adapter.getClientsLastTaxNote(
            client_id?.toString(), type?.toString(), client_ids = client_ids?.joinToString(","), sup = sup
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsTaxNoteMap(data, sup = sup)
            if (full && buildFullClientTaxNote(data, sup = sup) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        return Respone(data, dbQuery)
    }

    override fun getClientLightTaxNotes(
        fromDate: String, toDate: String, client_id: Int?
    ): Respone<List<ClientLightTaxNote>, DBQuery> {
        val taxNotes =
            if (client_id != null) clientIdToTaxNote[client_id] ?: listOf() else clientIdToTaxNote.values.flatten()
        val t1: MutableList<ClientLightTaxNote> = taxNotes.filter {
            it.document_date in fromDate..toDate
        }.toMutableList()
        val taxNotes2 =
            if (client_id != null) clientIdToTaxPayNote[client_id]
                ?: listOf() else clientIdToTaxPayNote.values.toList()
                .flatten()
        val t2 = taxNotes2.filter {
            it.document_date in fromDate..toDate
        }
        val taxNotes3 =
            if (client_id != null) clientIdToTaxCancel[client_id]
                ?: listOf() else clientIdToTaxCancel.values.toList()
                .flatten()
        val t3 = taxNotes3.filter {
            it.document_date in fromDate..toDate
        }
        t1.addAll(t2)
        t1.addAll(t3)
        return Respone(t1.filterIsInstance<ClientLightTaxNote>(), DBQuery.SUCCESS)
    }

    override fun getClientTaxNotes(
        fromDate: String,
        toDate: String,
        client_id: Int?,
        type: Int?,
        client_ids: List<Int>?,
        ids: List<Int>?,
        open: Boolean?,
        agents: List<String>?,
        order_ids: List<Int>?,
        withProduct: Int?, sup: Boolean
    ): Respone<List<ClientTaxNote>, DBQuery> {


        val ret = if (ids != null && type != null) {
            val idTnMap = if (sup) idToSupTaxNote else idToTaxNote
            val idTpnMap = if (sup) idToSupTaxPayNote else idToTaxPayNote
            val idTcMap = if (sup) idToTSupaxCancel else idToTaxCancel
            ids.mapNotNull { id ->
                when (type) {
                    0, 16 -> {
                        idTnMap[id] ?: return@mapNotNull null
                    }

                    1, 17 -> {
                        idTpnMap[id] ?: return@mapNotNull null
                    }

                    2, 18 -> {
                        idTcMap[id] ?: return@mapNotNull null
                    }

                    else -> {
                        return@mapNotNull null
                    }
                }

            }.filterIsInstance<ClientTaxNote>()
        } else {
            val idTnMap = if (sup) supIdToTaxNote else clientIdToTaxNote
            val idTpnMap = if (sup) supIdToTaxPayNote else clientIdToTaxPayNote
            val idTcMap = if (sup) supIdToTaxCancel else clientIdToTaxCancel
            val taxNotes = (if (type == null || type == 0)
                if (client_id != null) idTnMap[client_id]
                    ?: listOf() else idTnMap.values.toList()
                    .flatten() else listOf()).filterIsInstance<ClientTaxNote>()
            val taxNotes2 = (if (type == null || type == 1)
                if (client_id != null) idTpnMap[client_id]
                    ?: listOf() else idTpnMap.values.toList()
                    .flatten() else listOf()).filterIsInstance<ClientTaxNote>()

            val taxNotes3 = (if (type == null || type == 2)
                if (client_id != null) idTcMap[client_id]
                    ?: listOf() else idTcMap.values.toList()
                    .flatten() else listOf()).filterIsInstance<ClientTaxNote>()
            val tt1: MutableList<ClientTaxNote> = mutableListOf()
            tt1.addAll(taxNotes)
            tt1.addAll(taxNotes2)
            tt1.addAll(taxNotes3)

            val agentSet = agents?.toSet()
            val orderSet = order_ids?.toSet()
            val clientSet = client_ids?.toSet()
            val t1: MutableList<ClientTaxNote> = tt1.filter {
                it.document_date in fromDate..toDate && (withProduct == null || it.withProduct == withProduct) && (open == null || it.isUsed() == !open) && (client_id == null || it.client_id == client_id) && (clientSet == null || it.client_id in clientSet) && (agentSet == null || it.agent in agentSet) && (orderSet == null || it.order_id in orderSet)
            }.toMutableList()
            t1
        }


        return Respone(ret.filterIsInstance<ClientTaxNote>(), DBQuery.SUCCESS)
    }

    override fun getClientTaxNotes(
        fromDate: String, toDate: String, client_id: Int?, sup: Boolean
    ): Respone<List<ClientTaxNote>, DBQuery> {
        val idTnMap = if (sup) supIdToTaxNote else clientIdToTaxNote
        val idTpnMap = if (sup) supIdToTaxPayNote else clientIdToTaxPayNote
        val idTcMap = if (sup) supIdToTaxCancel else clientIdToTaxCancel
        val taxNotes =
            if (client_id != null) idTnMap[client_id] ?: listOf() else idTnMap.values.toList()
                .flatten()
        val t1: MutableList<ClientLightTaxNote> = taxNotes.filter {
            it.document_date in fromDate..toDate
        }.toMutableList()
        val taxNotes2 =
            if (client_id != null) idTpnMap[client_id]
                ?: listOf() else idTpnMap.values.toList()
                .flatten()
        val t2 = taxNotes2.filter {
            it.document_date in fromDate..toDate
        }
        val taxNotes3 =
            if (client_id != null) idTcMap[client_id]
                ?: listOf() else idTcMap.values.toList()
                .flatten()
        val t3 = taxNotes3.filter {
            it.document_date in fromDate..toDate
        }
        t1.addAll(t2)
        t1.addAll(t3)
        return Respone(t1.filterIsInstance<ClientTaxNote>(), DBQuery.SUCCESS)
    }


    override fun getAllClientTaxNote(sup: Boolean): Respone<Map<Int, List<ClientLightTaxNote>>, DBQuery> {
        val idTnMap = if (sup) supIdToTaxNote else clientIdToTaxNote
        return if (idTnMap.isEmpty()) Respone(idTnMap.toMap(), DBQuery.SUCCESS) else Respone(
            idTnMap.toMap(), DBQuery.SUCCESS
        )
    }

    fun getAllTaxNote(sup: Boolean = false): Respone<List<ClientTaxNote>, DBQuery> {
        val idTnMap = if (sup) supIdToTaxNote else clientIdToTaxNote
        val idTpnMap = if (sup) supIdToTaxPayNote else clientIdToTaxPayNote
        val idTcMap = if (sup) supIdToTaxCancel else clientIdToTaxCancel

        val tn = idTnMap.values.flatten().filterIsInstance<ClientTaxNote>()
        val tn2 = idTpnMap.values.flatten().filterIsInstance<ClientTaxNote>()
        val tn3 = idTcMap.values.flatten().filterIsInstance<ClientTaxNote>()
        val t1: MutableList<ClientTaxNote> = tn.toMutableList()
        t1.addAll(tn2)
        t1.addAll(tn3)
        return Respone(t1, DBQuery.SUCCESS)

    }

    fun getNotesOfClientTaxNote(taxNote: ClientTaxNote): Respone<List<DeliveryNote>, DBQuery> {
        return Respone(taxNote.getNotes().map {
            val q = if (taxNote.isClient) getClientDeliveryNotes(it) else getSupplierDeliveryNotes(delivery_id = it)
            if (q.second != DBQuery.SUCCESS) {
                return Respone(listOf(), q.second)
            }
            q.first
        }.flatten(), DBQuery.SUCCESS)

    }

    fun getClientTaxNoteAll(
        id: Int?, type: Int?
    ): Respone<List<ClientLightTaxNote>, DBQuery> {
        val curMap = when (type) {
            0 -> {
                clientIdToTaxNote
            }

            1 -> {
                clientIdToTaxPayNote
            }

            2 -> {
                clientIdToTaxCancel
            }

            else -> {
                return Respone(listOf(), DBQuery.FAILED)

            }
        }
        var ret: Respone<List<ClientLightTaxNote>, DBQuery> = Respone(curMap.values.flatten(), DBQuery.SUCCESS)
        id?.let {
            var lst = ret.first
            lst = lst.filter { it.id == id }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        return ret
    }


    override fun getClientTaxNote(
        client_id: Int?, id: Int?, type: Int?, ids: List<Int>?, sup: Boolean
    ): Respone<List<ClientTaxNote>, DBQuery> {
        val idTnMap = if (sup) supIdToTaxNote else clientIdToTaxNote
        val idTpnMap = if (sup) supIdToTaxPayNote else clientIdToTaxPayNote
        val idTcMap = if (sup) supIdToTaxCancel else clientIdToTaxCancel

        val curMap = when (type) {
            0, 16 -> {
                idTnMap
            }

            1, 17 -> {
                idTpnMap
            }

            2, 18 -> {
                idTcMap
            }

            else -> {
                return Respone(listOf(), DBQuery.FAILED)

            }
        }
        var ret: Respone<List<ClientTaxNote>, DBQuery> =
            Respone(curMap.values.flatten().filterIsInstance<ClientTaxNote>(), DBQuery.SUCCESS)
        client_id?.let {
            ret = if (it in curMap) {
                Respone(curMap[client_id]!!.filterIsInstance<ClientTaxNote>(), DBQuery.SUCCESS)
            } else Respone(listOf(), DBQuery.ID_ISSUE)
        }

        id?.let {
            var lst = ret.first
            lst = lst.filter { it.id == id }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        ids?.let {
            var lst = ret.first
            val set = it.toSet()
            lst = lst.filter { it.id in set }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        return ret
    }

    fun fixDVPrices(deliveryNotes: List<DeliveryNote>, sup: Boolean = false) {
        deliveryNotes.forEach {
            val client = it.getEnt(!sup)
            it.delivery_info.forEach { pd ->
                if (pd.use_price == (0).toByte()) {
                    val priceMenu = client.getPrice(pd.productId)!!
                    val prePrice = priceMenu.get(it.date)
                    pd.price = prePrice.first
                    pd.discount = prePrice.second
                    pd.use_price_tax_note = ProductDelivery.createUsedState(
                        pd.getProduct().getNoTaxProduct(it.date),
                        client.getNoTaxClient(it.date),
                        client.getIncludeTax(it.date),
                        false
                    )
                }
            }
        }
    }

    suspend fun updateAllClientTaxNotes(
        ids: List<Int>,
        types: List<Int>,
        active_state: List<Int>? = null,
        external_details: List<String>? = null,
        taxConfirmation: List<String>? = null,
        notes2: List<String>? = null,
        notes3: List<String>? = null,
        notes4: List<String>? = null,
        notes5: List<String>? = null,
        order_id: List<Int>? = null,
        agent: String? = null,
        agent_id: List<Int?>? = null,
        increase_id: Int? = null,
        value_left: List<Float>? = null,
        payments: List<List<PaymentData>>? = null,
        sup: Boolean = false
    ): Respone<List<ClientTaxNote>, DBQuery> {
        val data: MutableList<JsonObject> = mutableListOf()
        ids.forEachIndexed { index, i ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            map["id"] = JsonPrimitive(i.toString())
            map["type"] = JsonPrimitive(types[index])
            agent?.let {
                map["agent"] = JsonPrimitive(agent)
            }
            agent_id?.let {
                map["agent_id"] = JsonPrimitive(agent_id.toString())
            }
            if (sup) {
                map["sup"] = JsonPrimitive("true")
            }
            active_state?.get(index)?.let {
                map["active_state"] = JsonPrimitive(it.toString())
            }
            value_left?.get(index)?.let {
                map["value_left"] = JsonPrimitive(it.toString())
            }

            external_details?.get(index)?.let {
                map["external_details"] = JsonPrimitive(it.toString())
            }
            payments?.get(index)?.let {
                map["payment_data_raw"] = JsonPrimitive(PaymentData.toJsonArrayString(it))
            }
            notes2?.get(index)?.let {
                map["notes2"] = JsonPrimitive(it.toString())
            }
            notes3?.get(index)?.let {
                map["notes3"] = JsonPrimitive(it.toString())
            }
            notes4?.get(index)?.let {
                map["notes4"] = JsonPrimitive(it.toString())
            }
            notes5?.get(index)?.let {
                map["notes5"] = JsonPrimitive(it.toString())
            }
            order_id?.get(index)?.let {
                map["order_id"] = JsonPrimitive(it.toString())
            }
            increase_id?.let {
                map["increase_id"] = JsonPrimitive(it.toString())
            }
            taxConfirmation?.get(index)?.let {
                map["taxConfirmation"] = JsonPrimitive(it.toString())
            }
            data.add(JsonObject(map))
        }

        val response = adapter.updateAllClientTaxNote(
            data = "[${data.joinToString(",")}]", sup = sup
        )

        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsTaxNoteMap((dataR), sup = sup)

        }
        return Respone(dataR, dbQuery)
    }

    override suspend fun newClientTaxNote(
        client_id: Int,
        date: String,
        document_date: String,
        value: Float,
        total_value: Float,
        dataValues: List<ClientTaxNoteData>,
        details: ClientStaticData,
        discount_percent: Float?,
        cover_dates: String?,
        type: Int?,
        paymentsData: List<PaymentData>,
        extra_delivery_notes_meta: List<Int>?,
        with_price: Boolean,
        delivery_notes: List<DeliveryNote>?,
        active_state: Int?,
        external_details: String?,
        agent: String?,
        order_id: Int?,
        calculate_pdf: Boolean,
        tax_note_id: Int?,
        agent_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        increase_id: Int?,
        sup: Boolean
    ): Respone<List<ClientTaxNote>, DBQuery> {
        var dataValues = dataValues
        var value = value
        var total_value = total_value
        var type = type
        if (value < 0 && (type == 0 || type == 2)) {
            dataValues = dataValues.map {
                it.copy(value = -it.value, taxPaid = it.taxPaid?.let { -it })
            }
            value = -value
            total_value = -total_value
            type = if (type == 2) 0 else 2
        }
        if (!sup && (user?.tax_note_split_dv ?: 0) > 0 && (user?.tax_note_split
                ?: 0) > 0 && ((dataValues.size == 1 && dataValues.first().fillTaxNoteType == FillTaxNoteType.NOTES) || (dataValues.size == 2 && dataValues.any { it.fillTaxNoteType == FillTaxNoteType.NOTES } && dataValues.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.FREE }?.details?.let {
                it == "הנחת עיגול" || it == "עיגול סכום"
            } == true)) && delivery_notes != null
        ) {
            //split tax note
            val spliter = newClientTaxNoteDVWithSplit(client_id = client_id,
                date = date,
                document_date = document_date,
                deliveryNotes = delivery_notes,
                details = details,
                discount_percent = discount_percent,
                type = type!!,
                agent = agent!!,
                external_details = external_details,
                order_id = order_id,
                agent_id = agent_id,
                notes2 = notes2,
                notes3 = notes3,
                notes4 = notes4,
                notes5 = notes5,
                increase_id = increase_id,
                round = (dataValues.size == 2 && dataValues.any { it.fillTaxNoteType == FillTaxNoteType.NOTES } && dataValues.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.FREE }?.details?.let {
                    it == "הנחת עיגול" || it == "עיגול סכום"
                } == true))
            val data = spliter.first.first
            val deliveryNotesValuesMap = spliter.first.second

            val response = adapter.newAllClientTaxNotes(
                date = date,
                data = "[${data.joinToString(",")}]",
                deliveryNotesValuesMap = JsonObject(deliveryNotesValuesMap).toString()
            )


            var dataR = response.first
            val networkOutput = response.second
            val dbQuery = analyzeResponse(networkOutput, new = true)
            if (dbQuery == DBQuery.SUCCESS) {
                dataR = hashIsraelImplyMulti(dataR)

                _updateClientsTaxNoteMap((dataR))
                buildClientNotes(delivery_ids = delivery_notes.map { it.id })
                _updatePrevCarteset(client_id, document_date, date)


            }

            return Respone(dataR, dbQuery)
        }
        if (with_price && dataValues.any { it.fillTaxNoteType == FillTaxNoteType.PRODUCTS }) {
            val pd =
                ProductDelivery.createFromJson(dataValues.first { it.fillTaxNoteType == FillTaxNoteType.PRODUCTS }.details)
            pd.forEach {
                val c = getClient(client_id).first!!
                val price = pdUsePrice(it, c, document_date, 0, false)!!
                it.price = if (c.getIncludeTax(document_date) == 1) roundToDecimals(
                    price.second, 2
                ) else roundToDecimals(price.first, 2)
            }
            var ent_to_change_id = client_id
            if (getClient(client_id).first!!.master != -1) {
                ent_to_change_id = getClient(client_id).first!!.master
            }
            val q = changePriceFromProductDelivery(
                pd, ent_to_change_id, document_date, "client"
            ) //cur date price since this is products only
            if (q != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)

            }
        }
        val coverHolder = if (cover_dates.isNullOrEmpty()) DatesHolder(document_date) else DatesHolder(cover_dates)
        val delivery_notes_meta_data = if (type == 2) {
            extra_delivery_notes_meta?.joinToString(",")
        } else {
            dataValues.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.NOTES }?.details?.split(",")
                ?.map { it.split(":")[0] }?.joinToString(",")
        }
        val deliveryNotesValuesMap = delivery_notes?.let {
            val m: MutableMap<String, JsonElement> = mutableMapOf()
            it.forEach { n ->
                m[n.delivery_id.toString()] = JsonPrimitive(ProductDelivery.toJsonArrayString(n.delivery_info))
            }
            JsonObject(m).toString()
        }

        val response = adapter.newClientsTaxNote(client_id = client_id.toString(),
            date = date,
            document_date = document_date,
            value = value.toString(),
            total_value = total_value.toString(),
            value_data_raw = ClientTaxNoteData.toJsonArrayString(dataValues),
            details = ClientStaticData.toJsonArrayString(details),
            discount_percent = discount_percent?.toString(),
            cover_dates = cover_dates,
            cover_date_start = coverHolder?.getFirst(),
            cover_date_end = coverHolder?.getLast(),
            type = type?.toString(),
            increase_id = increase_id?.toString(),
            payment_data_raw = PaymentData.toJsonArrayString(paymentsData),
            delivery_notes_meta_data = delivery_notes_meta_data,
            withProduct = if (dataValues.any { it.fillTaxNoteType == FillTaxNoteType.PRODUCTS }) "1" else "0",
            tax_note_meta_data = dataValues.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.TAX_NOTES }?.let {
                it.details.split(
                    ","
                ).map { it.split(":")[0] }.joinToString(",")
            } ?: dataValues.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.TAX_PAY_NOTES }?.details?.split(
                ","
            )?.map {
                "-${it.split(":")[0]}"
            }?.joinToString(", ")
            ?: dataValues.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.TAX_CANCEL_NOTES }?.details?.split(
                ","
            )?.map {
                "-${it.split(":")[0]}"
            }?.joinToString(", "),

            delivery_notes_map = deliveryNotesValuesMap,
            active_state = active_state?.toString(),
            external_details = external_details?.let { prepareStrToJson(it) },
            agent = agent,
            order_id = order_id?.toString(),
            tax_note_id = tax_note_id?.toString(),
            sup = sup

        )
        var data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {

            if (sup) {
                _updateClientsTaxNoteMap(listOf(data!!), sup = true)
                if (data.withProduct == 1) _updateSupplierNoteForToday(data.client_id, data.document_date)
                _updatePrevCarteset(client_id, document_date, date, sup)
                return Respone(listOf(data), dbQuery)

            } else {
                val dataR = hashIsraelImplyMulti(listOf(data!!))
                data = dataR.first()

                _updateClientsTaxNoteMap(listOf(data))
                if (data.withProduct == 1) _updateClientNoteForToday(data.client_id, data)
                if (calculate_pdf) calcClientTaxNote(data.id, data.type)
                _updatePrevCarteset(client_id, document_date, date, sup)
                return Respone(listOf(data), dbQuery)
            }

        }
        return Respone(listOf(), dbQuery)
    }

    suspend fun updatePrevCarteset(client_id: Int, document_date: String, date: String, sup: Boolean = false): DBQuery {
        if (sup)
            return DBQuery.SUCCESS
        val year1 = DatesManipulator.getYear(document_date)
        val year2 = DatesManipulator.getYear(DatesManipulator.getNextYear(date, -1))
        if (year1 == year2) {
            //prev year addon
            val carteset = calcOptimizedCartesetValue(listOf(client_id), "$year1-12-31")
            if (carteset == DBQuery.SUCCESS) {
                val cur_year = DatesManipulator.getYear(date)
                val client = getClient(client_id).first!!
                if ((client.hasBranch() && client.master == -1) || !client.hasBranch()) {
                    val amount = client.debtWall!!.moneyOut - client.debtWall!!.moneyIn
                    val position = if (amount >= 0) amount else 0f
                    val negative = if (amount < 0) -amount else 0f
                    return newClientCarteset(
                        client_id,
                        "$cur_year-01-01",
                        client.debtWall!!.moneyOut,
                        client.debtWall!!.moneyIn,
                        state = 1
                    ).second
                }

            } else {
                return DBQuery.FAILED
            }
        }
        return DBQuery.SUCCESS
    }

    fun _updatePrevCarteset(client_id: Int, document_date: String, date: String, sup: Boolean = false) {
        GlobalScope.async {
            try {
                updatePrevCarteset(client_id, document_date, date, sup)
            } catch (e: Exception) {

            }
        }
    }

    override suspend fun cancelClientTaxNote(
        id: Int, type: Int, date: String, agent: String, increase_id: Int?, sup: Boolean
    ): Respone<ClientTaxNote?, DBQuery> {
        val taxNote = getClientTaxNote(id = id, type = type, sup = sup).first[0] // must be
        val typeOut = if (type == 0 || type == 1) 2 else 0
        val filltaxtype = if (taxNote.taxNoteType == TaxNoteType.WITH_PAYMENT) {
            val q = newClientPay(
                client_id = taxNote.client_id,
                date = DatesManipulator.dateNow(),
                document_date = date,
                value = -taxNote.total_value,
                details = taxNote.ClientStaticData,
                paymentsData = taxNote.paymentsData.map {
                    PaymentData(
                        value = -it.value,
                        pay_method = it.pay_method,
                        bank_id = it.bank_id,
                        bank_branch = it.bank_branch,
                        bank_number = it.bank_number,
                        check_number = it.check_number,
                        check_date = it.check_date
                    )
                },
                tax_note_id = (-taxNote.id).toString(),
                active = 0,
                agent = agent,
                increase_id = increase_id,
                sup = if (sup) 1 else null


            )
            if (q.second != DBQuery.SUCCESS) {
                return Respone(null, DBQuery.FAILED)
            }
            FillTaxNoteType.TAX_PAY_NOTES.state
        } else if (taxNote.taxNoteType == TaxNoteType.REGULAR) {
            FillTaxNoteType.TAX_NOTES.state

        } else {
            FillTaxNoteType.TAX_CANCEL_NOTES.state
        }

        val ret = if (taxNote.value_left == taxNote.total_value || taxNote.taxNoteType == TaxNoteType.WITH_PAYMENT) {
            newClientTaxNote(
                client_id = taxNote.client_id,
                date = DatesManipulator.dateNow(),
                document_date = date,
                value = taxNote.value,
                total_value = taxNote.total_value,
                details = taxNote.ClientStaticData,
                type = typeOut,
                cover_dates = taxNote.cover_dates,
                paymentsData = taxNote.paymentsData,
                extra_delivery_notes_meta = taxNote.getNotes(),
                dataValues = listOf(
                    ClientTaxNoteData(
                        taxNote.total_value, //after discounts
                        filltaxtype, taxNote.id.toString(), taxPaid = roundToDecimals(taxNote.getTaxPaid(), 4)
                    )
                ),
                discount_percent = taxNote.discount_percent,
                active_state = 0,
                agent = agent,
                increase_id = increase_id,
                sup = sup
            )
        } else {
            val valueLeft = taxNote.value_left
            val taxPerc = (taxNote.total_value / (1 - taxNote.discount_percent / 100)) / taxNote.value
            val value = valueLeft / taxPerc
            newClientTaxNote(
                client_id = taxNote.client_id,
                date = DatesManipulator.dateNow(),
                document_date = date,
                value = value,
                total_value = valueLeft,
                details = taxNote.ClientStaticData,
                type = typeOut,
                cover_dates = taxNote.cover_dates,
                paymentsData = taxNote.paymentsData,
                extra_delivery_notes_meta = taxNote.getNotes(),
                dataValues = listOf(
                    ClientTaxNoteData(
                        valueLeft, //after discounts
                        filltaxtype, taxNote.id.toString(), taxPaid = roundToDecimals(valueLeft - value, 4)
                    )
                ),
                discount_percent = 0f,
                active_state = 0,
                agent = agent,
                increase_id = increase_id,
                sup = sup
            )
        }

        return Respone(ret.first.firstOrNull(), ret.second)
    }

    override fun calcClientTaxNoteSumMap(notes: List<ClientTaxNote>, sup: Boolean): Respone<Float?, DBQuery> {
        return Respone(
            notes.sumByDouble { it.total_value?.toDouble() ?: (0f).toDouble() }.toFloat(), DBQuery.SUCCESS
        )
    }

    suspend fun buildFullClientPay(
        clientPays: List<ClientLightPay>,
        sup: Boolean
    ): DBQuery {
        if (clientPays.isEmpty()) {
            return DBQuery.SUCCESS
        }
        val dates: MutableSet<String> = mutableSetOf()
        clientPays.forEach {
            dates.add(it.document_date)
        }
        val d = DatesHolder(dates.toList())
        val first_date = d.getFirst()
        val last_date = d.getLast()
        val ids = clientPays.map { it.client_id }.distinct()
        val b = if (sup) fetchSpecificSupplierData(
            ids,
            fromDate = first_date,
            toDate = last_date,
        ) else fetchSpecificClientData(ids, fromDate = first_date, toDate = last_date, product_ids = listOf())
        if (b != DBQuery.SUCCESS) {
            return b

        }
        return DBQuery.SUCCESS
    }

    suspend fun buildClientOpenPays(
        fromDate: String? = null,
        toDate: String? = null,
        full: Boolean = false,
        client_ids: List<Int>? = null,
        sup: Boolean = false,
        paid: Boolean? = null,
        dateIsDoc: Boolean? = null
    ): Respone<List<InformationBase>, DBQuery> {
        val response = adapter.getClientsPay(
            client_ids = client_ids?.joinToString(","),
            fromDate = dateIsDoc?.let { if (it && fromDate != null) DatesManipulator.dateIsrael(fromDate) else null },
            toDate = dateIsDoc?.let { if (it && toDate != null) DatesManipulator.dateIsrael(toDate) else null },
            check_date = if (dateIsDoc == true) null else toDate,
            check_date_from = if (dateIsDoc == true) null else fromDate,
            sup = sup,
            paid = paid?.let { if (it) "1" else "0" }
        )
        var data = response.first
        val response2 = adapter.getClientsTaxNote(
            client_ids = client_ids?.joinToString(","),
            fromDate = dateIsDoc?.let { if (it && fromDate != null) DatesManipulator.dateIsrael(fromDate) else null },
            toDate = dateIsDoc?.let { if (it && toDate != null) DatesManipulator.dateIsrael(toDate) else null },
            check_date = if (dateIsDoc == true) null else toDate,
            check_date_from = if (dateIsDoc == true) null else fromDate,
            type = "1",
            sup = sup,
            paid = paid?.let { if (it) "1" else "0" }
        )
        val data2 = response2.first

        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        val networkOutput2 = response2.second
        val dbQuery2 = analyzeResponse(networkOutput2)

        if (dbQuery == DBQuery.SUCCESS && dbQuery2 == DBQuery.SUCCESS) {
            _updateClientsPayMap(data, sup)
            _updateClientsTaxNoteMap(data2, sup)
            val alldocs: MutableList<InformationBase> = data.toMutableList()
            alldocs.addAll(data2)
            if (full && buildFullOnDocs(alldocs, sup = sup) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            if (full) {
                val ids = data.map { it.taxNotes.filter { it > 0 } }.flatten()
                if (ids.isNotEmpty())
                    buildClientTaxNote(ids = ids, type = 0, sup = sup)
                val ids2 = data.map { it.taxNotes.filter { it < 0 }.map { -it } }.flatten()
                if (ids2.isNotEmpty())
                    buildClientTaxNote(ids = ids2, type = 2, sup = sup)

            }
            return Respone(alldocs, dbQuery)
        }

        return Respone(listOf(), dbQuery)
    }

    override suspend fun buildClientPay(
        client_id: Int?,
        id: Int?,
        fromDate: String?,
        toDate: String?,
        full: Boolean,
        client_ids: List<Int>?,
        ids: List<Int>?,
        agents: List<String>?,
        check_date: String?,
        withTaxIds: Boolean,
        sup: Boolean
    ): Respone<List<ClientPay>, DBQuery> {
        val needHistoryQ =
            if (getUser().first?.company_type == 4 && (fromDate != null && toDate != null) && DatesManipulator.daysDiffBetweenDates(
                    toDate,
                    fromDate
                ) > optimizeDaysDocuments
            ) {
                getClientPay(
                    client_id = client_id,
                    ids = id?.let { listOf(it) } ?: ids,
                    fromDate = fromDate,
                    toDate = toDate,
                    client_ids = client_ids,
                    agents = agents,
                    check_date = check_date,
                    sup = sup
                ).first
            } else null
        val response = adapter.getClientsPay(
            client_id?.toString(),
            id?.toString(),
            fromDate,
            toDate,
            client_ids = client_ids?.joinToString(","),
            pay_ids = ids?.joinToString(","),
            agent = agents?.joinToString(","),
            check_date = check_date,
            historyQ = needHistoryQ?.let { lst ->
                val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                lst.forEach {
                    map[it.id.toString()] = JsonPrimitive(it.action_time)
                }
                JsonObject(map).toString()
            },
            sup = sup
        )
        var data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsPayMap(data, sup)
            if (needHistoryQ != null) {
                data = getClientPay(
                    client_id = client_id,
                    ids = id?.let { listOf(it) } ?: ids,
                    fromDate = fromDate!!,
                    toDate = toDate!!,
                    client_ids = client_ids,
                    agents = agents,
                    check_date = check_date,
                    sup = sup
                ).first
            }
            if (full && buildFullClientPay(data, sup = sup) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            if (withTaxIds) {
                val ids = data.map { it.taxNotes.filter { it > 0 } }.flatten()
                if (ids.isNotEmpty())
                    buildClientTaxNote(ids = ids, type = 0, sup = sup)
                val ids2 = data.map { it.taxNotes.filter { it < 0 }.map { -it } }.flatten()
                if (ids2.isNotEmpty())
                    buildClientTaxNote(ids = ids2, type = 2, sup = sup)

            }
        }

        return Respone(data, dbQuery)
    }

    override suspend fun buildClientLightPay(
        client_id: Int?, id: Int?, fromDate: String?, toDate: String?, full: Boolean, client_ids: List<Int>?
    ): Respone<List<ClientLightPay>, DBQuery> {
        val response = adapter.getClientsLightPay(
            client_id?.toString(), id?.toString(), fromDate, toDate, client_ids = client_ids?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsPayMap(data)
            if (full && buildFullClientPay(data, false) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientChecks(
        client_id: Int?, client_ids: List<Int>?, last: Int?, sup: Boolean

    ): Respone<List<PaymentData>, DBQuery> {
        val response = adapter.getClientChecks(
            client_id = client_id?.toString(),
            client_ids = client_ids?.joinToString(","),
            last = last?.toString(),
            sup = sup
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientLastPay(
        client_id: Int?, full: Boolean, client_ids: List<Int>?, sup: Boolean
    ): Respone<List<ClientPay>, DBQuery> {
        val response = adapter.getClientsLastPay(
            client_id?.toString(), client_ids = client_ids?.joinToString(","), sup = sup
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsPayMap(data, sup)
            if (full && buildFullClientPay(data, sup) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override fun getClientLightPay(
        fromDate: String, toDate: String, client_id: Int?
    ): Respone<List<ClientLightPay>, DBQuery> {
        val pays = if (client_id != null) clientIdToClientPay[client_id] ?: listOf() else {
            clientIdToClientPay.values.toList().flatten()
        }
        val t1: MutableList<ClientLightPay> = pays.filter {
            it.document_date in fromDate..toDate
        }.filterIsInstance<ClientLightPay>().toMutableList()
        return Respone(t1, DBQuery.SUCCESS)
    }

    override fun getClientPay(
        fromDate: String, toDate: String, client_id: Int?, client_ids: List<Int>?,
        agents: List<String>?,
        check_date: String?,
        ids: List<Int>?,
        sup: Boolean
    ): Respone<List<ClientPay>, DBQuery> {
        val payMap = if (sup) supIdToClientPay else clientIdToClientPay
        val pays = ((client_id?.let { listOf(it) } ?: client_ids)?.mapNotNull { payMap[it] }?.flatten()
            ?: payMap.values.flatten()).filterIsInstance<ClientPay>()

        val agentSet = agents?.toSet()
        val idsSet = ids?.toSet()

        val t1: List<ClientPay> = pays.filter {
            it.document_date in fromDate..toDate && (agentSet == null || it.agent in agentSet) && (idsSet == null || it.id in idsSet) && (check_date == null || it.paymentsData.any { (it.paid == null || it.paid == 0) && it.check_date <= check_date || it.payMethod == PayMethod.CASH })
        }
        return Respone(t1, DBQuery.SUCCESS)
    }

    override fun getAllClientPay(sup: Boolean): Respone<Map<Int, List<ClientLightPay>>, DBQuery> {
        val payMap = if (sup) supIdToClientPay else clientIdToClientPay
        return if (payMap.isEmpty()) Respone(
            payMap.toMap(), DBQuery.SUCCESS
        ) else Respone(
            payMap.toMap(), DBQuery.SUCCESS
        )
    }

    fun getClientPayAll(
        id: Int?,
        sup: Boolean = false
    ): Respone<List<ClientLightPay>, DBQuery> {
        val payMap = if (sup) supIdToClientPay else clientIdToClientPay
        var ret: Respone<List<ClientLightPay>, DBQuery> =
            Respone(payMap.values.flatten(), DBQuery.SUCCESS)
        id?.let {
            var lst = ret.first
            lst = lst.filter { it.id == id }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        return ret
    }

    override fun getClientPay(
        client_id: Int?, id: Int?, ids: List<Int>?, sup: Boolean
    ): Respone<List<ClientPay>, DBQuery> {
        val payMap = if (sup) supIdToClientPay else clientIdToClientPay
        var ret: Respone<List<ClientPay>, DBQuery> =
            Respone(payMap.values.flatten().filterIsInstance<ClientPay>(), DBQuery.SUCCESS)
        client_id?.let {
            ret = if (it in payMap) {
                Respone(payMap[client_id]!!.filterIsInstance<ClientPay>(), DBQuery.SUCCESS)
            } else Respone(listOf(), DBQuery.ID_ISSUE)
        }
        (ids ?: id?.let { listOf(it) })?.let {
            var lst = ret.first
            val set = it.toSet()
            lst = lst.filter { it.id in set }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        return ret
    }

    override suspend fun newClientPay(
        client_id: Int,
        date: String,
        document_date: String,
        value: Float,
        details: ClientStaticData,
        paymentsData: List<PaymentData>,
        tax_note_id: String?,
        cancel_id: Int?,
        reopen_tax_notes: String?,
        active: Int?,
        external_details: String?,
        agent: String?,
        refundRest: Boolean?,
        type: Int?,
        increase_id: Int?,
        sup: Int?
    ): Respone<ClientPay?, DBQuery> {

        var refund: String? = null
        var refund_with_tax: String? = null
        var tax_note_idReal = tax_note_id
        if (tax_note_idReal != null && tax_note_idReal.isNotEmpty()) {

            val taxes = tax_note_idReal.split(",").map { it.toInt() }
            val taxNotes = getClientTaxNote(ids = taxes.filter { it > 0 }, type = 0, sup = sup == 1).first
            val taxCancel =
                getClientTaxNote(ids = taxes.filter { it < 0 }.map { -(it) }, type = 2, sup = sup == 1).first
            val res =
                taxNotes.sumByDouble { it.value_left.toDouble() } - taxCancel.sumByDouble { it.value_left.toDouble() } - value  //1112+-855 = 257 - 250 = 7
            val taxSums: MutableList<Triple<ClientTaxNote, Pair<Double, Double>, String>> = mutableListOf()

// Combine invoices and refunds, and sort by document date


// Initialize the current payment value
            var curValue =
                value.toDouble() + taxCancel.sumByDouble { it.value_left.toDouble() } + if (refundRest == true && res > 0) res else 0.0

// List to hold results


// Process all items in chronological order


            (taxNotes).sortedBy { it.document_date }.forEach { item ->

                // Invoice: Apply payment
                if (curValue <= 0) {
                    // No payment available, full amount is unpaid
                    taxSums.add(Triple(item, Pair(item.value_left.toDouble(), 0.0), item.document_date))
                } else if (curValue >= item.value_left) {
                    // Fully pay the item
                    taxSums.add(Triple(item, Pair(0.0, item.value_left.toDouble()), item.document_date))
                    curValue -= item.value_left.toDouble()
                } else {
                    // Partially pay the item
                    var partValue = item.value_left.toDouble() - curValue
                    var curPaidValue = curValue
                    if (partValue < abs(0.9999)) {
                        curPaidValue += partValue
                        partValue = 0.0
                    }

                    taxSums.add(
                        Triple(
                            item,
                            Pair(partValue, curPaidValue),
                            item.document_date
                        )
                    )
                    curValue = 0.0 // Payment is exhausted
                }
            }
            taxCancel.sortedByDescending { it.document_date }.forEach { item ->
                if (curValue <= 0) {
                    taxSums.add(Triple(item, Pair(0.0, item.value_left.toDouble()), item.document_date))
                } else if (curValue >= item.value_left) {
                    // Fully absorb the refund
                    taxSums.add(Triple(item, Pair(item.value_left.toDouble(), 0.0), item.document_date))
                    curValue -= item.value_left.toDouble() // Refund increases the available value
                } else {
                    // Partially absorb the refund
                    taxSums.add(
                        Triple(
                            item,
                            Pair(curValue, item.value_left.toDouble() - curValue),
                            item.document_date
                        )
                    )
                    curValue = 0.0 // Refund partially absorbed
                }
            }

            tax_note_idReal =
                taxSums.joinToString(",") {
                    "${it.first.id * (if (it.first.type == 0) 1 else -1)}:${
                        roundToDecimals(
                            it.second.first.toFloat(),
                            4
                        )
                    }:${roundToDecimals(it.second.second.toFloat(), 4)}"
                }





            if (refundRest == true && res > 0) {
                val taxPaid = taxNotes.sumByDouble { it.getTaxPaid().toDouble() } - taxCancel.sumByDouble {
                    it.getTaxPaid().toDouble()
                } // 161.57 - 0 = 161.57
                val maxWithTaxValue = taxPaid * tax.getTaxToTotal(document_date)
                val resWithTax = maxWithTaxValue - res
                if (resWithTax > 0) {
                    // all with tax
                    if (res > 0.2) {
                        val x = createTaxNoteDataFromFreeValue(
                            "זיכוי פערים עבור ${tax_note_id ?: ""}",
                            roundToDecimals(res.toFloat(), 2),
                            false,
                            document_date
                        )
                        refund_with_tax = (x.toJson().toString())
                    }
                } else {
                    // some with tax
                    val withTax = maxWithTaxValue
                    val noTax = res - maxWithTaxValue
                    if (withTax > 0.2) {
                        val x = createTaxNoteDataFromFreeValue(
                            "זיכוי פערים עבור ${tax_note_id ?: ""}",
                            roundToDecimals(withTax.toFloat(), 2),
                            false,
                            document_date
                        )
                        refund_with_tax = (x.toJson().toString())
                    }
                    if (noTax > 0.2) {
                        val x = createTaxNoteDataFromFreeValue(
                            "זיכוי פערים עבור ${tax_note_id ?: ""}",
                            roundToDecimals(noTax.toFloat(), 2),
                            true,
                            document_date
                        )
                        refund = (x.toJson().toString())
                    }
                }

            }
        }
        val response = adapter.newClientsPay(
            client_id = client_id.toString(),
            date = date,
            document_date = document_date,
            value = value.toString(),
            details = ClientStaticData.toJsonArrayString(details),
            payment_data_raw = PaymentData.toJsonArrayString(paymentsData),
            tax_note_id = tax_note_idReal,
            cancel_id = cancel_id?.toString(),
            reopen_tax_notes = reopen_tax_notes,
            active = active?.toString(),
            external_details = external_details?.let { prepareStrToJson(it) },
            agent = agent,
            refund = refund?.let { "[${it}]" },
            refund_with_tax = refund_with_tax?.let { "[${it}]" },
            type = type?.toString(),
            increase_id = increase_id?.toString(),
            sup = sup == 1
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            if (sup == 1) {
                _updateSupsPayMap(data!!)
            } else {
                _updateClientsPayMap(data!!)
            }

            _updatePrevCarteset(client_id, document_date, date, sup = sup == 1)
            val ids = data.taxNotes.filter { it > 0 }
            var taxNote = listOf<ClientTaxNote>()
            var taxNote2 = listOf<ClientTaxNote>()
            if (ids.isNotEmpty()) {
                taxNote = buildClientTaxNote(ids = ids, type = 0, sup = sup == 1).first
            }
            val ids2 = data.taxNotes.filter { it < 0 }.map { -it }
            if (ids2.isNotEmpty()) {
                taxNote2 = buildClientTaxNote(ids = ids2, type = 2, sup = sup == 1).first
            }
        }
        return Respone(data, dbQuery)
    }


    suspend fun updateClientTaxNotePay(
        id: Int,
        paymentsData: List<PaymentData>,
        sup: Boolean = false
    ): Respone<ClientTaxNote?, DBQuery> {
        val response = adapter.updateClientsTaxNotePayment(
            id = id.toString(),
            payment_data_raw = paymentsData.let { PaymentData.toJsonArrayString(it) },
            sup = sup
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsTaxNoteMap(listOf(data!!), sup = sup)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun updateClientPay(
        id: Int,
        paymentsData: List<PaymentData>?,
        tax_note_id: String?,
        cancel_id: Int?,
        reopen_tax_notes: String?,
        active: Int?,
        external_details: String?,
        sup: Boolean
    ): Respone<ClientPay?, DBQuery> {
        val response = adapter.updateClientsPay(
            id = id.toString(),
            payment_data_raw = paymentsData?.let { PaymentData.toJsonArrayString(it) },
            tax_note_id = tax_note_id,
            cancel_id = cancel_id?.toString(),
            reopen_tax_notes = reopen_tax_notes,
            active = active?.toString(),
            external_details = external_details?.let { prepareStrToJson(it) },
            value = paymentsData?.let {
                roundToDecimals(it.sumByDouble { it.value.toDouble() }.toFloat(), 2).toString()
            }, sup = sup
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            if (sup)
                _updateSupsPayMap(data!!)
            else
                _updateClientsPayMap(data!!)

        }
        return Respone(data, dbQuery)
    }

    override suspend fun cancelClientPay(
        id: Int,
        date: String,
        agent: String,
        increase_id: Int?,
        value: Float?,
        checkNumber: String?,
        bank_id: String?,
        method: Int?,
        sup: Boolean
    ): Respone<ClientPay?, DBQuery> {
        val payNote = getClientPay(id = id).first[0]

        val taxNotes = if (payNote.taxNotes.isNotEmpty()) {
            val ids = payNote.taxNotes.filter { it > 0 }
            var taxNote = listOf<ClientTaxNote>()
            var taxNote2 = listOf<ClientTaxNote>()
            if (ids.isNotEmpty()) {
                taxNote = buildClientTaxNote(ids = ids, type = 0, sup = sup).first
            }
            val ids2 = payNote.taxNotes.filter { it < 0 }.map { -it }
            if (ids2.isNotEmpty()) {
                taxNote2 = buildClientTaxNote(ids = ids2, type = 2, sup = sup).first
            }

            taxNote + taxNote2

        } else null
        if (value != null && checkNumber != null && bank_id != null && method != null) {
            val payment =
                payNote.paymentsData.firstOrNull() { it.value == value && it.check_number == checkNumber && it.bank_id == bank_id && it.pay_method == method }
                    ?: return Respone(null, DBQuery.ID_ISSUE)
            return newClientPay(
                client_id = payNote.client_id,
                date = DatesManipulator.dateNow(),
                document_date = date,
                value = -value,
                details = payNote.ClientStaticData,
                paymentsData = listOf(
                    PaymentData(
                        value = -value,
                        pay_method = payment.pay_method,
                        bank_id = payment.bank_id,
                        bank_branch = payment.bank_branch,
                        bank_number = payment.bank_number,
                        check_number = payment.check_number,
                        check_date = payment.check_date
                    )
                ),
                tax_note_id = "",
                active = 0,
                agent = agent,
                type = 0,
                increase_id = increase_id,
                sup = if (sup) 1 else null

            )
        }
        return newClientPay(
            client_id = payNote.client_id,
            date = DatesManipulator.dateNow(),
            document_date = date,
            value = -payNote.value,
            details = payNote.ClientStaticData,
            paymentsData = payNote.paymentsData.map {
                PaymentData(
                    value = -it.value,
                    pay_method = it.pay_method,
                    bank_id = it.bank_id,
                    bank_branch = it.bank_branch,
                    bank_number = it.bank_number,
                    check_number = it.check_number,
                    check_date = it.check_date
                )
            },
            tax_note_id = "",
            cancel_id = payNote.id,
            reopen_tax_notes = if (payNote.taxNotes.isNotEmpty()) payNote.getInverseTaxNoteTriple(taxNotes?.map { it.id * (if (it.taxNoteType == TaxNoteType.CANCEL) -1 else 1) to it }
                ?.toMap()) else null,
            active = 0,
            agent = agent,
            type = 0,
            increase_id = increase_id,
            sup = if (sup) 1 else null

        )
    }

    override suspend fun buildClientCartesetMap(
        id: Int?, fromDate: String?, toDate: String?, ids: List<Int>?, state: Int?,
        sup: Boolean
    ): Respone<List<ClientCarteset>, DBQuery> {
        val response = adapter.getCartesetClients(
            id = id?.toString(),
            fromDate = fromDate,
            toDate = toDate,
            ids = ids?.joinToString(","),
            state = state?.toString(),
            sup = sup
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            val map = if (sup) supCartesetMap else cartesetMap
            data.forEach {
                map[it.id]?.let { ml ->
                    ml.add(it)
                } ?: true.let { t ->
                    map[it.id] = mutableListOf()
                    map[it.id]!!.add(it)
                }
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newClientCarteset(
        client_id: Int, date: String, positive: Float, negative: Float, state: Int, notes: String?, sup: Boolean
    ): Respone<ClientCarteset?, DBQuery> {
        val response = adapter.newCartesetClients(
            id = client_id.toString(),
            date = date,
            positive = positive.toString(),
            negative = negative.toString(),
            date_updated = DatesManipulator.dateNow(),
            state = state.toString(),
            notes = notes?.let { prepareStrToJson(it) },
            sup = sup
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            val map = if (sup) supCartesetMap else cartesetMap
            map[data!!.id]?.let { ml ->
                ml.add(data)
            } ?: true.let { t ->
                map[data.id] = mutableListOf()
                map[data.id]!!.add(data)
            }

            _updatePrevCarteset(client_id, date, DatesManipulator.dateNow(), sup)
        }
        return Respone(data, dbQuery)
    }

    suspend fun newAllClientCarteset(
        date: String, carteset: List<ClientCarteset>, sup: Boolean = false
    ): Respone<List<ClientCarteset>, DBQuery> {
        val response = adapter.newAllCartesetClients(
            date = date,
            data = JsonArray(carteset.map { it.toJson() }).toString(),
            sup = sup

        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            val map = if (sup) supCartesetMap else cartesetMap
            data.forEach {
                map[it.id]?.let { ml ->
                    ml.add(it)
                } ?: true.let { t ->
                    map[it.id] = mutableListOf()
                    map[it.id]!!.add(it)
                }

            }


        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildClientEdiMap(date: String, ids: List<Int>?): Respone<List<EdiMember>, DBQuery> {
        val response = adapter.getEdiClients(
            date = date, ids = ids?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data.forEach {
                ediMap[it.client_id] = it
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newEdiClient(
        client_id: Int,
        date: String,
        edi_sup_id: String,
        edi_client_id: String,
        branch: String,
        internal_sup_edi: String,
        net_edi: String

    ): Respone<EdiMember?, DBQuery> {
        val response = adapter.newEdiClients(
            id = client_id.toString(),
            date = date,
            edi_client_id = edi_client_id.toString(),
            edi_sup_id = edi_sup_id.toString(),
            branch = branch.toString(),
            internal_sup_edi = internal_sup_edi,
            net_edi = net_edi
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            ediMap[data!!.client_id] = data
        }
        return Respone(data, dbQuery)
    }

    override fun getClientEdiMap(id: Int): Respone<EdiMember?, DBQuery> {
        return Respone(ediMap[id], ediMap[id]?.let { DBQuery.SUCCESS } ?: DBQuery.ID_ISSUE)
    }

    fun getClientEDI(edi: String): EdiMember? {
        return ediMap.values.firstOrNull { edi.contains(it.edi_client_id) }
    }

    override suspend fun deActivate(
        ent: String,
        deliveryNote: DeliveryNote,
        state: Int
    ): Respone<DeliveryNote?, DBQuery> {

        val response = if (ent.compareTo("client") == 0) adapter.updateClientNote(
            deliveryNote.delivery_id.toString(), active = "0"
        )
        else {
            adapter.updateSupplierNote(deliveryNote.delivery_id.toString(), active = "0")
        }
        if (state == 2 && ent.compareTo("client") == 0) {
            val products = deliveryNote.delivery_info.map { it.negative() }
            val newCancel = newClientDeliveryNote(
                deliveryNote.agent,
                ent_id = deliveryNote.ent_id,
                date = deliveryNote.date,
                date_issued = DatesManipulator.dateNow(),
                delivery_value = ProductDelivery.toJsonArrayString(products),
                active = 0
            )
        }
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            deliveryNote.active = 0
            if (ent.compareTo("client") == 0) {
                clientMonthlyCycleReCalculate(deliveryNote.ent_id, deliveryNote.date)
            } else {
                supplierMonthlyCycleReCalculate(deliveryNote.ent_id, deliveryNote.date)
            }
        }
        return Respone(response.first, dbQuery)
    }

    override suspend fun deActivate(client: Client): DBQuery {
        val response = adapter.updateClient(client.id.toString(), active = "0", date = client.date)
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            client.active = 0
        }
        return dbQuery
    }

    override suspend fun deActivate(ent: String, p: Product): DBQuery {
        val response =
            if (ent.compareTo("client") == 0) adapter.updateClientProduct(
                p.id.toString(),
                active = "0",
                date = p.date
            )
            else {
                adapter.updateSupplierProduct(p.id.toString(), active = "0", date = p.date)
            }

        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            p.active = 0
        }
        return dbQuery
    }

    override suspend fun deActivate(supplier: Supplier): DBQuery {
        val response = adapter.updateSupplier(supplier.id.toString(), active = "0", date = supplier.date)
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            supplier.active = 0
        }
        return dbQuery
    }

    override suspend fun activate(ent: String, deliveryNote: DeliveryNote): DBQuery {
        val response = if (ent.compareTo("client") == 0) adapter.updateClientNote(
            deliveryNote.delivery_id.toString(), active = "1"
        )
        else {
            adapter.updateSupplierNote(deliveryNote.delivery_id.toString(), active = "1")
        }
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            deliveryNote.active = 1
        }
        return dbQuery
    }

    override suspend fun activate(client: Client): DBQuery {
        val response = adapter.updateClient(client.id.toString(), active = "1", date = client.date)
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            client.active = 0
        }
        return dbQuery
    }

    override suspend fun activate(ent: String, p: Product): DBQuery {
        val response =
            if (ent.compareTo("client") == 0) adapter.updateClientProduct(
                p.id.toString(),
                active = "1",
                date = p.date
            )
            else {
                adapter.updateSupplierProduct(p.id.toString(), active = "1", date = p.date)
            }

        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            p.active = 1
        }
        return dbQuery
    }

    override suspend fun activate(supplier: Supplier): DBQuery {
        val response = adapter.updateSupplier(supplier.id.toString(), active = "1", date = supplier.date)
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            supplier.active = 0
        }
        return dbQuery
    }


    private fun _updateSuppliersMap(value: Supplier) {
        var existing = idToSupplier[value.id]
        if (existing == null) {
            existing = EntitySequence()
            idToSupplier[value.id] = existing
        }
        existing.add(value)
        if (!supplierIdToPrice.containsKey(value.id)) {
            supplierIdToPrice[value.id] = HashMap()
        }
    }

    private fun _updateSuppliersProductMap(value: Product) {
        var existing = idToSupplierProduct[value.id]
        if (existing == null) {
            existing = ProductSequence()
            idToSupplierProduct[value.id] = existing
        }
        existing.add(value)
    }

    private fun _updateSuppliersDebtMap(value: MonthlyCycle) {

        if (!supplierIdToMonthlyCycle.containsKey(value.id)) {
            supplierIdToMonthlyCycle[value.id] = mutableListOf()
        }
        val cur = supplierIdToMonthlyCycle[value.id]!!.indexOfFirst { it.id == value.id && it.date == value.date }
        if (cur == -1) supplierIdToMonthlyCycle[value.id]!!.add(value)
        else supplierIdToMonthlyCycle[value.id]!![cur] = value

    }

    private fun _updateSuppliersNotesMap(value: DeliveryNote) {
        if (!supplierIdToDeliveryNote.containsKey(value.ent_id)) {
            supplierIdToDeliveryNote[value.ent_id] = mutableListOf()
        }
        val exist = supplierIdToDeliveryNote[value.ent_id]!!.indexOfFirst { it.delivery_id == value.delivery_id }
        if (exist != -1) {
            supplierIdToDeliveryNote[value.ent_id]!![exist] = value
        } else {
            supplierIdToDeliveryNote[value.ent_id]!!.add(value)
        }
        _updateSuppliersDeliveryIdtoNotesMap(value)
    }


    private fun _updateSuppliersOrderNotesMap(value: OrderNote) {
        if (!supplierIdToOrderNote.containsKey(value.ent_id)) {
            supplierIdToOrderNote[value.ent_id] = mutableListOf()
        }
        val exist = supplierIdToOrderNote[value.ent_id]!!.indexOfFirst { it.order_id == value.order_id }
        if (exist != -1) {
            supplierIdToOrderNote[value.ent_id]!![exist] = value
        } else {
            supplierIdToOrderNote[value.ent_id]!!.add(value)
        }
    }

    private fun _updateSuppliersDeliveryIdtoNotesMap(value: DeliveryNote) {
        supplierDeliveryIdToDeliveryNote[value.delivery_id] = (value)
    }

    private fun _updateSuppliersMap(values: List<Supplier>) {
        for (value: Supplier in values) {
            _updateSuppliersMap(value)
        }
    }

    private fun _updateSuppliersProductMap(values: List<Product>) {
        for (value: Product in values) {
            _updateSuppliersProductMap(value)
        }
    }

    private fun _updateSuppliersNotesMap(values: List<DeliveryNote>) {
        for (value: DeliveryNote in values) {
            _updateSuppliersNotesMap(value)
        }

    }

    private fun _updateSuppliersOrderNotesMap(values: List<OrderNote>) {
        for (value: OrderNote in values) {
            _updateSuppliersOrderNotesMap(value)
        }

    }

    private fun _updateSuppliersDebtMap(values: List<MonthlyCycle>) {
        for (value: MonthlyCycle in values) {
            _updateSuppliersDebtMap(value)
        }

    }

    private fun _updateSupplierNoteForToday(id: Int, checkerDate: String) {
        val supplier = getSupplier(id)
        val today = DatesManipulator.dateNow().split(" ")[0]
        if (supplier.second == DBQuery.SUCCESS && today.compareTo(checkerDate) == 0) {
            todaySupplierDelivery.add(supplier.first!!.id)
        }
    }

    override suspend fun buildSuppliers(
        id: Int,
        fromDate: String,
        toDate: String
    ): Respone<List<Supplier>, DBQuery> {

        val response = adapter.getSuppliers(id = id.toString(), fromDate = fromDate, toDate = toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersMap(data)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildSuppliers(
        ids: List<Int>?, fromDate: String, toDate: String
    ): Respone<List<Supplier>, DBQuery> {

        val response = adapter.getSuppliers(ids = ids?.joinToString(","), fromDate = fromDate, toDate = toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersMap(data)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildSuppliers(
        active: Int?, fromDate: String, toDate: String
    ): Respone<List<Supplier>, DBQuery> {
        val response = adapter.getSuppliers(active = active?.toString(), fromDate = fromDate, toDate = toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersMap(data)
        }
        return Respone(data, dbQuery)
    }

    override fun getSupplier(ids: List<Int>): Respone<List<Supplier>, DBQuery> {
        val sups: MutableList<Supplier> = mutableListOf()
        ids.forEach { id ->
            if (id in idToSupplier) {
                sups.add(idToSupplier[id]!!.get() as Supplier)
            } else {
                return Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        return Respone(sups, DBQuery.SUCCESS)
    }

    override fun getSupplier(id: Int): Respone<Supplier?, DBQuery> {
        return if (id in idToSupplier) {
            Respone(idToSupplier[id]!!.get() as Supplier, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.ID_ISSUE)
        }
    }

    override fun getSupplier(name: String): Respone<Supplier?, DBQuery> {
        val Supplier = idToSupplier.values.filter { it.get().name.compareTo(name) == 0 }
        return if (Supplier.isNotEmpty()) {
            Respone(Supplier.first().get() as Supplier, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.NAME_ISSUE)
        }
    }

    override fun getAllSupplier(active: Boolean): Respone<List<Supplier>, DBQuery> {
        return if (idToSupplier.isEmpty()) {
            Respone(listOf(), DBQuery.SUCCESS)
        } else {
            Respone(idToSupplier.values.map { it.get() as Supplier }.toList().filter {
                if (active) {
                    it.getActive()
                } else {
                    true
                }
            }, DBQuery.SUCCESS)
        }

    }

    override suspend fun newSupplier(
        name: String,
        date: String,
        include_tax: Int?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: Int?,
        phone: String?,
        no_tax_client: Int?,
        external_id: String?,
        comments: String?,
        category: String?,
        payment_notes: String?,
        category2: String?,
        phone_contact: String?,
    ): Respone<Supplier?, DBQuery> {
        val response = adapter.newSupplier(
            name = name.replace("\"", "''").replace("\n", ""),
            date = date,
            include_tax = include_tax?.toString(),
            business_name = business_name?.replace("\"", "''")?.replace("\n", ""),
            business_id = business_id?.replace("\"", "''")?.replace("\n", ""),
            address = address?.replace("\"", "''")?.replace("\n", ""),
            print_state = print_state?.toString(),
            phone = phone?.replace("\n", ""),
            no_tax_client = no_tax_client?.toString(),
            external_id = external_id,
            comments = comments?.replace("\"", "''")?.replace("\n", ""),
            category = category?.replace("\"", "''")?.replace("\n", ""),
            payment_notes = payment_notes?.replace("\"", "''")?.replace("\n", ""),
            category2 = category2?.replace("\"", "''")?.replace("\n", ""),
            phone_contact = phone_contact?.replace("\n", "")


        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersMap(data!!)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun updateSupplier(
        id: Int,
        date: String,
        name: String?,
        include_tax: Int?,
        business_name: String?,
        business_id: String?,
        address: String?,
        print_state: Int?,
        position: Int?,
        active: Int?,
        phone: String?,
        no_tax_client: Int?,
        external_id: String?,
        comments: String?,
        category: String?,
        payment_notes: String?,
        category2: String?,
        phone_contact: String?
    ): Respone<Supplier?, DBQuery> {
        val supplier = getSupplier(id).first!!
        val response = adapter.updateSupplier(
            id = id.toString(),
            date = date,
            name = name?.replace("\"", "''")?.replace("\n", "") ?: supplier.getName(),
            include_tax = include_tax?.toString() ?: supplier.getIncludeTax().toString(),
            business_name = business_name?.replace("\"", "''")?.replace("\n", "") ?: supplier.getBusinessName(),
            business_id = business_id?.replace("\"", "''")?.replace("\n", "") ?: supplier.getBusinessId(),
            address = address?.replace("\"", "''")?.replace("\n", "") ?: supplier.getAddress(),
            print_state = print_state?.toString() ?: supplier.print_state.toString(),
            position = position?.toString() ?: supplier.position.toString(),
            active = active?.toString() ?: supplier.active.toString(),
            phone = phone?.replace("\n", "") ?: supplier.phone.toString(),
            no_tax_client = no_tax_client?.toString(),
            external_id = external_id,
            comments = comments?.replace("\"", "''")?.replace("\n", "") ?: supplier.comments.toString(),
            category = category?.replace("\"", "''")?.replace("\n", "") ?: supplier.category.toString(),
            payment_notes = payment_notes?.replace("\"", "''")?.replace("\n", "")
                ?: supplier.payment_notes.toString(),
            category2 = category2?.replace("\"", "''")?.replace("\n", "") ?: supplier.category2.toString(),
            phone_contact = phone_contact?.replace("\n", "") ?: supplier.phone_contact.toString()
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersMap(data)
        }
        return Respone(getSupplier(id).first, dbQuery)
    }

    override suspend fun buildSupplierProducts(
        active: Int?, fromDate: String, toDate: String, attach: Boolean?
    ): Respone<List<Product>, DBQuery> {
        val response = adapter.getSupplierProducts(active?.toString(), fromDate = fromDate, toDate = toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersProductMap(data)
            if (attach == true) {
                attachSupplierProductsToSuppliers()
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildSupplierProducts(
        id: Int, fromDate: String, toDate: String
    ): Respone<List<Product>, DBQuery> {
        val response = adapter.getSupplierProducts(id.toString(), fromDate = fromDate, toDate = toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersProductMap(data)
        }
        return Respone(data, dbQuery)
    }

    override fun getSupplierProduct(id: Int): Respone<Product?, DBQuery> {
        return if (id in idToSupplierProduct) {
            Respone(idToSupplierProduct[id]!!.get(), DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.ID_ISSUE)
        }
    }

    override fun getSupplierProduct(name: String): Respone<Product?, DBQuery> {
        val product = idToSupplierProduct.values.filter { it.get().name.compareTo(name) == 0 }
        return if (product.isNotEmpty()) {
            Respone(product.first().get(), DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.NAME_ISSUE)
        }
    }

    override fun getSupplierPrices(id: Int): HashMap<Int, Price> {
        return supplierIdToPrice[id]!!
    }

    override fun getAllSupplierProduct(active: Boolean): Respone<List<Product>, DBQuery> {
        return if (idToSupplierProduct.isEmpty()) {
            Respone(listOf(), DBQuery.SUCCESS)
        } else {
            Respone(idToSupplierProduct.values.map { it.get() }.toList().filter {
                if (active) {
                    it.active == 1
                } else {
                    true
                }
            }, DBQuery.SUCCESS
            )
        }
    }

    override suspend fun newSupplierProduct(
        name: String,
        date: String,
        barcode: String?,
        default_price: Float?,
        connected_product: Int?,
        no_tax_product: Int?,
        category: String?,
        external_id: String?
    ): Respone<Product?, DBQuery> {
        val response = adapter.newSupplierProduct(
            name = name?.let { prepareStrToJson(it) },
            date = date,
            barcode = barcode,
            default_price = default_price?.toString(),
            connected_product = connected_product?.toString(),
            no_tax_product = no_tax_product?.toString(),
            category = category,
            external_id = external_id
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersProductMap(data!!)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun updateSupplierProduct(
        id: Int,
        date: String,
        name: String?,
        barcode: String?,
        default_price: Float?,
        position: Int?,
        active: Int?,
        connected_product: Int?,
        no_tax_product: Int?,
        category: String?,
        external_id: String?
    ): Respone<Product?, DBQuery> {
        val product = getSupplierProduct(id).first!!
        val response = adapter.updateSupplierProduct(id = id.toString(),
            date = date,
            name = name?.let { prepareStrToJson(it) } ?: product.getName(),
            barcode = barcode ?: product.getBarcode(),
            default_price = default_price?.toString() ?: product.default_price?.toString(),
            position = position?.toString() ?: product.getPosition()?.toString(),
            active = active?.toString() ?: ((product.active)).toString(),
            connected_product = connected_product?.toString(),
            no_tax_product = no_tax_product?.toString(),
            category = category,
            external_id = external_id)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersProductMap(data)
        }
        return Respone(getSupplierProduct(id).first, dbQuery)
    }

    override fun attachSupplierProductsToSuppliers(ids: List<Int>?): DBQuery {
        //assuming already filled.
        val productsQuery = getAllSupplierProduct(false)
        val supplierQuery = ids?.let { getSupplier(it) } ?: getAllSupplier(false)
        if (!(productsQuery.second == DBQuery.SUCCESS && supplierQuery.second == DBQuery.SUCCESS)) {
            return DBQuery.FAILED
        }
        val products = productsQuery.first
        val suppliers = supplierQuery.first
        for (c: Supplier in suppliers) {
            for (p: Product in products) {
                c.fillProduct(p)
            }

        }
        return DBQuery.SUCCESS
    }

    override suspend fun buildSupplierPrices(
        ids: List<Int>?, product_ids: List<Int>?, fromDate: String, toDate: String
    ): Respone<List<RawPrice>, DBQuery> {
        val response = adapter.getSupplierPrices(
            ids = ids?.joinToString(","),
            product_ids = product_ids?.joinToString(","),
            fromDate = fromDate,
            toDate = toDate
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersPrice(data)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildSupplierPrices(
        id: Int, fromDate: String, toDate: String
    ): Respone<List<RawPrice>, DBQuery> {
        val response = adapter.getSupplierPrices(id.toString(), fromDate = fromDate, toDate = toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersPrice(data)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildSupplierPrices(
        id: Int?, product_id: Int?, fromDate: String, toDate: String
    ): Respone<List<RawPrice>, DBQuery> {
        val response = adapter.getSupplierPrices(id?.toString(), product_id?.toString(), fromDate, toDate)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersPrice(data)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildLatestSupplierPrices(id: Int?, product_id: Int?): Respone<List<RawPrice>, DBQuery> {
        val response = adapter.getLastSupplierPrices(id?.toString(), product_id?.toString())
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersPrice(data)
        }
        return Respone(data, dbQuery)
    }


    suspend fun updateAllSupplierProductPrice(
        date: String,
        prices: List<RawPrice>,
        toDate: String? = null,
        ids: List<Int>? = null,
        product_ids: List<Int>? = null,
        unique: Boolean = false
    ): DBQuery {


        val pricesWithMasters = prices.toMutableList()

        val applyToDate = if (unique && toDate != null) DatesManipulator.getNextDay(toDate) else null
        if (applyToDate != null) {
            val curPrices = buildSupplierPrices(
                ids = ids, product_ids = product_ids, fromDate = applyToDate, toDate = applyToDate
            )
            if (curPrices.second != DBQuery.SUCCESS) {
                return DBQuery.FAILED
            }
        }



        prices.forEach {
            if (applyToDate != null) {
                val client = getSupplier(it.id).first!!
                val price = client.getPrice(it.product_id)?.get(applyToDate)
                pricesWithMasters.add(
                    it.copy(
                        date = applyToDate,
                        price = price?.first ?: it.price,
                        discount = price?.second ?: it.discount
                    )
                )

            }
        }
        val response = adapter.updateSupplierAllPrices(
            date = date,
            data = "[${pricesWithMasters.map { it.toJson().toString() }.joinToString(",")}]",
            toDate = toDate
        )

        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            toDate?.let {
                ids?.forEach {
                    product_ids?.forEach { p_id -> clientIdToPrice[it]?.remove(p_id) }
                }
            }
            _updateSuppliersPrice(dataR)
//            dataR.forEach { clientMonthlyCycleReCalculate(date = it.date, id = it.id) }
            toDate?.let {
                val curDate = DatesManipulator.dateNowNoHour()
                if (curDate > toDate || curDate < date) {
                    val curPrices =
                        buildSupplierPrices(
                            ids = ids,
                            product_ids = product_ids,
                            fromDate = curDate,
                            toDate = curDate
                        )
                }
            }
        }
        return dbQuery
    }

    override suspend fun updateAllSuppliersProductPrice(
        ids: List<Int>?,
        product_id: List<Int>,
        date: String,
        price: Float?,
        default_price: Float?,
        percent: Float?,
        tax_mutate: Boolean,
        percent_price: Float?,
        toDate: String?,
        byPrice: Float?,
        unique: Boolean?
    ): DBQuery {
        //price is with no maam
        val curIds = ids ?: getAllSupplier(true).first.map { it.id }
        val applyToDate =
            if (unique != null && unique && toDate != null) DatesManipulator.getNextDay(toDate) else null
        val curPrices = buildSupplierPrices(
            ids = curIds, product_ids = product_id, fromDate = date, toDate = applyToDate ?: toDate ?: date
        )

        if (curPrices.second != DBQuery.SUCCESS) {
            return DBQuery.FAILED
        }


        val data: MutableList<RawPrice> = mutableListOf()
        product_id.forEach { p_id ->
            val p = getSupplierProduct(p_id).first!!
            val x = curIds.map {
                val c = getSupplier(it).first!!
                var updatedPrice = ((price?.let {
                    if (tax_mutate && c.getIncludeTax(date) == 1 && p.getNoTaxProduct(date) != 1) {
                        tax.get(toDate ?: date) * price
                    } else {
                        price
                    }
                }) ?: c.getPrice(p_id)!!.get(date).first * (percent_price?.let { 1 + it / 100 } ?: 1f))
                if (updatedPrice > 0) {
                    updatedPrice = max(
                        0f, ((byPrice?.times(
                            if (tax_mutate && c.getIncludeTax(date) == 1 && p.getNoTaxProduct(date) != 1) {
                                tax.get(toDate ?: date)
                            } else {
                                1f
                            }
                        ) ?: 0f) + updatedPrice)
                    )
                }

                val finalPrice = roundToDecimals(updatedPrice, 2)
                val discount = percent ?: c.getPrice(p_id)!!.get(date).second
                val changer = mutableListOf(RawPrice(it, p_id, finalPrice, date, discount))
                if (applyToDate != null) {
                    val priceX = c.getPrice(p_id)!!.get(applyToDate)
                    changer.add(RawPrice(it, p_id, priceX.first, applyToDate, priceX.second))
                }
                changer
            }.flatten()
            data.addAll(x)
        }

        return updateAllSupplierProductPrice(date, data, toDate, curIds, product_id)
    }

    override suspend fun newSupplierPrice(
        id: Int, product_id: Int, price: Float, date: String?, discount: Float, toDate: String?
    ): Respone<RawPrice?, DBQuery> {
        val response = if (toDate != null) {
            val next = DatesManipulator.getNextDay(toDate)

            val cur_prices = buildSupplierPrices(id, product_id, fromDate = date!!, toDate = toDate)
            val nextPrice = buildSupplierPrices(id, product_id, fromDate = next, toDate = next)


            if (nextPrice.second != DBQuery.SUCCESS || cur_prices.second != DBQuery.SUCCESS) {
                return Respone(null, DBQuery.FAILED)
            }

            if (((nextPrice.first.isEmpty() && getSupplierProduct(product_id).first!!.default_price > 0) || (nextPrice.first.isNotEmpty() && nextPrice.first.first().price > 0 && nextPrice.first.first().date < next))) {
                val nextPrice = if (nextPrice.first.isEmpty()) Pair(
                    getSupplierProduct(product_id).first!!.default_price, 0f
                ) else Pair(nextPrice.first.first().price, nextPrice.first.first().discount)
                val ret = adapter.newSupplierPrice(
                    RawPrice.newPriceDate(id, product_id, nextPrice.first, next, nextPrice.second)
                )
                val dbQuery = analyzeResponse(ret.second, new = true)
                if (dbQuery != DBQuery.SUCCESS) {
                    return Respone(null, dbQuery)
                }
            }
            val ret = adapter.newSupplierPrice(
                RawPrice.newPriceDate(id, product_id, price, date, discount)
            )
            val dbQuery = analyzeResponse(ret.second, new = true)
            if (dbQuery != DBQuery.SUCCESS) {
                return Respone(null, dbQuery)
            }

            cur_prices.first.filter { it.date > date }.forEach {
                val ret = adapter.newSupplierPrice(
                    RawPrice.newPriceDate(id, product_id, price, it.date, discount)
                )
                val dbQuery = analyzeResponse(ret.second, new = true)
                if (dbQuery != DBQuery.SUCCESS) {
                    return Respone(null, dbQuery)
                }
            }

            ret
        } else {
            adapter.newSupplierPrice(
                RawPrice.newPriceDate(id, product_id, price, date, discount)
            )
        }

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersPrice(data!!)
            id.let { supplierMonthlyCycleReCalculate(date = data.date, id = id) }
        }
        return Respone(data, dbQuery)
    }

    suspend fun buildFullSupplierNotes(notes: List<DeliveryNote>): DBQuery {
        if (notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        val dates = notes.map { it.date }.distinct()

        val d = DatesHolder(dates)
        val first_date = d.getFirst()
        val last_date = d.getLast()
        val ids = notes.map { it.ent_id }.distinct()
        var q = fetchSpecificSupplierData(ids, fromDate = first_date, toDate = last_date)
        if (q != DBQuery.SUCCESS) {
            return q
        }
        q = calcDeliveryNoteSumMap(notes, ent_type = "supplier").second
        return q
    }

    override suspend fun buildSupplierNotes(
        supplier_id: Int?, datesHolder: DatesHolder, full: Boolean
    ): Respone<List<DeliveryNote>, DBQuery> {
        val notes: MutableList<DeliveryNote> = mutableListOf()
        for (d in datesHolder.ranges) {
            val response = buildSupplierNotes(
                supplier_id, null, d.start_date, (d.end_date)
            )
            val data = response.first
            val dbQuery = response.second
            if (dbQuery == DBQuery.SUCCESS) {
                notes.addAll(data)
            } else {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        val retNotes = notes.distinctBy { it.delivery_id }
        _updateSuppliersNotesMap(retNotes)
        if (full && buildFullSupplierNotes(retNotes) != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }

        return Respone(retNotes, DBQuery.SUCCESS)
    }

    override suspend fun buildSupplierNotes(
        supplier_id: Int?,
        delivery_id: Int?,
        fromDate: String?,
        toDate: String?,
        full: Boolean,
        delivery_ids: List<Int>?,
        open: Boolean?
    ): Respone<List<DeliveryNote>, DBQuery> {
        val response = adapter.getSupplierNotes(
            supplier_id?.toString(),
            delivery_id?.toString(),
            fromDate,
            toDate,
            delivery_ids = delivery_ids?.joinToString(","),
            open = open?.let { if (it) "1" else "0" }
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersNotesMap(data)
            if (full && buildFullSupplierNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun buildSupplierLastNotes(
        supplier_id: Int,
        full: Boolean
    ): Respone<List<DeliveryNote>, DBQuery> {
        val response = adapter.getSupplierLastNotes(
            supplier_id?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersNotesMap(data)
            if (full && buildFullSupplierNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        return Respone(data, dbQuery)
    }

    override fun getAllSupplierDeliveryNotes(): Respone<Map<Int, List<DeliveryNote>>, DBQuery> {
        return Respone(supplierIdToDeliveryNote.toMap(), DBQuery.SUCCESS)
    }

    override fun getSupplierDeliveryNotes(
        fromDate: String, toDate: String, active: Boolean
    ): Respone<List<DeliveryNote>, DBQuery> {
        return Respone(supplierDeliveryIdToDeliveryNote.entries.mapNotNull {
            if ((!active || it.value.isActive()) && (it.value.date in fromDate..toDate)) {
                it.value
            } else null
        }, DBQuery.SUCCESS)
    }

    override fun getSupplierDeliveryNotes(
        delivery_id: Int?, supplier_id: Int?, delivery_ids: List<Int>?
    ): Respone<List<DeliveryNote>, DBQuery> {
        delivery_id?.let {
            return if (it in supplierDeliveryIdToDeliveryNote) {
                return Respone(listOf(supplierDeliveryIdToDeliveryNote[it]!!), DBQuery.SUCCESS)
            } else {
                Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        delivery_ids?.let {
            val m = it.map {
                if (it !in supplierDeliveryIdToDeliveryNote) {
                    return Respone(listOf(), DBQuery.ID_ISSUE)
                }
                supplierDeliveryIdToDeliveryNote[it]!!
            }
            return Respone(m, DBQuery.SUCCESS)

        }
        supplier_id?.let {
            return if (it in supplierIdToDeliveryNote) {
                return Respone(supplierIdToDeliveryNote[it]!!, DBQuery.SUCCESS)
            } else {
                Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        return Respone(supplierIdToDeliveryNote.values.flatten(), DBQuery.SUCCESS)
    }

    override suspend fun newSupplierDeliveryNote(
        agent: String, ent_id: Int, date: String, date_issued: String, delivery_value: String, connected_id: Long?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
    ): Respone<DeliveryNote?, DBQuery> {
        val response = adapter.newSupplierNote(
            agent, ent_id.toString(), date, date_issued, (delivery_value), connected_id = connected_id?.toString(),
            notes = notes,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersNotesMap(listOf(data!!))
            _updateSupplierNoteForToday(data.ent_id, data.date)
            supplierMonthlyCycleReCalculate(date = data.date, id = data.ent_id)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newSupplierDeliveryNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        connected_id: Long?,
        with_price: Boolean,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,

        ): Respone<List<DeliveryNote>, DBQuery> {
        if (with_price) {
            val q = changePriceFromProductDelivery(delivery_value, ent_id, date, "supplier")
            if (q != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }
        val p = newSupplierDeliveryNote(
            agent = agent,
            ent_id = ent_id,
            date = date,
            date_issued = date_issued,
            delivery_value = ProductDelivery.toJsonArrayString(delivery_value),
            connected_id = connected_id,
            notes = notes,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5


        )
        if (p.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        return Respone(listOf(p.first!!), p.second)
    }


    override suspend fun updateSupplierDeliveryNote(
        delivery_id: Int,
        paid: Int?,
        active: Int?,
        connected_id: Long?,
        delivery_value: List<ProductDelivery>?,
        notes: String?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
    ): Respone<DeliveryNote?, DBQuery> {

        if (delivery_value != null) {
            val currentNote = getSupplierDeliveryNotes(delivery_ids = listOf(delivery_id)).first.first()
            val priceUpdateList: MutableList<ProductDelivery> = mutableListOf()
            val client = currentNote.getEnt(false) as Supplier
            delivery_value.forEach {
                if (client.getPrice(it.productId)?.get(currentNote.date)?.first != it.price) {
                    priceUpdateList.add(it)
                }
            }
            var ent_to_change_id = client.id
            if (priceUpdateList.isNotEmpty()) {
                val q =
                    changePriceFromProductDelivery(priceUpdateList, ent_to_change_id, currentNote.date, "supplier")
                if (q != DBQuery.SUCCESS) {
                    return Respone(null, DBQuery.FAILED)
                }
            }
        }
        val response = adapter.updateSupplierNote(
            delivery_id = delivery_id.toString(),
            paid = paid?.toString(),
            active = active?.toString(),
            connected_id = connected_id?.toString(),
            delivery_value = delivery_value?.let { ProductDelivery.toJsonArrayString(delivery_value) },
            notes = notes,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersNotesMap(listOf(data!!))

            if (delivery_value != null)
                supplierMonthlyCycleReCalculate(date = data.date, id = data.ent_id)
            if (buildFullSupplierNotes(listOf(data)) != DBQuery.SUCCESS) {
                return Respone(data, DBQuery.SUCCESS)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun closeSupplierDeliveryNote(
        delivery_ids: List<Int>
    ): Respone<List<DeliveryNote>, DBQuery> {
        var lst: MutableList<DeliveryNote> = mutableListOf()
        delivery_ids.forEach {
            val q = updateSupplierDeliveryNote(it, 1)
            if (q.second != DBQuery.SUCCESS) {
                return Respone(listOf(), q.second)
            }
            lst.add(q.first!!)
        }

        return Respone(lst, DBQuery.SUCCESS)
    }

    override suspend fun supplierMonthlyCycleReCalculate(id: Int, date: String): DBQuery {
        GlobalScope.async {
            try {
                val min_date = DatesManipulator.minDay(date)
                val max_date = DatesManipulator.maxDay(date)
                val q = buildSupplierNotes(supplier_id = id, fromDate = min_date, toDate = max_date, full = true)
                val m = buildSupplierMonthlyCycle(id = id, date = min_date)
                if (q.second == DBQuery.SUCCESS && m.second == DBQuery.SUCCESS) {
                    val notes = q.first.filter { it.isActive() }
                    val c = calcDeliveryNoteSumMap(notes, ent_type = "supplier")
                    if (c.second == DBQuery.SUCCESS) {
                        val date_paid = m.first.firstOrNull()?.date_paid ?: "$min_date 00:00:00"
                        setSupplierMonthlyCycle(
                            min_date,
                            id,
                            c.first[-1]!!.totalValue,
                            date_updated = DatesManipulator.dateNow(),
                            date_paid = date_paid
                        )
                    }

                }
            } catch (e: Exception) {

            }
        }
        return DBQuery.SUCCESS
    }

    suspend fun buildFullSupplierMonthlyCycle(monthlyCycles: List<MonthlyCycle>): DBQuery {
        if (monthlyCycles.isEmpty()) return DBQuery.SUCCESS
        val dates = monthlyCycles.map { it.date }.distinct()

        val d = DatesHolder(dates)
        val first_date = d.getFirst()
        val last_date = DatesManipulator.maxDay(d.getLast())
        val ids = monthlyCycles.map { it.id }.distinct()
        val b = fetchSpecificSupplierData(ids, fromDate = first_date, toDate = last_date)
        if (b != DBQuery.SUCCESS) {
            return b

        }
        return DBQuery.SUCCESS
    }

    override suspend fun buildSupplierMonthlyCyclePdf(
        sup_name: String, month: String, year: String
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.getSuppliersMonthlyCyclePdf(
            sup_name, month, year
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildSupplierMonthlyCycle(
        date: String?, id: Int?, full: Boolean
    ): Respone<List<MonthlyCycle>, DBQuery> {
        val response = adapter.getSuppliersMonthlyCycle(
            date, id?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersDebtMap(data)
            if (full && buildFullSupplierMonthlyCycle(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
        }

        return Respone(data, dbQuery)
    }

    override fun getSupplierMonthlyCycle(): Respone<Map<Int, List<MonthlyCycle>>, DBQuery> {
        return if (supplierIdToMonthlyCycle.isEmpty()) Respone(
            supplierIdToMonthlyCycle.toMap(), DBQuery.SUCCESS
        ) else Respone(
            supplierIdToMonthlyCycle.toMap(), DBQuery.SUCCESS
        )
    }

    override fun getSupplierMonthlyCycle(
        date: String?, id: Int?
    ): Respone<List<MonthlyCycle>, DBQuery> {
        var ret: Respone<List<MonthlyCycle>, DBQuery> =
            Respone(supplierIdToMonthlyCycle.values.flatten(), DBQuery.SUCCESS)
        id?.let {
            if (it in supplierIdToMonthlyCycle) {
                ret = Respone(supplierIdToMonthlyCycle[it]!!, DBQuery.SUCCESS)
            } else {
                return Respone(listOf(), DBQuery.ID_ISSUE)
            }
        }
        date?.let {
            var lst = ret.first
            lst = lst.filter { it.date.compareTo(date) == 0 }
            ret = Respone(lst, DBQuery.SUCCESS)
        }
        return ret
    }

    override suspend fun setSupplierMonthlyCycle(
        date: String, id: Int, value: Float, paid: Float?, date_paid: String?, date_updated: String?
    ): Respone<MonthlyCycle?, DBQuery> {
        val response = adapter.newSuppliersMonthlyCycle(
            date, id.toString(), value.toString(), paid?.toString(), date_paid, date_updated
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSuppliersDebtMap(data!!)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun dataToAccountManager(date_range: String): Respone<ByteArray?, DBQuery> {
        val response = adapter.dataToAccountManager(
            date_range
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcDocumentSum(
        doc_type: Int,
        date_range: String,
        pdf: Boolean,
        open_only: Boolean?,
        active_only: Boolean?,
        ent_id: List<Int>?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcDocumentsSum(
            doc_type.toString(),
            date_range,
            if (pdf) "1" else null,
            open_only = open_only?.let { if (open_only == true) "1" else "0" },
            active_only = active_only?.let { if (active_only == true) "1" else "0" },
            ent_id = ent_id?.joinToString(","),
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcSupplierDeliveryNotePdf(
        sup_id: Int, date_range: String, specific_notes: String?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcSupplierDeliveryNotePdf(
            sup_id.toString(), date_range, specific_notes
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun buildAgents(): Respone<List<Agent>, DBQuery> {
        val response = adapter.getAgents(
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            agents = data
        }

        return Respone(data, dbQuery)
    }

    override fun getAgents(hasLine: Boolean): Respone<List<Agent>, DBQuery> {
        if (agents.isEmpty()) return Respone(listOf(), DBQuery.SUCCESS)
        return Respone(agents.filter { if (hasLine) it.isAgent() else true }, DBQuery.SUCCESS)
    }

    @myName("getCollectors")
    fun getCollectors(): Respone<List<Agent>, DBQuery> {
        val col = agents.filter { it.isCollector() }
        if (col.isEmpty()) return Respone(listOf(), DBQuery.SUCCESS)
        return Respone(col, DBQuery.SUCCESS)
    }

    @myName("getAgentBy")
    fun getAgentBy(
        id: Int?,
        name: String?,
        collector: Boolean = false,
        driver: Boolean = false,
        agent: Boolean = false,
        force: Boolean = false
    ): Respone<Agent?, DBQuery> {
        if (agents.isEmpty()) return Respone(null, DBQuery.ID_ISSUE)
        return Respone(
            agents.firstOrNull { (it.user_name == name || it.id == id) && (force || (it.isCollector() == collector && it.isAgent() == agent && it.isDriver() == driver)) },
            DBQuery.SUCCESS
        )
    }

    override suspend fun buildUserInfo(id: String?, company: String?, fromDate: String, toDate: String): DBQuery {
        val response = adapter.getCompanyInfo(
            id, company, fromDate = fromDate, toDate = toDate
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            user = data
        }
        return dbQuery
    }

    override suspend fun buildCofcUserConf(id: Int?, cofc: Boolean): DBQuery {
        val response = adapter.getCofcConf(
            id?.toString(), cofc = cofc
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            userCofcConf = data
            saveUserCofc()
        }
        return dbQuery
    }

    override fun getUser(): Respone<User?, DBQuery> {
        if (user == null) return Respone(null, DBQuery.FAILED)
        return Respone(user, DBQuery.SUCCESS)
    }

    fun getUserOrderCofc(): Respone<CofcConf?, DBQuery> {
        if (userCofcConf == null) return Respone(null, DBQuery.FAILED)
        return Respone(userCofcConf, DBQuery.SUCCESS)
    }

    suspend fun newAgent(
        user_name: String, password: String? = null, type: Int, id: Int? = null
    ): Respone<Agent?, DBQuery> {
        val response = adapter.newAgent(
            id = id?.toString(),
            user_name = user_name.let { prepareStrToJson(it) }.trim(),
            password = password,
            type = type.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            val a = agents.toMutableList()
            a.add(data!!)
            agents = a
        }
        return Respone(data, dbQuery)
    }

    override suspend fun downloadApp(): Respone<ByteArray?, DBQuery> {
        val response = adapter.downloadApp()
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcClientDebtXSL(
        date_range: String,
        agent: Int,
        noZero: Int?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcClientDebtXsl(
            date_range, agent.toString(), noZero = noZero?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    fun clearDocumentsFromMap() {
        clientIdToTaxNote.clear()
        clientIdToTaxPayNote.clear()
        clientIdToTaxCancel.clear()
        clientIdToClientPay.clear()
        clientIdToDeliveryNote.clear()
        supplierDeliveryIdToDeliveryNote.clear()
        supplierIdToDeliveryNote.clear()
        clientDeliveryIdToDeliveryNote.clear()
        clientOrderIdToDeliveryNote.clear()

    }

    override suspend fun calcClientDebtTillNow(
        fromDate: String, toDate: String, client_id: Int?, withDebtYearly: Boolean
    ): DBQuery {
        val client_ids = client_id?.let {
            clientDebtWall.remove(it)
            val q = fetchSpecificClientData(listOf(it))
            if (q != DBQuery.SUCCESS) {
                return DBQuery.FAILED
            }
            val c = getClient(it).first!!
            if (c.hasBranch()) {
                val q = buildClients(branch = c.branch, fromDate = fromDate, toDate = toDate)
                if (q.second != DBQuery.SUCCESS) {
                    return DBQuery.FAILED
                }
                q.first.map {
                    clientDebtWall.remove(it.id)
                    it.id
                }
            } else {
                listOf(c.id)
            }

        }

        var q = buildClientLightTaxNote(fromDate = fromDate, toDate = toDate, client_ids = client_ids)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        var p = buildClientLightPay(fromDate = fromDate, toDate = toDate, client_ids = client_ids)
        if (p.second != DBQuery.SUCCESS) {
            return p.second
        }
        var c = buildClientCartesetMap(fromDate = fromDate, toDate = toDate, ids = client_ids)
        if (c.second != DBQuery.SUCCESS) {
            return c.second
        }

        val taxNotes = q.first.groupBy {
            val c = getClient(it.client_id).first!!
            if (c.hasBranch()) {
                Pair(c.branch, true)
            } else {
                Pair(c.id, false)
            }
        }
        val payNotes = p.first.groupBy {
            val c = getClient(it.client_id).first!!
            if (c.hasBranch()) {
                Pair(c.branch, true)
            } else {
                Pair(c.id, false)
            }

        }
        val first_year = DatesManipulator.minDayOfYear(fromDate)
        val end_year = DatesManipulator.maxDayOfYear(toDate)
        val continues =
            c.first.filter { it.isContinuesYear() && it.date == fromDate }.sortedBy { it.date }
        val regular = c.first.filter { !it.isContinuesYear() }.toMutableList()
        continues.let { regular.addAll(continues) }
        val cartesetDebts = regular
//            if (!withDebtYearly && (DatesManipulator.getYear(first_year) != DatesManipulator.getYear(end_year) || first_year != fromDate || end_year != toDate)) {
//                c.first.filter { !it.isContinuesYear() }
//            } else {
//                val continues = c.first.filter { it.isContinuesYear() && it.date==fromDate }.sortedBy { it.date }.firstOrNull()
//                val regular = c.first.filter { !it.isContinuesYear() }.toMutableList()
//                continues?.let { regular.add(continues) }
//                regular
//
//            }
        val debts = cartesetDebts.groupBy {
            val c = getClient(it.id).first!!
            if (c.hasBranch()) {
                Pair(c.branch, true)
            } else {
                Pair(c.id, false)
            }
        }

        client_ids?.let {
            val c = getClient(it).first.first()
            val id = if (c.hasBranch()) Pair(c.branch, true) else Pair(c.id, false)
            buildClientDebtWall(
                client_id = c.masterBranch?.id ?: c.id,
                taxs = taxNotes[id],
                pays = payNotes[id],
                debt = debts[id],
                default_date = toDate
            )
        } ?: let {
            getAllClient(false).first.groupBy {
                val c = getClient(it.id).first!!
                if (c.hasBranch()) {
                    Pair(c.branch, true)
                } else {
                    Pair(c.id, false)
                }
            }.forEach {
                if (it.key.second) {
                    val clients = getBranchMembers((it.key.first), false).first.first()
                    buildClientDebtWall(
                        client_id = clients.masterBranch?.id ?: clients.id,
                        taxs = taxNotes[it.key],
                        pays = payNotes[it.key],
                        debt = debts[it.key],
                        default_date = toDate
                    )
                } else {
                    buildClientDebtWall(
                        client_id = it.key.first,
                        taxs = taxNotes[it.key],
                        pays = payNotes[it.key],
                        debt = debts[it.key],
                        default_date = toDate
                    )
                }

            }

        }
        return DBQuery.SUCCESS
    }

    override suspend fun calcOrdersAmountData(
        fromDate: String, toDate: String, sn: StateNotifier?, inventory: Boolean, withAgent: Boolean, id: Int?
    ): Respone<Pair<NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery> {
        val curMinD = DatesManipulator.minDay(DatesManipulator.dateNowNoHour())
        val receivedMinFrom = DatesManipulator.minDay(fromDate)
        val receivedMinTo = DatesManipulator.minDay(toDate)
        if (!(receivedMinFrom == curMinD && receivedMinTo == curMinD)) {
            val r =
                id?.let { fetchSpecificClientData(listOf(it), fromDate, toDate) } ?: if (withAgent) buildClients(
                    fromDate = fromDate, toDate = toDate
                ).second else fetchClients(fromDate, toDate)
            val p = fetchSuppliers(fromDate, toDate)
            if (r != DBQuery.SUCCESS || p != DBQuery.SUCCESS) {
                return Respone(Pair(null, null), DBQuery.FAILED)
            }
        }
        sn?.advance()


        val q2 = buildClientOrderNotes(fromDate = fromDate, toDate = toDate)
        if (q2.second != DBQuery.SUCCESS) {
            return Respone(Pair(null, null), DBQuery.FAILED)
        }

        val orders =
            if (inventory) q2.first.filter { it.isActive() && it.ent_id == -1 } else q2.first.filter { it.isActive() && it.ent_id != -1 }
        sn?.advance()
        val amount = NotesAmountsQuery(withAgent = withAgent)
        amount.add(orders)
        val supAmount = NotesAmountsQuery(false)
        supAmount.add(orders, true)
        sn?.advance()
        amount.buildInverseMap()
        supAmount.buildInverseMap()
        return Respone(Pair(amount, supAmount), DBQuery.SUCCESS)
    }

    suspend fun queryData(
        fromDate: String,
        toDate: String,
        byProduct: List<Int>? = null,
        byEnt: List<Int>? = null,
        byDay: Int? = null, // day of week
        byWeek: Boolean? = null, //by weeks
        byMonth: Int? = null,
        byYear: Int? = null,
        byAgent: List<String>? = null,
        byStd: Boolean? = null,
        byMoney: Boolean? = null,
        doc_type: Int? = 4,
        cofc: Int? = null,
        byDate: Boolean? = null,
        withDaily: Boolean = false,
        byCost: Boolean? = null,
        paid: Boolean? = null,
        filter_date: String? = null,
        inventory: Boolean = false,
        byDocId: Int? = null,
        byAgentId: List<Int>? = null,
        byCostTax: Boolean? = null

    ): Respone<List<DataQueryHolder?>?, DBQuery> {
        var holdersFromBad: MutableList<DataQueryHolder> = mutableListOf()
        var full = true
        if (byMoney != null && false) {
            full = false
            //bad only when we need money, else we dont need it
            val bad = adapter.getClientOptimziedBADDV(
                fromDate = fromDate,
                toDate = toDate,
                byEnt = byEnt?.joinToString(","),
                byAgent = byAgent?.joinToString(","),

                )
            val dbBadQuery = analyzeResponse(bad.second)
            if (dbBadQuery != DBQuery.SUCCESS) {
                return Respone(null, dbBadQuery)
            }
            //do work on bad
//            val fetchCurClients = if (user?.daily_prices == 1) {
//                val dn = DatesManipulator.dateNowNoHour()
//                !(fromDate == dn && toDate == dn)
//            } else {
//                val curMinD = DatesManipulator.minDay(DatesManipulator.dateNowNoHour())
//                val receivedMinFrom = DatesManipulator.minDay(fromDate)
//                val receivedMinTo = DatesManipulator.minDay(toDate)
//                !(receivedMinFrom == curMinD && receivedMinTo == curMinD)
//            }
            val fetchCurClients = bad.first.isNotEmpty()
            if (fetchCurClients) {
                val r = fetchClients(fromDate, toDate)
                if (r != DBQuery.SUCCESS) {
                    return Respone(null, r)
                }
            }
            holdersFromBad = DataQueryHolder.fromDVS(bad.first, true)

        }
        if (withDaily) {
            val cq = buildClients(ids = byEnt, fromDate = filter_date ?: toDate, toDate = filter_date ?: toDate)
            if (cq.second != DBQuery.SUCCESS) {
                return Respone(null, cq.second)
            }
            val q = buildClientsDailyData(
                ids = byEnt?.let { if (it.size == 1 && it.first() == -1) null else it },
                date = toDate,
                dailyFinder = true
            )
            if (q.second != DBQuery.SUCCESS) {
                return Respone(null, q.second)
            }

        }
        if ((doc_type == 4 && paid == null) || doc_type == 0) {

            val p = buildClientTaxNote(
                client_ids = byEnt?.let { if (it.size == 1 && it.first() == -1) null else it },
                fromDate = fromDate,
                toDate = toDate,
                withProduct = 1,
                agents = byAgent,
                full = full

            )
            val costs = buildClientCosts(fromDate, toDate, listOf(-1, -2))
            if (p.second != DBQuery.SUCCESS || costs.second != DBQuery.SUCCESS) {
                return Respone(null, p.second)
            }
            val taxNotes = p.first.filter { it.isActive() }
            holdersFromBad.addAll(DataQueryHolder.fromTN(taxNotes, byDate = byDate, byCostTax = byCostTax))
        }
        if (getUser().first?.visit_debt == 1 && doc_type == 4) {
            val visit = buildClientVisitNote(
                fromDate,
                toDate,
                ent_ids = byEnt?.let { if (it.size == 1 && it.first() == -1) null else it },
                has_order = true
            ).first.filter { it.isActive() && it.order_id != null && it.order_id != -1 }.map { it.order_id!! }
            val orders = buildClientOrderNotes(order_ids = visit, full = true).first.let {
                DataQueryHolder.fromDVS(it, true)
            }
            holdersFromBad.addAll(orders)

        }
        if (doc_type != 0) {
            // bringing good
            val datesChunkes = DatesManipulator.getDatesChunkes(fromDate, toDate, 4)
            if (datesChunkes.size > 3 || getUser().first?.company_type == 4) {

                val calls: MutableList<suspend () -> Respone<List<DataQueryHolder>, NetworkOutput>> =
                    mutableListOf()
                datesChunkes.forEach {

                    calls.add(suspend {

                        adapter.getClientOptimziedNotesFinal(
                            fromDate = it.first,
                            toDate = it.second,
                            byProduct = byProduct?.joinToString(","),
                            byEnt = byEnt?.joinToString(","),
                            byDay = byDay?.toString(),
                            byWeek = byWeek?.toString(),
                            byMonth = byMonth?.toString(),
                            byYear = byYear?.toString(),
                            byAgent = byAgent?.joinToString(","),
                            byStd = byStd?.toString(),
                            byMoney = byMoney?.toString(),
                            doc_type = doc_type?.toString(),
                            cofc = cofc?.toString(),
                            byCost = byCost?.toString(),
                            byDate = byDate?.toString(),
                            paid = paid?.let { if (paid) "1" else "0" },
                            inventory = inventory,
                            byDocId = byDocId?.toString(),
                            byAgentId = byAgentId?.joinToString(","),
                            byCostTax = byCostTax?.toString()
                        )
                    })

                }
                val executor = ParallelExecutor().getInfo(calls, { respone ->
                    analyzeResponse(respone.second) == DBQuery.SUCCESS
                }, { index, respone ->
                    holdersFromBad.addAll(respone.first)
                })
                if (!executor) return Respone(null, DBQuery.FAILED)
            } else {
                val r = adapter.getClientOptimziedNotesFinal(
                    fromDate = fromDate,
                    toDate = toDate,
                    byProduct = byProduct?.joinToString(","),
                    byEnt = byEnt?.joinToString(","),
                    byDay = byDay?.toString(),
                    byWeek = byWeek?.toString(),
                    byMonth = byMonth?.toString(),
                    byYear = byYear?.toString(),
                    byAgent = byAgent?.joinToString(","),
                    byStd = byStd?.toString(),
                    byMoney = byMoney?.toString(),
                    doc_type = doc_type?.toString(),
                    cofc = cofc?.toString(),
                    byCost = byCost?.toString(),
                    byDate = byDate?.toString(),
                    paid = paid?.let { if (paid) "1" else "0" },
                    inventory = inventory,
                    byDocId = byDocId?.toString(),
                    byAgentId = byAgentId?.joinToString(","),
                    byCostTax = byCostTax?.toString()
                )

                val dbQuery = analyzeResponse(r.second)
                if (dbQuery != DBQuery.SUCCESS) {
                    return Respone(null, dbQuery)
                }
                holdersFromBad.addAll(r.first)
            }


        }


        //merging

        val g = DataQueryHolder.toGroupAggregate(
            holdersFromBad,
            byYear != null,
            byMonth != null,
            byWeek != null,
            byDay != null,
            byEnt != null,
            byProduct != null,
            byAgent != null,
            byAgentId = byAgentId != null,
        )
        return Respone(g, DBQuery.SUCCESS)
    }

    suspend fun getFlatProducts(
        ids: List<Int>? = null, products: List<Int>? = null, fromDate: String, toDate: String
    ): Respone<List<ProductDeliveryFlat>, DBQuery> {
        val response = adapter.getOptimizeFlatNotes(
            byEnt = ids?.joinToString(","), products?.joinToString(","), fromDate = fromDate, toDate = toDate
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)

        return Respone(data, dbQuery)

    }

    suspend fun getClientCompare(
        date: String,
        method: Int,
        ids: List<Int>? = null,
        agents: List<String>? = null,
        cost: Boolean = false,
    ): Respone<Pair<List<ClientCompareHolder>, List<Any>>, DBQuery> {
        // bring 3 months data
        var placementArray: MutableList<Any> = mutableListOf()
        val dataMap = when (method) {
            0 -> {
                // 12 month back
                val toDate = DatesManipulator.maxDay(date)
                val curMonth = DatesManipulator.getMonth(date).toInt() - 1
                val fromDate = DatesManipulator.minDay(DatesManipulator.getNextMonth(date, -11))
                val q = queryData(
                    fromDate = fromDate,
                    toDate = toDate,
                    byEnt = listOf(-1),
                    byMoney = true,
                    byCost = cost,
                    byMonth = -1,
                    byYear = -1,
                    byAgent = agents,

                    )
                if (q.second != DBQuery.SUCCESS) {
                    return Respone(Pair(listOf(), listOf()), DBQuery.FAILED)
                }
                var i = 0
                while (i < 12) {
                    placementArray.add((curMonth - i + 12) % 12 + 1)
                    i += 1
                }
                q.first as List<DataQueryHolder>

            }

            2 -> {
                // 12 weeks back
                val toDate = DatesManipulator.maxWeekDay(date)

                val fromDate = DatesManipulator.minWeekDay(DatesManipulator.getNextMonth(date, -3))
                val q = queryData(
                    fromDate = fromDate,
                    toDate = toDate,
                    byEnt = listOf(-1),
                    byMoney = true,
                    byCost = cost,
                    byWeek = true,
                    byAgent = agents,

                    )
                if (q.second != DBQuery.SUCCESS) {
                    return Respone(Pair(listOf(), listOf()), DBQuery.FAILED)
                }
                var i = 0
                var curWeek = DatesManipulator.getStartWeek(date)
                while (i < 12) {

                    placementArray.add(curWeek)
                    curWeek = DatesManipulator.getNextDay(curWeek, -7)
                    i += 1
                }
                q.first as List<DataQueryHolder>

            }

            1 -> {
                // 3 month , same month last year
                val toDate = DatesManipulator.maxDay(date)
                val year = DatesManipulator.getYear(date).toInt()
                placementArray.add(year)
                placementArray.add(year - 1)
                placementArray.add(year - 2)

                val fromDate = DatesManipulator.minDay(date)
                val toDateLastYear = DatesManipulator.maxDay(DatesManipulator.getNextMonth(date, -12))
                val fromDateLastYear = DatesManipulator.minDay(DatesManipulator.getNextMonth(date, -12))
                val toDateLastLastYear = DatesManipulator.maxDay(DatesManipulator.getNextMonth(date, -24))
                val fromDateLastLastYear = DatesManipulator.minDay(DatesManipulator.getNextMonth(date, -24))
                val data = DB.queryData(
                    fromDate = fromDate,
                    toDate = toDate,
                    byEnt = listOf(-1),
                    byMoney = true,
                    byCost = cost,
                    byMonth = -1,
                    byYear = -1,

                    byAgent = agents
                )
                if (data.second != DBQuery.SUCCESS) {
                    return Respone(Pair(listOf(), listOf()), DBQuery.FAILED)
                }
                val dataLastYear = queryData(
                    fromDate = fromDateLastYear,
                    toDate = toDateLastYear,
                    byEnt = listOf(-1),
                    byMoney = true,
                    byCost = cost,
                    byMonth = -1,
                    byYear = -1,
                    byAgent = agents
                )
                if (dataLastYear.second != DBQuery.SUCCESS) {
                    return Respone(Pair(listOf(), listOf()), DBQuery.FAILED)
                }
                val dataLastLastYear = queryData(
                    fromDate = fromDateLastLastYear,
                    toDate = toDateLastLastYear,
                    byEnt = listOf(-1),
                    byMoney = true,
                    byCost = cost,
                    byMonth = -1,
                    byYear = -1,
                    byAgent = agents
                )
                if (dataLastYear.second != DBQuery.SUCCESS) {
                    return Respone(Pair(listOf(), listOf()), DBQuery.FAILED)
                }
                val dataMap = data.first?.toMutableList() ?: mutableListOf()
                dataMap.addAll(dataLastYear.first ?: mutableListOf())
                dataMap.addAll(dataLastLastYear.first ?: mutableListOf())
                dataMap as List<DataQueryHolder>
            }

            else -> {
                return Respone(Pair(listOf(), listOf()), DBQuery.FAILED)
            }
        }
        val data = dataMap.groupBy { it.byEnt!! }
        val compareLst: MutableList<ClientCompareHolder> = mutableListOf()
        data.forEach {
            val ent = it.key
            val arr = it.value
            val compare = ClientCompareHolder(ent, mutableListOf(), method, date)
            arr.forEach { dq ->
                val comp = ClientCompare(
                    ent,
                    dq.byMonth,
                    dq.byYear,
                    dq.byWeek,
                    dq.valueSum!!,
                    dq.returnSum!!,
                    dq.valueSumSec ?: 0f,
                    dq.returnSumSec ?: 0f,
                    dq.getTotalMoney(),
                    dq.getTotalCost()
                )
                compare.data.add(comp)

            }
            compareLst.add(compare)
        }

        return Respone(Pair(compareLst, placementArray), DBQuery.SUCCESS)
    }

    suspend fun getProductsStatistics(
        ids: List<Int>,
        date: String,
        day: Int? = null,
        force_from_date: String? = null,
        withLast: Int? = null,
        month_counter: Int? = null
    ): Respone<List<OrderStatistics>, DBQuery> {
        // bring 3 months data
        val datePrev3Months = force_from_date ?: DatesManipulator.getNextMonth(date, -3)
        val prevDay = date
        val q = queryData(
            fromDate = datePrev3Months,
            toDate = prevDay,
            byEnt = ids,
            byDay = day ?: -1,
            byMonth = -1,
            byWeek = true,
            byStd = false,
            byDate = true,
            byProduct = listOf(-1)
        )
        if (q.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        val group = q.first as List<DataQueryHolder>
        // total
        val entProductMapper = group.groupBy { Pair(it.byEnt!!, it.byProduct!!.toInt()) }
        val total = (group.groupingBy { Pair(it.byEnt!!, it.byProduct!!.toInt()) }
            .aggregate { key, accumulator: DataQueryHolder?, element, first ->
                if (first) element.copy(counter = 1f)
                else accumulator?.increase(element)
            }.values.toList() as List<DataQueryHolder>).groupBy { it.byEnt!! }
        // monthly
//        val monthly_holder = entProductMapper.entries.associateBy({ Pair(it.key.first, it.key.second) },
//            { (it.value.groupBy { it.byMonth!! }.size) })
        val weekly_holder = entProductMapper.entries.associateBy({ Pair(it.key.first, it.key.second) }, {
            it.let {

                (it.value.groupBy { it.byWeek!! }.size)
            }
        })
        val monthly_holder = entProductMapper.entries.associateBy({ Pair(it.key.first, it.key.second) }, {
            it.let {

                (it.value.groupBy { it.byMonth!! }.size)
            }
        })
        val monthly = total.entries.associateBy({ (it.key) }, {
            (it.value.map { dqh ->
                dqh.copy(
                    counter = month_counter?.toFloat() ?: monthly_holder[Pair(
                        it.key, dqh.byProduct!!.toInt()
                    )]!!.toFloat()
                )
//                    counter = month_counter?.toFloat() ?: (0.3 * monthly_holder[Pair(
//                        it.key, dqh.byProduct!!.toInt()
//                    )]!!.toFloat() + 0.7 * weekly_holder[Pair(
//                        it.key, dqh.byProduct!!.toInt()
//                    )]!! / (4.toFloat())).toFloat()

            })
        })

        //weekly


        val weekly = total.entries.associateBy({ (it.key) }, {
            (it.value.map { dqh ->
                dqh.copy(
                    counter = weekly_holder[Pair(
                        it.key, dqh.byProduct!!.toInt()
                    )]!!.toFloat()
                )
            })
        })

        val daily = (group.groupingBy { Triple(it.byEnt, it.byDay, it.byProduct) }
            .aggregate { key, accumulator: DataQueryHolder?, element, first ->
                if (first) element.copy(counter = 1f)
                else accumulator?.increase(element)
            }.values.toList() as List<DataQueryHolder>).groupBy { it!!.byEnt!! }
        val osAll: MutableList<OrderStatistics> = mutableListOf()
        val products = if (withLast != null) {
            val x = ProductDeliveryFlat.fromDataQueryHolder(group)?.sortedBy { it.date }?.groupBy { it.entId }
            x
        } else null
        group.groupBy { it.byEnt }.forEach {
            val os = OrderStatistics(datePrev3Months, prevDay, it.key!!)
            os.lastNotes =
                products?.get(it.key!!)
                    ?.groupBy { Pair(it.productId, DatesManipulator.getDayOfWeekIsrael(it.date)) }
                    ?: mutableMapOf()
            os.total = total[it.key!!]!!.associateBy({ it.byProduct!!.toInt() }, {
                ProductStatistics(
                    it.byProduct!!.toInt(),
                    it.valueSum + (it.valueSumSecJoin ?: 0f),
                    it.returnSum + (it.returnSumSecJoin ?: 0f),
                    it.valueSumSec ?: 0f,
                    it.returnSumSec ?: 0f,
                    it.number_of_rows.toInt(),
                    it.stdValue ?: -1f,
                    it.stdValue ?: -1f,
                    counter = it.counter,
                )
            })
            os.monthly = monthly[it.key!!]!!.associateBy({ it.byProduct!!.toInt() }, {
                ProductStatistics(
                    it.byProduct!!.toInt(),
                    it.valueSum + (it.valueSumSecJoin ?: 0f),
                    it.returnSum + (it.returnSumSecJoin ?: 0f),
                    it.valueSumSec ?: 0f,
                    it.returnSumSec ?: 0f,
                    it.number_of_rows.toInt(),
                    it.stdValue ?: -1f,
                    it.stdValue ?: -1f,
                    counter = it.counter,
                )
            })
            os.daily =
                daily[it.key!!]!!.associateBy({ Pair(it.byProduct!!.toInt(), (it.byDay!!.toInt() + 1) % 7) }, {
                    ProductStatistics(

                        it.byProduct!!.toInt(),
                        it.valueSum + (it.valueSumSecJoin ?: 0f),
                        it.returnSum + (it.returnSumSecJoin ?: 0f),
                        it.valueSumSec ?: 0f,
                        it.returnSumSec ?: 0f,
                        it.number_of_rows.toInt(),
                        it.stdValue ?: -1f,
                        it.stdValue ?: -1f,
                        counter = it.counter,
                    )
                })
            os.weekly = weekly[it.key!!]!!.associateBy({ it.byProduct!!.toInt() }, {
                ProductStatistics(

                    it.byProduct!!.toInt(),
                    it.valueSum + (it.valueSumSecJoin ?: 0f),
                    it.returnSum + (it.returnSumSecJoin ?: 0f),
                    it.valueSumSec ?: 0f,
                    it.returnSumSec ?: 0f,
                    it.number_of_rows.toInt(),
                    it.stdValue ?: -1f,
                    it.stdValue ?: -1f,
                    counter = it.counter,
                )
            })
            osAll.add(os)

        }

        //day of week

        // by week


        return Respone(osAll, DBQuery.SUCCESS)
    }

    suspend fun newCalcAmountData(
        fromDate: String, toDate: String, sn: StateNotifier? = null, id: Int? = null, withAgent: Boolean = false
    ): Respone<Triple<NotesAmountsQuery?, NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery> {
        val fetchCurClients = if (user?.daily_prices == 1) {
            val dn = DatesManipulator.dateNowNoHour()
            !(fromDate == dn && toDate == dn)
        } else {
            val curMinD = DatesManipulator.minDay(DatesManipulator.dateNowNoHour())
            val receivedMinFrom = DatesManipulator.minDay(fromDate)
            val receivedMinTo = DatesManipulator.minDay(toDate)
            !(receivedMinFrom == curMinD && receivedMinTo == curMinD)
        }

        if (fetchCurClients) {
            val r =
                id?.let { fetchSpecificClientData(listOf(it), fromDate, toDate) } ?: fetchClients(fromDate, toDate)
            if (r != DBQuery.SUCCESS) {
                return Respone(Triple(null, null, null), r)
            }
        }
        sn?.advance()
        val r = adapter.getClientOptimziedDV(fromDate = fromDate, toDate = toDate)
        sn?.advance()
        return Respone(Triple(null, null, null), DBQuery.SUCCESS)

    }

    override suspend fun calcAmountData(
        fromDate: String, toDate: String, sn: StateNotifier?, id: Int?, withAgent: Boolean
    ): Respone<Triple<NotesAmountsQuery?, NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery> {
        val fetchCurClients = if (user?.daily_prices == 1) {
            val dn = DatesManipulator.dateNowNoHour()
            !(fromDate == dn && toDate == dn)
        } else {
            val curMinD = DatesManipulator.minDay(DatesManipulator.dateNowNoHour())
            val receivedMinFrom = DatesManipulator.minDay(fromDate)
            val receivedMinTo = DatesManipulator.minDay(toDate)
            !(receivedMinFrom == curMinD && receivedMinTo == curMinD)
        }

        if (fetchCurClients) {
            val r =
                id?.let { fetchSpecificClientData(listOf(it), fromDate, toDate) } ?: if (withAgent) buildClients(
                    fromDate = fromDate, toDate = toDate
                ).second else fetchClients(fromDate, toDate)
            if (r != DBQuery.SUCCESS) {
                return Respone(Triple(null, null, null), r)
            }
        }

        sn?.advance()
        val inventory = calcOrdersAmountData(fromDate, toDate, null, inventory = true, withAgent, id = id)
        if (inventory.second != DBQuery.SUCCESS) {
            return Respone(Triple(null, null, null), inventory.second)
        }
        val q = buildClientNotes(client_id = id, fromDate = fromDate, toDate = toDate)
        if (q.second != DBQuery.SUCCESS) {
            return Respone(Triple(null, null, null), q.second)
        }

        val p = buildClientTaxNote(client_id = id, fromDate = fromDate, toDate = toDate, withProduct = 1)
        if (p.second != DBQuery.SUCCESS) {
            return Respone(Triple(null, null, null), q.second)
        }
        val notes = q.first.filter { it.isActive() }
        val taxNotes = p.first.filter { it.isActive() }
        sn?.advance()
        val amount = NotesAmountsQuery(withAgent = withAgent)
        amount.add(notes)
        amount.add(taxNotes)
        sn?.advance()
        amount.buildInverseMap()



        return Respone(Triple(amount, inventory.first.first, inventory.first.second), DBQuery.SUCCESS)
    }

    fun calcAmountDataOffline(
        fromDate: String, toDate: String, id: Int? = null

    ): Respone<Triple<NotesAmountsQuery?, NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery> {
        val q =
            getClientDeliveryNotes(client_id = id).first.filter { it.date >= fromDate && it.date <= toDate && it.isActive() }

        val p = getClientTaxNote(
            client_id = id, type = 0
        ).first.filter { it.document_date >= fromDate && it.document_date <= toDate && it.withProduct == 1 && it.isActive() && it.withProduct == 1 }
        val s = getClientTaxNote(
            client_id = id, type = 1
        ).first.filter { it.document_date >= fromDate && it.document_date <= toDate && it.withProduct == 1 && it.isActive() && it.withProduct == 1 }

        val notes = q
        val taxNotes = mutableListOf<ClientTaxNote>()
        taxNotes.addAll(p)
        taxNotes.addAll(s)
        val amount = NotesAmountsQuery()
        amount.add(notes)
        amount.add(taxNotes)
        amount.buildInverseMap()
        return Respone(Triple(amount, null, null), DBQuery.SUCCESS)
    }

    fun buildClientDebtWall(
        client_id: Int,
        taxs: List<ClientLightTaxNote>? = null,
        pays: List<ClientLightPay>? = null,
        debt: List<ClientCarteset>? = null,
        default_date: String
    ) {
        //assuming we cleaned the map

        val wall = DebtWall(client_id)
        wall.taxNotes = taxs?.filter { it.taxNoteType == TaxNoteType.REGULAR } ?: listOf()
        wall.taxPayNotes = taxs?.filter { it.taxNoteType == TaxNoteType.WITH_PAYMENT } ?: listOf()
        wall.taxCancelNotes = taxs?.filter { it.taxNoteType == TaxNoteType.CANCEL } ?: listOf()
        wall.payNotes = pays ?: listOf()
        wall.debts = debt?.toMutableList() ?: mutableListOf()
        wall.year = default_date.split("-")[0]
        clientDebtWall[client_id] = wall
    }

    suspend fun clearCartesert(client_id: Int, date: String, sup: Boolean = false): DBQuery {
        val cp = if (sup) calcSupOptimizedCartesetValue(
            listOf(client_id),
            DatesManipulator.getNextYear(DatesManipulator.minDayOfYear(date), -1),
            DatesManipulator.maxDayOfYear(date),
        ) else calcOptimizedCartesetValue(
            listOf(client_id),
            DatesManipulator.getNextYear(DatesManipulator.minDayOfYear(date), -1),
            DatesManipulator.maxDayOfYear(date),

            )
        if (cp != DBQuery.SUCCESS) {
            return cp
        }
        val c = if (sup) getSupplier(client_id).first else getClient(client_id).first
        val debt = c?.debtWall
        val positive = debt?.moneyOut ?: 0f
        val negative = debt?.moneyIn ?: 0f
        val diff = positive - negative
        val note = if (diff > 0 && diff <= 1000) {
            // clear with cancel invoice

            val taxX = (diff / tax.get(date)) * tax.getPercentDivided(date)
            val isTaxPaid = (debt?.taxPaid ?: 0f) > taxX

            if (isTaxPaid) {
                newClientTaxNote(
                    client_id,
                    DatesManipulator.dateNow(),
                    date,
                    (diff / tax.get(date)),
                    diff,
                    listOf(ClientTaxNoteData(diff, fill_type = FillTaxNoteType.FREE.state, taxPaid = taxX)),
                    ClientStaticData(client_id, c!!.business_name!!, c!!.business_id!!, c.address!!),
                    type = 2,
                    active_state = 2,
                    external_details = "איפוס כרטסת ",
                    agent = "תוכנה",
                    paymentsData = listOf(),
                    sup = sup
                )
            } else {
                newClientTaxNote(
                    client_id,
                    DatesManipulator.dateNow(),
                    date,
                    diff,
                    diff,
                    listOf(ClientTaxNoteData(diff, fill_type = FillTaxNoteType.FREE.state, taxPaid = 0f)),
                    ClientStaticData(client_id, c!!.business_name!!, c!!.business_id!!, c.address!!),
                    type = 2,
                    external_details = "איפוס כרטסת ",
                    active_state = 2,
                    agent = "תוכנה",
                    paymentsData = listOf(),
                    sup = sup
                )
            }

        } else if (diff < 0 && diff >= -1000) {
            newClientPay(
                client_id,
                DatesManipulator.dateNow(),
                date,
                value = diff,
                ClientStaticData(client_id, c!!.business_name!!, c!!.business_id!!, c.address!!),
                external_details = "איפוס כרטסת ",
                agent = "תוכנה",
                sup = if (sup) 1 else 0

            )
        } else {
            return DBQuery.SUCCESS
        }
        if (note.second != DBQuery.SUCCESS) {
            return note.second
        }
        return DBQuery.SUCCESS


    }

    fun setClientDebtWalls(debtWall: DebtWall) {
        clientDebtWall[debtWall.client] = debtWall
    }

    fun getClientDebtWalls(client_id: Int?): List<DebtWall> {
        client_id?.let { return clientDebtWall[client_id]?.let { listOf(it) } ?: listOf() }
        return clientDebtWall.values.toList()

    }

    override suspend fun duplicateTomorowsOrder(date: String, toDate: String?): DBQuery {
        val q = buildClientOrderNotes(
            fromDate = date, toDate = date
        ).first.filter { it.isActive() && it.ent_id != -1 }
        val tomorrow = toDate ?: DatesManipulator.getNextDay(DatesManipulator.dateNowNoHour())
        val tomorrowsOrders =
            buildClientOrderNotes(fromDate = tomorrow, toDate = tomorrow).first.groupBy { it.ent_id }
        val daily = buildClientsDailyData(date = date)
        q.forEach {
            val p: Respone<*, DBQuery> = if (tomorrowsOrders.containsKey(it.ent_id) && it.delivery_info.isEmpty()) {
                this.updateClientOrderNote(
                    order_id = tomorrowsOrders[it.ent_id]!!.first().order_id,
                    delivery_value = it.delivery_info,
                    sup_delivery_value = it.sup_delivery_value
                )
            } else if (!tomorrowsOrders.containsKey(it.ent_id)) {
                newClientOrderNote(
                    it.agent,
                    it.ent_id,
                    tomorrow,
                    DatesManipulator.dateNowNoHour(),
                    it.delivery_value,
                    it.sup_delivery_info
                )
            } else {
                Respone(null, DBQuery.SUCCESS)
            }
            if (p.second != DBQuery.SUCCESS) {
                return p.second
            }
        }
        if (daily.first.isNotEmpty())
            newClientAllDailyData(date = tomorrow, daily = daily.first)

        return DBQuery.SUCCESS
    }

    override suspend fun newInventoryNote(
        agents: List<String>,
        date: String,
        delivery_value: List<List<ProductDelivery>>,
        sup_delivery_value: List<List<ProductDelivery>>,
        orders: List<Int>,
        agent_ids: List<Int>?,
        increase_id: Int?
    ): Respone<List<OrderNote>, DBQuery> {
        val notes: MutableList<OrderNote> = mutableListOf()
        agents.forEachIndexed { index, s ->
            val q = if (orders[index] == -1) newClientOrderNote(
                s,
                -1,
                date,
                DatesManipulator.dateNow(),
                delivery_value[index],
                sup_delivery_value = sup_delivery_value[index],
                agent_id = agent_ids?.get(index),
                increase_id = increase_id
            ) else updateClientOrderNote(
                orders[index],
                s,
                delivery_value = delivery_value[index],
                sup_delivery_value = sup_delivery_value[index],
                inventory = "true",
                agent_id = agent_ids?.get(index),
                increase_id = increase_id
            )

            if (q.second != DBQuery.SUCCESS) {
                return Respone(listOf(), q.second)
            }
            notes.add(q.first!!)

        }
        return Respone(notes, DBQuery.SUCCESS)
    }

    override suspend fun buildIsraelCities(new: Boolean): DBQuery {
        return DBQuery.FAILED
    }

    override fun getPricesOfProduct(
        product_id: Int, client: Boolean
    ): Respone<List<EntityPriceHolder>, DBQuery> {
        return if (client) {
            Respone(getAllClient(true).first.mapNotNull {
                EntityPriceHolder(it, product_id)
            }, DBQuery.SUCCESS)
        } else {
            Respone(
                getAllSupplier(true).first.mapNotNull { EntityPriceHolder(it, product_id) }, DBQuery.SUCCESS
            )
        }
    }

    override fun helloJs(): User {
        val u = User(
            company_id = 7,
            user_company_name = "test",
            "test",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            0,
            0,
            0
        )

        return u
    }

    override fun musi(): Tasks {
        return Tasks.CALCULATE_AMOUNTS_DATA
    }

    suspend fun buildFullClientEdiNotes(notes: List<EdiNote>): DBQuery {
        if (notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        val ids = notes.map { it.note_id }.distinct()
        var q = buildClientNotes(delivery_ids = ids, full = true)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        return q.second
    }

    fun getLastproductMap(id: Int): Map<Int, Float>? {
        return clientIdToProductUse[id] ?: null
    }

    suspend fun lastProducts(id: Int, order: Boolean = true): Map<Int, Float> {
        val orders = if (order) buildClientLastOrderNotes(id).first else buildClientLastNotes(id).first
        val products = HashMap<Int, Float>()
        orders.forEach {
            it.delivery_info.forEach { p ->
                if (products.containsKey(p.productId))
                    products[p.productId] = products[p.productId]!! + p.value + p.wrapped_amount
                else
                    products[p.productId] = p.value + p.wrapped_amount
            }
        }
        clientIdToProductUse.getOrPut(id) { products }
        return products
    }

    override suspend fun buildClientEdiNotes(
        note_id: List<Int>?, connected_id: Long?, fromDate: String?, toDate: String?, send: Boolean?, full: Boolean
    ): Respone<List<EdiNote>, DBQuery> {
        val response = adapter.getEdiNote(
            note_id = note_id?.joinToString(","),
            connected_id = connected_id?.toString(),
            fromDate = fromDate,
            toDate = toDate,
            send = send?.let { if (it) 1 else 0 }?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            if (full && buildFullClientEdiNotes(data) != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            data.forEach { _updateClientsEdiNotesMap(it) }

        }
        return Respone(data, dbQuery)
    }

    fun getEdiNote(id: Int): EdiNote? {
        return clientEdiNoteIdToNote[id]
    }

    override suspend fun newClientEdiNote(
        note_id: Int, date: String, info: String, reason: Int?, connected_id: Long?, send: Boolean?
    ): Respone<EdiNote?, DBQuery> {
        val response = adapter.newEdiNote(
            note_id = note_id.toString(),
            connected_id = connected_id?.toString(),
            date = date,
            info = info,
            reason = reason?.toString(),
            send = send?.let { if (it) 1 else 0 }?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS) {
            Respone(null, dbQuery)

        }
        _updateClientsEdiNotesMap(data!!)
        return Respone(data, dbQuery)
    }

    override suspend fun updateClientEdiNote(
        note_id: Int, date: String, info: String?, reason: Int?, connected_id: Long?, send: Boolean?
    ): Respone<EdiNote?, DBQuery> {
        val response = adapter.updateEdiNote(
            note_id = note_id.toString(),
            connected_id = connected_id?.toString(),
            date = date,
            info = info,
            reason = reason?.toString(),
            send = send?.let { if (it) 1 else 0 }?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS) {
            Respone(null, dbQuery)
        }
        _updateClientsEdiNotesMap(data!!)
        return Respone(data, dbQuery)
    }

    fun getTaklitProductPrev(id: Int, date: String, prev: Int): TaklitProduct? {

        val day = DatesManipulator.getDayOfWeek(date)
        val prevDay =
            if (day >= 4) DatesManipulator.getNextDay(date, 3 - day) else DatesManipulator.getNextDay(date, -1)
        return if (prev >= 2) taklitProductsDated[prevDay]?.get(id) else getTaklitProduct(id, date)
    }

    fun getTaklitProduct(id: Int, date: String): TaklitProduct? {
        val tp = taklitProducts[id]
        val day = DatesManipulator.getDayOfWeek(date)
        val prevDay =
            if (day >= 4) DatesManipulator.getNextDay(date, 3 - day) else DatesManipulator.getNextDay(date, -1)
        return if (tp != null && (tp.date == date || tp.date == prevDay)) {
            tp
        } else null
    }

    @myName("getTaklitProducts")
    fun getTaklitProducts(): List<TaklitProduct> {
        return taklitProducts.entries.map { it.value }
    }

    override suspend fun buildTaklitProducts(id: Int?, date: String): Respone<List<TaklitProduct>, DBQuery> {
        val response = adapter.getTaklitProducts(id = id?.toString(), date = date)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return if (dbQuery == DBQuery.SUCCESS && data.isNotEmpty()) {
            data.forEach {
                val cur = taklitProducts[it.id]
                val map = taklitProductsDated.getOrPut(it.date) { mutableMapOf() }
                map[it.id] = it
                if (cur != null && cur.date.compareTo(it.date) > 0) {

                } else {
                    taklitProducts[it.id] = it
                }

            }
            Respone(data, DBQuery.SUCCESS)
        } else {
            Respone(listOf(), DBQuery.FAILED)
        }
    }

    override suspend fun calcTaklit(): DBQuery {
        val response = adapter.calcTaklit()
        return analyzeResponse(response)
    }

    fun _updateOrderProductData(data: List<OrderProduct>) {
        data.forEach {
            val op = orderProducts[it.product_id]
            if (op == null) {
                orderProducts[it.product_id] = mutableListOf(it)
            } else {
                val ind = op.indexOfFirst { l -> l.id == it.id }
                if (ind == -1) {
                    op.add(it)
                } else {
                    op[ind] = it
                }
            }

        }
    }

    override suspend fun buildOrderProducts(
        id: Int?, product_id: Int?, available: Boolean?, active: Boolean?, product_ids: List<Int>?
    ): Respone<List<OrderProduct>, DBQuery> {
        val response = adapter.getOrderProducts(id = id?.toString(),
            product_id = product_id?.toString(),
            available = available?.let { if (it) "1" else "0" },
            product_ids = product_ids?.joinToString(","),
            active = active?.let { if (it) "1" else "0" })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return if (dbQuery == DBQuery.SUCCESS) {
            _updateOrderProductData(data)
            Respone(data, DBQuery.SUCCESS)
        } else {
            Respone(listOf(), DBQuery.FAILED)
        }
    }

    @myName("getOrderProduct")
    fun getOrderProduct(id: Int): List<OrderProduct>? {
        return orderProducts[id]
    }

    fun getAllOrderPoducts(): List<OrderProduct> {
        return orderProducts.entries.map { it.value }.flatten()
    }

    override suspend fun newOrderProducts(
        id: Int,
        product_id: Int,
        extra_unit: Int,
        conversion_rate: Float,
        available: Boolean,
        active: Boolean,
        weight: Float?,
        englishName: String?,
        orderName: String?,
        position: Int?,
        volume: Float?,
        sales: Int?,
        forceLock: Int?,
        hourLock: String?,
        dayLock: Int?,
        step: Float?,
        collector: Int?,
        min_order: Float?,
        unit_preferece: Int?
    ): Respone<OrderProduct?, DBQuery> {
        val response = adapter.newOrderProducts(
            id = id.toString(),
            product_id = product_id.toString(),
            extra_unit = (extra_unit).toString(),
            conversion_rate = conversion_rate.toString(),
            available = if (available) "1" else "0",
            active = if (active) "1" else "0",
            weight = weight?.toString(),
            volume = volume?.toString(),
            englishName = englishName?.toString(),
            orderName = orderName,
            position = position?.toString(),
            sales = sales?.toString(),
            forceLock = forceLock?.toString(),
            hourLock = hourLock?.toString(),
            dayLock = dayLock?.toString(),
            step = step?.toString(),
            collector = collector?.toString(),
            min_order = min_order?.toString(),
            unit_preferece = unit_preferece?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return if (dbQuery == DBQuery.SUCCESS) {
            _updateOrderProductData(listOf(data!!))
            Respone(data, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.FAILED)
        }
    }

    override suspend fun updateAllOrderProduct(mapper: List<Map<String, Any>>): Respone<List<OrderProduct>, DBQuery> {

        val m = mapper.map { n ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            n.forEach {
                map[it.key] = JsonPrimitive(it.value.toString())
            }
            JsonObject(map)
        }.joinToString(",")
        val response = adapter.updateAllOrderProduct(
            date = DatesManipulator.minDay(DatesManipulator.dateNowNoHour()), data = "[$m]"
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateOrderProductData(data)
        }
        return Respone(data, dbQuery)
    }


    suspend fun fetchCofc(id: Int, date: String = DatesManipulator.dateNowNoHour()): DBQuery {

        var qqq = getCofcFull(id)
        if (qqq == DBQuery.SUCCESS) {

            val c = getAllClient(false).first.map { it.id }.toHashSet()
            c.add(id)
            var qu = attachClientProductsToClients(c.toList())
            if (qu != DBQuery.SUCCESS) {
                return qu
            }
            return DBQuery.SUCCESS
        } else
            return DBQuery.FAILED

//        if (buildUserInfo(company = getCompany()) != DBQuery.SUCCESS) return DBQuery.FAILED
//        if (buildCofcUserConf(id = id, true) != DBQuery.SUCCESS) return DBQuery.FAILED
//        var q: Respone<*, DBQuery> = getCofcProducts(id, date)
//        if (q.second != DBQuery.SUCCESS) {
//            return q.second
//        }
//
//        var qpu: Respone<*, DBQuery> = getCofcOrderProducts(id, available = true, active = true)
//        if (qpu.second != DBQuery.SUCCESS) {
//            return qpu.second
//        }
//
//        q = buildCofcClients(id, date)
//        if (q.second != DBQuery.SUCCESS) {
//            return q.second
//        }
//
//        q = getCofcClientsPrices(id, date)
//        if (q.second != DBQuery.SUCCESS) {
//            return q.second
//        }
//        val c = getAllClient(false).first.map { it.id }.toHashSet()
//        c.add(id)
//        var qu = attachClientProductsToClients(c.toList())
//        if (qu != DBQuery.SUCCESS) {
//            return qu
//        }

//        var qp = buildProductImages(id, date)
//        if (qp != DBQuery.SUCCESS) {
//            return qp
//        }

        return DBQuery.SUCCESS
    }

    override suspend fun buildCofcClients(id: Int, date: String): Respone<List<Client>, DBQuery> {
        val response = adapter.getCofcClients(id = id.toString(), date = date.toString())
        val data = response.first.toMutableList()
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)

        if (dbQuery == DBQuery.SUCCESS) {

            if (data.first().master >= 0) {

                val response2 = adapter.getCofcClients(
                    id = id.toString(), date = date.toString(), master = data.first().master.toString()
                )
                val data2 = response2.first.toMutableList()
                val networkOutput2 = response2.second
                val dbQuery2 = analyzeResponse(networkOutput2)
                if (dbQuery2 == DBQuery.SUCCESS) {
                    data.addAll(data2)
                }
            }
            _updateClientsMap(data)
        }
        return Respone(data.distinctBy { it.id }, dbQuery)
    }

    override suspend fun getCofcAgents(
        id: Int,
        collectors: Boolean?,
        drivers: Boolean?
    ): Respone<List<Agent>, DBQuery> {
        val response = adapter.getCofcAgents(
            id = id.toString(),
            collectors = collectors?.let { if (it) "1" else null },
            drivers = drivers?.let { if (it) "1" else null })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)

        if (dbQuery == DBQuery.SUCCESS) {
            agents = data

        }
        return Respone(data, dbQuery)
    }

    override suspend fun getCofcId(): Respone<String?, DBQuery> {
        val response = adapter.getCofcId()
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun registerCofcId(
        id: Int,
        until: String,
        open_date: String?,
        short: Boolean?
    ): Respone<String?, DBQuery> {
        if ((user?.order_cofc ?: 0) > 0 && userCofcConf == null) {
            buildCofcUserConf()
        }
        val response = if (short == true) adapter.registerCofcIdShort(
            id.toString(), company, until, if (userCofcConf?.daily_link == 1) open_date else null
        ) else adapter.registerCofcId(
            id.toString(), company, until, if (userCofcConf?.daily_link == 1) open_date else null
        )
        val data = open_date?.let { response.first + "&date=$it" } ?: response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    suspend fun registerCofcAll(until: String = ""): Respone<List<Pair<Int, String>>, DBQuery> {

        if ((user?.order_cofc ?: 0) > 0 && userCofcConf == null) {
            buildCofcUserConf()
        }
        val clients = getAllClient(true).first
        val ret = clients.mapNotNull {
            val response = adapter.registerCofcId(it.id.toString(), company, until)
            val data = response.first
            val networkOutput = response.second
            val dbQuery = analyzeResponse(networkOutput)
            if (dbQuery != DBQuery.FAILED) {
                null
            }
            Pair(it.id, data!!)
        }

        return Respone(ret, DBQuery.SUCCESS)
    }

    suspend fun getCofcUser(id: Int): Respone<User?, DBQuery> {
        val response = adapter.getCofcUser(id = id.toString())
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            user = data
        }
        return Respone(user, dbQuery)
    }

    override suspend fun getCofcProducts(id: Int, date: String): Respone<List<Product>, DBQuery> {
        val response = adapter.getCofcProducts(id = id.toString(), date)
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsProductMap(data)
        }
        return Respone(data, dbQuery)
    }


    @myName("getCofcRealImagesAvailable")
    suspend fun getCofcRealImagesAvailable(id: Int): Respone<List<Int>, DBQuery> {
        val products = getAllClientProduct(true).first
        val response = adapter.getClientProductsImagesMetaCofc(
            id = id.toString(), company = company
        )
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS) return Respone(listOf(), dbQuery)

        val hasImageMapper = response.first!!.keys
        val filteredP = products.filter { it.id.toString() in hasImageMapper }.sortedBy { it.position }
        return Respone(filteredP.map { it.id }, DBQuery.SUCCESS)
    }

    override suspend fun getCofcOrderProducts(
        id: Int, op_id: Int?, product_id: Int?, available: Boolean?, active: Boolean?
    ): Respone<List<OrderProduct>, DBQuery> {
        val response = adapter.getCofcOrderProducts(id = id.toString(),
            op_id = op_id?.toString(),
            product_id = product_id?.toString(),
            available = available?.let { if (it) "1" else "0" },
            active = active?.let { if (it) "1" else "0" })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return if (dbQuery == DBQuery.SUCCESS) {
            _updateOrderProductData(data)
            Respone(data, DBQuery.SUCCESS)
        } else {
            Respone(listOf(), DBQuery.FAILED)
        }
    }

    override suspend fun getCofcFull(
        id: Int
    ): DBQuery {
        val response = adapter.getCofcFull(id = id.toString())
        val res = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            val client = (res!!["client"] as JsonObject).let {
                getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
            }

            if (res["master"] is JsonObject) {
                val master = (res["master"] as JsonObject).let {
                    getJsonWithConfig().decodeFromJsonElement(Client.serializer(), it)
                }
                _updateClientsMap(master)
            }
            _updateClientsMap(client)

            val products = (res["product"] as JsonArray).let {
                it.forEach { p ->
                    p as JsonObject
                    val pid = (p["id"] as JsonPrimitive).content.toInt()
                    val name = (p["name"] as JsonPrimitive).content
                    val position = (p["position"] as JsonPrimitive).content.toInt()
                    val active = (p["active"] as JsonPrimitive).content.toInt()
                    val available = (p["available"] as JsonPrimitive).content.toInt()
                    val date = (p["date"] as JsonPrimitive).content
                    val barcode = (p["barcode"] as JsonPrimitive).content
                    val default_price = (p["default_price"] as JsonPrimitive).content.toFloat()
                    val no_tax_product = (p["no_tax_product"] as JsonPrimitive).content.toInt()
                    val taklit_id = (p["taklit_id"] as JsonPrimitive).content.toInt()
                    val taklit_type = (p["taklit_type"] as JsonPrimitive).content.toInt()
                    val external_id = (p["external_id"] as JsonPrimitive).content
                    val unit = (p["unit"] as JsonPrimitive).content.toInt()
                    val unit_amount = (p["unit_amount"] as JsonPrimitive).content.toFloat()
                    val category = (p["category"] as JsonPrimitive).content
                    val cost_price = (p["cost_price"] as JsonPrimitive).content.toFloat()
                    val cost_include_returns = (p["cost_include_returns"] as JsonPrimitive).content.toInt()
                    val split_category = (p["split_category"] as JsonPrimitive).content.toInt()
                    val base_price = (p["base_price"] as JsonPrimitive).content.toFloat()

                    val category2 = (p["category2"] as JsonPrimitive).content
                    val product = Product(
                        name = name,
                        position = position,
                        active = active,
                        date = date,
                        barcode = barcode,
                        default_price = default_price,
                        no_tax_product = no_tax_product,
                        taklit_id = taklit_id,
                        taklit_type = taklit_type,
                        external_id = external_id,
                        unit = unit,
                        unit_amount = unit_amount,
                        category = category,
                        cost_price = cost_price,
                        cost_include_returns = cost_include_returns,
                        split_category = split_category,
                        base_price = base_price,
                        category2 = category2,
                        id = pid
                    )

                    _updateClientsProductMap(product)
                    val product_id = pid
                    val extra_unit = (p["extra_unit"] as JsonPrimitive).content.toInt()
                    val conversion_rate = (p["conversion_rate"] as JsonPrimitive).content.toFloat()
                    val weight = (p["weight"] as JsonPrimitive).content.toFloat()
                    val volume = (p["volume"] as JsonPrimitive).content.toFloat()
                    val englishName = (p["englishName"] as JsonPrimitive).content
                    val orderName = (p["orderName"] as JsonPrimitive).content
                    val sales = (p["sales"] as JsonPrimitive).content.toInt()
                    val forceLock = (p["forceLock"] as JsonPrimitive).content.toInt()
                    val hourLock = (p["hourLock"] as JsonPrimitive).content
                    val dayLock = (p["dayLock"] as JsonPrimitive).content.toInt()
                    val step = (p["step"] as JsonPrimitive).content.toFloat()
                    val order_position = (p["order_position"] as JsonPrimitive).content.toInt()
                    val collector = (p["collector"] as JsonPrimitive).content.toInt()
                    val min_order = (p["min_order"] as JsonPrimitive).content.toFloat()
                    val unit_preferece = (p["unit_preferece"] as JsonPrimitive).content.toInt()
                    val priority = (p["priority"] as JsonPrimitive).content.toFloat()
                    val inventory = (p["inventory"] as JsonPrimitive).content.toFloat()
                    val orderProduct = OrderProduct(
                        id = -1,
                        product_id = product_id,
                        extra_unit = extra_unit,
                        conversion_rate = conversion_rate,
                        weight = weight,
                        volume = volume,
                        englishName = englishName,
                        position = order_position,
                        orderName = orderName,
                        sales = sales,
                        forceLock = forceLock,
                        hourLock = hourLock,
                        dayLock = dayLock,
                        step = step,
                        collector = collector,
                        min_order = min_order,
                        unit_preferece = unit_preferece,
                        active = 1,
                        available = 1
                    )
                    _updateOrderProductData(listOf(orderProduct))

                    val price = (p["price"] as JsonPrimitive?)?.content?.toFloatOrNull()
                    val discount = (p["discount"] as JsonPrimitive?)?.content?.toFloatOrNull()
                    val priceDate = DatesManipulator.dateNowNoHour()
                    val properId = if (client.masterBranch != null)
                        (if (client.masterBranch!!.price_control != 0) client.masterBranch!!.price_control else client.master) else (if (client.price_control != 0) client.price_control else client.id)

                    val prices = clientIdToPrice[properId] ?: let {
                        clientIdToPrice[properId] = HashMap<Int, Price>()
                        clientIdToPrice[properId]!!
                    }
                    if (!prices.containsKey(product_id)) {
                        prices[product_id] = Price(product_id, "client")
                    }
                    if (price != null && discount != null) {
                        val rawPrice = RawPrice(
                            id = properId,
                            product_id = product_id,
                            price = price,
                            discount = discount,
                            date = priceDate
                        )
                        prices[product_id]!!.add(rawPrice, false)

                    }


                    val availableObj = Available(
                        id = id,
                        product_id = product_id,
                        available = available,
                        priority = priority,
                        inventory = inventory,
                    )
                    clientIdToAvailable.getOrPut(availableObj.id, { HashMap() })
                        .put(availableObj.product_id, availableObj)


                }

            }
            val ag = (res["agents"] as JsonArray).let {
                it.map {
                    getJsonWithConfig().decodeFromJsonElement(Agent.serializer(), it)
                }
            }
            agents = ag
            val u =
                (res["user"] as JsonObject).let { getJsonWithConfig().decodeFromJsonElement(User.serializer(), it) }
            user = u
            if (res["cofc"] is JsonObject) {
                val conf =
                    (res["cofc"] as JsonObject).let {
                        getJsonWithConfig().decodeFromJsonElement(
                            CofcConf.serializer(),
                            it
                        )
                    }
                userCofcConf = conf
            }
            return DBQuery.SUCCESS

        }
        return DBQuery.FAILED
    }

    override suspend fun getCofcProductsImage(
        id: Int,
        date: String,
        product_id: Int
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.getCofcProductsImage(
            id = id.toString(), company = company, date = date, product_id = product_id.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data?.let { setImage(product_id, data) }
        }
        return Respone(data, dbQuery)
    }

    fun setImage(product_id: Int, byteArray: ByteArray) {
        productIdToByteArray[product_id] = byteArray
        saveImages(product_id, byteArray)
    }


    suspend fun buildProductImages(id: Int, date: String, product_id: Int? = null): DBQuery {
        product_id?.let {
            if (getOrderProduct(it) != null) getCofcProductsImage(id, date, it)
        } ?: true.let {
            getAllClientProduct(true).first.forEach {
                GlobalScope.async {
                    if (getOrderProduct(it.id) != null) getCofcProductsImage(id, date, it.id)
                }
            }
        }

        return DBQuery.SUCCESS
    }

    @myName("getProductImage")
    fun getProductImage(id: Int): Respone<ByteArray?, DBQuery> {
        val i = productIdToByteArray[id]
        return Respone(i, i?.let { DBQuery.SUCCESS } ?: DBQuery.ID_ISSUE)
    }

    override suspend fun getCofcClientsPrices(id: Int, date: String): Respone<List<RawPrice>, DBQuery> {
        val c = getClient(id).first
        val masterOrPrice = c?.masterBranch?.let {
            if (it.price_control <= -10)
                it.price_control
            else
                it.id
        } ?: c?.let {
            if (c.price_control <= -10)
                c.price_control
            else null
        }


        val response = adapter.getCofcClientsPrices(id.toString(), date, master = masterOrPrice?.toString())
        val data = response.first.mapNotNull { it.toRawPrice() }
        val available = response.first.map { it.toAvailable() }
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS && data.isNotEmpty()) {
            val idPrice = data.firstOrNull { it.id <= -10 }?.id
            if (idPrice != null) {
                val prices = clientIdToPrice[idPrice] ?: let {
                    clientIdToPrice[idPrice] = HashMap<Int, Price>()
                    clientIdToPrice[idPrice]!!
                }
                getAllClientProduct(true).first.forEach {
                    if (!prices.containsKey(it.id)) {
                        prices[it.id] = Price(it.id, "client")
                    }
                }
                data.filter { it.id <= -10 }.forEach {
                    prices[it.product_id]?.add(it, false)

                }
            }

            _updateClientsPrice(data)
            available.forEach {
                clientIdToAvailable.getOrPut(it.id, { HashMap() }).put(it.product_id, it)
            }

        }



        return Respone(data, dbQuery)
    }


    override suspend fun getCofcClientsOrder(
        id: Int, dateFrom: String, dateTo: String, status: Int?
    ): Respone<List<OrderNote>, DBQuery> {
        val response = adapter.getCofcClientsOrder(
            id.toString(), dateFrom, dateTo, status?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsOrderNotesMap(data)
            val pIds = data.map { it.delivery_info.map { it.productId } }.flatten().toSet()
            val needToFetch = pIds.filter { !idToClientProduct.containsKey(it) }
            if (needToFetch.isNotEmpty()) {
                val products = getCofcProducts(id, dateTo).first
                val orderProducts = getCofcOrderProducts(id).first
                _updateOrderProductData(orderProducts)
                _updateClientsProductMap(products)

            }
            calcDeliveryNoteSumMap((data))
        }
        return Respone(data, dbQuery)
    }


    override suspend fun updateCofcClientOrderNote(
        order_id: Int,
        ent_id: Int,
        agent: String?,
        date: String?,
        delivery_value: List<ProductDelivery>,
        sup_delivery_info: List<ProductDelivery>,
        active: Int?,
        notes: String?,
        category: String?,
        static_data: ClientStaticData?
    ): Respone<OrderNote?, DBQuery> {
        val order = getClientOrderNotes(order_id).first.firstOrNull()
        val title = "*[עדכון הזמנה]* ${getClient(ent_id).first!!.name} עבור תאריך  ${
            order?.let { DatesManipulator.dateIsrael(it.date) }
        }"
        //fetching order product for checking lock situation.

        val products = getCofcOrderProducts(ent_id)
        var curOrder: Map<Int, ProductDelivery>? = null
        if (order != null)
            curOrder = getCofcClientsOrder(
                ent_id,
                date ?: order.date,
                date ?: order.date
            ).first.firstOrNull { it.id == order_id }?.delivery_info?.map { it.productId to it }?.toMap()

        var pds: MutableList<ProductDelivery> = mutableListOf()
        delivery_value.forEach {
            val product = it.getProduct()
            val op = product.getOrderProduct()
            val isLocked = op?.isLocked(order?.date) ?: false
            val available = op?.isAvailable() ?: false
            if (!available) {

            } else if (isLocked) {
                val orderPd = curOrder?.get(product.id)

                orderPd?.let {
                    pds.add(it)
                }
            } else {
                pds.add(it)
            }
        }
        var collect = mutableMapOf<Int, CollectionObject>()
        val filled = agents.filter {
            (it.is_collector == 5) && (delivery_value.any { pd ->
                pd.getProduct().getOrderProduct()?.collector == it.id
            })
        }
        if (filled.size > 0) {
            filled.forEach { ag ->
                val curProductsPds = pds.filter { it.getProduct().getOrderProduct()?.collector == ag.id }
                val boxes = ProductDelivery.kartonEstimate(curProductsPds)
                collect[ag.id] = CollectionObject(ag.id, boxes)
            }

        }
        if (active != 12 && pds.isEmpty()) {
            return Respone(null, DBQuery.ERROR_EMPTY_NEW)
        }
        val response = adapter.updateCofcClientOrderNote(
            order_id = order_id.toString(),
            ent_id = ent_id.toString(),
            agent = agent,
            date = date,
            delivery_value = ProductDelivery.toJsonArrayString(pds),
            sup_delivery_info = ProductDelivery.toJsonArrayString(sup_delivery_info),
            active = active?.toString(),
            notes = notes?.let { prepareStrToJson(it) },
            category = category,
            title = title,
            collection = CollectionObject.toJson(collect),
            static_data = static_data?.let {
                ClientStaticData.toJsonArrayString(static_data)
            }
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsOrderNotesMap(data!!)
            calcDeliveryNoteSumMap(listOf(data))
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newCofcClientOrderNote(
        agent: String,
        ent_id: Int,
        date: String,
        date_issued: String,
        delivery_value: List<ProductDelivery>,
        sup_delivery_info: List<ProductDelivery>,
        order_id: Int?,
        active: Int?,
        notes: String?,
        category: String?,
        static_data: ClientStaticData?
    ): Respone<OrderNote?, DBQuery> {
        val title =
            "*[הזמנה חדשה]* ${getClient(ent_id).first!!.name} עבור תאריך ${DatesManipulator.dateIsrael(date)}"
        //fetching order product for checking lock situation.

        val products = getCofcOrderProducts(ent_id)
        val delivery = mutableListOf<ProductDelivery>()

        delivery_value.forEach { pd ->
            val product = pd.getProduct()
            val op = product.getOrderProduct()
            val isLocked = op?.isLocked(date) ?: false
            val available = op?.isAvailable() ?: false
            if (!available) {

            } else if (isLocked) {

            } else {
                delivery.add(pd)
            }
        }
        val collect = HashMap<Int, CollectionObject>()
        val filled = agents.filter {
            (it.is_collector == 5) && (delivery_value.any { pd ->
                pd.getProduct().getOrderProduct()?.collector == it.id
            })
        }
        if (filled.size > 0) {
            filled.forEach { ag ->
                val curProductsPds = delivery_value.filter { it.getProduct().getOrderProduct()?.collector == ag.id }
                val boxes = ProductDelivery.kartonEstimate(curProductsPds)
                val q = CollectionObject(ag.id, boxes)
                collect[ag.id] = q
            }

        }
        if (active != 12 && delivery.isEmpty()) {
            return Respone(null, DBQuery.ERROR_EMPTY_NEW)
        }

        val response = adapter.newCofcClientOrderNote(
            agent = agent,
            ent_id = ent_id.toString(),
            date = date,
            date_issued = date_issued,
            delivery_value = ProductDelivery.toJsonArrayString(delivery),
            sup_delivery_info = ProductDelivery.toJsonArrayString(sup_delivery_info),
            active = active?.toString(),
            notes = notes?.replace("\"", "")?.replace("\'", ""),
            category = category,
            title = title,
            collection = CollectionObject.toJson(collect),
            static_data = static_data?.let {
                ClientStaticData.toJsonArrayString(static_data)
            }
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientOrderNoteForToday(data!!.ent_id, data)
            _updateClientsOrderNotesMap(listOf(data))
            calcDeliveryNoteSumMap(listOf(data))
        }
        return Respone(data, dbQuery)
    }

    override suspend fun uploadImg(f: ByteArray, product_id: Int, offline: Boolean): DBQuery {
        val existingPhoto = getProductImage(product_id).first
        val needToUpdate = existingPhoto == null || existingPhoto.sum() != f.sum()
        if (!needToUpdate) return DBQuery.SUCCESS
        val n = if (offline) {
            val s = saveOfflineSign(false, DatesManipulator.dateNowNoHour(), "", product_id, -1, f)
            NetworkOutput.SUCCESS
        } else {
            adapter.uploadFile(f, product_id.toString())
        }

        val dbQuery = analyzeResponse(n)
        if (dbQuery == DBQuery.SUCCESS) {
            setImage(product_id, f)

        }
        return dbQuery
    }

    suspend fun uploadLogo(f: ByteArray): DBQuery {

        val n = adapter.uploadLogo(f, "logo")

        val dbQuery = analyzeResponse(n)
        if (dbQuery == DBQuery.SUCCESS) {
            logo = f
        }
        return dbQuery
    }

    suspend fun uploadSignForce(note_ids: List<Int>, doc_type: Int) {
        GlobalScope.async {
            try {
                sendAllOfflineSignHolder(note_ids, doc_type)
            } catch (t: Throwable) {

            }
        }
    }

    override suspend fun uploadSign(
        f: ByteArray, note_id: Int, date: String, signer: String, doc_type: Int, offline: Boolean
    ): Respone<NoteSignHolder?, DBQuery> {

        val s = if (offline) saveOfflineSign(true, date, signer, note_id, doc_type, f) else null
        val n = if (s != null) {
            uploadSignForce(listOf(note_id), doc_type)
            Respone(s, NetworkOutput.SUCCESS)
        } else adapter.uploadSign(f, note_id.toString(), date, prepareStrToJson(signer), doc_type.toString())

        val data = n.first
        val networkOutput = n.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSignIdData(listOf(data!!))

        }
        return Respone(data, dbQuery)
    }

    override suspend fun uploadPhoto(
        f: ByteArray, note_id: Int, date: String, doc_type: Int, offline: Boolean
    ): Respone<NoteSignHolder?, DBQuery> {
        val s = if (offline) saveOfflineSign(false, date, "", note_id, doc_type, f) else null
        val n = if (s != null) {
            Respone(s, NetworkOutput.SUCCESS)
        } else adapter.uploadPhoto(f, note_id.toString(), date, doc_type.toString())

        val data = n.first
        val networkOutput = n.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSignIdData(listOf(data!!))
        }
        return Respone(data, dbQuery)
    }

    override suspend fun getSignImg(
        note_id: Int,
        doc_type: Int,
        strict: Boolean
    ): Respone<Triple<ByteArray?, Int, Int>, DBQuery> {
        if (strict) {
            if (getSignOfflineByObject(note_id, doc_type) == null)
                return Respone(Triple(null, note_id, doc_type), DBQuery.SUCCESS)
        }
        getOfflineSign(true, note_id, doc_type)?.let {
            return Respone(Triple(it, note_id, doc_type), DBQuery.SUCCESS)
        }
        val response = adapter.getSignImg(
            note_id = note_id.toString(), doc_type = doc_type.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (data == null) {
            return Respone(Triple(null, note_id, doc_type), DBQuery.FAILED)
        }

        return Respone(Triple(data, note_id, doc_type), dbQuery)
    }

    override suspend fun getSignPhoto(
        note_id: Int,
        doc_type: Int,
        strict: Boolean
    ): Respone<Triple<ByteArray?, Int, Int>, DBQuery> {
        if (strict) {
            if (getSignOfflineByObject(note_id, doc_type) == null)
                return Respone(Triple(null, note_id, doc_type), DBQuery.SUCCESS)
        }
        getOfflineSign(false, note_id, doc_type)?.let {
            return Respone(Triple(it, note_id, doc_type), DBQuery.SUCCESS)
        }

        val response = adapter.getSignPhoto(
            note_id = note_id.toString(), doc_type = doc_type.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (data == null) {
            return Respone(Triple(null, note_id, doc_type), DBQuery.FAILED)
        }

        return Respone(Triple(data, note_id, doc_type), dbQuery)
    }

    override suspend fun getSign(ids: List<Int>, doc_type: Int): Respone<List<NoteSignHolder>, DBQuery> {
        val response = adapter.getSign(
            note_id = ids.joinToString(","), doc_type = doc_type.toString()

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateSignIdData((data))

        }
        return Respone(data, dbQuery)
    }

    @myName("getSignOfflineByObject")
    fun getSignOfflineByObject(id: Int, doc_type: Int = 4): NoteSignHolder? {
        return when (DocType.ORDERS.convert(doc_type)) {
            DocType.ORDERS -> {
                clientOrderIdToDeliveryNote[id]?.signData()
            }

            DocType.DELIVERY_NOTES -> {
                clientDeliveryIdToDeliveryNote[id]?.signData()
            }

            DocType.TAX_NOTE -> {
                idToTaxNote[id]?.signData()
            }

            DocType.TAX_PAY_NOTE -> {
                idToTaxPayNote[id]?.signData()
            }

            DocType.TAX_NOTE_CANCEL -> {
                idToTaxCancel[id]?.signData()
            }
//                DocType.PAY_NOTE->{
//                    clientIdToClientPay[it.note_id]?.setSign(it)
//                }
            DocType.VISIT -> {
                idToVisitNote[id]?.signData()
            }

            else -> {
                null
            }
        }
    }

    @myName("getSignOffline")
    fun getSignOffline(id: Int, doc_type: Int = 4): Respone<NoteSignHolder?, DBQuery> {
        val d = docIdToSign[doc_type]?.get(id)
        return if (d != null) {
            Respone(d, DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.ID_ISSUE)
        }
    }

    fun createImageListeners(): Map<Int, ByteArrayValueStore> {
        val state: MutableMap<Int, ByteArrayValueStore> = mutableMapOf()
        val products = getAllClientProduct(true).first
        products.forEach { state[it.id] = ByteArrayValueStore(it.id, noImgPhoto) }
        return state
    }

    @myName("buildProductImages")
    suspend fun buildProductImages(state: Map<Int, ByteArrayValueStore>? = null): DBQuery {
        val products = getAllClientProduct(true).first

        val response = adapter.getClientProductsImagesMeta(
            company = company
        )
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS) return dbQuery

        val hasImageMapper = response.first!!.keys
        val filteredP = products.filter { it.id.toString() in hasImageMapper }.sortedBy { it.position }
        filteredP.chunked(6).forEach {
            val calls: MutableList<suspend () -> Respone<Int, DBQuery>> = mutableListOf()
            it.forEach {
                calls.add(suspend { Respone(it.id, getClientProductsImage(it.id)) })
            }
            val executor = ParallelExecutor().getInfo(calls, { respone ->
                respone.second == DBQuery.SUCCESS
            }, { index, respone ->
                state?.get(respone.first)?.value = getProductImage(respone.first).first?.let {
                    if (it.contentEquals(badPhoto)) {
                        noImgPhoto
                    } else {
                        it
                    }
                } ?: noImgPhoto
            })
            if (!executor) return DBQuery.FAILED
        }
        imageFetched = true
        return DBQuery.SUCCESS
    }

    override suspend fun getClientProductsImage(product_id: Int?): DBQuery {

        if (product_id == null) {
            return buildProductImages()
        }
        val response = adapter.getClientProductsImage(
            company = company, product_id = product_id.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data?.let { setImage(product_id, data) }
        }
        return dbQuery
    }

    override suspend fun prepareMassTaxNote(
        date: String, client_ids: List<Int>?, fromDate: String?, allOpen: Boolean
    ): Respone<List<TaxNoteMassHolder>, DBQuery> {
        val minDate = if (fromDate != null) fromDate else DatesManipulator.minDay(date)
        val maxDate = if (fromDate != null) date else DatesManipulator.maxDay(date)
        val tn = buildClientTaxNote(client_ids = client_ids, fromDate = minDate, toDate = maxDate)
        val dv = client_ids?.let {
            var other_client_to_bring: MutableSet<Int> = mutableSetOf()
            it.forEach { client_id ->
                val c = getClient(client_id).first!!
                if (c.hasBranch()) {
                    val members = getBranchMembers((c as Client).branch).first.map { it.id }
                    other_client_to_bring.addAll(members)
                }
                other_client_to_bring.add(c.id)
            }
            buildClientNotes(
                client_ids = other_client_to_bring.toList(),
                fromDate = minDate,
                toDate = maxDate,
                full = true,
                api = true
            )
        } ?: buildClientNotes(
            fromDate = minDate,
            toDate = maxDate,
            full = true,
            api = true
        )

        if (dv.second != DBQuery.SUCCESS || tn.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        val dvs: MutableList<DeliveryNote> = dv.first.toMutableList()
        if (allOpen) {
            val aop = buildClientNotes(
                fromDate = unclosed_date,
                toDate = DatesManipulator.getNextDay(minDate, -1),
                full = true,
                api = true,
                open = true
            )
            if (aop.second == DBQuery.SUCCESS) {
                dvs.addAll(aop.first)
            }
        }


        val tnGroup = tn.first.groupBy { it.client_id }
        val ret =
            dvs.filter { getClient(it.ent_id).first != null }.groupBy { getClient(it.ent_id).first!!.branch }.map { k ->
                if (k.key == -1) {
                    val g = k.value.groupBy { it.ent_id }
                    g.map { m ->
                        val client = getClient(m.key).first!!
                        val taxNotes =
                            tnGroup[m.key]?.filter { it.isActive() && (it.taxNoteType == TaxNoteType.WITH_PAYMENT || it.taxNoteType == TaxNoteType.REGULAR) }
                                ?: listOf()
                        val notes = m.value.filter { it.isActive() }

                        val calcMapOpen = calcDeliveryNoteSumMap(notes.filter { !it.isUsed() })
                        val calcMapAll = calcDeliveryNoteSumMap(notes.filter { it.isActive() }).first[-1]!!
                        val totalOpenVal = calcMapOpen.first[-1]!!
                        TaxNoteMassHolder(
                            client_id = client.id,
                            notes = notes,
                            taxNotes = taxNotes,
                            openSum = totalOpenVal.totalValue,
                            openTax = totalOpenVal.taxPaid,
                            totalSum = taxNotes.sumByDouble { it.total_value.toDouble() }.toFloat(),
                            date = date,
                            totalTaxTn = taxNotes.sumByDouble { it.getTaxPaid().toDouble() }.toFloat(),
                            totalNotes = calcMapAll.totalValue,
                            totalTax = calcMapAll.taxPaid
                        )
                    }
                } else {
                    val tempC = getClient(k.value.first().ent_id).first!!
                    val client = if (tempC.master == -1) {
                        tempC
                    } else {
                        getClient(tempC.master).first!!
                    }
                    val getMemners = getBranchMembers(k.key).first
                    val taxNotes = getMemners.map {
                        tnGroup[it.id]?.filter { it.isActive() && (it.taxNoteType == TaxNoteType.WITH_PAYMENT || it.taxNoteType == TaxNoteType.REGULAR) }
                            ?: listOf()
                    }.flatten()
                    val notes = k.value.filter { it.isActive() }

                    val calcMapOpen = calcDeliveryNoteSumMap(notes.filter { !it.isUsed() })
                    val calcMapAll = calcDeliveryNoteSumMap(notes.filter { it.isActive() }).first[-1]!!
                    val totalOpenVal = calcMapOpen.first[-1]!!
                    listOf(
                        TaxNoteMassHolder(
                            client_id = client.id,
                            notes = notes,
                            taxNotes = taxNotes,
                            openSum = totalOpenVal.totalValue,
                            openTax = totalOpenVal.taxPaid,
                            totalSum = taxNotes.sumByDouble { it.total_value.toDouble() }.toFloat(),
                            date = date,
                            totalTaxTn = taxNotes.sumByDouble { it.getTaxPaid().toDouble() }.toFloat(),
                            totalNotes = calcMapAll.totalValue,
                            totalTax = calcMapAll.taxPaid
                        )
                    )
                }
            }.flatten().filter { it.notes.isNotEmpty() }

        return Respone(ret, DBQuery.SUCCESS)
    }

    override suspend fun calcDocumentZip(doc_type: Int, date_range: String): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcDocumentZip(
            doc_type.toString(), date_range
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    suspend fun buildStoreNextConfig(): Respone<StoreNextConfig?, DBQuery> {
        val response = adapter.getStoreNextInfo()
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return if (dbQuery == DBQuery.SUCCESS && data.isNotEmpty()) {
            Respone(data.first(), DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.FAILED)
        }
    }

    suspend fun buildHashavshevetConfig(): Respone<HashavshevetS?, DBQuery> {
        val response = adapter.getHashavshevetInfo()
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return if (dbQuery == DBQuery.SUCCESS && data.isNotEmpty()) {
            Respone(data.first(), DBQuery.SUCCESS)
        } else {
            Respone(null, DBQuery.FAILED)
        }
    }

    suspend fun getW(): String {
        return adapter.getWeigth();
    }

    @myName("cofcLogin")
    suspend fun cofcLogin(token: String, url: String? = null, web: Boolean = false): Respone<Int?, DBQuery> {
        var token = token
        var log: String
        if (url != null) {
            val ret = NKAdapter(web = web).getCofcId2(url)
            if (ret.second != NetworkOutput.SUCCESS) {
                return Respone(null, DBQuery.LOGIN_FAILED)
            }

            token = ret.first!!.first
            log = ret.first!!.second
        } else {
            httpWorker.curToken = token
            val logX = NKAdapter(web = web).getCofcId()
            if (logX.second != NetworkOutput.SUCCESS) {
                return Respone(null, DBQuery.LOGIN_FAILED)
            }
            log = logX.first!!
        }
        httpWorker.curToken = token

        val items = log.split("_")
        val company_id = items[0]
        val client_id = items[1]
        val company = items[2]
        cofcMendatoryDate = if (items.size == 5 && items[4] != "") items[4] else null
        setCompany(company, web = web)
        setCompanyId(company_id)
        fetchTaxData()
//        getCofcUser(client_id.toInt())
        fetchCofc(client_id.toInt())
        return Respone(client_id.toInt(), DBQuery.SUCCESS)
    }

    suspend fun prepareMainGraphData(): Respone<MutableMap<String, Any>?, DBQuery> {
        val graphMapData: MutableMap<String, Any> = mutableMapOf()
        val now = DatesManipulator.dateNowNoHour()
        val first_year = DatesManipulator.minDayOfYear(now)
        val end_year = DatesManipulator.maxDayOfYear(now)
        val min_month = DatesManipulator.minDay(now)
        val max_month = DatesManipulator.maxDay(now)
        val d = calcClientDebtTillNow(first_year, end_year)
        if (d != DBQuery.SUCCESS) {
            return Respone(null, DBQuery.FAILED)
        }

        var total_sum = 0f
        getAllClient(false).first.forEach {
            total_sum += it.debtWall?.let { db ->
                (db.moneyOut - db.moneyIn)
            } ?: 0f
        }
        graphMapData["totalSum"] = total_sum

        var taxes = getClientLightTaxNotes(first_year, end_year)
        if (taxes.second != DBQuery.SUCCESS) {
            return Respone(null, taxes.second)
        }
        var pays = getClientLightPay(first_year, end_year)
        if (pays.second != DBQuery.SUCCESS) {
            return Respone(null, pays.second)
        }

        val info: MutableList<InformationBase> = mutableListOf()
        info.addAll(pays.first)
        info.addAll(taxes.first)
        val gr = info.groupBy {
            it.getConnectedDate().split("-")[1].toInt()
        }
        val perMonth: MutableMap<Int, List<Float>> = mutableMapOf()
        gr.keys.forEach {
            val month = it
            val docs = gr[month]!!
            var taxSum: Float = 0f
            var taxPaySum: Float = 0f
            var paySum: Float = 0f
            var taxCancelSum: Float = 0f
            docs.forEach {
                when (val doc = it) {
                    is ClientLightTaxNote -> {
                        when (doc.taxNoteType) {
                            TaxNoteType.WITH_PAYMENT -> {
                                taxPaySum += doc.getDiscountValue()
                            }

                            TaxNoteType.CANCEL -> {
                                taxCancelSum += doc.getConnectedValue()
                            }

                            else -> {
                                taxSum += doc.getConnectedValue()
                            }
                        }
                    }

                    is ClientLightPay -> {
                        paySum += doc.getConnectedValue()
                    }

                }
            }
            perMonth[it] = listOf(taxSum, paySum, taxCancelSum, taxPaySum)
        }

        graphMapData["perMonth"] = perMonth
        val r = calcAmountData(min_month, max_month)
        if (r.second != DBQuery.SUCCESS) {
            return Respone(null, DBQuery.FAILED)
        }
        graphMapData["monthlyAmounts"] = r.first

        val dailyAmounts = calcAmountDataOffline(now, now)
        graphMapData["dailyAmounts"] = dailyAmounts.first
        val weeklyAmounts = calcAmountData(DatesManipulator.minWeekDay(now), now)
        graphMapData["weeklyAmounts"] = weeklyAmounts.first


        val daysExplain = DatesManipulator.getDatesRange(min_month, now).map {
            val dayCalc = calcAmountDataOffline(it, it)
            if (dayCalc.second != DBQuery.SUCCESS) return Respone(null, DBQuery.FAILED)
            Pair(it.split("-")[2].toInt(), dayCalc.first.first!!)
        }
        graphMapData["daysExplain"] = daysExplain

        val months = listOf(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12)
        val years =
            listOf(DatesManipulator.getYear(DatesManipulator.getNextYear(now, -1)), DatesManipulator.getYear(now))
        val curMonthlyCycles: MutableMap<Int, Float> = mutableMapOf()
        val prevMonthlyCycles: MutableMap<Int, Float> = mutableMapOf()
        val mc = buildClientMonthlyCycle()
        if (mc.second != DBQuery.SUCCESS) {
            return Respone(null, DBQuery.FAILED)
        }


        val monthlyCycleGroup =
            mc.first.filter { it.date <= end_year && it.date >= first_year }
                .groupBy { it.date.split("-")[1].toInt() }

        graphMapData["curMonthlyCycles"] = months.map {
            val curCylcles = monthlyCycleGroup[it] ?: listOf()
            curCylcles.sumByDouble { d -> d.value.toDouble() }.toFloat()
        }

        val prev_end_year = DatesManipulator.getNextYear(end_year, -1)
        val prev_start_year = DatesManipulator.getNextYear(first_year, -1)
        val monthlyCycleGroupPrev = mc.first.filter { it.date <= prev_end_year && it.date >= prev_start_year }
            .groupBy { it.date.split("-")[1].toInt() }

        graphMapData["curMonthlyCyclesPrev"] = months.map {
            val curCylcles = monthlyCycleGroupPrev[it] ?: listOf()
            curCylcles.sumByDouble { d -> d.value.toDouble() }.toFloat()
        }

        return Respone(graphMapData, DBQuery.SUCCESS)
    }

    @myName("calculateRoundingTax")
    fun calculateRoundingTax(
        before: Float, after: Float, discount: Float, date: String = DatesManipulator.dateNowNoHour()
    ): Pair<Float, Float> {
        if (after < 1) {
            return Pair(0f, 0f)
        }
        val th = 0.5
        val sumToRound = if (after - after.toInt() >= th) {
            roundToDecimals(after.roundToInt() - after, 4)
        } else {
            roundToDecimals(after.toInt() - after, 4)
        }

        val perc = 1 - discount / 100
        val discountValue = discount / 100 * (before)
        val taxPaid = after + discountValue - before
        val round_sum_before_discount = roundToDecimals(sumToRound / perc, 4)

        val tax_paid = if (taxPaid * tax.get(date) / tax.getPercentDivided(date) > abs(sumToRound)) {
            // we add row with maam
            roundToDecimals(round_sum_before_discount - round_sum_before_discount / tax.get(date), 4)
        } else {
            // we add row without maam
            0f
        }
        return Pair(round_sum_before_discount, tax_paid)


    }

    @myName("getValidPhone")
    fun getValidPhone(phone: String): String? {
        val cphone = phone.trim()
        if (cphone.startsWith("972") && cphone.length == 12) {
            return phone
        }
        if (cphone.length != 10) return null
        if (cphone[0] != '0') {
            return null
        }
        return "972" + cphone.subSequence(1, 10)
    }

    @myName("getValidEmail")
    fun getValidEmail(mail: String): String? {
        val cmail = mail.trim()
        val emailRegex = Regex("^[a-zA-Z0-9._%+-]+@.*")

        return if (emailRegex.matches(cmail)) cmail else null
    }

    suspend fun trasferAllAgents(from: String, to: String): DBQuery {
        getAllClient(false).first.forEach {
            if (it.getAgent() == from) {
                val q = updateClient(it.id, DatesManipulator.minDay(DatesManipulator.dateNowNoHour()), agent = to)
                if (q.second != DBQuery.SUCCESS) {
                    return q.second
                }
            }
        }
        return DBQuery.SUCCESS

    }

    override suspend fun takanonPDF(
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.takanonPDF(

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcApiExcelZip(
        date_start: String,
        date_end: String,
        xlsxType: String?,
        c_ids: List<Int>?,
        ids: List<Int>?,
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcApiExcelZip(
            ("$date_start..$date_end"),
            xlsxType,
            c_ids?.joinToString(","),
            ids?.joinToString(","),
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcApiHashavshevetZip(
        date_start: String,
        date_end: String,
        ids: List<Int>?,
        types: List<Int>?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcApiHashavshevetZip(
            ("$date_start..$date_end"), ids = ids?.joinToString(","), types = types?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    suspend fun _buildFullClientApiNotes(notes: List<ApiNote>): DBQuery {
        if (notes.isEmpty()) {
            return DBQuery.SUCCESS
        }

        val dates = notes.map { it.doc_date }.distinct()

        notes.groupBy { it.docType }.forEach {
            val ids = it.value.map { it.doc_id }.toSet().toList()
            val n = when (it.key) {
                DocType.TAX_NOTE, DocType.TAX_NOTE_CANCEL, DocType.TAX_PAY_NOTE -> {
                    buildClientTaxNote(ids = ids, type = it.key.state)
                }

                DocType.DELIVERY_NOTES -> {
                    buildClientNotes(delivery_ids = ids)
                }

                DocType.PAY_NOTE -> {
                    buildClientPay(ids = ids)
                }

                DocType.ORDERS -> {
                    buildClientOrderNotes(order_ids = ids)
                }

                else -> {
                    throw NotImplementedError("")
                }
            }
            if (n.second != DBQuery.SUCCESS) {
                return n.second
            }
        }
        val q = buildFullOnDocs(notes)
        return q
    }

    fun getApiNoteOffline(
        doc_ids: List<Int>?,
        doc_type: Int?,
        api_method: Int?,
        connected_id: Long?,
        fromDate: String?,
        toDate: String?,
        send: Int?,
        retries: Int?,
        doc_types: List<Int>?

    ): List<ApiNote> {
        val docIdSet = doc_ids?.toSet()
        val docTypeSet = doc_types?.toSet()
        return idToApiNote.values.filter {
            (docIdSet == null || it.doc_id in docIdSet) &&
                    (doc_type == null || it.docType.state == doc_type) &&
                    (api_method == null || it.api_method == api_method) &&
                    (connected_id == null || it.connected_id == connected_id) &&
                    (fromDate == null || it.doc_date >= fromDate) &&
                    (toDate == null || it.doc_date <= toDate) && (send == null || it.send == send) &&
                    (retries == null || it.retries == retries) &&
                    (docTypeSet == null || it.docType.state in docTypeSet)

        }

    }

    override suspend fun getApiNote(
        doc_ids: List<Int>?,
        doc_type: Int?,
        api_method: Int?,
        connected_id: Long?,
        fromDate: String?,
        toDate: String?,
        send: Int?,
        full: Boolean,
        retries: Int?,
        doc_types: List<Int>?
    ): Respone<List<ApiNote>, DBQuery> {
        val needHistoryQ =
            if (getUser().first?.company_type == 4 && (fromDate != null && toDate != null) && DatesManipulator.daysDiffBetweenDates(
                    toDate,
                    fromDate
                ) > optimizeDaysDocuments
            ) {

                getApiNoteOffline(
                    doc_ids,
                    doc_type,
                    api_method,
                    connected_id,
                    fromDate,
                    toDate,
                    send,
                    retries,
                    doc_types
                )
            } else null
        val response = adapter.getApiNote(
            doc_ids = doc_ids?.map { it.toString() }?.joinToString(","),
            doc_type = doc_types?.let { it.joinToString(",") } ?: doc_type?.toString(),
            api_method = api_method?.toString(),
            connected_id = connected_id?.toString(),
            fromDate = fromDate,
            toDate = toDate,
            send = send?.toString(),
            retries = retries?.toString(),
            needHistoryQ = needHistoryQ?.let { lst ->
                val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                lst.forEach {
                    map["${it.doc_id.toString()}_${it.doc_type.toString()}"] = JsonPrimitive(it.action_time)
                }
                JsonObject(map).toString()
            }
        )
        var data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data.forEach {
                idToApiNote[Pair(it.doc_id, it.docType.state)] = it
            }
            if (needHistoryQ != null) {
                data = getApiNoteOffline(
                    doc_ids,
                    doc_type,
                    api_method,
                    connected_id,
                    fromDate,
                    toDate,
                    send,
                    retries,
                    doc_types
                )
            }
            if (full) return Respone(data, _buildFullClientApiNotes(data))
        }


        return Respone(data, dbQuery)
    }


    override suspend fun setApiNote(
        doc_id: Int,
        doc_type: Int,
        api_method: Int,
        ent_id: Int?,
        connected_id: Long?,
        doc_date: String?,
        send: Int?,
        reason: String?,
        retries: Int?
    ): Respone<List<ApiNote>, DBQuery> {
        val response = adapter.setApiNote(
            doc_id = doc_id.toString(),
            doc_type = doc_type.toString(),
            api_method = api_method.toString(),
            ent_id = ent_id?.toString(),
            connected_id = connected_id?.toString(),
            doc_date = doc_date?.toString(),
            send = send?.toString(),
            reason = reason?.let { prepareStrToJson(it) },
            retries = retries?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun getApiConfig(
        withOrders: Boolean?,
        whatsapp: Boolean?,
        last_counter: Boolean?
    ): Respone<ApiConfig?, DBQuery> {
        val response = adapter.getApiConfig(
            withOrders = withOrders?.let { if (it) "true" else null },
            whatsapp = whatsapp?.let { if (it) "true" else null },
            last_counter = last_counter?.let { if (it) "true" else null }
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            apiConfig = data!!
        } else if (dbQuery == DBQuery.ERROR_EMPTY_NEW) {
            apiConfig = null
            return Respone(data, DBQuery.SUCCESS)
        }
        return Respone(data, dbQuery)
    }

    override suspend fun calcClientOrderPdf(ids: List<Int>, cofc: Boolean, id: Int?): Respone<ByteArray?, DBQuery> {

        val response = adapter.calcClientOrderPdf(
            ids.joinToString(","), cofc, id?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcMASSdocPdf(
        ids: List<Int>, docs: List<Int>, force_price: Boolean?, origin: Boolean?, originForce: Int?, dates: String?
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcMASSdoc(ids.joinToString(","),
            docs.joinToString(","),
            force_price = force_price?.let { if (it) "1" else "0" },
            dates = dates,
            origin = originForce?.let { it.toString() } ?: origin?.let { if (it) "1" else "0" })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcClientReportPdf(
        fromDate: String, toDate: String, report_type: ReportType
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcClientReportPdf(
            fromDate, toDate, report_type.name
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcClientsXslx(clients: List<Int>?): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcClientsXslx(
            clients?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun calcProductsXslx(products: List<Int>?): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcProductsXslx(
            products?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun getClientsLastVisitationOptimized(
        ids: List<Int>?,
        allClients: Boolean,
        tax_visitation: Boolean,
        note_visitation: Boolean,
        dv_visitation: Boolean
    ): Respone<List<ClientLastVisit>, DBQuery> {
        val response = adapter.getClientLastVisitation(
            ids = ids?.joinToString(","),
            tax_visitation = if (tax_visitation) tax_visitation.toString() else null,
            note_visitation = if (note_visitation) note_visitation.toString() else null,
            dv_visitation = if (dv_visitation) dv_visitation.toString() else null,
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            clientIdToLastVisit.clear()
            data.forEach {
                clientIdToLastVisit[it.client_id] = it.date
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun getClientsVisitationOptimized(
        ids: List<Int>?,
        fromDate: String,
        toDate: String,
        allClients: Boolean,
        tax_visitation: Boolean,
        note_visitation: Boolean,
        dv_visitation: Boolean,
        last: Boolean
    ): Respone<Pair<List<ClientVisitationHolder>, List<String>>?, DBQuery> {
        val response = adapter.getClientVisitation(
            ids = ids?.joinToString(","),
            fromDate = fromDate,
            toDate = toDate,
            tax_visitation = if (tax_visitation) tax_visitation.toString() else null,
            note_visitation = if (note_visitation) note_visitation.toString() else null,
            dv_visitation = if (dv_visitation) dv_visitation.toString() else null,
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            val q = buildClientsDailyData(ids = ids, date = toDate, dailyFinder = true)
            val dates_number =
                data.distinctBy { it.start_week }.map { it.start_week }.sortedDescending().toMutableList()
            val dates_map = dates_number.mapIndexed { index: Int, s: String -> s to index }.toMap()
            val result: MutableMap<Int, ClientVisitationHolder> = mutableMapOf()
            data.forEach {
                val ind = dates_map[it.start_week]!!
                if (!result.containsKey(it.client_id)) {
                    val newLst = MutableList<ClientVisitation?>(dates_number.size) { null }
                    result[it.client_id] = ClientVisitationHolder(
                        it.client_id,
                        newLst,
                        DatesManipulator.getStartWeek(fromDate),
                        DatesManipulator.getEndWeek(toDate)
                    )
                }
                val holder = result[it.client_id]!!
                holder.data[ind] = it
                holder.dv_visiting += it.dv_visiting
                holder.dv_documents += it.dv_documents
                holder.tn_visiting += it.tn_visiting
                holder.tn_documents += it.tn_documents
                holder.pay_visiting += it.pay_visiting
                holder.pay_documents += it.pay_documents
                holder.visit_visiting += it.visit_visiting
                holder.visit_documents += it.visit_documents
                holder.valueSum += it.valueSum
                holder.returnSum += it.returnSum
                holder.valueSumSec += it.valueSumSec
                holder.returnSumSec += it.returnSumSec
                holder.total_visit += it.total_visit
                holder.comments = if (it.comments.isNotEmpty()) it.comments else holder.comments
            }
            if (allClients) {
                getAllClient(true).first.forEach {
                    if (!result.containsKey(it.id)) {
                        result[it.id] = ClientVisitationHolder(
                            it.id,
                            from = DatesManipulator.getStartWeek(fromDate),
                            to = DatesManipulator.getEndWeek(toDate),
                            placeHolder = true
                        )
                    }
                }

            }
            if (dates_number.isEmpty()) {
                dates_number.add(fromDate)
            }
            return Respone(Pair(result.values.toList(), dates_number), DBQuery.SUCCESS)
        }
        return Respone(null, dbQuery)
    }

    override suspend fun getClientsDebts(
        ids: List<Int>?, fromDate: String, toDate: String
    ): Respone<List<ClientDebt>, DBQuery> {
        val response = adapter.getClientsDebts(
            ids = ids?.joinToString(","), fromDate = fromDate, toDate = toDate
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            val q = buildClientsDailyData(ids = ids, date = toDate, dailyFinder = true)
        }
        return Respone(data, dbQuery)
    }

    suspend fun getSuppliersDebts(
        ids: List<Int>?, fromDate: String, toDate: String
    ): Respone<List<ClientDebt>, DBQuery> {
        val response = adapter.getClientsDebts(
            ids = ids?.joinToString(","), fromDate = fromDate, toDate = toDate, sup = true
        )

        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun getClientPayDetails(
        ids: List<Int>?, fromDate: String?, toDate: String?, paid: Boolean?
    ): Respone<List<PaymentData>, DBQuery> {
        val response = adapter.getClientPayDetails(ids = ids?.joinToString(","),
            fromDate = fromDate,
            toDate = toDate,
            paid = paid?.let { if (paid) "1" else "0" })
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    override suspend fun getOptimizedClientNote(
        ent_id: List<Int>?,
        delivery_id: Int?,
        fromDate: String?,
        toDate: String?,
        delivery_ids: List<Int>?,
        open: Boolean?,
        full: Boolean,
        order: Boolean
    ): Respone<List<Note>, DBQuery> {
        val response = adapter.getOptimizedClientNote(
            ent_id = ent_id?.joinToString(","),
            delivery_ids = delivery_ids?.joinToString(","),
            fromDate = fromDate,
            toDate = toDate,
            open = open?.let { if (open) "1" else "0" },
            byMoney = if (full) "1" else null,
            order = if (order) "1" else null

        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            if (order) _updateClientsOrderNotesMap(data as List<OrderNote>)
            else _updateClientsNotesMap(data as List<DeliveryNote>)
        }

        return Respone(data, dbQuery)
    }

    suspend fun calcQuerySum(
        ids: List<Int>,
        fromDate: String,
        toDate: String,
        title: String,
        issuer: String,
        withOrders: Boolean? = null,
        withNotes: Boolean? = null,
        specific_products: List<Int>? = null,
        seperate: Int? = null,
        byIds: Int? = null
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcQuerySumPdf(
            date_range = DatesHolder("${fromDate}..${toDate}").toString(),
            ids = ids.joinToString(","),
            title = title,
            issuer = issuer,
            withNotes = withNotes?.let { "1" },
            withOrders = withOrders?.let { "1" },
            specific_products = specific_products?.joinToString(","),
            seperate = seperate?.toString(),
            byIds = byIds?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    suspend fun calcDriverOrderSum(
        ids: List<Int>,
        date: String,
        groupType: Int = 0,
        withOrders: Int? = null,
        withNotes: Int? = null,
        specific_products: List<Int>? = null,
        title: String? = null,
        seperate: Int? = null,
        byIds: Int? = null
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcDriverOrderSumPdf(
            date = date,
            ids = ids.joinToString(","),
            groupType = groupType.toString(),
            withNotes = withNotes?.let { it.toString() },
            withOrders = withOrders?.let { it.toString() },
            specific_products = specific_products?.joinToString(","),
            title = title,
            seperate = seperate?.toString(),
            byIds = byIds?.toString()
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    suspend fun calcEDI100(
        tax_id: Int, notes: List<Int>? = null
    ): Respone<ByteArray?, DBQuery> {
        val response = adapter.calcEDI100(
            tax_id = tax_id.toString(),
            notes = notes?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        return Respone(data, dbQuery)
    }

    @myName("convertUnitToInt")
    fun convertUnitToInt(unit: String): Int {
        return WrappedName.convertFrom(unit)?.state ?: 0

    }

    @myName("deliveryNoteAssignRealPrice")
    fun deliveryNoteAssignRealPrice(
        isClient: Boolean,
        doc: Note,
    ): List<ProductDelivery> {
        //only in full
        var ent_id = doc.ent_id
        if (ent_id == -1) return doc.delivery_info
        val c = if (isClient) UINKDBInterface.activeDB.getClient(ent_id).first!!
        else UINKDBInterface.activeDB.getSupplier(ent_id).first!!
        val note_used: Byte = if (doc.isUsed()) 1 else 0
        val withTax = c.getIncludeTax(doc.date) == 1
        return doc.delivery_info.map {
            val copy = it.doCopy()
            val date =
                if (doc.date <= unclosed_date && doc.getConnectedDocType() == 10) DatesManipulator.dateNowNoHour() else doc.date
            var p = pdUsePrice(it, c, date, note_used, false)
            var price: Float
            var discount: Float = p!!.third
            price = if (withTax) {
                p.second
            } else {
                p.first
            }
            copy.price = price
            copy.priceBefore = p.first
            copy.priceAfter = p.second

            copy.discount = discount

            copy


        }
    }

    @myName("TaxNoteAssignRealPrice")
    fun TaxNoteAssignRealPrice(
        doc: ClientTaxNote, sup: Boolean = false
    ): List<ProductDelivery> {
        //only in full
        var ent_id = doc.client_id
        val c =
            if (sup) UINKDBInterface.activeDB.getSupplier(ent_id).first!! else UINKDBInterface.activeDB.getClient(ent_id).first!!

        val withTax = c.getIncludeTax(doc.date) == 1
        return doc.productDeliveries.map {
            val copy = it.doCopy()
            var p = pdPriceFromTaxNote(it, c, doc.date)
            var price: Float
            var discount: Float = p.third
            price = if (withTax) {
                p.second
            } else {
                p.first
            }
            copy.price = price
            copy.priceBefore = p.first
            copy.priceAfter = p.second
            copy.discount = discount
            copy


        }
    }

    @JsName("toGroupAggregate")
    fun toGroupAggregate(
        data: List<DataQueryHolder>,
        byYear: Boolean,
        byMonth: Boolean,
        byWeek: Boolean,
        byDay: Boolean,
        byEnt: Boolean,
        byProduct: Boolean,
        byAgent: Boolean
    ): List<DataQueryHolder?> {
        return DataQueryHolder.toGroupAggregate(data, byYear, byMonth, byWeek, byDay, byEnt, byProduct, byAgent)
    }

    @JsName("dataQueryAggregate")
    fun dataQueryAggregate(
        data: List<DataQueryHolder>, mayBy: Boolean = false
    ): DataQueryHolder {
        return data.reduceIndexed { index, acc, dataQueryHolder ->
            if (index == 1) return@reduceIndexed acc.copy().increase(dataQueryHolder!!, mayBy)
            acc.increase(dataQueryHolder!!, mayBy)
        }
    }

    suspend fun updateAllClientNotes(
        delivery_ids: List<Int>,
        active: List<Int>? = null,
        paid: List<Int>? = null,
        order_id: List<Int>? = null,
        pdf: List<Int>? = null,
        pds: List<List<ProductDelivery>>? = null,
        full: Boolean = false
    ): Respone<List<DeliveryNote>, DBQuery> {
        val curOrders = if (paid != null) {
            val n = buildClientNotes(delivery_ids = delivery_ids, full = true)
            if (n.second != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)
            }
            n.first.groupBy { it.delivery_id }
        } else null
        val data: MutableList<JsonObject> = mutableListOf()
        delivery_ids.forEachIndexed { index, i ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            map["delivery_id"] = JsonPrimitive(i.toString())

            active?.get(index)?.let {
                map["active"] = JsonPrimitive(it.toString())
            }
            pds?.get(index)?.let {
                map["delivery_value"] = JsonPrimitive(ProductDelivery.toJsonArrayString((it)))
            }
            paid?.get(index)?.let {
                map["paid"] = JsonPrimitive(it.toString())
                val dv = curOrders!!.get(i)!!.first()
                val pds = dv.delivery_info
                val c = dv.getEnt(true)
                pds.forEach { pd ->
                    if (pd.use_price == (0).toByte()) {
                        val priceMenu = c.getPrice(pd.productId)!!
                        val prePrice = priceMenu.get(dv.date)
                        pd.price = prePrice.first
                        pd.discount = prePrice.second
                        pd.use_price_tax_note = ProductDelivery.createUsedState(
                            pd.getProduct().getNoTaxProduct(dv.date),
                            c.getNoTaxClient(dv.date),
                            c.getIncludeTax(dv.date),
                            false
                        )
                    }

                }
                map["delivery_value"] = JsonPrimitive(ProductDelivery.toJsonArrayString(pds))
            }
            pdf?.get(index)?.let {
                map["pdf"] = JsonPrimitive(it.toString())
            }
            order_id?.get(index)?.let {
                map["order_id"] = JsonPrimitive(it.toString())
            }
            data.add(JsonObject(map))
        }

        val response = adapter.updateAllClientNotes(
            date = "", data = "[${data.joinToString(",")}]", agent = "", date_issued = DatesManipulator.dateNow()
        )

        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            dataR.forEach { _updateClientNoteForToday(it.ent_id, it) }
            _updateClientsNotesMap((dataR))
            if (full)
                buildFullClientNotes(dataR)
        }
        return Respone(dataR, dbQuery)
    }

    suspend fun newAllClientNotes(
        ids: List<Int>,
        pds: List<List<ProductDelivery>>,
        date: String,
        agent: String,
        order_id: List<Int?>? = null,
        notes: List<String>? = null,
        notes2: List<String>? = null,
        notes3: List<String>? = null,
        notes4: List<String>? = null,
        notes5: List<String>? = null,
        delivery_ids: List<Int>? = null,
        increase_id: Int? = null,
        withTaxNotes: Boolean = false,
        agent_id: List<Int?>? = null,
        round: Boolean = false,
        withSave: Boolean = true
    ): Respone<List<InformationBase>, DBQuery> {
        val masterMapper: MutableMap<Int, MutableMap<Int, Int>> = mutableMapOf()

        if (withTaxNotes) {
            val fetchIds = ids.toMutableList()
            ids.forEach {
                if (getClient(it).first?.master != -1)
                    fetchIds.add(getClient(it).first?.master!!)
            }
            val curOrders =
                buildClientPrices(
                    id = fetchIds.distinct(),
                    fromDate = date,
                    toDate = date,
                    master = true
                ) // add specific ids
            if (curOrders.second != DBQuery.SUCCESS) {
                // protection of double note by trying to fetch and check good network
                return Respone(listOf(), DBQuery.FAILED)
            }
            val mapperX = curOrders.first.map { Pair(it.id, it.product_id) to it }.toMap()
            ids.forEach {
                val c = getClient(it).first!!
                val pricesHere = c.getActivePrices(date)
                pricesHere.forEach {
                    val p = mapperX[Pair(c.masterBranch?.id ?: c.id, it.product_id)]
                    if (p?.master != null && p.master > 0) {
                        val map = masterMapper.getOrPut(c.id) { mutableMapOf() }
                        map[it.product_id] = p.master
                    }

                }

            }
//            curOrders.first.forEach {
//                if (it.master != null && it.master > 0) {
//                    val map = masterMapper.getOrPut(it.id) { mutableMapOf() }
//                    map[it.product_id] = it.master
//                }
//
//            }
        }

        val oIds = order_id?.map { it ?: -1 }
        val n = if (order_id != null) buildClientNotes(
            client_ids = ids,
            fromDate = date,
            toDate = date,
            orders_ids = oIds,
            full = true
        ).first.filter { it.isActive() } else listOf()
        val t = if (order_id != null) buildClientTaxNote(
            client_ids = ids,
            order_ids = oIds,
            fromDate = date,
            toDate = date,
            full = true
        ).first.filter { it.isActive() && it.taxNoteType != TaxNoteType.CANCEL } else listOf()

        val orderIdTakenCare: MutableSet<Int> = mutableSetOf()
        n.forEach {
            if (it.order_id > 0)
                orderIdTakenCare.add(it.order_id)
        }
        t.forEach {
            if (it.order_id > 0)
                orderIdTakenCare.add(it.order_id)
        }


        val data: MutableList<JsonObject> = mutableListOf()
        val dataTN: MutableList<JsonObject> = mutableListOf()
        val updatePrices: MutableList<RawPrice> = mutableListOf()
        val priceChange = (getUser().first?.note_price ?: 0) > 0
        ids.forEachIndexed { index, i ->
            val client = getClient(i).first!!
            val oId = order_id?.get(index)
            if (oId != null && orderIdTakenCare.contains(oId))
                return@forEachIndexed
            if (withTaxNotes && getClient(i).first?.getNotes() == 0) {
                dataTN.addAll(
                    newClientTaxNoteWithSplitPrepare(
                        i,
                        DatesManipulator.dateNow(),
                        date,
                        pds[index].filter { !it.empty() }.map {
                            val masterProductId =
                                masterMapper.get(client.id)?.get(it.productId)?.let { pp ->
                                    if (idToClientProduct.containsKey(pp)) pp else null
                                } ?: it.productId
                            val pd1 = it.doCopy(id = masterProductId)

                            val price = client.getPrice(it.productId)!!.get(date)
                            pd1.discount = price.second
                            if (it.price == 0f) {
                                pd1.price = price.first
                            } else {
                                updatePrices.add(
                                    RawPrice(
                                        client.masterBranch?.id ?: client.id,
                                        it.productId,
                                        pd1.price,
                                        date,
                                        pd1.discount
                                    )
                                )
                            }
                            if (it.getProduct().getNoTaxProduct() == 1) {
                                pd1.use_price = 1;
                            } else if (client.getIncludeTax() == 0) {
                                pd1.use_price = 2;
                            } else if (client.getIncludeTax() == 1) {
                                pd1.use_price = 3;
                            }
                            pd1
                        },
                        ClientStaticData(
                            client!!.id,
                            if (getCompany() == "galrazit" && notes5?.get(index)
                                    ?.isNotEmpty() == true
                            ) notes5.get(index) else client.getBusinessName()!!,
                            client.getBusinessId()!!,
                            client.getAddress()!!,
                            client.getPhone()!!
                        ),
                        client.getDiscount(),
                        0,
                        false,
                        notes?.get(index),
                        agent,
                        order_id?.get(index),
                        round,
                        agent_id = agent_id?.get(index),
                        notes2 = notes2?.get(index),
                        notes3 = notes3?.get(index),
                        notes4 = notes4?.get(index),
                        notes5 = notes5?.get(index)
                    ).first
                )
            } else {
                val save = getUser().first?.savePriceInNote() == true && withSave
                val pd = pds[index].filter { !it.empty() }.map {
                    var pdd = it
                    if (it.price != 0f) {
                        val price = client.getPrice(it.productId)!!.get(date)

                        if (save) {
                            pdd = pdd.doCopy()
                            pdd.price = it.price
                            pdd.discount = if (it.discount != 0f) it.discount else price.second
                            if (it.getProduct().getNoTaxProduct() == 1 || client.getNoTaxClient(date) == 1) {
                                pdd.use_price = 1;
                            } else if (client.getIncludeTax() == 0) {
                                pdd.use_price = 2;
                            } else if (client.getIncludeTax() == 1) {
                                pdd.use_price = 3;
                            }
                            printPlat(pdd)
                        }
                        if (price.first != it.price) {
                            updatePrices.add(
                                RawPrice(
                                    client.masterBranch?.id ?: client.id,
                                    it.productId,
                                    it.price,
                                    date,
                                    it.discount
                                )
                            )
                        }
                    }
                    val masterProductId = masterMapper.get(client.id)?.get(it.productId)?.let { pp ->
                        if (idToClientProduct.containsKey(pp)) pp else null
                    } ?: it.productId
                    val pd1 = pdd.doCopy(id = masterProductId)
                    pd1
                }
                val groups = pd.map { it.getProduct().getSplitCategory() }.distinct()
                if (groups.size == 1) {
                    val curPd = ProductDelivery.toJsonArrayString(pd)
                    val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                    map["id"] = JsonPrimitive(i.toString())
                    delivery_ids?.get(index)?.let {
                        map["delivery_id"] = JsonPrimitive(it.toString())
                    }
                    notes?.get(index)?.let {
                        map["notes"] = JsonPrimitive(prepareStrToJson(it))
                    }
                    notes2?.get(index)?.let {
                        map["notes2"] = JsonPrimitive(prepareStrToJson(it))
                    }
                    notes3?.get(index)?.let {
                        map["notes3"] = JsonPrimitive(prepareStrToJson(it))
                    }
                    notes4?.get(index)?.let {
                        map["notes4"] = JsonPrimitive(prepareStrToJson(it))
                    }
                    notes5?.get(index)?.let {
                        map["notes5"] = JsonPrimitive(prepareStrToJson(it))
                    }
                    agent_id?.get(index)?.let {
                        map["agent_id"] = JsonPrimitive(it.toString())
                    }
                    map["delivery_value"] = JsonPrimitive(curPd)
                    increase_id?.let { map["increase_id"] = JsonPrimitive(increase_id.toString()) }
                    order_id?.get(index)?.let {
                        map["order_id"] = JsonPrimitive(it.toString())
                    }
                    data.add(JsonObject(map))
                } else if (groups.size > 1) {
                    groups.forEach { group ->
                        val curPd = ProductDelivery.toJsonArrayString(pds[index].filter {
                            !it.empty() && it.getProduct().getSplitCategory() == group
                        })
                        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
                        map["id"] = JsonPrimitive(i.toString())
                        delivery_ids?.get(index)?.let {
                            map["delivery_id"] = JsonPrimitive(it.toString())
                        }
                        notes?.get(index)?.let {
                            map["notes"] = JsonPrimitive(prepareStrToJson(it))
                        }
                        notes2?.get(index)?.let {
                            map["notes2"] = JsonPrimitive(prepareStrToJson(it))
                        }
                        notes3?.get(index)?.let {
                            map["notes3"] = JsonPrimitive(prepareStrToJson(it))
                        }
                        notes4?.get(index)?.let {
                            map["notes4"] = JsonPrimitive(prepareStrToJson(it))
                        }
                        notes5?.get(index)?.let {
                            map["notes5"] = JsonPrimitive(prepareStrToJson(it))
                        }
                        agent_id?.get(index)?.let {
                            map["agent_id"] = JsonPrimitive(it.toString())
                        }
                        map["delivery_value"] = JsonPrimitive(curPd)
                        increase_id?.let { map["increase_id"] = JsonPrimitive(increase_id.toString()) }
                        order_id?.get(index)?.let {
                            map["order_id"] = JsonPrimitive(it.toString())
                        }
                        data.add(JsonObject(map))
                    }
                }

            }

        }
        val ret: MutableList<InformationBase> = mutableListOf()
        if (data.isNotEmpty()) {
            val response = adapter.updateAllClientNotes(
                date = date,
                data = "[${data.joinToString(",")}]",
                agent = agent,
                date_issued = DatesManipulator.dateNow(),
                new = "T",
            )

            val dataR = response.first
            val networkOutput = response.second
            val dbQuery = analyzeResponse(networkOutput, new = true)
            if (dbQuery == DBQuery.SUCCESS) {
                dataR.forEach { _updateClientNoteForToday(it.ent_id, it) }
                _updateClientsNotesMap((dataR))
                calcDeliveryNoteSumMap((dataR))
                ret.addAll(dataR)
            } else {
                return Respone(ret, dbQuery)
            }
        }

        if (dataTN.isNotEmpty()) {
            val response = adapter.newAllClientTaxNotes(
                date = date, data = "[${dataTN.joinToString(",")}]"
            )

            var dataR = response.first
            val networkOutput = response.second
            val dbQuery = analyzeResponse(networkOutput, new = true)
            if (dbQuery == DBQuery.SUCCESS) {
                dataR = hashIsraelImplyMulti(dataR)

                _updateClientsTaxNoteMap((dataR))
                ret.addAll(dataR)
                if (priceChange && updatePrices.isNotEmpty())
                    updateAllClientProductPrice(date, updatePrices, ids = ids)
//                dataR.forEach {
//                    calcClientTaxNote(it.id, it.type)
//                }
//                _updateClientNoteForToday(client_id, dataR.first())
//                _updatePrevCarteset(client_id, document_date, date)
            }


        }

        val s: MutableSet<Pair<Int, Int>> = mutableSetOf()
        ret.forEach {
            s.add(Pair(it.getConnectedId(), it.getConnectedDocType()))
        }
        n.forEach {
            if (!s.contains(Pair(it.id, it.getConnectedDocType())))
                ret.add(it)
        }
        t.forEach {
            if (!s.contains(Pair(it.id, it.getConnectedDocType())))
                ret.add(it)
        }

        return Respone(ret, DBQuery.SUCCESS)
    }

    suspend fun newAllClientOrders(
        ids: List<Int>,
        pds: List<List<ProductDelivery>>,
        date: String,
        agent: String,
        categories: List<String>? = null,
        status: List<OrderStatus>? = null,
        notes: List<String>? = null,
        notes2: List<String>? = null,
        notes3: List<String>? = null,
        notes4: List<String>? = null,
        notes5: List<String>? = null,
        agent_id: List<Int?>? = null,
        run_over: Boolean = true,
        order_ids: List<Int?>? = null,
        ref_ids: List<String>? = null,
        withHistory: Boolean = false,
        collection: List<List<CollectionObject>>? = null
    ): Respone<List<OrderNote>, DBQuery> {
        val curOrders = buildClientOrderNotes(fromDate = date, toDate = date, client_ids = ids) // add specific ids

        if (curOrders.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        val updatePrices: MutableList<RawPrice> = mutableListOf()
        val existingOrders = curOrders.first.groupBy { it.ent_id }
        val data: MutableList<JsonObject> = mutableListOf()
        ids.forEachIndexed { index, i ->
            val client = getClient(i).first
            var curPds = pds[index].map {
                if (it.price != 0f && client != null) {
                    val price = client.getPrice(it.productId)!!.get(date)
                    if (price.first != it.price) {
                        updatePrices.add(
                            RawPrice(
                                client.masterBranch?.id ?: client.id,
                                it.productId,
                                it.price,
                                date,
                                it.discount
                            )
                        )
                    }
                }
                it
            }
            if (status?.get(index) == OrderStatus.REJECTED) {
                curPds = listOf()
            }
            val pd = ProductDelivery.toJsonArrayString(curPds)

            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            val exist = if (run_over) existingOrders[i] else null
            map["id"] = JsonPrimitive(i.toString())
            map["delivery_value"] = JsonPrimitive(pd)

            categories?.get(index)?.let {
                map["category"] = JsonPrimitive(it)
            }
            notes?.get(index)?.let {
                map["notes"] = JsonPrimitive(prepareStrToJson(it))
            }
            notes2?.get(index)?.let {
                map["notes2"] = JsonPrimitive(prepareStrToJson(it))
            }
            notes3?.get(index)?.let {
                map["notes3"] = JsonPrimitive(prepareStrToJson(it))
            }
            notes4?.get(index)?.let {
                map["notes4"] = JsonPrimitive(prepareStrToJson(it))
            }
            notes5?.get(index)?.let {
                map["notes5"] = JsonPrimitive(prepareStrToJson(it))
            }
            status?.get(index)?.let {
                map["active"] = JsonPrimitive(it.state.toString())
            }

            agent_id?.get(index)?.let {
                map["agent_id"] = JsonPrimitive(it.toString())
            }
            ref_ids?.get(index)?.let {
                map["ref_id"] = JsonPrimitive(it)
            }

            var curOrder: OrderNote? = null
            exist?.let { olist ->
                if (order_ids != null) {
                    val order_id = order_ids.get(index)
                    curOrder = olist.find { it.order_id == order_id }
                    order_id?.let { o ->
                        map["order_id"] = JsonPrimitive(o.toString())
                        if (withHistory)
                            map["freeze"] = JsonPrimitive(pd)

                    }

//                        ?: let {
//                        olist.maxByOrNull { it.order_id }?.let { o -> map["order_id"] = JsonPrimitive(o.order_id.toString()) }
//                    }
                } else {
                    curOrder = olist.maxByOrNull { it.order_id }
                    curOrder
                        ?.let { o ->
                            map["order_id"] = JsonPrimitive(o.order_id.toString())
                            if (withHistory)
                                map["freeze"] = JsonPrimitive(pd)
                        }
                }
            }

            var collect: MutableMap<Int, CollectionObject> =
                (curOrder?.collectionObject?.toMutableMap() ?: mutableMapOf()).toMutableMap()
            collection?.get(index)?.let {
                printPlat(it)
                it.forEach {
                    collect[it.id] = it
                }
            }

            val needAutoCollection = agents.filter { (it.is_collector == 5) }

            if (needAutoCollection.isNotEmpty()) {
                val filled = needAutoCollection
                if (filled.size > 0) {

                    filled.forEach { ag ->
                        val curProductsPds =
                            curPds.filter { it.getProduct().getOrderProduct()?.collector == ag.id }
                        if (curProductsPds.isNotEmpty()) {
                            val boxes = ProductDelivery.kartonEstimate(curProductsPds)
                            collect[ag.id] = CollectionObject(ag.id, boxes)
                        } else {
                            if (collect.containsKey(ag.id))
                                collect.remove(ag.id)
                        }

                    }

                }
            }

            collect?.let {
                map["collection"] = JsonPrimitive(CollectionObject.toJson(it))
            }
            data.add(JsonObject(map))


        }
        val response = adapter.updateAllClientOrders(
            date = date,
            data = "[${data.joinToString(",")}]",
            agent = agent,
            date_issued = DatesManipulator.dateNow(),
        )

        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            dataR.forEach { _updateClientOrderNoteForToday(it.ent_id, it) }
            _updateClientsOrderNotesMap((dataR))
            if (updatePrices.isNotEmpty())
                updateAllClientProductPrice(date, updatePrices, ids = ids)
            calcDeliveryNoteSumMap(dataR.filter { it.ent_id > -1 || it.ent_id <= -10 })
        }
        return Respone(dataR, dbQuery)
    }

    suspend fun updateAllClientOrders(
        order_ids: List<Int>,
        categories: List<String>? = null,
        status: List<OrderStatus>? = null,
        notes: List<String>? = null,
        notes2: List<String>? = null,
        notes3: List<String>? = null,
        notes4: List<String>? = null,
        notes5: List<String>? = null,
        agent: String? = null,
        agent_id: List<Int?>? = null,
        date: String? = null,
        pds: List<List<ProductDelivery>>? = null,
        full: Boolean = false
    ): Respone<List<OrderNote>, DBQuery> {
        val data: MutableList<JsonObject> = mutableListOf()

        order_ids.forEachIndexed { index, o ->
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            categories?.get(index)?.let {
                map["category"] = JsonPrimitive(it)
            }
            notes?.get(index)?.let {
                map["notes"] = JsonPrimitive(it)
            }
            notes2?.get(index)?.let {
                map["notes2"] = JsonPrimitive(it)
            }
            notes3?.get(index)?.let {
                map["notes3"] = JsonPrimitive(it)
            }
            notes4?.get(index)?.let {
                map["notes4"] = JsonPrimitive(it)
            }
            notes5?.get(index)?.let {
                map["notes5"] = JsonPrimitive(it)
            }
            status?.get(index)?.let {
                map["active"] = JsonPrimitive(it.state.toString())
            }
            pds?.get(index)?.let {
                map["delivery_value"] = JsonPrimitive(ProductDelivery.toJsonArrayString(it))
            }
            date?.let {
                map["date"] = JsonPrimitive(it.toString())
            }
            agent_id?.get(index)?.let {
                map["agent_id"] = JsonPrimitive(it.toString())
            }
            map["order_id"] = JsonPrimitive(o.toString())
            data.add(JsonObject(map))
        }

        val response = adapter.updateAllClientOrders(
            date = "",
            data = "[${data.joinToString(",")}]",
            agent = agent ?: "",
            date_issued = DatesManipulator.dateNow(),
        )

        val dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            dataR.forEach { _updateClientOrderNoteForToday(it.ent_id, it) }
            _updateClientsOrderNotesMap((dataR))
            if (full)
                buildFullClientOrderNotes(dataR)
        }
        return Respone(dataR, dbQuery)
    }

    suspend fun calcSupOptimizedCartesetValue(
        ids: List<Int>? = null,
        toDate: String? = null,
        toDateTime: String? = null
    ): DBQuery {
        var clients = buildSuppliers(ids).first.toMutableList()

        val response = adapter.getSupOptimizedCartesetValue(
            ids = ids?.let { clients.map { it.id }.joinToString(",") }, toDate = toDate, toDateTime = toDateTime
        )
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {

            val d = DatesManipulator.dateNowNoHour()
            clients.forEach { clientDebtWall.remove(it.id) }
            val debts = response.first.groupBy {
                val c = getSupplier(it.id).first!!
                Pair(c.id, false)
            }
            clients.groupBy {
                val c = getSupplier(it.id).first!!
                Pair(c.id, false)
            }.forEach {
                buildClientDebtWall(
                    client_id = it.key.first,
                    taxs = null,
                    pays = null,
                    debt = debts[it.key],
                    default_date = toDate ?: d
                )

            }
        }
        return dbQuery

    }

    suspend fun calcOptimizedCartesetValue(
        ids: List<Int>? = null,
        toDate: String? = null,
        toDateTime: String? = null
    ): DBQuery {
        var clients = buildClients(ids).first.toMutableList()
        val branches = clients.map { it.branch }.distinct().filter { it != -1 }
        if (ids != null && branches.isNotEmpty()) {
            val branch_clients = buildClients(branches = branches).first
            clients.addAll(branch_clients)
            clients = clients.distinctBy { it.id }.toMutableList()
        }


        val response = adapter.getOptimizedCartesetValue(
            ids = ids?.let { clients.map { it.id }.joinToString(",") }, toDate = toDate, toDateTime = toDateTime
        )
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {

            val d = DatesManipulator.dateNowNoHour()
            clients.forEach { clientDebtWall.remove(it.id) }
            val debts = response.first.groupBy {
                val c = getClient(it.id).first!!
                if (c.hasBranch()) {
                    Pair(c.branch, true)

                } else {
                    Pair(c.id, false)
                }
            }
            clients.groupBy {
                val c = getClient(it.id).first!!
                if (c.hasBranch()) {
                    Pair(c.branch, true)

                } else {
                    Pair(c.id, false)
                }
            }.forEach {
                if (it.key.second) {
                    val temp_clients = getBranchMembers((it.key.first), false).first.first()
                    buildClientDebtWall(
                        client_id = temp_clients.masterBranch?.id ?: temp_clients.id,
                        taxs = null,
                        pays = null,
                        debt = debts[it.key],
                        default_date = toDate ?: d
                    )
                } else {
                    buildClientDebtWall(
                        client_id = it.key.first,
                        taxs = null,
                        pays = null,
                        debt = debts[it.key],
                        default_date = toDate ?: d
                    )
                }

            }
        }
        return dbQuery

    }

    suspend fun isLocal(): Boolean {
        return adapter.isLocal()
    }

    suspend fun buildLogo(cofc: Int? = null): Respone<ByteArray?, DBQuery> {
        val response = adapter.userLogo(cofc?.toString())
        val data = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            logo = data
        }
        return Respone(data, dbQuery)
    }

    suspend fun reportError(data: String, agent: String, version: String): DBQuery {
        val response = adapter.reportError(data, agent, version)
        println(response)
        val networkOutput = response
        return analyzeResponse(networkOutput)
    }

    suspend fun copyPriceFromClientAll(
        from: Int,
        to: List<Int>?,
        dateFrom: String,
        toDate: String?,
        datePrice: String
    ): DBQuery {
        val q = buildClientPrices(id = listOf(from), fromDate = datePrice, toDate = datePrice)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        val cc = buildClientPrices(id = to, fromDate = dateFrom, toDate = dateFrom)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        val client_prices = getClient(from).first!!.getActivePrices(datePrice)
        val clients = to?.map { getClient(it).first!! } ?: getAllClient(true).first
        val cq = clients.map { client ->

            client_prices.map {
                RawPrice(
                    client.id,
                    it.product_id,
                    it.get(datePrice).first,
                    dateFrom,
                    client.getPrice(it.product_id)?.get(dateFrom)?.second ?: 0f
                )
            }

        }.flatten()
        val y = cq
        return updateAllClientProductPrice(
            date = dateFrom,
            toDate = toDate,
            ids = to,
            product_ids = getAllClientProduct(true).first.map { it.id },
            prices = cq
        )
    }

    suspend fun copyPriceFromClient(
        from: Int,
        to: Int,
        dateFrom: String,
        toDate: String?,
        datePrice: String
    ): DBQuery {
        val q = buildClientPrices(id = listOf(from), fromDate = datePrice, toDate = datePrice)
        if (q.second != DBQuery.SUCCESS) {
            return q.second
        }
        val client_prices = getClient(from).first!!.getPrices()
        return updateAllClientProductPrice(date = dateFrom,
            toDate = toDate,
            ids = listOf(to),
            product_ids = getAllClientProduct(true).first.map { it.id },
            prices = client_prices.map {
                RawPrice(
                    to, it.product_id, it.get(datePrice).first, dateFrom, it.get(datePrice).second
                )
            })
    }

    fun createTNProductize(
        client: Client,
        pds: List<ProductDelivery>,
        date: String,
        round: Boolean,
        issueDate: String,
        details: ClientStaticData,
        discount_percent: Float?,
        type: Int,
        agent: String?,
        external_details: String?,
        order_id: Int?,
        sumForRound: Float? = null,
        sumForRoundBefore: Float? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: Int? = null,
    ): Triple<MutableMap<String, JsonElement>, Float, Float>? {
        val calcMap = calcClientProductDeliverySumMap(pds, client.id, date, true, force_price_from_tn = true)
        val totalVal = calcMap.first[-1]!!

        val productTax = totalVal.taxPaid
        var valBefore = totalVal.totalValue - productTax

        val discount_percent = discount_percent ?: 0f
        val discount_mult = 1 - discount_percent / 100
        var final_val: Float = roundToDecimals((discount_mult * totalVal.totalValue), 4)

        val data = ClientTaxNoteData(
            totalVal.totalValue, 1, ProductDelivery.toJsonArrayString(pds!!), productTax
        )
        var datas = mutableListOf(data)
        if (final_val < 0f) {
            return null
        }
        if (round) {
            val p = calculateRoundingTax(
                valBefore + (sumForRoundBefore ?: 0f), final_val + (sumForRound ?: 0f), discount_percent
            )
            val round_tax = p.second
            val round_val = p.first
            if (round_val != 0f) {
                valBefore += round_val - round_tax
                final_val = roundToDecimals((discount_mult * (totalVal.totalValue + round_val)), 2)
                val addStr = if (round_val < 0) "הנחת עיגול" else "עיגול סכום"
                val round_data = ClientTaxNoteData(
                    round_val, FillTaxNoteType.FREE.state, addStr, round_tax
                )
                datas.add(round_data)
            }
        }
        val map: MutableMap<String, JsonElement> = mutableMapOf()
        map["client_id"] = JsonPrimitive(client.id.toString())
        map["date"] = JsonPrimitive(issueDate)
        map["document_date"] = JsonPrimitive(date)
        map["value"] = JsonPrimitive(valBefore)
        map["total_value"] = JsonPrimitive(final_val)
        map["details"] = JsonPrimitive(ClientStaticData.toJsonArrayString(details))
        map["value_data_raw"] = JsonPrimitive(ClientTaxNoteData.toJsonArrayString(datas).toString())
        map["cover_dates"] = JsonPrimitive(date)
        map["cover_date_start"] = JsonPrimitive(date)
        map["cover_date_end"] = JsonPrimitive(date)
        map["discount_percent"] = JsonPrimitive(discount_percent)
        map["withProduct"] = JsonPrimitive("1")
        map["type"] = JsonPrimitive(type.toString())
        map["payment_data_raw"] = JsonPrimitive("[]")
        if (increase_id != null)
            map["increase_id"] = JsonPrimitive(increase_id.toString())
        notes2?.let { map["notes"] = JsonPrimitive(prepareStrToJson(notes2)) }
        notes3?.let { map["notes3"] = JsonPrimitive(prepareStrToJson(notes3)) }
        notes4?.let { map["notes4"] = JsonPrimitive(prepareStrToJson(notes4)) }
        notes5?.let { map["notes5"] = JsonPrimitive(prepareStrToJson(notes5)) }
        agent_id?.let { map["agent_id"] = JsonPrimitive(agent_id.toString()) }
        order_id?.let { map["order_id"] = JsonPrimitive(order_id.toString()) }
        external_details?.let { map["external_details"] = JsonPrimitive(prepareStrToJson(external_details)) }
        agent?.let { map["agent"] = JsonPrimitive(agent.toString()) }

        return Triple(map, final_val, valBefore)
    }

    fun getSplitFromProducts(
        client: Client,
        delivery_value: List<ProductDelivery>,
        date: String,
        use_note: Byte = 0,
        dv: Boolean = false,
        save_in_dv: Boolean = false
    ): Triple<Float, Triple<Boolean, Float, Float>, List<ProductDelivery>> {
        var distribute = 0f
        val realProducts = mutableListOf<ProductDelivery>()
        val canSplit = delivery_value.all { it.getProduct().getNoTaxProduct() == 1 }
        val canSplit2 = delivery_value.all { it.getProduct().getNoTaxProduct() != 1 }
        var taxPaid = 0f
        var total = 0f
        delivery_value.forEach {
            val bp = it.getProduct().getBasePrice()

            var price_before: Float
            var discount: Float
            var final_price: Float

            if (dv) {
                val price = pdUsePrice(it, client, date, use_note)!!
                discount = price.third
                price_before = price.first / (1 - discount / 100)

            } else {
                val price = pdPriceFromTaxNote(it, client, date)
                discount = price.third
                price_before = price.first
            }
            final_price = price_before * (1 - discount / 100)
            val curValue =
                (it.value + it.wrapped_amount * it.conversion_ratio - it.returns - it.wrapped_amount_return * it.conversion_ratio)
            if (bp > 0 && final_price > bp && (canSplit || canSplit2)) {
                val pd1 = it.doCopy()
                pd1.price = bp
                pd1.discount = 0f
                pd1.use_price = if (dv && !save_in_dv) 0 else if (it.getProduct().getNoTaxProduct() == 1) 1 else 2
                pd1.use_price_tax_note = if (dv) (if (it.getProduct().getNoTaxProduct() == 1) 1 else 2) else 0
                realProducts.add(pd1)

                total += curValue * bp
                taxPaid += if (it.getProduct()
                        .getNoTaxProduct() == 1
                ) 0f else curValue * bp * tax.getPercentDivided(
                    date
                )
                distribute += curValue * (final_price - bp)
            } else {
                total += curValue * final_price
                taxPaid += if (it.getProduct()
                        .getNoTaxProduct() == 1
                ) 0f else curValue * final_price * tax.getPercentDivided(date)
                val pd1 = it.doCopy()
                pd1.price = price_before
                pd1.discount = discount
                pd1.use_price = if (dv && !save_in_dv) 0 else if (it.getProduct().getNoTaxProduct() == 1) 1 else 2
                pd1.use_price_tax_note = if (dv) (if (it.getProduct().getNoTaxProduct() == 1) 1 else 2) else 0
                realProducts.add(pd1)
            }
        }
        return Triple(distribute, Triple(canSplit, total, taxPaid), realProducts)

    }

    suspend fun hashIsraelImplyMulti(dataR: List<ClientTaxNote>): List<ClientTaxNote> {
        if (user?.hesh_israel == 1) {
            val curData = mutableListOf<ClientTaxNote>()
            dataR.forEach {
                if (it.total_value >= TAX_ISRAEL_LIMIT) {
                    val r = hashIsraelSign(it.id, it.type)
                    if (r.first != null)
                        curData.add(r.first!!)
                    else
                        curData.add(it)
                } else {
                    curData.add(it)
                }
            }
            return curData
        }
        return dataR

    }

    suspend fun newClientTaxNoteDVWithBranchSplit(
        document_date: String, deliveryNotes: List<DeliveryNote>, agent: String, discount_percent: Float? = null,

        external_details: String? = null, doRound: Boolean = false, splitBranch: Boolean = false,
        agent_id: Int? = null,
        order_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: Int? = null,
    ): Respone<List<String>, DBQuery> {
        var data = mutableListOf<JsonObject>()
        val finalDVS: MutableList<DeliveryNote> = mutableListOf()
        val clients: MutableList<Int> = mutableListOf()
        val errors: MutableList<String> = mutableListOf()
        val dvData: MutableList<Pair<Int, List<DeliveryNote>>> = mutableListOf()
        val m: MutableMap<String, JsonElement> = mutableMapOf()

        deliveryNotes.map {
            getClientDeliveryNotes(delivery_id = it.delivery_id).first.first()
        }.filter { it.isActive() && it.paid == 0 }.groupBy { (it.getEnt(true) as Client).branch }
            .forEach { outMap ->
                if (outMap.key >= 0) {


                    var master = outMap.value.get(0).getEnt(true) as Client
                    master = if (master.master == -1) master else master.masterBranch!!
                    if (splitBranch || master.net_split == 1) {
                        outMap.value.groupBy { it.ent_id }.forEach { inMap ->
                            dvData.add(Pair(inMap.key, inMap.value))
                        }
                    } else {
                        dvData.add(Pair(master.id, outMap.value))
                    }

                } else {
                    outMap.value.groupBy { it.ent_id }.forEach { inMap ->
                        dvData.add(Pair(inMap.key, inMap.value))
                    }

                }

            }
        if (dvData.isEmpty()) return Respone(errors, DBQuery.FAILED)
        dvData.forEach {
            val client = getClient(it.first).first!!
            val q = allNotesWithPrice(it.second)
            if (q.isNotEmpty()) {
                errors.addAll(q)
                return@forEach
            }
            val d = createTaxNoteDataFromNotesCalculation(it.first, it.second, date = document_date)

            if (d == null) {
                errors.add("${client.getName()} - נכשל בהנפקה")
                return@forEach
            }
            if (d.first.value <= 0) {
                errors.add("${client.getName()} - סכום שלילי או 0 ")
                return@forEach
            }
            val clientStaticData = ClientStaticData(
                client.id,
                client.getBusinessName()!!,
                client.getBusinessId()!!,
                client.getAddress()!!,
                client.getPhone()!!
            )
            val finalDiscount = discount_percent ?: client.getDiscount(document_date)
            if ((user?.tax_note_split_dv ?: 0) > 0 && (user?.tax_note_split
                    ?: 0) > 0
            ) {
                //handle splitting
                val spliter = newClientTaxNoteDVWithSplit(
                    client.id,
                    document_date,
                    document_date,
                    it.second,
                    clientStaticData,
                    finalDiscount,
                    0,
                    agent,
                    external_details = external_details,
                    round = doRound,
                    notes2 = notes2,
                    notes3 = notes3,
                    notes4 = notes4,
                    notes5 = notes5,
                    agent_id = agent_id,
                    order_id = order_id,
                    increase_id = increase_id,
                )
                val d = spliter.first.first
                data.addAll(d)
                m.putAll(spliter.first.second)
                return@forEach
            }


            val dh = DatesHolder(it.second.map { it.date }.distinct())
            var total_value = d.first.value
            var total_tax = d.third.second
            var total_before = roundToDecimals(total_value - total_tax, 4);

            var roundTax: Float;
            var roundValue: Float;
            val taxData: MutableList<ClientTaxNoteData> = mutableListOf(d.first)
            val secondData = d.third.third
            if (secondData != null) {
                total_value += secondData.value
                total_tax += secondData.taxPaid!!
                total_before = roundToDecimals(total_value - total_tax, 4);
                taxData.add(secondData)
            }

            var total_value_after_discount = total_value * (1 - finalDiscount / 100);


            if (doRound) {
                var out =
                    calculateRoundingTax(total_before, total_value_after_discount, finalDiscount, (document_date));
                roundTax = roundToDecimals(out.second, 4);
                total_tax += roundTax;
                total_before = roundToDecimals(total_before + out.first - out.second, 4);
                roundValue = out.first
                if (roundValue != 0f) {
                    val addStr = if (roundValue < 0) "הנחת עיגול" else "עיגול סכום"
                    val round_data = ClientTaxNoteData(
                        roundValue, FillTaxNoteType.FREE.state, addStr, roundTax
                    )
                    taxData.add(round_data)
                }
            }
            var preValue = total_before;
            total_tax *= (1 - finalDiscount / 100);
            total_before *= (1 - finalDiscount / 100);
            total_value_after_discount = (total_tax + total_before);


            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
            map["client_id"] = JsonPrimitive(client.id.toString())
            map["date"] = JsonPrimitive(DatesManipulator.dateNow())
            map["document_date"] = JsonPrimitive(document_date)
            map["value"] = JsonPrimitive(preValue)
            map["total_value"] = JsonPrimitive(total_value_after_discount)
            map["details"] = JsonPrimitive(ClientStaticData.toJsonArrayString(clientStaticData))
            map["value_data_raw"] = JsonPrimitive(ClientTaxNoteData.toJsonArrayString(taxData).toString())
            map["cover_dates"] = JsonPrimitive(dh.toStringFirstLastRange())
            if (increase_id != null)
                map["increase_id"] = JsonPrimitive(increase_id.toString())
            map["cover_date_start"] = JsonPrimitive(dh.getFirst())
            map["cover_date_end"] = JsonPrimitive(dh.getLast())
            map["discount_percent"] = JsonPrimitive(finalDiscount)
            map["type"] = JsonPrimitive("0")
            map["payment_data_raw"] = JsonPrimitive("[]")
            external_details?.let { map["external_details"] = JsonPrimitive(prepareStrToJson(external_details)) }
            notes2?.let { map["notes2"] = JsonPrimitive(prepareStrToJson(notes2)) }
            notes3?.let { map["notes3"] = JsonPrimitive(prepareStrToJson(notes3)) }
            notes4?.let { map["notes4"] = JsonPrimitive(prepareStrToJson(notes4)) }
            notes5?.let { map["notes5"] = JsonPrimitive(prepareStrToJson(notes5)) }
            agent_id?.let { map["agent_id"] = JsonPrimitive(agent_id.toString()) }
            order_id?.let { map["order_id"] = JsonPrimitive(order_id.toString()) }


            agent.let { map["agent"] = JsonPrimitive(agent.toString()) }
            data.add(JsonObject(map))
            finalDVS.addAll(it.second)
            it.second.let {
                it.forEach { n ->
                    m[n.delivery_id.toString()] = JsonPrimitive(ProductDelivery.toJsonArrayString(n.delivery_info))
                }

            }
            clients.add(client.id)
        }
        val deliveryNotesValuesMap = JsonObject(m).toString()
        if (data.isEmpty()) return Respone(errors, DBQuery.SUCCESS)
        val response = adapter.newAllClientTaxNotes(
            date = document_date,
            data = "[${data.joinToString(",")}]",
            deliveryNotesValuesMap = deliveryNotesValuesMap
        )

        var dataR = response.first
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        if (dbQuery == DBQuery.SUCCESS) {
            dataR = hashIsraelImplyMulti(dataR)

            _updateClientsTaxNoteMap((dataR))
            buildClientNotes(delivery_ids = deliveryNotes.map { it.id })
            dataR.forEach {
                calcClientTaxNote(it.id, it.type)
            }
            clients.forEach {
                _updatePrevCarteset(it, document_date, DatesManipulator.dateNow())
            }
        }
        return Respone(errors, dbQuery)
    }

    suspend fun newClientTaxNoteDVWithSplit(
        client_id: Int,
        date: String,
        document_date: String,
        deliveryNotes: List<DeliveryNote>,
        details: ClientStaticData,
        discount_percent: Float?,
        type: Int,
        agent: String,
        external_details: String?,
        round: Boolean,
        order_id: Int? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: Int? = null,
    ): Respone<Pair<List<JsonObject>, Map<String, JsonElement>>, DBQuery> {
        val client = getClient(client_id).first!!
        var distribute = 0f
        var canSplit = false
        var total: Float = 0f
        var taxPaid: Float = 0f
        val values: MutableList<Pair<Float, Float>> = mutableListOf()
        val newDvs: MutableList<List<ProductDelivery>> = mutableListOf()
        val needSaveInNote = (user?.tax_note_split_dv ?: 0) == 2
        deliveryNotes.forEach {
            val pair = getSplitFromProducts(
                client,
                it.delivery_info,
                it.date,
                it.isUsed().toInt().toByte(),
                true,
                needSaveInNote
            )

            newDvs.add(pair.third)
            distribute += pair.first
            val valu = pair.second
            canSplit = valu.first
            total += valu.second
            taxPaid += valu.third
            values.add(Pair(valu.second, valu.third))
        }


        val dh = DatesHolder(deliveryNotes.map { it.date }.distinct())

        val note_data = deliveryNotes.mapIndexed { index: Int, deliveryNote: DeliveryNote ->
            "${deliveryNote.delivery_id}:${
                ((values[index].first + values[index].second).roundToDecimals(4))
            }:${values[index].second}"
        }.joinToString(",") // סכוםכולל  מע''מ
        val valueData = ClientTaxNoteData(
            total.roundToDecimals(4) + taxPaid, 0, note_data, taxPaid
        )
        val finalDiscount = discount_percent ?: 0f
        val final_value = roundToDecimals((taxPaid + total) * (1 - finalDiscount / 100), 3)
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["client_id"] = JsonPrimitive(client.id.toString())
        map["date"] = JsonPrimitive(date)
        map["document_date"] = JsonPrimitive(document_date)
        map["value"] = JsonPrimitive(total)
        map["total_value"] = JsonPrimitive(final_value)
        map["details"] = JsonPrimitive(ClientStaticData.toJsonArrayString(details))
        map["value_data_raw"] = JsonPrimitive(ClientTaxNoteData.toJsonArrayString(listOf(valueData)).toString())
        map["cover_dates"] = JsonPrimitive(dh.toStringFirstLastRange())
        map["cover_date_start"] = JsonPrimitive(dh.getFirst())
        map["cover_date_end"] = JsonPrimitive(dh.getLast())
        if (increase_id != null)
            map["increase_id"] = JsonPrimitive(increase_id.toString())
        map["discount_percent"] = JsonPrimitive(finalDiscount)
        map["type"] = JsonPrimitive(type.toString())
        map["payment_data_raw"] = JsonPrimitive("[]")
        external_details?.let { map["external_details"] = JsonPrimitive(prepareStrToJson(external_details)) }
        agent.let { map["agent"] = JsonPrimitive(agent.toString()) }
        agent_id?.let { map["agent_id"] = JsonPrimitive(agent_id.toString()) }
        order_id?.let { map["order_id"] = JsonPrimitive(order_id.toString()) }
        notes2?.let { map["notes2"] = JsonPrimitive(prepareStrToJson(notes2)) }
        notes3?.let { map["notes3"] = JsonPrimitive(prepareStrToJson(notes3)) }
        notes4?.let { map["notes4"] = JsonPrimitive(prepareStrToJson(notes4)) }
        notes5?.let { map["notes5"] = JsonPrimitive(prepareStrToJson(notes5)) }

        val distributeValue = if (distribute > 0) {
            ProductDelivery(
                productId = user?.tax_note_split!!,
                value = 1f,
                price = distribute,
                use_price = if (canSplit) 1 else 2
            )
        } else {
            null
        }
        var total_sum = final_value
        var data = mutableListOf<JsonObject>()
        data.add(JsonObject(map))
        if (distributeValue != null) {
            val triple2 = createTNProductize(
                client,
                listOf(distributeValue),
                document_date,
                round,
                date,
                details,
                finalDiscount,
                type,
                agent,
                external_details,
                order_id,
                sumForRound = final_value,
                sumForRoundBefore = total,
                notes2 = notes2,
                notes3 = notes3,
                notes4 = notes4,
                notes5 = notes5,
                agent_id = agent_id,

                )
            total_sum += triple2!!.second
            triple2.first["external_details"] = JsonPrimitive("סכום כולל משולב ${roundToDecimals(total_sum, 2)}")
            map["external_details"] = JsonPrimitive("סכום כולל משולב ${roundToDecimals(total_sum, 2)}")
            data.add(JsonObject(triple2.first))
        }
        val m: MutableMap<String, JsonElement> = mutableMapOf()
        deliveryNotes.let {
            it.forEachIndexed { index, n ->
                m[n.delivery_id.toString()] = JsonPrimitive(ProductDelivery.toJsonArrayString(newDvs[index]))
            }

        }
        return Respone(Pair(data, m), DBQuery.SUCCESS)
    }

    suspend fun newClientTaxNoteWithSplitPrepare(
        client_id: Int,
        date: String,
        document_date: String,
        delivery_value: List<ProductDelivery>,
        details: ClientStaticData,
        discount_percent: Float?,
        type: Int,
        with_price: Boolean,
        external_details: String?,
        agent: String,
        order_id: Int?,
        round: Boolean,
        fastPay: PaymentData? = null,
        agent_id: Int? = null,
        notes2: String? = null,
        notes3: String? = null,
        notes4: String? = null,
        notes5: String? = null,
        increase_id: Int? = null,
    ): Respone<List<JsonObject>, DBQuery> {
        val client = getClient(client_id).first!!
        var first = delivery_value.map { it.doCopy() }
        var distributeValue: ProductDelivery? = null
        if (with_price) {

            delivery_value.forEach {
                it.price = if (getClient(client_id).first!!.getIncludeTax(document_date) == 1 || it.getProduct()
                        .getNoTaxProduct(document_date) == 1 || getClient(client_id).first!!.getNoTaxClient(
                        document_date
                    ) == 1
                ) it.price else roundToDecimals(
                    it.price / tax.get(
                        document_date
                    ), 2
                )
            }
            var ent_to_change_id = client_id
            if (getClient(client_id).first!!.master != -1) {
                ent_to_change_id = getClient(client_id).first!!.master
            }
            val q = changePriceFromProductDelivery(
                delivery_value, ent_to_change_id, document_date, "client"
            ) //cur date price since this is products only
            if (q != DBQuery.SUCCESS) {
                return Respone(listOf(), DBQuery.FAILED)

            }
        }
        if ((user?.tax_note_split ?: 0) > 0) {
            // פיצול מבסיס
            val realProducts = mutableListOf<ProductDelivery>()

            var distribute = 0f
            val canSplit = first.all { it.getProduct().getNoTaxProduct() == 1 }
            val pair = getSplitFromProducts(client, first, date)
            first = pair.third
            distribute = pair.first
            distributeValue = if (distribute > 0) {
                ProductDelivery(
                    productId = user?.tax_note_split!!,
                    value = 1f,
                    price = distribute,
                    use_price = if (canSplit) 1 else 2
                )
            } else {
                null
            }

        }
        val dateNow = DatesManipulator.dateNow()
        val triple1 = createTNProductize(
            client,
            first,
            document_date,
            round && distributeValue == null,
            dateNow,
            details,
            discount_percent ?: 0f,
            type,
            agent,
            external_details,
            order_id = order_id,
            agent_id = agent_id,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5,
            increase_id = increase_id,
        )
        if (triple1 == null) return Respone(listOf(), DBQuery.FAILED)
        val data: MutableList<JsonObject> = mutableListOf()

        if (distributeValue != null) {
            val triple2 = createTNProductize(
                client,
                listOf(distributeValue),
                document_date,
                round,
                dateNow,
                details,
                discount_percent ?: 0f,
                type,
                agent,
                external_details,
                order_id,
                sumForRound = triple1.second,
                sumForRoundBefore = triple1.third,
                notes2 = notes2,
                notes3 = notes3,
                notes4 = notes4,
                notes5 = notes5,
                agent_id = agent_id,
                increase_id = increase_id

            )
            if (triple2 == null) return Respone(listOf(), DBQuery.FAILED)
            val total_sum = triple1.second + triple2.second
            if (fastPay != null && abs(fastPay.value - total_sum) < 0.1f) {
                triple1.first["type"] = JsonPrimitive("1")
                triple2.first["type"] = JsonPrimitive("1")
                triple1.first["payment_data_raw"] = fastPay.copy(triple1.second).toJson()
                triple2.first["payment_data_raw"] = fastPay.copy(triple2.second).toJson()
            }
            triple1.first["external_details"] = JsonPrimitive("סכום כולל משולב ${roundToDecimals(total_sum, 2)}")
            triple2.first["external_details"] = JsonPrimitive("סכום כולל משולב ${roundToDecimals(total_sum, 2)}")
            data.add(JsonObject(triple1.first))
            data.add(JsonObject(triple2.first))
        } else {
            if (fastPay != null && abs(fastPay.value - triple1.second) < 0.1f) {
                triple1.first["type"] = JsonPrimitive("1")
                triple1.first["payment_data_raw"] =
                    JsonPrimitive("[${fastPay.toJson().toString()}]")
            }
            data.add(JsonObject(triple1.first))
        }
        return Respone(data, DBQuery.SUCCESS)
    }

    override suspend fun newClientTaxNoteWithSplit(
        client_id: Int,
        date: String,
        document_date: String,
        delivery_value: List<ProductDelivery>,
        details: ClientStaticData,
        discount_percent: Float?,
        type: Int,
        with_price: Boolean,
        external_details: String?,
        agent: String,
        order_id: Int?,
        round: Boolean,
        fastPay: PaymentData?,
        agent_id: Int?,
        notes2: String?,
        notes3: String?,
        notes4: String?,
        notes5: String?,
        increase_id: Int?
    ): Respone<List<InformationBase>, DBQuery> {
        val data = newClientTaxNoteWithSplitPrepare(
            client_id,
            date,
            document_date,
            delivery_value,
            details,
            discount_percent,
            type,
            with_price,
            external_details,
            agent,
            order_id,
            round,
            fastPay,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5,
            agent_id = agent_id,
            increase_id = increase_id
        ).first
        val response = adapter.newAllClientTaxNotes(
            date = date, data = "[${data.joinToString(",")}]"
        )

        var dataR = response.first.toMutableList()
        dataR = hashIsraelImplyMulti(dataR).toMutableList()
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput, new = true)
        var ret: MutableList<InformationBase> = mutableListOf()
        ret.addAll(dataR)
        if (dbQuery == DBQuery.SUCCESS) {
            _updateClientsTaxNoteMap((dataR))
            _updateClientNoteForToday(client_id, dataR.first())
            dataR.forEach {
                calcClientTaxNote(it.id, it.type)
            }
            if (fastPay != null && abs(fastPay.value - dataR.sumByDouble { it.total_value.toDouble() }
                    .toFloat()) >= 0.1f) {
                val p = newClientPay(
                    client_id,
                    date,
                    document_date,
                    fastPay.value,
                    details,
                    listOf(fastPay),
                    tax_note_id = dataR.map { it.id }.joinToString(","),
                    agent = agent
                )
                if (p.second == DBQuery.SUCCESS)
                    ret.add(p.first!!)

            }
            _updatePrevCarteset(client_id, document_date, date)
        }

        return Respone(ret, dbQuery)

    }

    override suspend fun sendEmail(
        f: ByteArray, email: String, title: String, doc_type: Int?, doc_id: Int?
    ): DBQuery {
        val response = adapter.sendEmail(f, email, title, doc_type?.toString(), doc_id?.toString())
        val networkOutput = response
        var dbQuery = analyzeResponse(networkOutput)
        return dbQuery
    }

    override suspend fun sendReqForApi(
        doc_type: Int,
        doc_id: Int,
        api_method: Int?,
    ): DBQuery {
        val response = adapter.sendReqForApi(doc_type.toString(), doc_id.toString(), api_method?.toString())
        val networkOutput = response
        var dbQuery = analyzeResponse(networkOutput)
        return dbQuery
    }

    override suspend fun loadXlsx(
        f: ByteArray, load_type: String, date: String?, type: String?, load_doc_id: Boolean?
    ): Respone<String?, DBQuery> {
        val response =
            adapter.loadXlsx(f, load_type, date, type?.toString(), load_doc_id?.let { if (it) "1" else null })

        var dbQuery = analyzeResponse(response.second)
        return Respone(response.first, dbQuery)
    }

    @myName("checkValidForDv")
    suspend fun checkValidForDv(pds: List<ProductDelivery>): Boolean {
        return pds.all { !it.empty() }
    }

    @myName("getGeoNames")
    suspend fun updateGeoNames(
        clients: List<Int>, force: Boolean = false
    ): Respone<List<Int>?, DBQuery> {
        var clients_addresses = clients.mapNotNull {
            val c = getClient(it).first
            return@mapNotNull c
        }
        if (!force) {
            clients_addresses = clients.mapNotNull {
                val c = getClient(it).first
                c?.location?.let { loc ->
                    val l = loc.split(",")
                    if (l.size < 2) return@mapNotNull c
                    return@mapNotNull null
                }
            }
        }
        clients_addresses = clients_addresses.mapNotNull {
            it.getAddressStr().let { loc ->

                if (loc.isEmpty()) return@mapNotNull null
                return@mapNotNull it
            }
        }
        val date = DatesManipulator.minDay(DatesManipulator.dateNowNoHour())
        val failed = mutableListOf<Int>()
        clients_addresses.forEach { c ->
            val address = c.getAddressStr()
            if (address.isEmpty()) {
                failed.add(c.id)
                return@forEach
            }
            var q = adapter.getGeoName(c.getAddressStr(), c.getCity().isNull())
            var dbQuery = analyzeResponse(q.second)
            if (dbQuery != DBQuery.SUCCESS || !q.first.second) {
                val tryAdd = if (c.getCity().isNotEmpty()) c.getCity() else if (c.getStreet()
                        .isNotEmpty()
                ) c.getStreet() else null
                if (tryAdd != null) {
                    val q2 = adapter.getGeoName(tryAdd)
                    dbQuery = analyzeResponse(q2.second)
                    if (dbQuery == DBQuery.SUCCESS) {
                        q = q2
                    }

                }

            }
            if (dbQuery != DBQuery.SUCCESS || updateClient(
                    c.id, date = date, location = q.first.first
                ).second != DBQuery.SUCCESS
            ) {
                failed.add(c.id)
            }

        }

        return Respone(failed, DBQuery.SUCCESS)

    }

    @myName("getGeoLocations")
    fun getGeoLocations(
        clients: List<Int>
    ): Respone<List<String>, DBQuery> {

        return Respone(clients.map {
            getClient(it).first?.location?.isNotEmpty(true, getClient(it).first?.getAddressStr() ?: "") ?: ""
        }, DBQuery.SUCCESS)

    }

    suspend fun splitDriversClientsToCollectors(
        driver_id: List<Int>,
        collector_id: List<Int>,
        client_id: List<Int>,
        date: String,
        maxBoxSize: Int,
        inverseToCollectors: Boolean = false,
        save: Boolean = false
    ): Respone<List<ClientDailyData>, DBQuery> {
        val clientsCall = buildClients(ids = client_id, fromDate = date, toDate = date)
        val orderCall = buildClientOrderNotes(client_ids = client_id, fromDate = date, toDate = date)
        val dailyCall = buildClientsDailyData(ids = client_id, driver_id = driver_id, date = date)
        val agentCall = buildAgents()
        if (clientsCall.second != DBQuery.SUCCESS || dailyCall.second != DBQuery.SUCCESS || agentCall.second != DBQuery.SUCCESS || orderCall.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        val clients = clientsCall.first.groupBy { it.id }
        val daily = dailyCall.first
        val agents = agentCall.first.filter { it.isDriver() }.groupBy { it.id }
        val groups = daily.groupBy { it.driver_id }
        val orders = orderCall.first.groupBy { it.ent_id }
        val dailyClientMap = daily.groupBy { it.id }
        var curCollectorBox: MutableList<MutableList<List<ClientDailyData>>> =
            MutableList(collector_id.size) { mutableListOf() }

        val orderSign = if (inverseToCollectors) -1 else 1
        var curList: MutableList<OrderNote> = mutableListOf()
        var curSize = 0f
        var curBox = 0
        var curCollector = 0
        driver_id.forEach {
            val cdaily = groups[it]?.sortedBy { it.position }
            val agent = agents[it]
            val curOrders = cdaily?.map { orders[it.id] ?: listOf() }?.flatten()
            curOrders?.forEach {
                val box = ProductDelivery.kartonEstimate(it.delivery_info)
                if (curSize + box < maxBoxSize) {
                    curList.add(it)
                    curSize += box
                } else {
                    curCollectorBox[curCollector].add(curList.distinctBy { it.ent_id }
                        .mapIndexed { index: Int, orderNote: OrderNote ->
                            dailyClientMap[orderNote.ent_id]!!.first()
                                .copy(
                                    collector_id = collector_id[curCollector],
                                    position_col = orderSign * (index + 1),
                                    group_id = curBox
                                )
                        })
                    curList.clear()
                    curSize = box
                    curList.add(it)
                    curBox += 1
                    curCollector = (curCollector + 1) % (collector_id.size)
                }
            }
            if (curList.size > 0) {
                curCollectorBox[curCollector].add(curList.distinctBy { it.ent_id }
                    .mapIndexed { index: Int, orderNote: OrderNote ->
                        dailyClientMap[orderNote.ent_id]!!.first()
                            .copy(
                                collector_id = collector_id[curCollector],
                                position_col = orderSign * (index + 1),
                                group_id = curBox
                            )
                    })
                curList.clear()
                curSize = 0f
                curBox += 1
                curCollector = (curCollector + 1) % (collector_id.size)
            }
        }


        val res = curCollectorBox.flatten().flatten()
        return if (save) {
            val saver = newClientAllDailyData(date = date, res)
            Respone(res, saver.second)
        } else {
            Respone(res, DBQuery.SUCCESS)
        }

    }

    suspend fun buildOptimizeRouteByDrivers(
        client_id: List<Int>,
        date: String,
        save: Boolean = false,
        start_point: String? = null,
        end_point: String? = null
    ): Respone<List<ClientDailyData>, DBQuery> {
        val clientsCall = buildClients(ids = client_id)
        val dailyCall = buildClientsDailyData(ids = client_id, date = date)
        val agentCall = buildAgents()
        if (clientsCall.second != DBQuery.SUCCESS || dailyCall.second != DBQuery.SUCCESS || agentCall.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        val clients = clientsCall.first.groupBy { it.id }
        val daily = dailyCall.first
        val agents = agentCall.first.filter { it.isDriver() }.groupBy { it.id }
        val groups = daily.groupBy { it.driver_id }

        val doneClient: MutableSet<Int> = clients.keys.toMutableSet()
        val result: MutableMap<Int, List<ClientDailyData>> = mutableMapOf()
        groups.forEach {
            val driver = agents[it.key]?.getOrNull(0)

            val start_point = if (driver?.start_point?.isNullOrEmpty() == true) start_point else driver?.start_point
            val end_point = if (driver?.end_point?.isNullOrEmpty() == true) end_point else driver?.end_point
            val curPath = it.value
            val optimizeRoute = getOptimizedRoute(curPath.map { it.id }, true, start_point, end_point)
            if (optimizeRoute.second == DBQuery.SUCCESS) {
                doneClient.removeAll(curPath.map { it.id })
                result[it.key] = optimizeRoute.first!!.mapIndexed { index: Int, oR: Int ->
                    val cd = curPath[oR]
                    cd.copy(position = index)
                }
            }
        }
        val res = result.values.flatten()
        return if (save) {
            val saver = newClientAllDailyData(date = date, res)
            Respone(res, saver.second)
        } else {
            Respone(res, DBQuery.SUCCESS)
        }

    }

    @myName("getOptimizedRoute")
    suspend fun getOptimizedRoute(
        clients: List<Int>, updateGeo: Boolean = false, start_point: String? = null, end_point: String? = null
    ): Respone<List<Int>?, DBQuery> {
        if (updateGeo) {
            updateGeoNames(clients)
        }
        val clients_addresses: MutableList<Pair<Int, Location>> = mutableListOf()
        val cannotOrder: MutableList<Int> = mutableListOf()
        clients.forEachIndexed { index, it ->
            val c = getClient(it).first
            c?.location?.let { loc ->
                val l = loc.split(",")
                if (l.size < 2) {
                    cannotOrder.add(index)
                } else {
                    clients_addresses.add(
                        Pair(
                            index,
                            Location(
                                lon = l[1],
                                lat = l[0],
                                city = c.getCity(),
                                street = c.getStreet(),
                                streetNumber = ""
                            )
                        )
                    )
                }
            }
        }


        val resp = adapter.getOptimizedRoutes(clients_addresses.map { it.second }, start_point?.let {
            Location(
                lon = start_point.split(",")[1],
                lat = start_point.split(",")[0],
                city = "",
                street = "",
                streetNumber = ""
            )

        }, end_point?.let {

            Location(
                lon = end_point.split(",")[1],
                lat = end_point.split(",")[0],
                city = "",
                street = "",
                streetNumber = ""
            )

        })
        var res = resp.first?.toMutableList()
        val dbQuery = analyzeResponse(resp.second)
        if (dbQuery == DBQuery.SUCCESS) {
            if (start_point != null) {
                res = res!!.map { it - 1 }.toMutableList()
            }
            res = res!!.map { clients_addresses[it].first }.toMutableList()
            res.addAll(cannotOrder)

        }
        return Respone(res, dbQuery)

    }


    @JsName("createTaxNoteDataFromNotesCalculation")
    fun createTaxNoteDataFromNotesCalculation(
        id: Int, deliveryNotes: List<DeliveryNote>, sup: Boolean = false, date: String
    ): Triple<ClientTaxNoteData, List<DeliveryNote>, Triple<String, Float, ClientTaxNoteData?>>? {
        val c = if (sup) getSupplier(id).first!! else getClient(id).first!!
        val calculationMapRaw =
            calcDeliveryNoteSumMap(deliveryNotes, ent_type = if (sup) "supplier" else "client", forceTaxDate = date)
        val dh = DatesHolder(deliveryNotes.map { it.date }.distinct())

        deliveryNotes.forEach {
            it.delivery_info.forEach { pd ->
                if (pd.use_price == (0).toByte()) {
                    val priceMenu = c.getPrice(pd.productId)!!
                    val prePrice = priceMenu.get(it.date)
                    pd.price = prePrice.first
                    pd.discount = prePrice.second
                    pd.use_price_tax_note = ProductDelivery.createUsedState(
                        pd.getProduct().getNoTaxProduct(it.date),
                        c.getNoTaxClient(it.date),
                        c.getIncludeTax(it.date),
                        false
                    )
                }

            }
        }
        val note_data = deliveryNotes.map {
            "${it.delivery_id}:${
                (it.value.roundToDecimals(4))
            }:${it.taxPaid}"
        }.joinToString(",") // סכוםכולל  מע''מ
        return Triple(
            ClientTaxNoteData(
                calculationMapRaw.first[-1]!!.totalValue.roundToDecimals(4),
                0,
                note_data,
                calculationMapRaw.first[-1]!!.taxPaid.roundToDecimals(4)
            ), deliveryNotes, Triple(
                dh.toString(),
                calculationMapRaw.first[-1]!!.taxPaid,
                if (calculationMapRaw.first[-2]!!.totalValue != 0f || calculationMapRaw.first[-2]!!.taxPaid != 0f) ClientTaxNoteData(
                    calculationMapRaw.first[-2]!!.totalValue.roundToDecimals(4),
                    7,
                    "קיזוז להשלמת מעמ",
                    calculationMapRaw.first[-2]!!.taxPaid.roundToDecimals(4)
                ) else null
            )
        )
    }

    @JsName("calculateKartonFromClients")
    fun calculateKartonFromClients(ids: List<Int>): Float {
        val orders = getClientOrderNotes(order_ids = ids).first

        var karton = 0f
        orders.forEach {
            karton += ProductDelivery.kartonEstimate(it.delivery_info)
        }
        return karton
    }

    @JsName("createTaxNoteDataFromFreeValue")
    fun createTaxNoteDataFromFreeValue(
        text: String,
        value: Float,
        withoutMaam: Boolean = false,
        date: String = DatesManipulator.dateNowNoHour(),
        amount: Float? = null
    ): ClientTaxNoteData {
        return if (withoutMaam) ClientTaxNoteData(value, 7, text, taxPaid = 0f, amount)
        else ClientTaxNoteData(
            roundToDecimals(value, 4),
            7,
            prepareStrToJson(text),
            taxPaid = roundToDecimals(value - value / tax.get(date), 4),
            amount = amount
        )
    }

    @JsName("allNotesWithPrice")
    fun allNotesWithPrice(dvs: List<DeliveryNote>): List<String> {

        val issues: MutableList<String> = mutableListOf()
        dvs.forEach {
            val note_used: Byte = if (it.isUsed()) 1 else 0
            val client = it.getEnt(true)
            it.delivery_info.forEach { pd ->
                val p = pd.getProduct()
                var name = p.getName(it.date)
                val prePrice = pdUsePrice(pd, client, it!!.date, note_used, discounted = false)!!
                val price = if (client.getIncludeTax(it!!.date) == 1) prePrice.second else prePrice.first
                if (price == 0f) {
                    issues.add("תעודה: ${it.id}, מוצר :\t${name}")
                }
            }
        }
        return issues
    }


    // offline mode
    open suspend fun buildImagesOffline() {

    }

    open suspend fun buildOfflineBigStuff() {

    }

    open suspend fun freeMemoryApp() {

    }

    open suspend fun buildPricesOffline(id: List<Int>) {

    }

    open suspend fun buildFetchOffline() {

    }

    open suspend fun saveUserOffline() {

    }

    open suspend fun saveUserCofc() {

    }

    open suspend fun savePriceOffline(rawPrices: List<RawPrice>, delete: Boolean = true) {

    }

    open suspend fun saveDaily(delete: Boolean = true) {

    }

    open suspend fun saveLoginInfo(first: Agent) {

    }

    open suspend fun saveClientOffline(clients: List<Client>, delete: Boolean = true) {

    }

    open suspend fun saveAgentsOffline(agents: List<Agent>) {

    }

    open suspend fun saveProductOffline(products: List<Product>, delete: Boolean = true) {

    }

    open fun saveImages(product_id: Int, byteArray: ByteArray) {

    }

//sign

    open fun getOfflineSign(sign: Boolean, id: Int, type: Int): ByteArray? {
        return null
    }

    open fun getManyOfflineSignHolder(ids: List<Int>, type: Int, send: Boolean = false): List<NoteSignHolder> {
        return listOf()
    }

    open fun saveOfflineSign(
        sign: Boolean,
        date: String,
        signer: String,
        id: Int,
        type: Int,
        byteArray: ByteArray
    ): NoteSignHolder? {
        return null
    }

    override suspend fun clearInventory(
        product_id: List<Int>?,
        agent_id: List<Int>?,
    ): DBQuery {
        val response = adapter.clearInventory(
            product_id?.joinToString(","),
            agent_id?.joinToString(","),
        )
        return analyzeResponse(response)
    }

    override suspend fun insertInventory(
        date: String,
        inventoryItems: List<InventoryItem>
    ): DBQuery {
        val x = JsonArray(inventoryItems.map { it.toJson() })
        val response = adapter.insertInventory(
            data = x.toString(),
            date = date
        )

        val networkOutput = response
        var dbQuery = analyzeResponse(networkOutput)
        return dbQuery

    }

    override suspend fun getInventory(
        product_id: List<Int>?,
        agent_id: List<Int>?,
        fromDate: String?,
        toDate: String?,
        inv_type: List<Int>?,
        last: Boolean,
        byEnt: Int
    ): Respone<List<InventoryItem>, DBQuery> {
        val response = adapter.getInventory(
            product_id?.joinToString(","),
            agent_id?.let { if (it.size == 1 && it.first() == -1) null else it }?.joinToString(","),
            toDate = toDate,
            inv_type = inv_type?.joinToString(",")
        )


        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS) {
            return Respone(data, dbQuery)
        }

        if (fromDate != null && toDate != null) {
            val invMap = data.map { Pair(it.product_id, it.agent_id) to it }.toMap().toMutableMap()
            if (last) {
                val lastInv = adapter.getInventory(
                    product_id?.joinToString(","),
                    agent_id?.let { if (it.size == 1 && it.first() == -1) null else it }?.joinToString(","),
                    toDate = DatesManipulator.getNextDay(fromDate, -1),
                    inv_type = inv_type?.joinToString(",")
                ).first.map { Pair(it.product_id, it.agent_id) to it }.toMap().toMutableMap()
                invMap.forEach {
                    val key = it.key
                    val inv = it.value
                    val lastInvItem = lastInv[key]
                    if (lastInvItem != null) {
                        inv.lastInventory = lastInvItem.valueSum
                        inv.lastInventorySec = lastInvItem.valueSumSec
                    }
                }
            }
            var semiInvMap: MutableMap<Pair<Int, Int>, InventoryItem> = mutableMapOf()
            if (fromDate == toDate) {
                val sim = adapter.getInventory(
                    product_id?.joinToString(","),
                    agent_id?.let { if (it.size == 1 && it.first() == -1) null else it }?.joinToString(","),
                    date = toDate,
                    inv_type = "1"
                )
                semiInvMap = sim.first.map { Pair(it.product_id, it.agent_id) to it }.toMap().toMutableMap()
                semiInvMap.forEach {
                    val key = it.key
                    val inv = invMap[key]
                    if (inv != null) {
                        inv.inventoryMatch = it.value.valueSum
                        inv.inventoryMatchSec = it.value.valueSumSec
                    }
                }

            }

            val agents = getAgents().first.map { it.user_name to it }.toMap()

            val q = queryData(
                fromDate,
                toDate,
                product_id ?: listOf(-1),
//                byAgent = agent_id?.mapNotNull { getAgentBy(it, null).first?.user_name },
                byAgentId = agent_id,
            )
            if (q.second != DBQuery.SUCCESS)
                return Respone(data, q.second)
            q.first?.forEach { inv ->
                val a_id = inv?.byAgentId ?: -1
                val key = Pair(inv!!.byProduct!!.toInt(), a_id)
                val invItem = invMap[key]
                val semiInvItem = semiInvMap[key]
                if (invItem != null) {
                    invItem.amountDistribution =
                        (invItem.amountDistribution ?: 0f) + (inv.valueSumSecJoin ?: 0f) + (inv.valueSum ?: 0f)
                    invItem.amountDistribution2 = (invItem.amountDistribution2 ?: 0f) + (inv.valueSumSec ?: 0f)
                    invItem.inventoryMatch = semiInvItem?.valueSum ?: 0f
                    invItem.inventoryMatchSec = semiInvItem?.valueSumSec ?: 0f
                } else {
                    invMap[key] = InventoryItem(

                        a_id,
                        inv.byProduct!!.toInt(),
                        0f,
                        0f,
                        0f,
                        0f,
                        DatesManipulator.dateNow(),
                        0f, 0f,
                        inv.valueSum,
                        inv.valueSumSec,
                        inventoryMatch = semiInvItem?.valueSum ?: 0f,
                        inventoryMatchSec = semiInvItem?.valueSumSec ?: 0f

                    )

                }
            }
            val i = queryData(
                fromDate,
                toDate,
                product_id ?: listOf(-1),
                byEnt = listOf(byEnt),
//                byAgent = agent_id?.mapNotNull { getAgentBy(it, null).first?.user_name },
                doc_type = 10,
                inventory = true,
                byAgentId = agent_id,
            )
            if (i.second != DBQuery.SUCCESS)
                return Respone(data, i.second)
            i.first?.forEach { inv ->
                val a_id = inv?.byAgentId ?: -1
                val key = Pair(inv!!.byProduct!!.toInt(), a_id)
                val invItem = invMap[key]
                val semiInvItem = semiInvMap[key]

                if (invItem != null) {
                    invItem.inventoryStart =
                        (invItem.inventoryStart ?: 0f) + (inv.valueSumSecJoin ?: 0f) + (inv.valueSum ?: 0f)
                    invItem.inventoryStart2 = (invItem.inventoryStart2 ?: 0f) + (inv.valueSumSec ?: 0f)
                    invItem.inventoryMatch = semiInvItem?.valueSum ?: 0f
                    invItem.inventoryMatchSec = semiInvItem?.valueSumSec ?: 0f
                } else {
                    invMap[key] = InventoryItem(
                        a_id,
                        inv.byProduct!!.toInt(),
                        0f,
                        0f,
                        0f,
                        0f,
                        DatesManipulator.dateNow(),
                        inv.valueSum,
                        inv.valueSumSec,
                        0f, 0f,
                        inventoryMatch = semiInvItem?.valueSum ?: 0f,
                        inventoryMatchSec = semiInvItem?.valueSumSec ?: 0f
                    )

                }
            }
            val sup = queryData(
                fromDate,
                toDate,
                product_id ?: listOf(-1),
                byEnt = listOf(byEnt),
//                byAgent = agent_id?.mapNotNull { getAgentBy(it, null).first?.user_name },
                doc_type = 6,

                byAgentId = null,
            )
            if (sup.second != DBQuery.SUCCESS)
                return Respone(data, i.second)
            if (!sup.first.isNullOrEmpty()) {
                buildSupplierProducts()
            }
            sup.first?.forEach { inv ->
                val a_id = -1
                val supP = getSupplierProduct(inv!!.byProduct!!.toInt())
                val clientProduct = supP.first?.connected_product
                if (clientProduct == null || clientProduct == -1) {
                    return@forEach
                }
                val key = Pair(clientProduct, a_id)
                val invItem = invMap[key]
                val semiInvItem = semiInvMap[key]

                if (invItem != null) {
                    invItem.inventoryStart =
                        (invItem.inventoryStart ?: 0f) + (inv.valueSumSecJoin ?: 0f) + (inv.valueSum ?: 0f)
                    invItem.inventoryStart2 = (invItem.inventoryStart2 ?: 0f) + (inv.valueSumSec ?: 0f)
                    invItem.inventoryMatch = semiInvItem?.valueSum ?: 0f
                    invItem.inventoryMatchSec = semiInvItem?.valueSumSec ?: 0f
                } else {
                    invMap[key] = InventoryItem(
                        a_id,
                        clientProduct,
                        0f,
                        0f,
                        0f,
                        0f,
                        DatesManipulator.dateNow(),
                        inv.valueSum,
                        inv.valueSumSec,
                        0f, 0f,
                        inventoryMatch = semiInvItem?.valueSum ?: 0f,
                        inventoryMatchSec = semiInvItem?.valueSumSec ?: 0f
                    )

                }
            }

            return Respone(invMap.values.toList(), dbQuery)
        }

        return Respone(data, dbQuery)
    }

    open suspend fun sendAllOfflineSignHolder(ids: List<Int>? = null, docType: Int? = null) {

    }

    open suspend fun getTaxInfoOffline() {

    }

    override suspend fun sendMsgAll(
        ids: List<Int>,
        msg: String,
        title: String,
        emails: List<String>?,
        phones: List<String>?,
        action_date: String?,
        doc_type: List<Int>?,
        special_arg: String?,
        external_var: List<String>?,
        agent: String?
    ): DBQuery {
        val response = adapter.sendMsgAll(
            ids.joinToString(","),
            msg,
            title,
            emails?.joinToString(","),
            phones?.joinToString(","),
            action_date,
            doc_type?.joinToString(","),
            special_arg = special_arg,
            external_var = external_var?.joinToString(","),
            agent = agent
        )
        val networkOutput = response
        var dbQuery = analyzeResponse(networkOutput)
        return dbQuery
    }

    override suspend fun getProductAvailable(
        ids: List<Int>?,
        product_ids: List<Int>?,
        available: Int?,
    ): Respone<List<Available>, DBQuery> {
        val response = adapter.getProductAvailable(
            ids?.joinToString(","),
            product_ids?.joinToString(","),
            available?.toString()
        )

        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data.forEach {
                clientIdToAvailable.getOrPut(it.id, { HashMap() }).put(it.product_id, it)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun newAllAvailable(
        data: List<Available>,
    ): Respone<List<Available>, DBQuery> {
        val response = adapter.newAllAvailable(
            JsonArray(data.map { it.toJson() }).toString()
        )
        val d = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data.forEach {
                clientIdToAvailable.getOrPut(it.id, { HashMap() }).put(it.product_id, it)
            }
        }

        return Respone(d, dbQuery)
    }

    suspend fun newAllMsgTracker(
        data: List<MsgTracker>,
    ): Respone<List<MsgTracker>, DBQuery> {
        val response = adapter.newAllMsgTracker(
            JsonArray(data.mapNotNull { it.toJson() }).toString()
        )
        val d = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        return Respone(d, dbQuery)
    }

    suspend fun getMsgTracker(
        msg_id: List<Int>? = null,
        fromDate: String? = null,
        toDate: String? = null,
        type: Int? = null,
        send: Int? = null,
        tries: Int? = null,
        validate: Int? = null,
    ): Respone<List<MsgTracker>, DBQuery> {
        val response = adapter.getMsgTracker(
            msg_id?.joinToString(","),
            fromDate,
            toDate,
            type?.toString(),
            send?.toString(),
            tries?.toString(),
            validate?.toString()
        )
        val d = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        return Respone(d, dbQuery)
    }

    @JsName("getClientProductAvailable")
    fun getClientProductAvailable(id: Int, product_id: Int): Available? {
        return clientIdToAvailable[id]?.get(product_id)
    }

    fun getNextAvailableOrderDay(days: List<Int>, date: String): String? {
        if (false && date < DatesManipulator.dateNowNoHour())
            return null
        var orderDate = date
        var day = DatesManipulator.getDayOfWeekIsrael(orderDate)
        val clientDays = days.toSet()
        if (clientDays.isEmpty())
            return null
        var i = 0
        while (i < 7) {
            if (clientDays.contains(day))
                return orderDate
            orderDate = DatesManipulator.getNextDay(orderDate, 1)
            day = (day + 1) % 7

            i++
        }
        if (i == 8)
            return null
        return orderDate
    }

    override suspend fun sendClientApi(doc_id: List<Int>, doc_type: List<Int>): DBQuery {
        val response = adapter.sendClientApi(
            doc_id.joinToString(","),
            doc_type.joinToString(",")
        )
        val networkOutput = response
        var dbQuery = analyzeResponse(networkOutput)
        return dbQuery
    }

    override suspend fun resetWhatsappConn(): DBQuery {
        val response = adapter.resetWhatsappConn()
        val networkOutput = response
        var dbQuery = analyzeResponse(networkOutput)
        return dbQuery
    }

    override suspend fun updateAgent(
        ids: List<Int>,
        user_name: List<String>?,
        password: List<String>?,
        comments: List<String>?,
    ): Respone<List<Agent>, DBQuery> {
        val d = JsonArray(ids.mapIndexed { index, i ->
            val user = user_name?.getOrNull(index)
            val pass = password?.getOrNull(index)
            val comm = comments?.getOrNull(index)
            val map: MutableMap<String, JsonPrimitive> = mutableMapOf()

            map["id"] = JsonPrimitive(i.toString())
            if (user != null)
                map["user_name"] = JsonPrimitive(user)
            if (pass != null)
                map["user_password"] = JsonPrimitive(pass)
            if (comm != null)
                map["comment"] = JsonPrimitive(prepareStrToJson(comm) ?: "")

            JsonObject(map)

        })

        val response = adapter.updateAgent(
            d.toString()
        )
        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            //the rest returns all agents
            agents = data
        }
        return Respone(data, dbQuery)
    }

    override suspend fun getClientCPData(
        ids: List<Int>?,
        product_ids: List<Int>?,
    ): Respone<List<ClientProductData>, DBQuery> {
        val response = adapter.getClientCPData(
            ids?.joinToString(","),
            product_ids?.joinToString(",")
        )
        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            clientProductsData = HashMap()
            data.forEach {
                clientProductsData?.put(Pair(it.client_id, it.product_id), it)
            }
        }
        return Respone(data, dbQuery)
    }

    override suspend fun setClientCPData(
        ids: List<Int>,
        product_ids: List<Int>,
        barcode: List<String?>?,
    ): Respone<List<ClientProductData>, DBQuery> {
        val d = JsonArray(ids.mapIndexed { index, i ->
            ClientProductData(
                i,
                product_ids[index],
                barcode?.getOrNull(index)
            ).toJson()
        })
        val response = adapter.setClientCPData(
            d.toString()
        )
        val data = response.first
        val networkOutput = response.second
        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery == DBQuery.SUCCESS) {
            data.forEach {
                clientProductsData?.put(Pair(it.client_id, it.product_id), it)
            }
        }
        return Respone(data, dbQuery)
    }

    suspend fun updateApiConfig(whatsappInstance: String): Respone<ApiConfig?, DBQuery> {
        if (apiConfig?.whatsappJson != null) {
            val whatsappConf = (apiConfig?.whatsappJson as JsonObject).toMutableMap()
            whatsappConf.get("sender")?.let {
                val sender = (it as JsonObject).toMutableMap()
                sender["instanceId"] = JsonPrimitive(whatsappInstance)
                whatsappConf["sender"] = JsonObject(sender)
            }
            val ret = adapter.updateApiConfig(JsonObject(whatsappConf).toString())
            val dbQuery = analyzeResponse(ret.second)
            if (dbQuery == DBQuery.SUCCESS) {
                apiConfig = ret.first
            }
            return Respone(ret.first, dbQuery)
        }

        return Respone(null, DBQuery.FAILED)
    }

    @myName("getClientCPDataOffline")
    fun getClientCPDataOffline(
        id: Int,
        product_id: Int,
    ): ClientProductData? {
        return clientProductsData?.get(Pair(id, product_id))
    }

    @myName("hashIsraelAuth")
    suspend fun hashIsraelAuth(
        token: String
    ): DBQuery {


        val response = adapter.hashIsraelAuth(
            token
        )
        val networkOutput = response.second

        var dbQuery = analyzeResponse(networkOutput)
        if (dbQuery != DBQuery.SUCCESS)
            return dbQuery
        val json = getJsonWithConfig().parseToJsonElement(response.first!!)
        val access_token = json.jsonObject["access_token"]?.jsonPrimitive?.content
        val refresh_token = json.jsonObject["refresh_token"]?.jsonPrimitive?.content
        if (access_token == null || refresh_token == null)
            return DBQuery.FAILED
        // pushing to db
        val updateQ = adapter.updateHashTaxInvoice(
            access_token,
            refresh_token
        )
        val dbQuery2 = analyzeResponse(updateQ)
        if (dbQuery2 != DBQuery.SUCCESS)
            return dbQuery2

        return dbQuery2


    }

    @myName("hashIsraelSign")
    suspend fun hashIsraelSign(
        id: Int, type: Int
    ): Respone<ClientTaxNote?, DBQuery> {


        val response = adapter.hashIsraelSign(
            id.toString(),
            type.toString()
        )
        val networkOutput = response.second
        val dbQuery = analyzeResponse(networkOutput)
        val data = response.first.firstOrNull()
        return Respone(data, dbQuery)


    }
}

private fun String.isNull(): String? {
    return if (this.isEmpty()) null else this
}

fun Float.roundToDecimals(decimals: Int): Float {
    var dotAt = 1
    repeat(decimals) { dotAt *= 10 }
    val roundedValue = (this * dotAt).roundToLong()
    return (roundedValue / dotAt) + (roundedValue % dotAt).toFloat() / dotAt
}