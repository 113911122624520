import { Avatar, Chip, CircularProgress, Tooltip } from "@mui/material";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { saveColumnState } from "./ag-grid-functions";
import { graph, xslx } from 'assets/images';
import { useDispatch } from "react-redux";
import { SNACKBAR_OPEN } from "../../store/actions";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import HeightIcon from '@mui/icons-material/Height';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ExcelExportModal from "./Excel-export-modal";
import ClearIcon from '@mui/icons-material/Clear';
import {autoSizeAllColumns} from "./ag-table-utils";
import FilterListIcon from '@mui/icons-material/FilterList';
const AgTableActionsBar = ({
    setRowData,
    rowData,
    statusBarData,
    setStatusBarData,
    tableRef,
    setEditMode,
    editMode,
    tableControls,
    setResetTableDialog,
    setEditList,
    editList,
    allowGraph,
    columns,
    setPinnedRowDataCallback,
    filterChange,
    sortChange,
    customHeaderComponents,
    saveDataCallback,
    addDataAction,
    customeCancelEdit,
    gridReady,
    setFilters,
    setIsExternalFilterPresent,
}) => {

    const [searchValue, setSearchValue] = useState<string>("");
    const [tableWidth, setTableWidth] = useState<number>(0);
    const [isFilterSortActive, setActiveFilterSort] = useState<boolean>(false);
    const [openExcelExportModal, setOpenExcelExportModal] = useState<boolean>(false);


    const dispatch = useDispatch();


    const columnState = tableRef && tableRef?.current?.columnApi?.getColumnState();
    React.useEffect(() => {
        const isFilterActive = tableRef?.current?.api?.isAnyFilterPresent();
        if(isFilterActive){
            setFilters(tableRef?.current?.api?.getFilterModel());
        }
        const isSortActive = columnState && columnState?.some((col: any) => col?.sort);
        setActiveFilterSort(isFilterActive || isSortActive);
    }, [filterChange, sortChange])


    const saveCallBack = React.useCallback(
        () => {

                const genDoc = async () => {
                    const edited: any[] = [];
                    tableRef?.current?.api?.forEachNode((node: any, i) => {
                        const rowNodeData = { ...node?.data };
                        if (rowNodeData?.hasBeenEdited) {
                            delete rowNodeData?.hasBeenEdited;
                            edited.push(rowNodeData);
                        }
                    });
                    try {
                        if (saveDataCallback) {
                            const success = await saveDataCallback(edited,editList);
                            if (success && typeof success === "boolean") {
                                setEditMode(false);
                                setStatusBarData({
                                    ...statusBarData,
                                    editedRows: 0
                                });

                                editList.forEach((node) => {
                                    const rowNode = tableRef?.current?.api?.getRowNode(node?.id);
                                    const nodeData = rowNode?.data;
                                    nodeData?.hasBeenEdited && delete nodeData?.hasBeenEdited;
                                    rowNode?.setData(nodeData);
                                });
                                tableRef?.current?.api?.refreshCells({
                                    force: true,
                                    rowNodes: editList?.map((node: any) => tableRef?.current?.api?.getRowNode(node?.id))
                                });
                                setEditList([]);
                                dispatch({
                                    type: SNACKBAR_OPEN,
                                    open: true,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    message: `שורות ${edited.length} נשמרו בהצלחה`,
                                    variant: 'alert',
                                    alertSeverity: 'success'
                                });
                                return
                            } else if(success && typeof success === "string") {
                                dispatch({
                                    type: SNACKBAR_OPEN,
                                    open: true,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    message: success,
                                    variant: 'alert',
                                    alertSeverity: 'error'
                                });
                            } else {
                                dispatch({
                                    type: SNACKBAR_OPEN,
                                    open: true,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    message: `שמירה נכשלה`,
                                    variant: 'alert',
                                    alertSeverity: 'error'
                                });
                            }
                        } else {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                message: `שמירה נכשלה`,
                                variant: 'alert',
                                alertSeverity: 'error'
                            });
                        }

                    } catch (e) {

                        console.log(e)
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                            },
                            message: `שמירה נכשלה`,
                            variant: 'alert',
                            alertSeverity: 'error'
                        });
                    }

                    //----------------------------------------------//
                    //----------------// CALLBACK //----------------//

                    //----------------------------------------------//


                    // setRowData(allRowNodes);
                    // tableRef?.current?.api?.refreshCells({ force: true, rowNodes: editList?.map((row: any) => row?.node) });

                    // tableRef?.current?.api?.forEachNode((node: any, i) => {
                    //     if (rowNodeData?.hasBeenEdited) {
                    //         delete rowNodeData?.hasBeenEdited;
                    //     }
                    // });


                };
                genDoc();
        },
        [editList, tableControls, saveDataCallback]
    );
    const [loading, setLoading] = React.useState<boolean>(undefined);
    React.useEffect(() => {
        if (loading!=undefined && !loading) {
            saveCallBack();
            setLoading(undefined);
        }
    },[loading]);
    const cancelCallBack = React.useCallback(
        () => {
            setEditMode(false);
            setStatusBarData({
                ...statusBarData,
                editedRows: 0
            });
            editList.forEach((node) => {
                const rowNode = tableRef?.current?.api?.getRowNode(node?.id);

                const originalData = {...rowNode?.data} ?? {};
                originalData?.hasBeenEdited && delete originalData?.hasBeenEdited;
                Object.keys(node.data).forEach((key) => {
                    if(key.endsWith("_old")){
                        originalData[key.replace("_old","")] = node.data[key];
                    }

                })
                console.log(originalData)
                rowNode?.setData(originalData);
            });
            setPinnedRowDataCallback();

            if (customeCancelEdit) {
                customeCancelEdit(tableRef.current);
            }
            tableRef?.current?.api?.refreshCells({
                force: true,
                rowNodes: editList?.map((node: any) => node)
            });
            setEditList([]);
        },
        [editList]
    );

    const fitColumnsCallBack = React.useCallback(
        () => {
            autoSizeAllColumns(tableRef.current)
        },
        [tableRef]
    );

    useEffect(() => {
        const tableId = tableControls?.customeId ? tableControls?.customeId : "actual-ag-table";
        const table = document.getElementById(tableId);
        if (table) {
            const tableWidth = table?.offsetWidth;
            setTableWidth(tableWidth);
        }
    }, [tableRef]);

    useEffect(() => {
        const tableId = tableControls?.customeId ? tableControls?.customeId : "actual-ag-table";
        const table = document.getElementById(tableId);
        const handleResize = () => {
            if (table) {
                const tableWidth = table?.offsetWidth;
                setTableWidth(tableWidth);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const resetTableCallBack = React.useCallback(
        () => {
            if (isFilterSortActive) {
                setIsExternalFilterPresent(undefined)
                tableRef?.current?.api?.setFilterModel(null);
                setFilters(tableRef?.current?.api?.getFilterModel());
                tableRef?.current?.columnApi?.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
                tableRef?.current?.api?.refreshClientSideRowModel()
                saveColumnState(tableRef?.current, tableControls, gridReady);
                return;
            }
            setResetTableDialog(true);
        },
        [tableRef, isFilterSortActive]
    );
    return (
        <>
            <div style={{
                position: "relative",
                paddingBottom: "2px",
            }}>
                <div className="selected-highlight">
                    {statusBarData?.selectedRows > 0 ?
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <Tooltip
                                title="נקה בחירות"
                                placement="top"

                                arrow
                                onClick={() => {
                                    setIsExternalFilterPresent(undefined);
                                    tableRef?.current?.api?.deselectAll();
                                }}
                            >
                                <ClearIcon style={{
                                    color: "#d12323",
                                    cursor: "pointer",
                                    fontSize: "1.2rem",
                                    marginBottom: "0.5rem",
                                    marginLeft: "0.5rem",
                                    border: "1px solid #999",
                                    borderRadius: "3px",
                                    backgroundColor: "#fff",
                                }} />
                            </Tooltip>
                            <Tooltip
                                title="פלטור בחירה"
                                placement="top"
                                arrow
                                onClick={() => {
                                    const selectedNodes = tableRef.current?.api.getSelectedNodes();
                                    if (selectedNodes) {
                                        const selectedIds = new Set(Array.from(selectedNodes.map(node => node.id)));
                                        setIsExternalFilterPresent(selectedIds)
                                    }
                                }}
                            >
                                <FilterListIcon style={{
                                    color: "#69d123",
                                    cursor: "pointer",
                                    fontSize: "1.2rem",
                                    marginLeft: "0.5rem",
                                    border: "1px solid #999",
                                    borderRadius: "3px",
                                    backgroundColor: "#fff",
                                }} />
                            </Tooltip>
                        </div>
                       : null
                    }
                    {
                        statusBarData?.selectedRows > 0 && statusBarData?.selectedRows || ""
                    }
                </div>
                <div id="ag-costume-conrols" className='save-order-controls'>
                    <div className="general-search">
                        {
                            customHeaderComponents?.search ? customHeaderComponents?.search(tableRef) : (
                                <Input.Group style={{ width: "100%" }} compact>
                                    <Input
                                        value={searchValue}
                                        className="general-search-input"
                                        placeholder="חפש בכול הטבלה"
                                        onChange={(e) => {
                                            setSearchValue(e.target.value);
                                            tableRef?.current?.api?.setQuickFilter(e.target.value);
                                        }}
                                    />
                                    <Button
                                        className="general-search-button"
                                        onClick={() => {
                                            setSearchValue("");
                                            tableRef?.current?.api?.setQuickFilter("");
                                        }}
                                    >x</Button>
                                </Input.Group>
                            )
                        }
                    </div>
                    <div className="status-bar-div">
                        <span className="status-bar-data">{statusBarData?.selectedRows <= 0 ?
                            `סה"כ שורות: ${statusBarData?.numberOfItems}` :
                            `סה"כ שורות: ${statusBarData?.numberOfItems} / ${statusBarData?.selectedRows || 0}`
                        }
                        </span>
                        {statusBarData?.filteredRows > 0 &&
                            <span>
                                ({statusBarData?.filteredRows} {" "})
                            </span>
                        }
                    </div>
                    <div
                        className="edit-order-controls"
                    >
                        {editList.length > 0 && <>
                            {statusBarData.editedRows !== 0 && <span
                                className="edited-rows-badge"
                                onClick={() => {
                                    const allRowNodes: any = [];
                                    tableRef?.current?.api?.forEachNode((rowNode: any) => {
                                        allRowNodes.push({ ...rowNode });
                                    });

                                    const sortedRowNodes = allRowNodes?.sort((a: any, b: any) => {
                                        if(a?.data?.hasBeenEdited && b?.data?.hasBeenEdited){
                                           return (a?.data?.editPosition ?? 0) - (b?.data?.editPosition ?? 0)
                                        }
                                        return a?.data?.hasBeenEdited ?  -1 : 1;
                                    });
                                    tableRef?.current?.columnApi?.applyColumnState({
                                        defaultState: {
                                            sort: null
                                        }
                                    });
                                    tableRef?.current?.api?.setRowData(sortedRowNodes?.map((rowNode: any) => rowNode?.data));
                                    tableRef?.current?.api?.ensureIndexVisible(0);
                                }}
                            >
                                {statusBarData.editedRows}
                            </span>}
                            {tableWidth < 900 ? (
                                <Tooltip
                                    style={{
                                        display: tableControls?.disableDefaultEditActions ? "none" : "inline-block"
                                    }}
                                    id="ag-grid-save-data-button"
                                    title="שמור"
                                    placement="top"
                                    onClick={() => {
                                        setLoading(true);
                                        setTimeout(() => {
                                            setLoading(false);
                                        },100)

                                    }}
                                >
                                    {
                                        loading ? <CircularProgress size={22} style={{
                                            margin: "0 15px"
                                        }} /> :
                                            <SaveIcon className="control-icon save-icon" />
                                    }
                                </Tooltip>
                            ) : (loading ? <CircularProgress size={22} style={{
                                margin: "0 15px"
                            }} /> :
                                <button
                                    style={{
                                        display: tableControls?.disableDefaultEditActions ? "none" : "inline-block"
                                    }}
                                    id="ag-grid-save-data-button"
                                    className='save-order-button control-button'
                                    color="primary"
                                    onClick={() => {
                                        setLoading(true);
                                        setTimeout(() => {
                                            setLoading(false);
                                        },1000)
                                    }}
                                >
                                    שמור
                                </button>
                            )
                            }
                            {
                                tableWidth < 900 ? (
                                    <Tooltip
                                        title="בטל"
                                        placement="top"
                                        onClick={() => cancelCallBack()}
                                    >
                                        <CancelIcon className="control-icon cancel-icon" />
                                    </Tooltip>
                                ) : (

                                    <button
                                        className='cancel-order-button control-button'
                                        color="primary"
                                        onClick={() => cancelCallBack()}
                                    >
                                        בטל
                                    </button>
                                )
                            }
                        </>
                        }
                    </div>
                    <div>
                        {
                            tableWidth < 900 ? (
                                <Tooltip
                                    title="התאם עמודות"
                                    placement="top"
                                    onClick={() => fitColumnsCallBack()}
                                >
                                    <HeightIcon className="control-icon width-icon" />
                                </Tooltip>
                            ) : (
                                <button
                                    className='reset-table-button control-button'
                                    onClick={() => fitColumnsCallBack()}
                                >
                                    התאם עמודות
                                </button>
                            )
                        }
                        {
                            tableWidth < 900 ? (
                                <Tooltip
                                    title="איפוס טבלה"
                                    placement="top"
                                    onClick={() => resetTableCallBack()}
                                >
                                    <RestartAltIcon className="control-icon reset-icon" />
                                </Tooltip>
                            ) : (
                                <button
                                    style={{
                                        backgroundColor: isFilterSortActive ? 'rgb(255, 247, 158)' : 'inherit',
                                    }}
                                    className='reset-table-button control-button'
                                    onClick={() => resetTableCallBack()}
                                >
                                    {isFilterSortActive ? 'איפוס פילטור' : 'איפוס טבלה'}
                                </button>
                            )
                        }
                    </div>
                    {addDataAction && !addDataAction?.component ?
                        <Tooltip title={addDataAction?.tooltip}
                            onClick={() => addDataAction?.action()}>
                            <Chip icon={<AddBoxOutlinedIcon fontSize={'medium'} />} sx={{
                                borderRadius: "5px",
                                border: "1px solid #000080",
                                fontWeight: "bold",
                                ml: 0.5, mr: 0.5, mb: 0.5, '& .MuiChip-icon': {
                                    color: '#000080',
                                },
                            }} label={addDataAction?.label} variant="outlined" />
                        </Tooltip>
                        : addDataAction?.component(tableRef?.current?.api)
                    }
                    <div style={{
                        position: "relative",
                    }}>
                        <Tooltip id="export-to-excel-icon" onClick={() => {
                            setOpenExcelExportModal(true);
                        }} title='יצא טבלה לאקסל'>
                            <Avatar variant='square'
                                sx={{ cursor: 'pointer', bgcolor: '#f8f8f8', width: '30px', height: '30px', mr: 1 }}
                                src={xslx} />
                        </Tooltip>
                        <ExcelExportModal open={openExcelExportModal} setOpen={setOpenExcelExportModal} tableRef={tableRef} tableControls={tableControls} />
                    </div>
                    {allowGraph && <Tooltip onClick={() => {
                        const graphParams = {
                            cellRange: {
                                rowStartIndex: 0,
                                rowEndIndex: tableRef?.current?.api?.getDisplayedRowCount() - 1,
                                columns: [],
                            },
                            chartType: 'groupedColumn',
                            chartThemeName: 'ag-vivid',
                        };
                        tableRef?.current?.api?.createRangeChart(graphParams);
                    }}
                        title='צור גרף'
                    >
                        <Avatar variant='square'
                            sx={{ cursor: 'pointer', bgcolor: '#f8f8f8', width: '30px', height: '30px', mr: 1 }}
                            src={graph} />
                    </Tooltip>}
                </div>
            </div>
        </>
    )
}

export default React.memo(AgTableActionsBar);
